import React from "react";

import { BadgeProps, BadgeTypeEnum } from "./types";

const Badge = ({ badgeType = BadgeTypeEnum.normal, text }: BadgeProps) => {
  return (
    <div
      className={`
      py-1 px-5 rounded font-medium type-p-sm w-28 text-center
      ${badgeType == BadgeTypeEnum.normal ? "text-grey-01 bg-grey-button-bg" : ""}
      ${badgeType == BadgeTypeEnum.red ? "text-tangerine-01 bg-red-button-bg" : ""}
      ${badgeType == BadgeTypeEnum.green ? "text-green-dark bg-green-button-bg" : ""}
      ${badgeType == BadgeTypeEnum.orange ? "text-yellow-01 bg-red-button-bg" : ""}
      `}
    >
      {text}
    </div>
  );
};

export default Badge;

export const TextPlaceholder = () => {
  return (
    <div className="animate-pulse py-1 px-5 rounded font-medium type-p-sm w-28 text-center text-grey-01 bg-grey-button-bg h-6"></div>
  );
};
