import "./navigation.scss";

import React, { useState } from "react";
import { BrowserRouter as Router, NavLink, Route, Switch } from "react-router-dom";

import chevronUpPrimary from "assets/chevronUpPrimary.svg";
import BurgerIcon from "assets/HamburgerMenu.svg";
import CloseMenuIcon from "assets/MenuClose.svg";
import teller_logo from "assets/teller_logo.svg";
import teller_logo_white from "assets/teller-logo-full-white.svg";

import FullScreenModal from "components/FullScreenModal";
import { NavNFTButton } from "components/NFTButton/NFTButton";
import BR from "components/UI/BR";
import { useUIContext } from "contexts/global/uiContext";
import useModal from "hooks/useModal";
import useDarkNav from "hooks/useDarkNav";
import { NavLoginButton } from "components/LoginButton/LoginButton";
import DashboardNav from "pages/Dashboard/DashboardNav";

interface NavProps {
  to: string;
  name: string;
  className?: string;
  CTA?: () => void;
  darkNav?: boolean;
}

const NavigationLink = ({
  to,
  name,
  className = "",
  CTA = () => null,
  darkNav = false,
}: NavProps) => {
  return (
    <NavLink
      onClick={() => {
        CTA();
      }}
      className={`font-medium menu-button type-p-lg ${className} ${
        darkNav ? "text-white" : "text-green-02"
      }`}
      activeClassName="active"
      to={`/${to}`}
    >
      {name}
    </NavLink>
  );
};

const Navigation = () => {
  const { closeModal, modalOpened, setModalOpened } = useModal();
  const { isBorrowing, setLeaveBorrowProcessModal } = useUIContext();
  const { darkNav } = useDarkNav();

  return (
    <div className="  xs:pt-2 sm:pt-4 md:pt-6 xl:pt-9 lg:pt-7 sm:px-12 md:px-16 xs:mx-4">
      <div className="max-w-7xl mx-auto p-0 nav-bar flex flex-row justify-between items-center flex-wrap">
        <a href="https://teller.finance">
          <img
            alt="Teller logo"
            src={darkNav ? teller_logo_white : teller_logo}
            height="32"
            width="97"
            className="d-inline-block align-top min-w-min"
          />
        </a>
        <div id="responsive-navbar-nav" className="p-0 m-0 justify-end navbar-links">
          <div className="my-6 m-0 px-0 flex  md:py-0 flex-row space-x-4 2xl:space-x-6 items-center">
            {/* <NavigationLink
              className="hidden lg:inline"
              to={isBorrowing ? "borrow" : "deposit"}
              name={"Deposit"}
              CTA={() => {
                setModalOpened(false);
                if (isBorrowing) {
                  setLeaveBorrowProcessModal("deposit");
                }
              }}
            /> */}
            <NavigationLink
              to={isBorrowing ? "borrow" : "liquidity"}
              name={"Manage Liquidity"}
              className="hidden lg:inline"
              CTA={() => {
                setModalOpened(false);
                if (isBorrowing) {
                  setLeaveBorrowProcessModal("liquidity");
                }
              }}
              darkNav={darkNav}
            />
            <NavigationLink
              to={isBorrowing ? "borrow" : "nft-dashboard"}
              name={"Manage NFT"}
              className="hidden lg:inline"
              CTA={() => {
                setModalOpened(false);
                if (isBorrowing) {
                  setLeaveBorrowProcessModal("nft-dashboard");
                }
              }}
              darkNav={darkNav}
            />
            <NavigationLink
              to={isBorrowing ? "borrow" : "dashboard/borrow-repay"}
              name={"Manage Loans"}
              className="hidden lg:inline"
              CTA={() => {
                setModalOpened(false);
                if (isBorrowing) {
                  setLeaveBorrowProcessModal("dashboard/borrow-repay");
                }
              }}
              darkNav={darkNav}
            />
            <NavigationLink
              className="hidden lg:inline"
              to={"borrow"}
              name={"Borrow"}
              CTA={() => {
                setModalOpened(false);
              }}
              darkNav={darkNav}
            />
            <div className="xs:hidden">
              <NavNFTButton />
            </div>
            <div className="xs:hidden">
              <NavLoginButton />
            </div>
            <div
              className="lg:hidden"
              onClick={() => {
                setModalOpened(!modalOpened);
              }}
            >
              {modalOpened ? (
                <img
                  alt="Close Menu Icon"
                  src={CloseMenuIcon}
                  height="12"
                  width="18"
                  className="d-inline-block align-top h-8"
                />
              ) : (
                <img
                  alt="Burger Menu Icon"
                  src={BurgerIcon}
                  height="12"
                  width="18"
                  className="d-inline-block align-top h-8 cursor-pointer"
                />
              )}
            </div>
          </div>

          {modalOpened && (
            <div>
              <MobileNavModal closeModal={closeModal} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navigation;

const MobileNavModal = ({ closeModal }: { closeModal: () => void }) => {
  const isBorrowing = false; //TODO

  const [showDashboardNav, setShowDashboardNav] = useState(false);

  return (
    <FullScreenModal
      onClose={closeModal}
      footerCTA={
        <>
          <div className="sm:hidden">
            <NavNFTButton />
          </div>
        </>
      }
      footerCopy={
        <>
          <div className="sm:hidden">
            <NavLoginButton />
          </div>
        </>
      }
      mainContent={
        <div className="flex flex-1 justify-start content-start flex-col items-start h-max mb-20 space-y-3 w-40 md:mx-auto">
          {/* <NavigationLink
            to={isBorrowing ? "borrow" : "deposit"}
            name={"Deposit"}
            CTA={() => {
              closeModal();
              if (isBorrowing) {
                setLeaveBorrowProcessModal("deposit");
              }
            }}
            className="text-left  "
          /> */}
          <NavigationLink
            to={isBorrowing ? "borrow" : "liquidity"}
            name={"Manage Liquidity"}
            className="text-left"
            CTA={closeModal}
          />
          <NavigationLink
            to={isBorrowing ? "borrow" : "nft-dashboard"}
            name={"Manage NFT"}
            className="text-left"
            CTA={closeModal}
          />

          <div className="flex flex-row justify-center items-center">
            <NavigationLink
              to={isBorrowing ? "borrow" : "dashboard/borrow-repay"}
              name={"Manage Loans"}
              CTA={() => {
                setShowDashboardNav(!showDashboardNav);
              }}
              className="text-left  "
            />
            <div
              onClick={() => {
                setShowDashboardNav(!showDashboardNav);
              }}
              className="cursor-pointer"
            >
              <img
                src={chevronUpPrimary}
                className={`cursor-pointer h-5 inline ${
                  showDashboardNav ? "" : "transform rotate-180"
                }  h-5 w-5 `}
              />
            </div>
          </div>

          {showDashboardNav && (
            <>
              <BR className="ml-3" />
              <div className="">
                <DashboardNav setExpanded={closeModal} isMobile={true} />
              </div>
            </>
          )}
          <NavigationLink to={"borrow"} name={"Borrow"} CTA={closeModal} className="text-left" />
        </div>
      }
    />
  );
};
