import React, { useContext } from "react";

import CollateralAmountSelection from "./BorrowFormStage3FormCollateralAmountSelection";

import copy from "constants/copy.json";
import { BorrowPageContext } from "contexts/borrow/borrowContext";
import TableRow from "components/UI/TableRow";
import BR from "components/UI/BR";
import { roundToFourDecimals } from "utils/helperFunctions";
import { useBNMethods } from "hooks/useBNMethods";
import BigNumber from "bignumber.js";

type ThirdStageProps = {
  page?: string;
};
const BorrowFormStage3Table = ({ page = "borrow" }: ThirdStageProps) => {
  const { borrowRequest, loanTerms, NFTs } = useContext(BorrowPageContext);
  const { loanTerm, loanType, lendWith, collateralWith } = borrowRequest;
  const { interestRate, collateralRatio } = loanTerms[0];
  const pageCopy = copy.pages.borrow.main.form.step4;
  const selectedNFTs = NFTs
    ? NFTs.filter((NFT: any) => {
        return NFT.selected == true;
      })
    : null;
  const showCollateral = Boolean(
    loanType
      ? loanType.toLowerCase() == "secured" || !selectedNFTs
      : Number(collateralRatio) > 0 && !selectedNFTs,
  );

  const yearlyInterestRate = showCollateral
    ? new BigNumber(interestRate).div(100)
    : new BigNumber(0);
  const dailyInterestRate = showCollateral ? yearlyInterestRate.div(365) : new BigNumber(0);

  const collateralBNMethods = useBNMethods(collateralWith);

  const loanAmount = new BigNumber(borrowRequest.loanSize);
  const calc = new BigNumber(loanAmount)
    .times(new BigNumber(interestRate).div(365).div(10000))
    .times(new BigNumber(loanTerm));
  const amountOwed = loanAmount.plus(calc);
  return (
    <div>
      <p className="font-medium mb-4 text-2xl xs:text-xl">Borrow</p>
      <div>{pageCopy.description}</div>
      <div className="border-thin my-5">
        <TableRow title="Daily interest rate">
          <div className="font-medium"> {roundToFourDecimals(dailyInterestRate)} % </div>
        </TableRow>
        <BR />
        <TableRow
          title="Yearly interest rate"
          tooltip_text="The total interest accrued over the duration of your loan term."
        >
          <div className="font-medium"> {roundToFourDecimals(yearlyInterestRate)} % </div>
        </TableRow>
        <BR />
        {showCollateral && (
          <div>
            <TableRow
              title={pageCopy.collateralRatio}
              tooltip_text="Maximum collateral value required to request and be approved for your loan, e.g. 150%."
            >
              <div className="font-medium">{Number(collateralRatio) / 100} % </div>
            </TableRow>
            <BR />
            <TableRow
              title="Liquidation ratio"
              tooltip_text="Loans that fall below the liquidation ratio, or the total value of the outstanding loan, will be automatically liquidated."
            >
              <div className="font-medium">{`${Math.round(
                Number(collateralRatio) / 100 - 15,
              )} %`}</div>
            </TableRow>
            <BR />
          </div>
        )}
        <TableRow
          title="Amount owed"
          tooltip_text="Total amount owed in addition to the principal value of your loan."
        >
          <div className="font-medium">{`${roundToFourDecimals(amountOwed)}`}</div>
        </TableRow>
        <BR />
        <TableRow title={pageCopy.loanSize}>
          <div className="font-medium">{`${roundToFourDecimals(loanAmount)} ${lendWith}`}</div>
        </TableRow>
        <BR />
        <TableRow title={pageCopy.loanTerm}>
          <div className="font-medium">{`${loanTerm} ${loanTerm == 1 ? "day" : "days"}`}</div>
        </TableRow>
        {loanType && (
          <>
            <BR />
            <TableRow title={pageCopy.loanType}>
              <div className="font-medium">{`${loanType}`}</div>
            </TableRow>
          </>
        )}
        {showCollateral && (
          <>
            <BR />
            <TableRow title="Collateral needed">
              <div className="font-medium">{`${roundToFourDecimals(
                collateralBNMethods.convertToString(
                  borrowRequest.collateralInfo.collateralTokensNeeded,
                ),
              )} ${collateralWith}`}</div>
            </TableRow>
          </>
        )}
      </div>
      <div className="border-thin my-4">
        {showCollateral && (
          <>
            <TableRow
              title={pageCopy.collateralAmount}
              tooltip_text="You can increase your collateral amount to reduce liquidation risk."
            >
              <CollateralAmountSelection page={page} />
            </TableRow>
          </>
        )}
        {selectedNFTs != null && selectedNFTs.length > 0 && (
          <>
            <TableRow title="NFTs linked" tooltip_text="NFT IDs linked to this loan request.">
              <div className="font-medium">
                {selectedNFTs
                  ? selectedNFTs.map(function (NFT) {
                      return <ul key={NFT.id}>{NFT.id}</ul>;
                    })
                  : 0}
              </div>
            </TableRow>
          </>
        )}
      </div>
    </div>
  );
};

export default BorrowFormStage3Table;
