import { useBalance } from "hooks/useBalance";

import { useOneDayAPY } from "hooks/useOneDayApy";
import { useSuppliedBalance } from "hooks/useSuppliedBalance";
import { useUserTellerTokenValue } from "hooks/useUserTellerTokenValue";

import React, { useEffect, useState } from "react";
import formatNumberForDisplay from "utils/formatNumberForDisplay";
import wallet_icon from "assets/wallet_Icon.svg";
import percentage_icon from "assets/percentage_Icon.svg";
import CurrencyIcon from "components/UI/CurrencyIcon";

const UserTellerTokenData = (token: any) => {
  // get balance of user's tTokens
  const tellerBalance = useSuppliedBalance(token.token);

  // get wallet balance of native tokens in user's wallet
  const walletBalance = useBalance(token.token);

  // get one day APY
  const priceUSD = useUserTellerTokenValue(token.token, tellerBalance);
  const tellerBalanceString = formatNumberForDisplay(tellerBalance);
  const walletBalanceString = formatNumberForDisplay(walletBalance);
  const oneDayAPY = useOneDayAPY(token.token);

  useEffect(() => {
    if (!priceUSD) return;
    token.calculateTotalUSD(Number(priceUSD));
  }, [priceUSD]);

  return (
    <div className="flex flex-row flex-wrap flex-1 md:mb-1.5 font-sans" key={token}>
      <div className="flex flex-1 p-8 shadow-card z-10 relative bg-white rounded-bl">
        <IconBackground>
          <CurrencyIcon currency={token.token} />
        </IconBackground>
        <div className="my-auto ml-2">
          <div className="text-green-02 type-h3-sans font-medium font-sans whitespace-pre">
            {tellerBalanceString} <span className="font-light">{token.token}</span>
          </div>
          <div className="text-black type-p-lg font-light">Total Supplied</div>
        </div>
      </div>
      <div className="flex-1 flex flex-row p-8 shadow-card relative bg-white rounded-br">
        <IconBackground>
          <img src={percentage_icon} className={`h-5 w-5`} />
        </IconBackground>
        <div className="my-auto ml-2">
          <div className="text-green-02 type-h3-sans font-medium whitespace-pre">
            +{formatNumberForDisplay(oneDayAPY * 365)}
            <span className="font-light">%</span>
          </div>
          <div className="text-black type-p-lg font-light"> APY</div>
        </div>
      </div>
      <div className="flex-1 p-8 flex flex-row">
        <IconBackground>
          <img src={wallet_icon} className={`h-5 w-5`} />
        </IconBackground>
        <div className="my-auto ml-2">
          <div className="text-green-02 type-h3-sans font-medium whitespace-pre">
            {walletBalanceString} <span className="font-light">{token.token}</span>
          </div>
          <div className="text-black type-p-lg font-light">Wallet Balance</div>
        </div>
      </div>
    </div>
  );
};

export default UserTellerTokenData;

const IconBackground = ({ children }: { children: React.ReactChild }) => {
  return (
    <div className="bg-green-06 rounded rounded-full h-10 w-10 flex items-center justify-center">
      {children}
    </div>
  );
};
