import React from "react";

const BR = ({
  variant = "green",
  className = "",
}: {
  variant?: "green" | "grey";
  className?: string;
}) => (
  <div
    className={className}
    style={{
      height: "1px",
      width: "100%",
      borderBottom: `1px solid
      ${variant == "green" ? "#aededd" : ""}
      ${variant == "grey" ? "#d4d9d9" : ""}`,
    }}
  />
);

export default BR;
