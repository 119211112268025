import React from "react";

import CurrencyIcon from "./CurrencyIcon";
import Tooltip from "./Tooltip";

interface RowProps {
  title: string;
  children: React.ReactNode;
  currency?: string;
  tooltip_text?: string;
}
const TableRow = ({ title, children, currency = "", tooltip_text = "" }: RowProps) => {
  return (
    <div className="text-xs sm:text-base flex flex-row justify-between p-3 md:p-4 m-x-auto align-items-center sm:space-x-4 xs:flex-wrap">
      <div className="flex flex-row items-center flex-1">
        {currency && (
          <div className="flex items-center	">
            <CurrencyIcon currency={currency} />
          </div>
        )}
        <div className="whitespace-nowrap">{title}</div>
        {tooltip_text && <Tooltip text={tooltip_text} header={title} />}
      </div>
      {children}
    </div>
  );
};

export default TableRow;
