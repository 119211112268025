import React, { useContext, useMemo } from "react";

import dropdown from "assets/arrow-down.svg";
import CurrencyIcon from "components/UI/CurrencyIcon";
import NumberInput from "components/UI/NumberInput";
import { UniswapContext } from "contexts/dashboard/uniswapContext";
import { formatSmallNumber } from "utils/helperFunctions";

const UniswapInputField = () => {
  const {
    options,
    setTokenSelectionDropdown,
    isExactIn,
    setIsExactIn,
    trade,
    values,
    setValues,
    escrowBalances,
  } = useContext(UniswapContext);
  const { input } = values;
  const { amount, token } = input;
  const balanceString = useMemo(() => {
    return `Balance: ${escrowBalances ? formatSmallNumber(escrowBalances[token]) : "-"}`;
  }, [escrowBalances, input.token]);

  const getInputValue = () => {
    return isExactIn ? amount : trade ? trade.inputAmount.toSignificant(10) : 0;
  };
  const inputValue = useMemo(() => {
    return getInputValue();
  }, [isExactIn, values, trade]);

  const onDropdownClick = () => {
    if (!escrowBalances) return;

    setTokenSelectionDropdown({
      tokens: values.output.token
        ? options.filter((option: any) => {
            return option != values.output.token;
          })
        : options,
      type: "First",
    });
  };

  React.useEffect(() => null, [values]);
  return (
    <>
      <div className="flex flex-row border-thin justify-between p-4">
        <div className="flex flex-grow flex-col items-start">
          <div className="text-lightest-gray mb-1">
            From {trade && !isExactIn && " (estimated)"}
          </div>
          <NumberInput
            style={{ width: "100%" }}
            className={`text-left text-lg xs:text-md font-medium  ${
              values.output.token && escrowBalances ? "text-black" : "text-gray"
            }`}
            value={inputValue}
            setValue={(e: any) => {
              if (!isExactIn) {
                setIsExactIn(true);
              }
              const newValues = { ...values };
              newValues.input.amount = e;
              setValues(newValues);
            }}
          />
        </div>
        <div className="flex flex-col align-items-end">
          <div className="text-lightest-gray mb-1 font-medium" style={{ whiteSpace: "nowrap" }}>
            {balanceString}
          </div>
          <div className="flex flex-row items-center	">
            {escrowBalances && amount !== escrowBalances[token] && (
              <div
                className="mr-4 py-1 px-2 border-thin text-lightest-gray pointer"
                onClick={() => {
                  const newValues = { ...values };
                  newValues.input.amount = escrowBalances[token].toString();
                  setValues(newValues);
                }}
              >
                Max
              </div>
            )}
            <div
              className="dropdown font-medium text-gray dropdown-title dropdown-title flex flex-row items-center pointer"
              onClick={onDropdownClick}
            >
              <div className="mr-3 text-black">{token}</div>
              <CurrencyIcon className="mx-3" currency={token} />
              <img className="ml-3" height={14} src={dropdown} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UniswapInputField;
