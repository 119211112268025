import React, { useContext, useMemo } from "react";

import dropdownWhite from "assets/arrow-down-white.svg";
import dropdown from "assets/arrow-down.svg";
import CurrencyIcon from "components/UI/CurrencyIcon";
import NumberInput from "components/UI/NumberInput";
import { UniswapContext } from "contexts/dashboard/uniswapContext";
import { formatSmallNumber } from "utils/helperFunctions";

const UniswapOutputField = () => {
  const {
    values,
    options,
    setTokenSelectionDropdown,
    isExactIn,
    trade,
    selectedLoan,
    escrowBalances,
  } = useContext(UniswapContext);
  const { input, output } = values;
  const onDropdownClick = () => {
    if (!escrowBalances) return;

    setTokenSelectionDropdown({
      tokens: options.filter((option: any) => {
        return option != input.token;
      }),
      type: "Second",
    });
  };

  const BalanceString = useMemo(() => {
    return `Balance: ${
      output.token ? (escrowBalances ? formatSmallNumber(escrowBalances[output.token]) : "-") : "0"
    }`;
  }, [escrowBalances, output.token]);

  return (
    <>
      <div className="flex flex-row border-thin justify-between p-4 mt-2">
        <div className="flex flex-col items-start">
          <div className="text-lightest-gray mb-1">To {trade && isExactIn && "(estimated)"}</div>
          <div
            className={`text-left text-lg xs:text-md font-medium ${
              output.token && escrowBalances ? "text-black" : "text-gray"
            }`}
            style={{ cursor: "default" }}
          >
            {isExactIn
              ? trade
                ? trade.outputAmount.toSignificant(10)
                : "0"
              : output.amount || "0"}
          </div>
          {/* <NumberInput
            className={`text-left text-lg xs:text-md font-medium ${
              output.token && userWalletBalance ? "text-black" : "text-gray"
            }`}
            value={
              isExactIn
                ? trade
                  ? trade.outputAmount.toSignificant(4)
                  : "0"
                : output.amount || "0"
            }
            setValue={(e: any) => {
              if (isExactIn) {
                setIsExactIn(false);
              }
              const newValues = { ...values };
              newValues.output.amount = e;
              setValues(newValues);
            }}
          /> */}
        </div>
        <div className="flex flex-col align-items-end">
          <div className="text-lightest-gray mb-1 font-medium">{BalanceString}</div>
          <div
            className="dropdown font-medium text-gray dropdown-title dropdown-title flex flex-row items-center	 pointer"
            onClick={onDropdownClick}
          >
            {output.token ? (
              <>
                <div className="mr-3 text-black">{output.token}</div>
                <CurrencyIcon className="mr-3" currency={output.token} />
                <img className="ml-3" height={14} src={dropdown} />
              </>
            ) : (
              <div
                className="border-thin px-2 py-1 flex flex-row items-center	"
                style={{ backgroundColor: "#00A09D", color: "white" }}
              >
                <div className="mr-3">Select a token</div>
                <img className="ml-3" height={14} width={14} src={dropdownWhite} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UniswapOutputField;
