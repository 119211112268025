import { useTellerContext } from "contexts/global/tellerContext";
import { useWeb3StateContext } from "contexts/global/web3StateContext";
import { createNewTransactionWithHandler } from "actions/utils";
import { useCallback } from "react";

export const useBridgeNFT = () => {
  const { tellerDiamondContract } = useTellerContext();
  const { userAddress } = useWeb3StateContext();

  const bridgeNFT = useCallback(
    async (bridgeTransactionHandler: any, ID: string, type: "V1" | "V2") => {
      const params = type == "V1" ? [ID] : [ID, 1];
      if (type == "V1") {
        await createNewTransactionWithHandler(
          () => tellerDiamondContract.methods.bridgeNFTsV1(...params).send({ from: userAddress }),
          bridgeTransactionHandler,
        );
      } else {
        await createNewTransactionWithHandler(
          () => tellerDiamondContract.methods.bridgeNFTsV2(...params).send({ from: userAddress }),
          bridgeTransactionHandler,
        );
      }
    },
    [tellerDiamondContract, userAddress],
  );

  return { bridgeNFT };
};
