import Button from "components/Button";
import useNFT from "hooks/useNFT";
import React, { useState } from "react";
import { NFTImage } from "./NFTImage";
import NFTDashboardAccordionRowHeaderCTA from "./NFTDashboardAccordionRowHeaderCTA";
import Badge from "components/Badge";
import { NFTStatusBadgeStyles } from "./types";
import CurrencyIcon from "components/UI/CurrencyIcon";
import Tooltip from "components/UI/Tooltip";
import NFTDetailsTable from "./NFTDetailsTable";
import FullScreenModal from "components/FullScreenModal";
import { useOpenseaLink } from "hooks/useEtherscanLink";
import DataLoadingWrapper from "components/DataLoadingWrapper";
import { NFT_CONTRIBUTION_MULTIPLIER } from "constants/constants";

const NFTDashboardAccordionMobileCard = ({ id }: { id: any }) => {
  const [cardExpanded, setCardExpanded] = useState(false);
  const { status, type, attributes, network } = useNFT(id);
  const openSeaLink = useOpenseaLink(id);
  return (
    <>
      <div className="flex flex-col py-8 px-6 rounded flex-1 my-1 md:max-w-md gap-x-1 relative shadow-under type-p-sm font-light text-black">
        <div className="flex flex-row justify-between h-36">
          <DataLoadingWrapper controlVar={attributes.Base_Loan_Asset} width={1500} height={144}>
            <NFTImage id={id} />
          </DataLoadingWrapper>
        </div>
        <div className="flex flex-col mt-5 mb-7">
          <div className="flex flex-row justify-between my-2 items-center">
            <div className="">Status</div>
            <div className="">
              <Badge text={status} badgeType={NFTStatusBadgeStyles[status]} />
            </div>
          </div>
          <div className="flex flex-row justify-between my-2 items-center">
            <div className="">Token ID</div>
            <div className="">{id} </div>
          </div>
          <div className="flex flex-row justify-between my-2 items-center">
            <div className="flex flex-1 items-center justify-start">
              Loan Size
              <span>
                <Tooltip
                  white={true}
                  header="Time Remaining"
                  text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitatio"
                />
              </span>
            </div>
            <div className="">
              <div className="flex-1 text-left flex flex-row items-center ">
                {(attributes.Base_Loan_Size * NFT_CONTRIBUTION_MULTIPLIER).toLocaleString()}{" "}
                {attributes.Base_Loan_Asset}
                <span>
                  <CurrencyIcon currency={attributes.Base_Loan_Asset} className="ml-2" />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-row justify-between space-x-4">
          <Button
            color="green"
            type="secondary"
            text="View Details"
            size="md"
            CTA={() => {
              if (attributes.Base_Loan_Size) setCardExpanded(true);
            }}
            disabled={!attributes.Base_Loan_Size}
          />
          <NFTDashboardAccordionRowHeaderCTA
            status={status}
            id={id}
            type="secondary"
            nftType={type}
          />
        </div>
      </div>

      {cardExpanded && (
        <FullScreenModal
          onClose={() => {
            setCardExpanded(false);
          }}
          footerCTA={
            <NFTDashboardAccordionRowHeaderCTA
              status={status}
              id={id}
              type="secondary"
              nftType={type}
            />
          }
          footerCopy={
            <div className="xs:mt-4 text-green-02 whitespace-pre xs:whitespace-normal mr-16">
              View on{" "}
              <a target="_blank" rel="noreferrer" href={openSeaLink} className="link text-gray">
                <span className="text-green-03">OpenSea</span>
              </a>
            </div>
          }
          mainContent={
            <div className="flex flex-col mx-10 overflow-y-scroll">
              <div className="mb-10 type-h3 text-green-02">
                Your <span className="text-green-03">NFT Details</span>
              </div>
              <div className="flex flex-1 mb-6">
                <NFTDetailsTable id={id} />
              </div>
              <div className="flex flex-1 bg-white rounded p-6 mb-10 shadow-card">
                <NFTImage id={id} />
              </div>
            </div>
          }
        />
      )}
    </>
  );
};

export default NFTDashboardAccordionMobileCard;
