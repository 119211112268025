import React, { useState, useContext, useEffect, useCallback, useMemo } from "react";

/**
 * Custom modalOpened control hook
 * Freezes the scroll of components in the background  which is useful when we have see-through opacity modalOpeneds
 * @returns modalOpened - a boolean variable to be used to hide or show modalOpened
 * @returns closeModal - callback function to close modalOpened
 * @returns setModalOpened - function  that takes in boolean param to hide or open modalOpened
 */

const useModal = () => {
  // console.log("useModal");

  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const closeModal = () => {
    setModalOpened(false);
  };
  useEffect(() => {
    if (modalOpened) {
      document.body.style.overflow = "hidden";
      document.body.classList.add("no-sroll");
    } else {
      document.body.style.overflow = "unset";
      document.body.classList.remove("no-sroll");
    }
  }, [modalOpened]);

  return { closeModal, modalOpened, setModalOpened };
};

export default useModal;
