import { AvailableTokens } from "contexts/types";
import BigNumber from "bignumber.js";
import React, { useCallback, useMemo } from "react";
import { useToken } from "./useToken";
BigNumber.config({ EXPONENTIAL_AT: 1e9 });

export const useBNMethods = (assetID: AvailableTokens) => {
  // console.log("useBMMethods");

  const { tokenDecimals } = useToken(assetID);
  const convertToBNString = useCallback(
    (amount: number | string) => {
      if (!tokenDecimals) return null;
      const res = new BigNumber(amount).shiftedBy(tokenDecimals).toFixed(0).toString();
      return res;
    },
    [tokenDecimals],
  );
  const convertToString = useCallback(
    (amount: number | string | BigNumber) => {
      if (!tokenDecimals) return null;
      const res = new BigNumber(amount).shiftedBy(-tokenDecimals).toString();
      return res;
    },
    [tokenDecimals],
  );

  const convertToBN = useCallback(
    (amount: number | string) => {
      return new BigNumber(amount).shiftedBy(+tokenDecimals);
    },
    [tokenDecimals],
  );

  const convertToShortBN = useCallback(
    (amount: number | string) => {
      return new BigNumber(amount).shiftedBy(-tokenDecimals);
    },
    [tokenDecimals],
  );

  return useMemo(() => {
    return { convertToBNString, convertToString, convertToBN, convertToShortBN };
  }, [convertToBNString, convertToString, convertToBN, convertToShortBN]);
};
