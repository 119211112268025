import React from "react";

import infoTriangle from "assets/info-triangle.svg";

interface validationProps {
  message: string;
}

const FormValidationWarning = ({ message }: validationProps) => {
  return message ? (
    <div className="flex flex-row  items-center	 justify-center">
      <img className="mr-2" src={infoTriangle} height={14} />
      <div className="text-xs text-gray">{message}</div>
    </div>
  ) : (
    <div style={{ height: "18px" }}>{""}</div>
  );
};

export default FormValidationWarning;
