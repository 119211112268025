import React, { useEffect, useMemo, useState } from "react";

import Accordion from "components/Accordion";
import Button from "components/Button";
import NFTDashboardAccordionRowExpand from "./NFTDashboardAccordionRowExpand";
import NFTDashboardAccordionRowHeader from "./NFTDashboardAccordionRowHeader";
import NFTDashboardAccordionMobile from "./NFTDashboardAccordionMobile";
import StakeAllCTA from "./StakeAllCTA";
import { useNFTsContext } from "contexts/global/nftsContext";
import { useTellerContext } from "contexts/global/tellerContext";
import CurrencyIcon from "../../components/UI/CurrencyIcon";
import swap from "assets/swap.svg";
import { useBridgeNFT } from "../../actions/BridgeNFT";
import { useWeb3StateContext } from "contexts/global/web3StateContext";
import { useApproveNFT } from "actions/ApproveNFT";
import ApproveTransactionModal from "./ApproveTransactionModal";

const NFTDashboardAccordion = () => {
  const { allClaimedNFTs } = useNFTsContext();
  const { tellerNFTContract, tellerNFTV2Contract, tellerDiamondContract } = useTellerContext();
  const { userAddress } = useWeb3StateContext();

  useEffect(() => {
    if (!tellerNFTContract) return;
    if (!userAddress) return;
    if (!tellerNFTV2Contract) return;
    if (!tellerDiamondContract) return;
    const isMounted = true;
  }, [tellerNFTContract, userAddress, tellerNFTV2Contract, tellerDiamondContract]);

  return (
    <>
      <div className="xs:p-4 sm:p-0 py-10 sm:mt-16 flex flex-row flex-wrap justify-between items-center">
        <div className="mb-6 text-green-03 type-h4 font-medium xs:flex-1 whitespace-nowrap mr-36 text-left">
          Your NFT(s):
        </div>
        <StakeAllCTA />
        <NFTDashboardAccordionBridgeControls />
      </div>
      <div className="modal-shadow flex-1 rounded hidden lg:block">
        <div className="flex flex-row p-10 justify-between text-grey-01 type-p-xs">
          <div className="flex-1 text-left">Token ID</div>
          <div className="flex-1 text-left">Status</div>
          <div className="flex-1 text-left">Type</div>
          <div className="flex-1 text-left">Loan Size</div>
          <div className="flex-1 text-left" />
        </div>
        {allClaimedNFTs && (
          <Accordion
            rowIDs={allClaimedNFTs.map((nft) => nft.id)}
            HeaderComponent={NFTDashboardAccordionRowHeader}
            BodyComponent={NFTDashboardAccordionRowExpand}
          />
        )}
      </div>
      <NFTDashboardAccordionMobile />
    </>
  );
};

export default NFTDashboardAccordion;

const NFTDashboardAccordionBridgeControls = () => {
  const { ownedNFTs, bridgingTo, setBridgingTo } = useNFTsContext();
  const { bridgeNFT } = useBridgeNFT();

  // const bridgeTransactionHandler = useTransactionHandler(
  //   "bridgeNFT",
  //   (e) => null,
  //   (e) => null,
  // );
  // const bridgeAll = useCallback(() => {
  //   const NFTsToBridge = ownedNFTs.filter((nft) => {
  //     return nft.network == bridgingTo && nft.status == "Unstaked";
  //   });
  //   bridgeNFT(bridgeTransactionHandler, NFTsToBridge);
  // }, [bridgeNFT]);

  return (
    <>
      {bridgingTo != null && ownedNFTs?.length > 0 && (
        <div className="flex flex-row sm:mb-6 mb-0 items-center type-medium">
          <div className="mr-8 ml-2 flex flex-row items-center">
            <div className="flex flex-row items-center">
              {bridgingTo == "mainnet" ? "Polygon" : "Mainnet"}
              <CurrencyIcon
                currency={bridgingTo == "polygon" ? "ETH" : "POLYGON"}
                className="mx-1"
              />
            </div>
            <div
              className="pointer mx-1"
              onClick={() => {
                setBridgingTo(bridgingTo == "polygon" ? "mainnet" : "polygon");
              }}
            >
              <img src={swap} className={`h-8 w-8 mx-2`} />
            </div>
            <div className="flex flex-row items-center">
              {bridgingTo}
              <CurrencyIcon
                currency={bridgingTo == "polygon" ? "POLYGON" : "ETH"}
                className="mx-1"
              />
            </div>
          </div>
          {/* <div className="text-green-03 xs:flex-1">
            <Button
              CTA={bridgeAll}
              color="green"
              type="secondary"
              text={`Bridge All (${ownedNFTs?.length})`}
              size="md"
              disabled={false}
            />
          </div> */}
        </div>
      )}
    </>
  );
};
