import React from "react";

import lightGreen from "assets/claimCardBG.svg";
import darkGreen from "assets/darkGreenBG.svg";
import purple from "assets/purpleCardBG.svg";

import BR from "../UI/BR";

type CardWithTableProps = {
  header?: React.ReactNode | null;
  rows?: any;
  variant?: "lightGreen" | "darkGreen" | "purple";
  body?: React.ReactNode | null;
};

const CardWithTable = ({
  header = null,
  rows = [],
  variant = "lightGreen",
  body = null,
}: CardWithTableProps) => {
  return (
    <div className="modal-shadow rounded">
      {header && (
        <CardHeader
          className="rounded-t flex flex-col p-10 bg-primary text-white"
          variant={variant}
        >
          {header}
        </CardHeader>
      )}
      <div className="rounded-b flex flex-col px-10 bg-white">
        {rows.map((row: any, i: any) => (
          <div key={i}>
            {!row.hidden && (
              <>
                <div className="flex flex-row  justify-between type-p-sm my-5">
                  <div className="text-black font-light">{row.left}</div>
                  <div className="text-green-02 font-medium">{row.right}</div>
                </div>
                {rows.length != i + 1 && <BR />}
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardWithTable;

export const CardHeader = ({
  variant,
  children,
  className = "",
  style = {},
}: {
  variant?: "lightGreen" | "darkGreen" | "purple";
  children?: React.ReactNode | null;
  className?: string;
  style?: { [key: string]: any };
}) => {
  return (
    <div
      className={`flex bg-primary text-white ${className}`}
      style={{
        backgroundImage: `url(${
          variant == "lightGreen" ? lightGreen : variant == "purple" ? purple : darkGreen
        })`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        minWidth: "100%",
        ...style,
      }}
    >
      {children}
    </div>
  );
};
