import React, { useContext, useEffect, useState } from "react";

import copy from "constants/copy.json";
import { BorrowPageContext } from "contexts/borrow/borrowContext";
import SubmenuCard from "components/UI/SubmenuCard";
import CustomSlider from "components/UI/CustomSlider";
import PrimaryButton from "components/UI/PrimaryButton";
import CustomInput from "components/UI/CustomInput";

import CustomSubmenuLink from "components/UI/CustomSubmenuLink";
import formatNumberForDisplay from "utils/formatNumberForDisplay";
import FormValidationWarning from "components/UI/FormValidationWarning";

export const LoanSizeSubmenu = () => {
  const { borrowRequest, setBorrowRequest, setSubmenu, maxLoanAmount, value, setValue, isSecured } =
    useContext(BorrowPageContext);
  const pageCopy = copy.pages.borrow.main.form.step2.loanSize.submenu;

  const [maxAmountExceeded, setMaxAmountExceeded] = useState("");

  useEffect(() => {
    if (Number(value) > Number(maxLoanAmount)) {
      setMaxAmountExceeded("Max loan amount exceeded");
    } else {
      setMaxAmountExceeded("");
    }
  }, [maxLoanAmount, value]);

  return (
    <SubmenuCard
      title={pageCopy.title}
      onCloseAction={() => {
        setSubmenu(null);
      }}
    >
      <div className="flex flex-col">
        <div className="mb-4">{pageCopy.description} </div>
        <CustomInput
          placeholder="0.0000"
          onChangeFunction={(e: any) => {
            let value = e.target.value.replace(/[^0-9.]/g, "");

            const split = value.split(".");
            if (split[1] && split[1].length > 4) {
              value = `${split[0]}.${split[1].substring(0, 4)}`;
            }
            if (isNaN(value)) {
              value = "";
            }
            setValue(value);
          }}
          value={value != null ? value.toString() : ""}
          type="string"
          onBlur={(e: any) => {
            let value = parseFloat(e.target.value);
            if (isNaN(value)) {
              value = 0;
            }
            if (value > maxLoanAmount) {
              value = Math.floor(maxLoanAmount);
            }
            setValue(value.toString());
          }}
        />
        <div className="text-lightest-gray text-lg xs:text-md ">{borrowRequest.lendWith}</div>
        {!isSecured && (
          <CustomSlider
            min={0}
            max={maxLoanAmount}
            onChange={(e: any) => setValue(e.target.value)}
            value={Number(value)}
          />
        )}
        <div className="mt-4 mb-5 mx-auto">
          <PrimaryButton
            onClick={() => {
              let finalValue = value;
              if (Number(value) > maxLoanAmount && !isSecured) {
                finalValue = maxLoanAmount.toString();
              }
              setBorrowRequest({
                ...borrowRequest,
                loanSize: finalValue,
              });
              setSubmenu(null);
            }}
            text={pageCopy.CTA}
          />
        </div>
        {maxAmountExceeded && (
          <div className="mt-6">
            <FormValidationWarning message={maxAmountExceeded} />
          </div>
        )}
      </div>
    </SubmenuCard>
  );
};

const LoanSizeSelection = () => {
  const { borrowRequest, setSubmenu } = useContext(BorrowPageContext);

  return (
    <CustomSubmenuLink
      title={`${
        borrowRequest.loanSize ? formatNumberForDisplay(borrowRequest.loanSize, 10) : "-"
      } ${borrowRequest.lendWith}`}
      CTA={() => {
        setSubmenu("LoanSize");
      }}
    />
  );
};

export default LoanSizeSelection;
