import { transactionTypeEnum } from "hooks/useTransactionHandler";
import React, {
  createContext,
  Dispatch,
  useCallback,
  useContext,
  useMemo,
  useReducer,
  useState,
} from "react";

type TransactionType = { type: transactionTypeEnum; hash: string };
type TransactionsContextType = {
  /**
   * An array of past successful transactions from current session. Can be used in a useEffect hook to trigger state updates
   */
  transactions: TransactionType[];
  /**
   *Function to add transactions to the global list
   @param {TransactionType} transaction - successful transaction to be added to the list
   */
  addTransaction: Dispatch<TransactionType>;
};

const defaultTransactionsState = {
  transactions: [] as TransactionType[],
  addTransaction: (transaction: TransactionType) => null as null,
};
const TransactionsContext = createContext<TransactionsContextType>(defaultTransactionsState);

export const TransactionsContextProvider = ({ children }: any) => {
  const [transactions, setTransactions] = useState([]);
  const addTransaction = useCallback(
    (transaction: any) => {
      setTransactions((prevState: any) => [...prevState, transaction]);
    },
    [transactions],
  );

  const values = useMemo(() => {
    return {
      transactions,
      addTransaction,
    };
  }, [transactions, addTransaction]);

  return <TransactionsContext.Provider value={values}>{children}</TransactionsContext.Provider>;
};

/**
 *Custom hook used to add successful transactions to a globally accessed list
 */
export const useTransactionsContext: () => TransactionsContextType = () => {
  const context = useContext(TransactionsContext);
  if (context === undefined) {
    throw new Error("useTransactions must be used within a useTransactionsProvider");
  }
  return context;
};
