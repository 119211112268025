import { useTellerContext } from "contexts/global/tellerContext";
import { useWeb3StateContext } from "contexts/global/web3StateContext";
import { createNewTransactionWithHandler } from "actions/utils";
import { useCallback } from "react";

export const useApproveNFT = () => {
  const { tellerDiamondContract, tellerNFTContract, tellerNFTV2Contract } = useTellerContext();
  const { userAddress } = useWeb3StateContext();

  const approveNFT = useCallback(
    async (approveTransactionHandler: any, type: "V1" | "V2") => {
      const contract = type == "V1" ? tellerNFTContract : tellerNFTV2Contract;
      await createNewTransactionWithHandler(
        () =>
          contract.methods
            .setApprovalForAll(tellerDiamondContract._address, true)
            .send({ from: userAddress }),
        approveTransactionHandler,
      );
    },
    [tellerNFTV2Contract, tellerNFTContract, userAddress, tellerDiamondContract],
  );

  const isNFTApproved = useCallback(
    async (type: "V1" | "V2") => {
      return (async () => {
        const contract = type == "V1" ? tellerNFTContract : tellerNFTV2Contract;
        if (!contract) return;
        return await contract.methods
          .isApprovedForAll(userAddress, tellerDiamondContract._address)
          .call();
      })();
    },
    [tellerNFTContract, tellerNFTV2Contract, tellerDiamondContract, userAddress],
  );

  return { approveNFT, isNFTApproved };
};
