import { useCallback } from "react";

import { createNewTransactionWithHandler } from "actions/utils";
import { useWeb3StateContext } from "contexts/global/web3StateContext";
import { useTellerContext } from "contexts/global/tellerContext";
import { useBNMethods } from "hooks/useBNMethods";
import { AvailableCollateralTokens } from "contexts/types";

/**
 * Withdraws collateral from a loan
 */
export const useWithdrawCollateral = (collateralWith: AvailableCollateralTokens) => {
  const { tellerDiamondContract } = useTellerContext();
  const { userAddress } = useWeb3StateContext();
  const { convertToBNString } = useBNMethods(collateralWith);

  const withdrawCollateral = useCallback(
    async (transactionHandler: any, amount: string, loanID: string) => {
      const amountToWithdrawBN = convertToBNString(amount);
      await createNewTransactionWithHandler(
        () =>
          tellerDiamondContract.methods
            .withdrawCollateral(amountToWithdrawBN, loanID)
            .send({ from: userAddress }),
        transactionHandler,
      );
    },
    [convertToBNString, tellerDiamondContract, userAddress],
  );

  return {
    withdrawCollateral,
  };
};
