import { useTellerContext } from "contexts/global/tellerContext";
import { useWeb3StateContext } from "contexts/global/web3StateContext";
import { AvailableLendingTokens } from "contexts/types";
import { useBNMethods } from "hooks/useBNMethods";
import { useToken } from "hooks/useToken";
import { useCallback } from "react";
import { createNewTransactionWithHandler } from "./utils";

export const useAaveSupply = (lendingToken: AvailableLendingTokens) => {
  const { tellerDiamondContract } = useTellerContext();
  const { userAddress } = useWeb3StateContext();
  const { convertToBNString } = useBNMethods(lendingToken);
  const { tokenAddress } = useToken(lendingToken);

  const aaveSupply = useCallback(
    async (aaveSupplyTransactionHandler: any, amount: number, loanID: any) => {
      const amountBN = convertToBNString(amount);
      await createNewTransactionWithHandler(
        () =>
          tellerDiamondContract.methods
            .aaveDeposit(loanID, tokenAddress, amountBN)
            .send({ from: userAddress }),
        aaveSupplyTransactionHandler,
      );
    },
    [tellerDiamondContract, userAddress, convertToBNString, tokenAddress],
  );

  return { aaveSupply };
};
