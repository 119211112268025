import { useTransactionsContext } from "contexts/global/transactionsContext";
import { useWeb3StateContext } from "contexts/global/web3StateContext";
import { AvailableTokens } from "contexts/types";
import React, { useState, useEffect, useMemo } from "react";
import { useDeepCompareEffect } from "react-use";
import { useBNMethods } from "./useBNMethods";
import { useToken } from "./useToken";

/**
 *
 * @param assetID Asset ID of the token we want to check the balance of
 * @param address Optional - provide an address to check balance of, defaults to user wallet
 * @returns balance string
 */
export const useBalance = (assetID: AvailableTokens, address?: string | null) => {
  // console.log("useBalance");

  const { convertToString } = useBNMethods(assetID);
  const { tokenContract } = useToken(assetID);
  const [balance, setBalance] = useState(null);
  const { transactions } = useTransactionsContext();
  const { userAddress, web3, network } = useWeb3StateContext();
  const addressToCheck = address ? address : userAddress;

  useDeepCompareEffect(() => {
    if (
      (!tokenContract && assetID != "ETH" && assetID != "MATIC") ||
      !convertToString ||
      !web3 ||
      !addressToCheck ||
      !userAddress
    )
      return;
    let isMounted = true;
    (async () => {
      const balanceBN =
        (assetID == "ETH" && network.name != "polygon") ||
        (assetID == "MATIC" && network.name == "polygon")
          ? await web3.eth.getBalance(addressToCheck)
          : await tokenContract.methods.balanceOf(addressToCheck).call();

      const balanceStr = convertToString(balanceBN);
      if (isMounted) setBalance(balanceStr);
    })();

    return () => {
      isMounted = false;
    };
  }, [tokenContract, userAddress, transactions, convertToString, web3, addressToCheck]);

  return useMemo(() => {
    return balance;
  }, [balance]);
};
