import { NFTInterface } from "contexts/types";
import distributions from "./distributions.json";
import { BigNumber } from "ethers";

const merkleDist: any = distributions;

export const getNFTsFromDistribution = async (address: string, NFTDistributorContract: any) => {
  if (!address || !NFTDistributorContract) return null;
  const unclaimedNFTs: NFTInterface[] = [];
  const allNFTsFromDistribution = Array.from({ length: merkleDist.length }, (x, i) => {
    return {
      merkleIndex: i,
      nodeIndex: "0",
      amount: 0,
      merkleProof: "0",
      isClaimed: false,
      exists: false,
      tierIndex: merkleDist[i].tierIndex,
    };
  });

  for (let i = 0; i < merkleDist.length; i++) {
    for (const j in merkleDist[i].claims) {
      if (j.toUpperCase() == address.toUpperCase()) {
        const isClaimed = await NFTDistributorContract.methods
          .isClaimed(i, merkleDist[i].claims[j].index.toString())
          .call();
        allNFTsFromDistribution[i].nodeIndex = merkleDist[i].claims[j].index.toString();
        allNFTsFromDistribution[i].amount = Number(BigNumber.from(merkleDist[i].claims[j].amount));
        allNFTsFromDistribution[i].merkleProof = merkleDist[i].claims[j].proof;
        allNFTsFromDistribution[i].isClaimed = isClaimed;
        allNFTsFromDistribution[i].exists = true;
        if (allNFTsFromDistribution[i].exists && !allNFTsFromDistribution[i].isClaimed) {
          unclaimedNFTs.push(allNFTsFromDistribution[i]);
        }
      }
    }
  }
  return { allNFTsFromDistribution, unclaimedNFTs };
};
