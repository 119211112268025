import { NETWORKS_CONFIG } from "constants/constants";
import { useWeb3StateContext } from "contexts/global/web3StateContext";
import React, { useMemo } from "react";

export const useNetworkConfig = () => {
  // console.log("useNetworkConfig");

  const { network } = useWeb3StateContext();
  return useMemo(() => {
    if (!network.name)
      return {
        networkSupported: false,
        collateralTokens: [],
        lendingTokens: [],
        firstBlock: "",
        dapps: [],
      };
    return NETWORKS_CONFIG[network.name];
  }, [network.name]);
};
