import { useNotificationsContext } from "contexts/global/notificationsContext";
import React, { useReducer } from "react";
import successIcon from "assets/ic20-check-fill.svg";
import errorIcon from "assets/ic20-warning.svg";
import xIconGreen from "assets/xIconGreen.svg";
import xIconRed from "assets/xIconRed.svg";
/**
 *
 * Displays warning and success notifications on the top of the screen
 *
 */
const NotificationsBar = () => {
  const { removeNotification, notifications } = useNotificationsContext();

  return (
    <>
      {notifications.length > 0 && (
        <div className="fixed left-0 top-0 right-0 flex flex-col z-50">
          {notifications.map((notification: any) => {
            return (
              <div
                key={notification.id}
                onClick={() => {
                  removeNotification(notification.id);
                }}
                className={`
          py-6 px-10 flex flex-1 justify-between
          ${notification.variant == "success" ? "bg-green-success text-green-02" : ""}
          ${notification.variant == "error" ? "bg-tangerine-05 text-tangerine-01" : ""}
          `}
              >
                <img
                  className=""
                  height={20}
                  width={20}
                  src={notification.variant == "success" ? successIcon : errorIcon}
                />
                <>{notification.copy}</>
                <img
                  className="transform hover:rotate-90 transition duration-300 ease-in-out "
                  height={20}
                  width={20}
                  src={notification.variant == "success" ? xIconGreen : xIconRed}
                />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default NotificationsBar;
