import React, { useState } from "react";

import BR from "components/UI/BR";
import Card from "components/UI/Card";
import TableRow from "components/UI/TableRow";
import { useMarketState } from "hooks/useMarketState";
import { useTellerContext } from "contexts/global/tellerContext";
import formatNumberForDisplay from "utils/formatNumberForDisplay";
import { CustomDropdown } from "components/UI/CustomDropdown";
import chevronDown from "assets/ic16-chevron-down-white.svg";
import chevronDownPrimary from "assets/chevronDownPrimary.svg";
import { useNetworkConfig } from "hooks/useNetworkConfig";
import { useOneDayAPY } from "hooks/useOneDayApy";
import CurrencyIcon from "components/UI/CurrencyIcon";

const Stats = () => {
  const { lendingTokens } = useNetworkConfig();
  const [selectedCurrency, setSelectedCurrency] = useState(lendingTokens[0]);
  const { totalBorrowOutstanding, totalSupplied, availableLiquidity } =
    useMarketState(selectedCurrency);
  const oneDayAPY = useOneDayAPY(selectedCurrency);
  const totalBorrowOutstandingString = `${formatNumberForDisplay(
    totalBorrowOutstanding,
    2,
  )} ${selectedCurrency}`;

  const currentTVLAmount = `${formatNumberForDisplay(
    Number(totalSupplied) + Number(totalBorrowOutstanding),
    2,
  )} ${selectedCurrency}`;

  const liquidityAvailableStr = `${formatNumberForDisplay(
    availableLiquidity,
    2,
  )} ${selectedCurrency}`;

  return (
    <div className="lg:self-start">
      <>
        <div className="cards-container ">
          <Card
            className="main-card bg-teal-lightest text-center "
            // title="Global stats"
            dashboard={true}
          >
            <div className="text-3xl mr-3 text-center">Global Stats</div>
            <div className="flex justify-end mt-6">
              <CustomDropdown
                options={lendingTokens}
                selected={selectedCurrency}
                handleSelect={(e) => {
                  console.log(e);
                  setSelectedCurrency(e);
                }}
              />
            </div>
            <div className="border-thin mt-3 mb-2 lg:mt-5 lg:mb-4">
              <TableRow title="Liquidity available">
                <div className="font-medium">{liquidityAvailableStr}</div>
              </TableRow>
              <BR />
              <TableRow title="Total borrow outstanding">
                <div className="font-medium">{totalBorrowOutstandingString}</div>
              </TableRow>
              <BR />
              <TableRow title="Current TVL">
                <div className="font-medium">{currentTVLAmount}</div>
              </TableRow>
              <BR />
              <TableRow title="APY">
                <div className="font-medium">{formatNumberForDisplay(oneDayAPY * 365, 2)} %</div>
              </TableRow>
            </div>
          </Card>
        </div>
      </>
    </div>
  );
};

export default Stats;
