import React from "react";

import copy from "constants/copy.json";
import { useOnboardContext } from "contexts/global/onboardContext";
import { useWeb3StateContext } from "contexts/global/web3StateContext";
import { truncateAddress } from "utils/helperFunctions";
import { useUIContext } from "contexts/global/uiContext";
import LogOutModal from "components/LogOutModal";
import walletIcon from "assets/ic20-wallet.svg";

export const NavLoginButton = () => {
  const { explorerMode } = useUIContext();
  const { userAddress } = useWeb3StateContext();

  return (
    <>
      <div>
        {!userAddress ? (
          <LoginButton />
        ) : explorerMode ? (
          <ExplorerModeInput />
        ) : (
          <UserWalletButton />
        )}
      </div>
    </>
  );
};

const ExplorerModeInput = () => {
  const { web3, updateUserAddress, userAddress } = useWeb3StateContext();
  return (
    <input
      className="type-button-md xs:text-md text-teal font-medium login-button py-3 md:py-2 px-4 md:px-5 menu-button bg-teal-lightest rounded w-36"
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          const address = e.target.value;
          const isAddress = web3.utils.isAddress(address);
          if (isAddress) {
            updateUserAddress(address.toLowerCase());
          }
        }
      }}
      placeholder={`🔍 ${truncateAddress.apply(userAddress, [6])}`}
    />
  );
};

const UserWalletButton = () => {
  const { userAddress } = useWeb3StateContext();
  const { setShowLogOutModal } = useUIContext();
  return (
    <>
      <LogOutModal />
      <div
        className="cursor-pointer flex flex-row items-center type-button-md xs:text-md text-teal font-medium login-button py-3 md:py-2 px-4 md:px-5 menu-button bg-teal-lightest rounded border-2 border-teal-lightest"
        onClick={() => {
          setShowLogOutModal(true);
        }}
      >
        <img src={walletIcon} className="mr-3" style={{ height: "24px" }} />
        {truncateAddress.apply(userAddress, [6])}
      </div>
    </>
  );
};

const LoginButton = () => {
  const { callWeb3FromProvider } = useOnboardContext();
  const { loginButtonTitle } = copy.CTAs;

  return (
    <>
      <div className="cursor-pointer type-button-md xs:text-md text-teal font-medium login-button py-3 md:py-2 px-4 md:px-5 menu-button bg-teal-lightest rounded border-2 border-teal-lightest">
        <div className="" onClick={() => callWeb3FromProvider()}>
          {loginButtonTitle}
        </div>
      </div>
    </>
  );
};

export default LoginButton;
