import BigNumber from "bignumber.js";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { useWithdrawCollateral } from "actions/WithdrawCollateral";
import BR from "components/UI/BR";
import CurrencyIcon from "components/UI/CurrencyIcon";
import CustomInput from "components/UI/CustomInput";
import CustomSubmenuLink from "components/UI/CustomSubmenuLink";
import FormValidationWarning from "components/UI/FormValidationWarning";
import PrimaryButton from "components/UI/PrimaryButton";
import SubmenuCard from "components/UI/SubmenuCard";
import TableRow from "components/UI/TableRow";
import copy from "constants/copy.json";
import {
  BorrowTermsContext,
  BorrowWithdrawContext,
  DashboardContext,
} from "contexts/dashboard/dashboardContext";
import { useTellerContext } from "contexts/global/tellerContext";
import { LoanInterface } from "contexts/types";
import { useTokenPrice } from "hooks/useTokenPrice";
import { useTransactionHandler } from "hooks/useTransactionHandler";
import { calculateCollateralPercent, roundToFourDecimals } from "utils/helperFunctions";

import ViewContractLink from "../ViewContractLink";
import { useBNMethods } from "hooks/useBNMethods";

const WithdrawMainSection = () => {
  const { loans } = useContext(DashboardContext);

  const filteredLoans = useMemo(() => {
    if (loans) {
      return loans.filter((loan: LoanInterface) => {
        return loan?.collateralAmount > 0;
      });
    } else {
      return null;
    }
  }, [loans]);

  const { selectedLoan, setSelectedLoan, setWithdrawAmount, setNewCollateralPercent } =
    useContext(BorrowWithdrawContext);

  const { description } = copy.pages.dashboard["borrow-withdraw"];

  return (
    <div className="">
      {selectedLoan && <WithdrawCollateralSelectedLoan />}
      {!selectedLoan && (
        <div className="my-2">
          <div className="text-gray mb-4">{description}</div>
          <div className=" border-thin mb-4 mt-3">
            {filteredLoans &&
              filteredLoans
                .sort((a: any, b: any) => {
                  return b.status - a.status;
                })
                .map((loan: any, i: number) => {
                  return (
                    <div key={loan?.id}>
                      <TableRow title={loan?.id}>
                        <div className="flex-2 flex flex-row justify-between align-items-center">
                          <div className="text-xs text-lightest-gray my-auto">
                            {loan?.statusName}
                          </div>
                          <CustomSubmenuLink
                            title={`${roundToFourDecimals(loan?.collateralAmountNumber)} ${
                              loan?.collateralToken
                            }`}
                            CTA={() => {
                              setSelectedLoan(loan);
                              setWithdrawAmount(0);
                              setNewCollateralPercent(0);
                            }}
                          />
                        </div>
                      </TableRow>
                      {i !== filteredLoans.length - 1 && <BR />}
                    </div>
                  );
                })}
          </div>
        </div>
      )}
    </div>
  );
};
export default WithdrawMainSection;

interface WithdrawCollateralProps {
  page?: string;
}
export const WithdrawCollateralSelectedLoan = () => {
  const {
    setWithdrawing,
    selectedLoan,
    withdrawCollateralSubmenu,
    setWithdrawCollateralSubmenu,
    withdrawAmount,
    setWithdrawAmount,
    setNewCollateralPercent,
    newCollateralPercent,
  } = useContext(BorrowWithdrawContext);

  const { convertToBNString, convertToString } = useBNMethods(selectedLoan?.collateralToken);
  const getMaxWithdrawAmount = useCallback(
    (loan: any) => {
      return loan?.isTerms
        ? convertToString(loan?.collateralAmount)
        : convertToString(
            new BigNumber(loan?.collateralAmount)
              .minus(loan?.collateralInfo.neededInCollateralTokens)
              .toString(),
          );
    },
    [convertToString],
  );

  const onSuccess = (hash: any) => {
    setWithdrawAmount(0);
    setNewCollateralPercent(0);
  };
  const transactionHandler = useTransactionHandler("withdrawCollateral", onSuccess, setWithdrawing);
  const { withdrawCollateral } = useWithdrawCollateral(selectedLoan?.collateralToken);

  const collateralAmount = selectedLoan?.collateralAmountNumber;

  const withdraw = useCallback(async () => {
    setWithdrawing("loading");
    if (Number(withdrawAmount) > Number(selectedLoan?.collateralAmount)) return;
    await withdrawCollateral(transactionHandler, withdrawAmount, selectedLoan?.IDNumber);
  }, [withdrawAmount, selectedLoan, transactionHandler, withdrawCollateral]);

  const getExpiryDateString = (date: number) => {
    const expiryDate = new Date(date);
    return expiryDate.toLocaleDateString();
  };
  const [maxWithdrawAmount, setMaxWithdrawAmount] = useState("0");

  useEffect(() => {
    if (selectedLoan) {
      setMaxWithdrawAmount(getMaxWithdrawAmount(selectedLoan));
    }
  }, [selectedLoan]);

  const { header } = copy.pages.dashboard["borrow-withdraw"];

  const withdrawDisabled = useMemo(() => {
    return selectedLoan
      ? Boolean(new BigNumber(withdrawAmount).isGreaterThan(maxWithdrawAmount))
      : true;
  }, [selectedLoan, withdrawAmount, maxWithdrawAmount]);

  const { collateralBuffer } = useTellerContext();

  const collateralTokenPrice = useTokenPrice(selectedLoan?.collateralToken);
  const borrowedTokenPrice = useTokenPrice(selectedLoan?.token);

  const percentAwayFromLiquidation = useMemo(() => {
    return roundToFourDecimals(
      selectedLoan?.currentCollateralPercent -
        selectedLoan?.terms.collateralRatio / 100 +
        collateralBuffer,
    );
  }, [selectedLoan, collateralAmount, collateralBuffer]);

  const currentCollateralPercent = roundToFourDecimals(selectedLoan?.currentCollateralPercent);
  const onSubmenuClose = () => {
    if (
      Boolean(new BigNumber(withdrawAmount).isGreaterThan(maxWithdrawAmount)) ||
      (selectedLoan?.isTerms && selectedLoan?.expired)
    ) {
      setWithdrawAmount(maxWithdrawAmount);
      const newCollateralPercent = calculateCollateralPercent(
        collateralTokenPrice,
        borrowedTokenPrice,
        selectedLoan?.collateralAmountNumber - Number(maxWithdrawAmount),
        selectedLoan?.borrowedAmountNumber,
      );
      setNewCollateralPercent(newCollateralPercent);
    }
    setWithdrawCollateralSubmenu(false);
  };

  const formValidationMessage =
    withdrawAmount && Number(withdrawAmount) > Number(maxWithdrawAmount)
      ? `You cannot withdraw more than ${roundToFourDecimals(maxWithdrawAmount)} ${
          selectedLoan?.collateralToken
        }`
      : "";

  const onInputChange = (e: any) => {
    let value = e.target.value.replace(/[^0-9.]/g, "");
    const split = value.split(".");
    if (split[1] && split[1].length > 4) {
      value = `${split[0]}.${split[1].substring(0, 4)}`;
    }
    if (isNaN(value)) {
      value = "";
    }
    setWithdrawAmount(value);
    const newCollateralPercent = calculateCollateralPercent(
      collateralTokenPrice,
      borrowedTokenPrice,
      selectedLoan?.collateralAmountNumber - value,
      selectedLoan?.borrowedAmountNumber,
    );
    setNewCollateralPercent(newCollateralPercent);
  };

  const onInputBlur = (e: any) => {
    let value = parseFloat(e.target.value);
    if (isNaN(value)) {
      value = 0;
    }
    if (new BigNumber(withdrawAmount).isGreaterThan(maxWithdrawAmount)) {
      value = maxWithdrawAmount;
    }
    setWithdrawAmount(value);
    const newCollateralPercent = calculateCollateralPercent(
      collateralTokenPrice,
      borrowedTokenPrice,
      selectedLoan?.collateralAmountNumber - value,
      selectedLoan?.borrowedAmountNumber,
    );
    setNewCollateralPercent(newCollateralPercent);
  };
  const onMaxClick = () => {
    setWithdrawAmount(maxWithdrawAmount);
    const newCollateralPercent = calculateCollateralPercent(
      collateralTokenPrice,
      borrowedTokenPrice,
      Number(selectedLoan?.collateralAmountNumber) - Number(maxWithdrawAmount),
      selectedLoan?.borrowedAmountNumber,
    );
    setNewCollateralPercent(newCollateralPercent);
  };

  const onSubmit = () => {
    if (Number(withdrawAmount) > Number(maxWithdrawAmount)) {
      setWithdrawAmount(maxWithdrawAmount);
      const newCollateralPercent = calculateCollateralPercent(
        collateralTokenPrice,
        borrowedTokenPrice,
        selectedLoan?.collateralAmountNumber - Number(maxWithdrawAmount),
        selectedLoan?.borrowedAmountNumber,
      );
      setNewCollateralPercent(newCollateralPercent);
    }
    setWithdrawCollateralSubmenu(false);
  };

  const collateralAmountString =
    collateralAmount > 0 && collateralAmount < 0.0001
      ? "<0.0001"
      : roundToFourDecimals(collateralAmount);

  const availableCollateral = useMemo(() => {
    return roundToFourDecimals(collateralAmount);
  }, [selectedLoan]);

  const onWithdrawClick = useCallback(() => {
    if (withdrawDisabled) return;
    const collateralAmount = convertToBNString(selectedLoan?.collateralAmount);
    let amount = withdrawAmount;
    if (
      selectedLoan?.statusName === "Repaid" ||
      new BigNumber(amount).isGreaterThan(collateralAmount) ||
      (selectedLoan?.isTerms && selectedLoan?.expired)
    ) {
      amount = collateralAmount;
    }
    withdraw();
  }, [selectedLoan, convertToBNString, withdraw, withdrawAmount, withdrawDisabled]);

  return (
    <>
      {withdrawCollateralSubmenu ? (
        <SubmenuCard title={header} onCloseAction={onSubmenuClose}>
          <div className="flex flex-col items-center	">
            <FormValidationWarning message={formValidationMessage} />
            <CurrencyIcon currency={selectedLoan?.collateralToken} className="my-2 h-5" />
            <CustomInput
              placeholder="0.0000"
              onChangeFunction={onInputChange}
              value={withdrawAmount ? withdrawAmount.toString() : ""}
              type="string"
              onBlur={onInputBlur}
            />
            <div className="text-lightest-gray text-lg xs:text-md">
              New collateral: {`${Math.round(newCollateralPercent) || 0}%`}
            </div>
            <div className="flex flex-row space-between space-x-4">
              <div
                className="py-1 px-3 my-4 mx-auto border-thin pointer text-black"
                onClick={onMaxClick}
              >
                Max
              </div>
              <div
                className="py-1 px-3 my-4 mx-auto border-thin pointer text-black"
                onClick={onSubmit}
              >
                Enter
              </div>
            </div>
            <div className=" mt-3 text-gray text-sm">
              {`Available collateral: ${availableCollateral} ${selectedLoan?.collateralToken}`}
            </div>
          </div>
        </SubmenuCard>
      ) : (
        <div>
          {selectedLoan?.statusName === "Repaid" && (
            <div className=" border-thin mb-4 mt-3">
              <TableRow title="Status">
                <div className="font-medium">{selectedLoan?.statusName}</div>
              </TableRow>
              <BR />
              <TableRow title="Loan Expiration">
                <div className="font-medium">{getExpiryDateString(selectedLoan?.endDate)}</div>
              </TableRow>
              <BR />
              <TableRow title="Collateral amount">
                <div className="font-medium">
                  {selectedLoan?.collateralAmount} {selectedLoan?.collateralToken}
                </div>
              </TableRow>
            </div>
          )}
          {selectedLoan?.statusName !== "Repaid" && (
            <div>
              <div className=" border-thin mb-4 mt-3">
                {!selectedLoan?.isTerms && (
                  <>
                    <TableRow title="% Away from liquidation">
                      <div className="font-medium">{percentAwayFromLiquidation}%</div>
                    </TableRow>
                    <BR />
                  </>
                )}
                <TableRow title="Current Collateral %">
                  <div className="font-medium">{currentCollateralPercent} %</div>
                </TableRow>
                <BR />
                <TableRow title="Collateral amount">
                  <div className="font-medium">
                    {collateralAmountString} {selectedLoan?.collateralToken}
                  </div>
                </TableRow>
              </div>
              {!(selectedLoan?.isTerms && selectedLoan?.expired) && (
                <div className=" border-thin mb-4 mt-3">
                  <TableRow title="Withdraw collateral">
                    <CustomSubmenuLink
                      title={`${withdrawAmount ? roundToFourDecimals(withdrawAmount) : "-"} ${
                        selectedLoan?.collateralToken
                      }`}
                      CTA={() => {
                        setWithdrawCollateralSubmenu(true);
                      }}
                    />
                  </TableRow>
                  <BR />
                  <TableRow title="New collateral %">
                    <div className="font-medium">
                      {newCollateralPercent ? `${Math.round(newCollateralPercent)}%` : "-"}
                    </div>
                  </TableRow>
                </div>
              )}
            </div>
          )}
          <ViewContractLink hash={selectedLoan?.escrow} />
          <div>
            <PrimaryButton disabled={withdrawDisabled} text="Withdraw" onClick={onWithdrawClick} />
          </div>
        </div>
      )}
    </>
  );
};
