import BigNumber from "bignumber.js";
import React, { useContext, useEffect, useMemo, useState } from "react";

import BR from "components/UI/BR";
import CustomInput from "components/UI/CustomInput";
import CustomSubmenuLink from "components/UI/CustomSubmenuLink";
import FormValidationWarning from "components/UI/FormValidationWarning";
import PrimaryButton from "components/UI/PrimaryButton";
import SubmenuCard from "components/UI/SubmenuCard";
import TableRow from "components/UI/TableRow";
import copy from "constants/copy.json";
import { DashboardContext, UseYearnContext } from "contexts/dashboard/dashboardContext";
import { LoanInterface } from "contexts/types";
import { roundToFourDecimals } from "utils/helperFunctions";

import ViewContractLink from "../ViewContractLink";
import { useYearnWithdraw } from "actions/YearnWithdraw";
import { useTransactionHandler } from "hooks/useTransactionHandler";
import { useUIContext } from "contexts/global/uiContext";
import { useYearnSupply } from "actions/YearnSupply";
import { useEscrowBalances } from "contexts/global/loansContext";

const UseYearnMainSection = () => {
  const { loans } = useContext(DashboardContext);
  const [isMaxWithdraw, setMaxWithdraw] = useState(false);
  const [isMaxDeposit, setMaxDeposit] = useState(false);

  const { selectedLoan, setSelectedLoan, amountSubmenu, setAmountSubmenu, setAmount } =
    useContext(UseYearnContext);

  const activeLoans = loans
    ? loans.filter((loan: LoanInterface) => {
        return (
          loan.escrow !== "0x0000000000000000000000000000000000000000" &&
          loan.statusName !== "Repaid"
        );
      })
    : null;

  const { setOnSubmenuClose } = useUIContext();

  const { description } = copy.pages.dashboard["spend-yearn"];
  const getAvailableBalance = (loan: any) => {
    return loan.borrowedAmountNumber;
  };
  const onSumbmenuClose = () => {
    setAmountSubmenu(false);
  };
  useEffect(() => {
    setOnSubmenuClose(() => (amountSubmenu ? onSumbmenuClose : null));
  }, [amountSubmenu]);

  return (
    <div className="my-4 flex-1">
      {selectedLoan && <YearnSelectedLoan />}
      {!selectedLoan && (
        <div className="my-2">
          <div className="text-gray mb-2">{description}</div>
          <div className="text-gray text-sm mb-4">
            {activeLoans.length < 1 &&
              "You can only use Unsecured loans or Secured loans with <135% collateral"}
          </div>

          {activeLoans.length > 0 && (
            <div className="border-thin mb-4 mt-3">
              {activeLoans.map((loan: LoanInterface, i: number) => {
                return (
                  <div key={loan.id}>
                    <TableRow title={`${loan.id}`}>
                      <CustomSubmenuLink
                        title={`${
                          getAvailableBalance(loan) < 0.0001
                            ? "<0.0001"
                            : roundToFourDecimals(getAvailableBalance(loan))
                        } ${loan.token}`}
                        CTA={() => {
                          setSelectedLoan(loan);
                          setAmount(0);
                          if (isMaxWithdraw) {
                            setMaxWithdraw(false);
                          }
                          if (isMaxDeposit) {
                            setMaxDeposit(false);
                          }
                        }}
                      />
                    </TableRow>
                    {activeLoans.length - 1 !== i && <BR />}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default UseYearnMainSection;

const YearnSelectedLoan = () => {
  const [maxDepositWarning, setMaxWithdrawDepositWarning] = useState("");
  const [maxWithdrawWarning, setMaxWithdrawWithdrawWarning] = useState("");
  const [transactionType, setTransactionType] = useState("Deposit");
  const [isMaxWithdraw, setMaxWithdraw] = useState(false);
  const [isMaxDeposit, setMaxDeposit] = useState(false);
  const [maxDepositAmount, setMaxDepositAmount] = useState(0);
  const [supplied, setSupplied] = useState("0");
  const [earned, setEarned] = useState("0");

  const {
    setSuccess,
    setWithdrawing,
    setSupplying,
    selectedLoan,
    amountSubmenu,
    setAmountSubmenu,
    amount,
    setAmount,
    DAIsupplyAPY,
    setSelectedLoan,
  } = useContext(UseYearnContext);

  // const yToken = useYToken(selectedLoan.token)
  const withdrawTransactionHandler = useTransactionHandler(
    "yearnWithdraw",
    setSuccess,
    setWithdrawing,
  );
  const withdraw = async () => {
    setWithdrawing(true);
    await yearnWithdraw(
      withdrawTransactionHandler,
      amount,
      supplied,
      selectedLoan.IDNumber,
      isMaxWithdraw,
    );
  };

  const { yearnSupply } = useYearnSupply(selectedLoan.token);
  const { yearnWithdraw } = useYearnWithdraw(selectedLoan.token);

  const supplyTransactionHandler = useTransactionHandler("yearnSupply", setSuccess, setSupplying);
  const supply = async () => {
    setSupplying("loading");

    try {
      const supplyAmount = isMaxDeposit ? maxDepositAmount : amount;
      await yearnSupply(supplyTransactionHandler, supplyAmount, selectedLoan.IDNumber);
    } catch (err) {
      setSupplying(null);
      console.log(err);
      return false;
    }
  };

  const { setOnSubmenuClose } = useUIContext();

  // useEffect(() => {
  //   (async () => {
  //     if (yToken.cTokenContract) {
  //       const supplied = await yToken.cTokenContract.methods
  //         .balanceOfUnderlying(selectedLoan.escrow)
  //         .call();
  //       setSupplied(
  //         new BigNumber(supplied).shiftedBy(-selectedLoan.lendingTokenDecimals).toString(),
  //       );
  //
  //       // const earned = "0"
  //       // setEarned(earned);
  //     }
  //   })();
  // }, [selectedLoan, yToken]);

  useEffect(() => {
    if (!selectedLoan) return;
    const maxAmount =
      selectedLoan && selectedLoan.escrowBalances
        ? selectedLoan.escrowBalances[selectedLoan.token]
        : 0;
    setMaxDepositAmount(maxAmount);
    if (Number(amount) > Number(maxAmount)) {
      setMaxWithdrawDepositWarning(
        `You cannot deposit more than ${roundToFourDecimals(maxAmount)} ${selectedLoan.token}.`,
      );
    } else {
      setMaxWithdrawDepositWarning("");
    }
    if (Number(amount) > Number(selectedLoan.compound.supplied)) {
      setMaxWithdrawWithdrawWarning(
        `You cannot withdraw more than ${roundToFourDecimals(supplied)} ${selectedLoan.token}.`,
      );
    } else {
      setMaxWithdrawWithdrawWarning("");
    }
  }, [amount, selectedLoan]);
  const getAvailableBalance = (loan: any) => {
    return loan.borrowedAmountNumber;
  };
  const onSumbmenuClose = () => {
    setAmountSubmenu(false);
  };
  useEffect(() => {
    setOnSubmenuClose(() => (amountSubmenu ? onSumbmenuClose : null));
  }, [amountSubmenu]);
  const withdrawDisabled = isMaxWithdraw ? false : Boolean(maxWithdrawWarning != "" || amount == 0);
  const depositDisabled = isMaxDeposit ? false : Boolean(maxDepositWarning != "" || amount == 0);

  const escrow = useEscrowBalances(selectedLoan.escrow);
  useEffect(() => {
    if (!escrow) return;
    let mounted = true;

    (async () => {
      if (mounted) {
        setSelectedLoan({ ...selectedLoan, escrowBalances: escrow });
      }
    })();
    return () => {
      mounted = false;
    };
  }, [escrow]);

  return amountSubmenu ? (
    <SubmenuCard title="Amount" onCloseAction={onSumbmenuClose}>
      <div className="flex flex-col align-items-center">
        <CustomInput
          onChangeFunction={(e: any) => {
            let value = e.target.value.replace(/[^0-9.]/g, "");
            const split = value.split(".");
            if (split[1] && split[1].length > 4) {
              value = `${split[0]}.${split[1].substring(0, 4)}`;
            }
            if (isNaN(value)) {
              value = "0.0000";
            }
            setAmount(value);
            if (isMaxWithdraw) {
              setMaxWithdraw(false);
            }
            if (isMaxDeposit) {
              setMaxDeposit(false);
            }
          }}
          value={amount ? amount.toString() : ""}
          type="string"
          onBlur={(e: any) => {
            let value = parseFloat(e.target.value);
            if (isNaN(value)) {
              value = 0;
            }
            setAmount(value);
            if (isMaxWithdraw) {
              setMaxWithdraw(false);
            }
            if (isMaxDeposit) {
              setMaxDeposit(false);
            }
          }}
          placeholder="0.0000"
        />
        <div className="text-lightest-gray text-lg xs:text-md ">{selectedLoan.token}</div>
        {transactionType == "Withdraw" && Number(supplied) > 0 && (
          <label className="mt-4 inline-flex items-center text-sm text-lightest-gray">
            <input
              type="checkbox"
              className="form-checkbox h-4 w-4"
              style={{ color: "#8B7CF1" }}
              checked={isMaxWithdraw}
              onChange={() => {
                if (!isMaxWithdraw) {
                  setAmount(supplied.toString());
                  setMaxWithdraw(true);
                  setMaxDeposit(false);
                } else {
                  setMaxWithdraw(false);
                }
              }}
            />
            <span className="ml-2">
              {`Select MAX withdraw amount (~${Math.round(supplied.toString())} DAI)`}
            </span>
          </label>
        )}
        {transactionType == "Deposit" && maxDepositAmount > 0 && (
          <label className="mb-2 mt-1 inline-flex items-center text-sm text-lightest-gray">
            <input
              type="checkbox"
              className="form-checkbox h-4 w-4"
              style={{ color: "#8B7CF1" }}
              checked={isMaxDeposit}
              onChange={() => {
                if (!isMaxDeposit) {
                  setAmount(maxDepositAmount);
                  setMaxDeposit(true);
                  setMaxWithdraw(false);
                } else {
                  setMaxDeposit(false);
                }
              }}
            />
            <span className="ml-2">
              {`Select MAX deposit amount (~${Math.round(maxDepositAmount)} DAI)`}
            </span>
          </label>
        )}
        <div
          className="py-1 px-3 my-4 mx-auto border-thin pointer text-black"
          onClick={() => {
            setAmountSubmenu(false);
          }}
        >
          Submit
        </div>
      </div>
    </SubmenuCard>
  ) : (
    <div>
      <div className="border-t-none border-thin mb-4 mt-3">
        <div className="flex flex-row text-center">
          <div
            onClick={() => {
              setTransactionType("Deposit");
            }}
            className={`${
              transactionType == "Deposit" ? "bg-gray-dark text-white" : "goback-header text-teal"
            } flex flex-1 rounded-tl-md p-4 pointer justify-center`}
          >
            Deposit
          </div>
          <div
            className={`${
              transactionType == "Withdraw" ? "bg-gray-dark text-white" : "goback-header text-teal"
            } flex flex-1 rounded-tr-md p-4 pointer justify-center`}
            onClick={() => {
              setTransactionType("Withdraw");
            }}
          >
            Withdraw
          </div>
        </div>
        <BR />
        <TableRow title="Loan amount">
          <div className="font-medium">
            {`${
              getAvailableBalance(selectedLoan) < 0.0001
                ? "<0.0001"
                : roundToFourDecimals(getAvailableBalance(selectedLoan))
            } ${selectedLoan.token}`}
          </div>
        </TableRow>
        <BR />
        <TableRow title={`Deposited ${selectedLoan.token}`}>
          <div className="font-medium">
            {`${roundToFourDecimals(supplied)} ${selectedLoan.token}`}
          </div>
        </TableRow>
        <BR />
        <TableRow title="APY">
          <div className="font-medium">{roundToFourDecimals(DAIsupplyAPY)}%</div>
        </TableRow>
      </div>
      <div className="border-t-none border-thin mt-6 mb-4">
        {/* Commented out until DAI earned calculation is finalized
              <TableRow title={`${selectedLoan.token} earned`}>
                <div className="font-medium">{`${selectedLoan.compound.earned} ${selectedLoan.token}`}</div>
                  </TableRow> */}
        <TableRow title="Amount">
          <CustomSubmenuLink
            title={`${amount || 0} ${selectedLoan.token}`}
            CTA={() => {
              setAmountSubmenu(true);
            }}
          />
        </TableRow>
      </div>
      <ViewContractLink hash={selectedLoan.escrow} />
      {transactionType == "Deposit" && maxDepositWarning && (
        <FormValidationWarning message={maxDepositWarning} />
      )}
      {transactionType == "Withdraw" && maxWithdrawWarning && (
        <FormValidationWarning message={maxWithdrawWarning} />
      )}
      <div className="flex flex-row flex-wrap justify-center">
        <div>
          <PrimaryButton
            text={transactionType}
            onClick={() => {
              if (transactionType == "Deposit") {
                if (!maxDepositWarning && amount > 0) {
                  supply();
                }
              } else if (!maxWithdrawWarning && amount > 0) {
                withdraw();
              }
            }}
            disabled={transactionType == "Deposit" ? depositDisabled : withdrawDisabled}
          />
        </div>
      </div>
    </div>
  );
};
