import { useLoansContext } from "contexts/global/loansContext";
import { useCompAPY } from "hooks/useCompAPY";
import React, { createContext, useEffect, useMemo, useState } from "react";

import {
  AvailableLendingTokens,
  AvailableTokens,
  BorrowDepositStateInterface,
  BorrowRepayStateInterface,
  BorrowWithdrawStateInterface,
  ContextProps,
  DashboardContextInterface,
  LendClaimStateInterface,
  LendWithdrawStateInterface,
  LoanInterface,
  PageTypes,
  UseCompoundStateInterface,
} from "../types";
import { useTellerContext } from "contexts/global/tellerContext";
import { useAaveDaiAPY } from "hooks/useAaveDaiAPY";
import { useYearnAPY } from "hooks/useYearnAPY";

const defaultNavigationMap: NavigationInterface = {
  // DEPOSIT: { Portfolio: "deposit-portfolio", Withdraw: "deposit-withdraw" },
  BORROW: {
    Deposit: "borrow-deposit",
    Repay: "borrow-repay",
    Withdraw: "borrow-withdraw",
  },
  SPEND: {
    Compound: "spend-compound",
    Uniswap: "spend-uniswap",
    Yearn: "spend-yearn",
    Aave: "spend-aave",
    // PoolTogether: "spend-pooltogether",
    Sushiswap: "spend-sushiswap",
  },
};

const defaultDashboardContext: DashboardContextInterface = {
  loanTransactions: [],
  loans: null,
  loansLoading: false,
  loanIDs: [],
  loansLoadingStatus: "loading",
  onPage: PageTypes["borrow-repay"],
  setLoansLoadingStatus: () => null,
  setOnPage: () => null,
};
export const DashboardContext = createContext<DashboardContextInterface>(defaultDashboardContext);

const DashboardContextProvider = ({ children }: ContextProps) => {
  const [loansLoadingStatus, setLoansLoadingStatus] = useState("loading");
  const [onPage, setOnPage] = useState(PageTypes["borrow-repay"]);
  const { loans, loansLoading, loanIDs } = useLoansContext();

  const value = useMemo(() => {
    return {
      loans,
      loansLoading,
      loanIDs,
      loansLoadingStatus,
      onPage,
      setLoansLoadingStatus,
      setOnPage,
    };
  }, [loans, loansLoading, loanIDs, loansLoadingStatus, onPage]);
  return <DashboardContext.Provider value={value}>{children}</DashboardContext.Provider>;
};
export default DashboardContextProvider;

// LEND-CLAIM
const defaultLendClaimState: LendClaimStateInterface = {
  assetChangeWarning: null as null,
  assetClaimed: null as null,
  isClaiming: null as null,
  isCollecting: null as null,
  setAssetChangeWarning: () => null,
  setAssetClaimed: () => null,
  setClaiming: () => null,
  setCollecting: () => null,
  setSuccess: () => null,
  success: null as null,
};
export const LendClaimContext = React.createContext(defaultLendClaimState);
export const LendClaimContextProvider = ({ children }: ContextProps) => {
  const [success, setSuccess] = useState<LendClaimStateInterface["success"]>(
    defaultLendClaimState.success,
  );
  const [isCollecting, setCollecting] = useState<LendClaimStateInterface["isCollecting"]>(null);
  const [assetChangeWarning, setAssetChangeWarning] =
    useState<LendClaimStateInterface["assetChangeWarning"]>(null);
  const [isClaiming, setClaiming] = useState<LendClaimStateInterface["isClaiming"]>(null);
  const [assetClaimed, setAssetClaimed] = useState<LendClaimStateInterface["assetClaimed"]>(null);

  const state = useMemo(() => {
    return {
      assetChangeWarning,
      assetClaimed,
      isClaiming,
      isCollecting,
      setAssetChangeWarning,
      setAssetClaimed,
      setClaiming,
      setCollecting,
      setSuccess,
      success,
    };
  }, [assetChangeWarning, assetClaimed, isClaiming, isCollecting, success]);
  return <LendClaimContext.Provider value={state}>{children}</LendClaimContext.Provider>;
};

// LEND-WITHDRAW
const defaultLendWithdrawState: LendWithdrawStateInterface = {
  isWithdrawing: null,
  selectedCurrency: null,
  setSelectedCurrency: () => null,
  setSuccess: () => null,
  setWarning: () => null,
  setWarningMessage: () => null,
  setWithdrawing: () => null,
  success: null,
  warning: "",
  warningMessage: "",
};
export const LendWithdrawContext = React.createContext(defaultLendWithdrawState);
export const LendWithdrawContextProvider = ({ children }: ContextProps) => {
  const { lendingToken } = useTellerContext();
  const [selectedCurrency, setSelectedCurrency] = useState<AvailableLendingTokens>(lendingToken);
  const [isWithdrawing, setWithdrawing] =
    useState<LendWithdrawStateInterface["isWithdrawing"]>(null);
  const [success, setSuccess] = useState<LendWithdrawStateInterface["success"]>(null);
  const [warningMessage, setWarningMessage] =
    useState<LendWithdrawStateInterface["warningMessage"]>("");
  const [warning, setWarning] = useState("");

  const state = useMemo(() => {
    return {
      isWithdrawing,
      selectedCurrency,
      setSelectedCurrency,
      setSuccess,
      setWarning,
      setWarningMessage,
      setWithdrawing,
      success,
      warning,
      warningMessage,
    };
  }, [isWithdrawing, selectedCurrency, success, warning, warningMessage]);

  return <LendWithdrawContext.Provider value={state}>{children}</LendWithdrawContext.Provider>;
};

// BORROW-REPAY
const defaultBorrowRepayState: BorrowRepayStateInterface = {
  selectedLoan: null as null,
  setSelectedLoan: () => null,
  isRepaying: null,
  setRepaying: () => null,
  repaySuccess: null,
  setRepaySuccess: () => null,
};

export const BorrowRepayContext = React.createContext(defaultBorrowRepayState);
export const BorrowRepayContextProvider = ({ children }: ContextProps) => {
  const [selectedLoan, setSelectedLoan] = useState<LoanInterface | null>(null);
  const [isRepaying, setRepaying] = useState<BorrowRepayStateInterface["isRepaying"]>(null);
  const [repaySuccess, setRepaySuccess] = useState<boolean>(null);

  return (
    <BorrowRepayContext.Provider
      value={{
        isRepaying,
        repaySuccess,
        selectedLoan,
        setRepaySuccess,
        setRepaying,
        setSelectedLoan,
      }}
    >
      {children}
    </BorrowRepayContext.Provider>
  );
};

// BORROW-DEPOSIT
const defaultBorrowDepositState: BorrowDepositStateInterface = {
  addCollateralSubmenu: null as null,
  collateral: null as null,
  isDepositing: "",
  newCollateralPercent: null as null,
  selectedLoan: null as null,
  setAddCollateralSubmenu: () => null,
  setCollateral: () => null,
  setDepositing: () => null,
  setNewCollateralPercent: () => null,
  setSelectedLoan: () => null,
  setSuccess: () => null,
  success: false,
};
export const BorrowDepositContext = React.createContext(defaultBorrowDepositState);
export const BorrowDepositContextProvider = ({ children }: ContextProps) => {
  const [success, setSuccess] = useState<BorrowDepositStateInterface["success"]>(false);
  const [isDepositing, setDepositing] = useState<BorrowDepositStateInterface["isDepositing"]>("");
  const [selectedLoan, setSelectedLoan] =
    useState<BorrowDepositStateInterface["selectedLoan"]>(null);
  const [addCollateralSubmenu, setAddCollateralSubmenu] =
    useState<BorrowDepositStateInterface["addCollateralSubmenu"]>(false);
  const [collateral, setCollateral] = useState<BorrowDepositStateInterface["collateral"]>(
    selectedLoan ? selectedLoan.collateral : null,
  );
  const [newCollateralPercent, setNewCollateralPercent] =
    useState<BorrowDepositStateInterface["newCollateralPercent"]>(null);

  const state = useMemo(() => {
    return {
      addCollateralSubmenu,
      collateral,
      isDepositing,
      newCollateralPercent,
      selectedLoan,
      setAddCollateralSubmenu,
      setCollateral,
      setDepositing,
      setNewCollateralPercent,
      setSelectedLoan,
      setSuccess,
      success,
    };
  }, [addCollateralSubmenu, collateral, isDepositing, newCollateralPercent, selectedLoan, success]);

  return <BorrowDepositContext.Provider value={state}>{children}</BorrowDepositContext.Provider>;
};

// BORROW-WITHDRAW
const defaultBorrowWithdrawState: BorrowWithdrawStateInterface = {
  isWithdrawing: null as null,
  newCollateralPercent: null,
  selectedLoan: null as null,
  setNewCollateralPercent: () => null,
  setSelectedLoan: () => null,
  setSuccess: () => null,
  setWithdrawAmount: () => null,
  setWithdrawCollateralSubmenu: () => null,
  setWithdrawing: () => null,
  success: null as null,
  withdrawAmount: 0,
  withdrawCollateralSubmenu: false,
};
export const BorrowWithdrawContext = React.createContext(defaultBorrowWithdrawState);
export const BorrowWithdrawContextProvider = ({ children }: ContextProps) => {
  const [success, setSuccess] = useState<BorrowWithdrawStateInterface["success"]>(null);
  const [isWithdrawing, setWithdrawing] =
    useState<BorrowWithdrawStateInterface["isWithdrawing"]>(null);
  const [selectedLoan, setSelectedLoan] =
    useState<BorrowWithdrawStateInterface["selectedLoan"]>(null);
  const [withdrawCollateralSubmenu, setWithdrawCollateralSubmenu] =
    useState<BorrowWithdrawStateInterface["withdrawCollateralSubmenu"]>(false);
  const [withdrawAmount, setWithdrawAmount] =
    useState<BorrowWithdrawStateInterface["withdrawAmount"]>(null);
  const [newCollateralPercent, setNewCollateralPercent] =
    useState<BorrowDepositStateInterface["newCollateralPercent"]>(null);

  const state = useMemo(() => {
    return {
      isWithdrawing,
      newCollateralPercent,
      selectedLoan,
      setNewCollateralPercent,
      setSelectedLoan,
      setSuccess,
      setWithdrawAmount,
      setWithdrawCollateralSubmenu,
      setWithdrawing,
      success,
      withdrawAmount,
      withdrawCollateralSubmenu,
    };
  }, [
    isWithdrawing,
    newCollateralPercent,
    selectedLoan,
    success,
    withdrawAmount,
    withdrawCollateralSubmenu,
  ]);

  return <BorrowWithdrawContext.Provider value={state}>{children}</BorrowWithdrawContext.Provider>;
};

// BORROW-TERMS
const defaultBorrowTermsState: any = {
  NFTs: [],
  borrowRequest: null as null,
  isWithdrawing: null,
  loading: null as null,
  loanTerms: null as null,
  newCollateralPercent: null,
  selectedLoan: null as null,
  setBorrowRequest: () => null,
  setNFTs: () => null,
  setNewCollateralPercent: () => null,
  setRequestingLoan: () => null,
  setSelectedLoan: () => null,
  setSubmenu: () => null,
  setSuccess: () => null,
  setTerms: () => null,
  setTermsAction: () => null,
  setWithdrawAmount: () => null,
  setWithdrawCollateralSubmenu: () => null,
  setWithdrawing: () => null,
  submenu: null,
  success: null as null,
  termsAction: null as null,
  withdrawAmount: null,
  withdrawCollateralSubmenu: null,
};
export const BorrowTermsContext = React.createContext(defaultBorrowTermsState);
export const BorrowTermsContextProvider = ({ children }: ContextProps) => {
  const [NFTs, setNFTs] = useState(null);
  const [borrowRequest, setBorrowRequest] = useState(null);
  const [isWithdrawing, setWithdrawing] = useState(null);
  const [loading, setRequestingLoan] = useState(null);
  const [loanTerms, setLoanTerms] = useState(null);
  const [newCollateralPercent, setNewCollateralPercent] = useState(null);
  const [selectedLoan, setSelectedLoan] = useState(null);
  const [submenu, setSubmenu] = useState(null);
  const [success, setSuccess] = useState(null);
  const [termsAction, setTermsAction] = useState(null);
  const [withdrawAmount, setWithdrawAmount] = useState(null);
  const [withdrawCollateralSubmenu, setWithdrawCollateralSubmenu] = useState(false);

  const state = useMemo(() => {
    return {
      NFTs,
      borrowRequest,
      isWithdrawing,
      loading,
      loanTerms,
      newCollateralPercent,
      selectedLoan,
      setBorrowRequest,
      setLoanTerms,
      setNFTs,
      setNewCollateralPercent,
      setRequestingLoan,
      setSelectedLoan,
      setSubmenu,
      setSuccess,
      setTermsAction,
      setWithdrawAmount,
      setWithdrawCollateralSubmenu,
      setWithdrawing,
      submenu,
      success,
      termsAction,
      withdrawAmount,
      withdrawCollateralSubmenu,
    };
  }, [
    NFTs,
    borrowRequest,
    isWithdrawing,
    loading,
    loanTerms,
    newCollateralPercent,
    selectedLoan,
    submenu,
    success,
    termsAction,
    withdrawAmount,
    withdrawCollateralSubmenu,
  ]);

  return <BorrowTermsContext.Provider value={state}>{children}</BorrowTermsContext.Provider>;
};

// USE-COMPOUND
const defaultUseLendingDappState: UseCompoundStateInterface = {
  amount: 0,
  amountSubmenu: false,
  isSupplying: null,
  isWithdrawing: null,
  selectedLoan: null as null,
  setAmount: () => null,
  setAmountSubmenu: () => null,
  setSelectedLoan: () => null,
  setSuccess: () => null,
  setSuccessMessage: () => null,
  setSupplying: () => null,
  setWithdrawing: () => null,
  success: null,
  successMessage: "",
  useCompAPY: (symbol: AvailableTokens) => null,
};
export const UseCompoundContext = React.createContext(defaultUseLendingDappState);
export const UseCompoundContextProvider = ({ children }: ContextProps) => {
  const [amount, setAmount] = useState(null);
  const [amountSubmenu, setAmountSubmenu] = useState(false);
  const [isSupplying, setSupplying] = useState(null);
  const [isWithdrawing, setWithdrawing] = useState(null);
  const [selectedLoan, setSelectedLoan] = useState(null);
  const [success, setSuccess] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");

  const state = useMemo(() => {
    return {
      useCompAPY,
      success,
      setSuccess,
      isWithdrawing,
      setWithdrawing,
      isSupplying,
      setSupplying,
      selectedLoan,
      setSelectedLoan,
      amountSubmenu,
      setAmountSubmenu,
      amount,
      setAmount,
      successMessage,
      setSuccessMessage,
    };
  }, [
    useCompAPY,
    success,
    isWithdrawing,
    setWithdrawing,
    selectedLoan,
    isSupplying,
    amountSubmenu,
    amount,
    successMessage,
  ]);
  return <UseCompoundContext.Provider value={state}>{children}</UseCompoundContext.Provider>;
};

export const UseAaveContext = React.createContext(defaultUseLendingDappState);
export const UseAaveContextProvider = ({ children }: ContextProps) => {
  const [amount, setAmount] = useState(null);
  const [amountSubmenu, setAmountSubmenu] = useState(false);
  const [isSupplying, setSupplying] = useState(null);
  const [isWithdrawing, setWithdrawing] = useState(null);
  const [selectedLoan, setSelectedLoan] = useState(null);
  const [success, setSuccess] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");

  const DAIsupplyAPY = useAaveDaiAPY();

  const state = useMemo(() => {
    return {
      DAIsupplyAPY,
      success,
      setSuccess,
      isWithdrawing,
      setWithdrawing,
      isSupplying,
      setSupplying,
      selectedLoan,
      setSelectedLoan,
      amountSubmenu,
      setAmountSubmenu,
      amount,
      setAmount,
      successMessage,
      setSuccessMessage,
    };
  }, [
    DAIsupplyAPY,
    amount,
    amountSubmenu,
    isSupplying,
    isWithdrawing,
    selectedLoan,
    success,
    successMessage,
  ]);
  return <UseAaveContext.Provider value={state}>{children}</UseAaveContext.Provider>;
};

export const UsePoolTogetherContext = React.createContext(defaultUseLendingDappState);
export const UsePoolTogetherContextProvider = ({ children }: ContextProps) => {
  const [amount, setAmount] = useState(null);
  const [amountSubmenu, setAmountSubmenu] = useState(false);
  const [isSupplying, setSupplying] = useState(null);
  const [isWithdrawing, setWithdrawing] = useState(null);
  const [selectedLoan, setSelectedLoan] = useState(null);
  const [success, setSuccess] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");

  const DAIsupplyAPY = useAaveDaiAPY();

  const state = useMemo(() => {
    return {
      DAIsupplyAPY,
      amount,
      amountSubmenu,
      isSupplying,
      isWithdrawing,
      selectedLoan,
      setAmount,
      setAmountSubmenu,
      setSelectedLoan,
      setSuccess,
      setSuccessMessage,
      setSupplying,
      setWithdrawing,
      success,
      successMessage,
    };
  }, [
    DAIsupplyAPY,
    amount,
    amountSubmenu,
    isSupplying,
    isWithdrawing,
    selectedLoan,
    success,
    successMessage,
  ]);
  return (
    <UsePoolTogetherContext.Provider value={state}>{children}</UsePoolTogetherContext.Provider>
  );
};

export const UseYearnContext = React.createContext(defaultUseLendingDappState);
export const UseYearnContextProvider = ({ children }: ContextProps) => {
  const [amount, setAmount] = useState(null);
  const [amountSubmenu, setAmountSubmenu] = useState(false);
  const [isSupplying, setSupplying] = useState(null);
  const [isWithdrawing, setWithdrawing] = useState(null);
  const [selectedLoan, setSelectedLoan] = useState(null);
  const [success, setSuccess] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");

  const DAIsupplyAPY = useYearnAPY("DAI");

  const value = useMemo(() => {
    return {
      DAIsupplyAPY,
      success,
      setSuccess,
      isWithdrawing,
      setWithdrawing,
      isSupplying,
      setSupplying,
      selectedLoan,
      setSelectedLoan,
      amountSubmenu,
      setAmountSubmenu,
      amount,
      setAmount,
      successMessage,
      setSuccessMessage,
    };
  }, [
    DAIsupplyAPY,
    success,
    isWithdrawing,
    isSupplying,
    selectedLoan,
    amountSubmenu,
    amount,
    successMessage,
  ]);

  return <UseYearnContext.Provider value={value}>{children}</UseYearnContext.Provider>;
};
