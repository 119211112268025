import { CustomSubmitButton } from "components/UI/CustomSubmitButton";
import { BorrowPageContext } from "contexts/borrow/borrowContext";
import { BorrowTermsContext } from "contexts/dashboard/dashboardContext";
import { useNFTsContext } from "contexts/global/nftsContext";
import { useWeb3StateContext } from "contexts/global/web3StateContext";
import React, { useContext, useEffect, useMemo, useState } from "react";
import LinkNFTModal from "./LinkNFTModal";

type LinkNFTButtonProps = {
  page?: string;
};
const LinkNFTButton = ({ page = "borrow" }: LinkNFTButtonProps) => {
  const { NFTs } = useContext(page == "borrow" ? BorrowPageContext : BorrowTermsContext);

  const nftIsSelected = useMemo(() => {
    return NFTs ? NFTs.some((nft: any) => nft.selected == true) : false;
  }, [NFTs]);

  const [loading, setLoading] = useState(false);
  const [approved, setApproved] = useState(nftIsSelected);
  const [showModal, setShowModal] = useState(false);
  const toggleModalOpen = (bool: true | false) => {
    setLoading(bool);
    setShowModal(bool);
  };
  const { userAddress } = useWeb3StateContext();
  const stakedNFTs = useNFTsContext();

  useEffect(() => {
    setApproved(nftIsSelected);
  }, [nftIsSelected]);

  return (
    <>
      <CustomSubmitButton
        disabled={loading || !userAddress || !stakedNFTs}
        loading={loading}
        CTA={() => {
          toggleModalOpen(true);
        }}
        approved={nftIsSelected}
        text="Submit"
      />
      {stakedNFTs && (
        <LinkNFTModal
          showModal={showModal}
          setModalOpened={toggleModalOpen}
          setLoading={setLoading}
          setApproved={setApproved}
          page={page}
        />
      )}
    </>
  );
};

export default LinkNFTButton;
