import { useNFTsContext } from "contexts/global/nftsContext";
import { NFTInterface } from "contexts/types";

const useNFT: (id: string) => NFTInterface = (id) => {
  // console.log("useNFT");

  const { allClaimedNFTs } = useNFTsContext();
  const nft = allClaimedNFTs.find((x) => x.id === id);

  return nft;
};

export default useNFT;
