import React, { useState } from "react";
import "./customSlider.scss";

interface sliderProps {
  min: number;
  max: number;
  onChange: (e: any) => void;
  value: number;
}

const CustomSlider = ({ min, max, onChange, value }: sliderProps) => {
  return (
    <div className="flex flex-1 flex-col slider">
      <input
        className="mb-0 mt-4 rounded-lg overflow-hidden appearance-none bg-grey-03 h-3 color-primary flex flex-1 cursor"
        type="range"
        min={min}
        max={max}
        step={max < 10 ? 0.01 : 1}
        value={value}
        onChange={onChange}
      />
      <div className="text-xs text-gray flex flex-row justify-between my-2">
        <div>{min.toLocaleString()}</div>
        <div>{max.toLocaleString()}</div>
      </div>
    </div>
  );
};

export default CustomSlider;
