import React from "react";
import ReactTooltip from "react-tooltip";

import tooltip_icon from "assets/tooltip.svg";
import tooltip_icon_white from "assets/tooltip-white.svg";

interface TooltipProps {
  header: string;
  text: string;
  white?: boolean;
}
const Tooltip = React.memo(({ header, text, white = false }: TooltipProps) => {
  return (
    <div className="whitespace-normal">
      <div
        data-tip={`
        <div class = "rounded flex flex-col max-w-xxs py-3 px-2 text-left">
          <div class="font-medium text-sm ">${header}<div>
          <div class="text-xs font-normal mt-3">${text}<div>
        </div>
        `}
        data-html
      >
        <img className="mx-2" src={white ? tooltip_icon_white : tooltip_icon} height="16" />
      </div>
      <div>
        <ReactTooltip className="tooltip" backgroundColor="white" textColor="#09152B" html />
      </div>
    </div>
  );
});

Tooltip.displayName = "Tooltip";

export default Tooltip;
