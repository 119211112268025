import React from "react";

export type DataLoadingWrapperProps = {
  controlVar: any;
  height?: number;
  width?: number;
  children: React.ReactNode;
  bg?: string;
};

/**
 *
 * @param controlVar  - Variable that is used to check if data was loaded
 * @param height - Optional - pre-defined height of an element. Defaults to 8px
 * @param width  -Optional - width of an element. Defaults to 112ps
 * @param children - Component we want to render
 * @returns Replaces the element with e grey box until control var is loaded
 */

const DataLoadingWrapper = ({
  controlVar = 2,
  height = 8,
  width = 112,
  children,
  bg = "",
}: DataLoadingWrapperProps) => {
  return (
    <>
      {controlVar ? (
        children
      ) : (
        <div
          className={`animate-pulse py-1 px-5 rounded text-grey-01 my-auto ${
            bg ? bg : "bg-grey-button-bg"
          }`}
          style={{ height: `${height}px`, width: `${width}px` }}
        ></div>
      )}
    </>
  );
};

export default DataLoadingWrapper;
