import React, { useContext, useEffect, useMemo, useState } from "react";

import BR from "components/UI/BR";
import CustomInput from "components/UI/CustomInput";
import CustomSubmenuLink from "components/UI/CustomSubmenuLink";
import FormValidationWarning from "components/UI/FormValidationWarning";
import PrimaryButton from "components/UI/PrimaryButton";
import SubmenuCard from "components/UI/SubmenuCard";
import TableRow from "components/UI/TableRow";
import { NULL_ADDRESS } from "constants/constants";
import copy from "constants/copy.json";
import { BorrowDepositContext, DashboardContext } from "contexts/dashboard/dashboardContext";
import { calculateCollateralPercent, roundToFourDecimals } from "utils/helperFunctions";

import ViewContractLink from "../ViewContractLink";
import { useTransactionHandler } from "hooks/useTransactionHandler";
import { useTellerContext } from "contexts/global/tellerContext";
import { useDepositCollateral } from "actions/DepositCollateral";
import { useTokenPrice } from "hooks/useTokenPrice";
import { useBalance } from "hooks/useBalance";
import { useAllowance } from "hooks/useAllowance";
import { useApprove } from "actions/Approve";
import CurrencyIcon from "components/UI/CurrencyIcon";

const DepositMainSection = () => {
  const { loans } = useContext(DashboardContext);
  const { selectedLoan, setSelectedLoan } = useContext(BorrowDepositContext);
  const { collateralBuffer } = useTellerContext();
  const descriptionCopy = copy.pages.dashboard["borrow-deposit"].description;

  const currentLoans = useMemo(() => {
    return loans
      ? loans.filter((loan: any) => {
          return loan?.statusName !== "Repaid" && loan?.collateralAmount > 0;
        })
      : null;
  }, [loans]);

  return (
    <div className="">
      {selectedLoan && <SelectedLoan />}
      {!selectedLoan && (
        <div className="my-2">
          <div className="text-gray mb-4">{descriptionCopy}</div>
          <div className="my-4">
            {currentLoans &&
              currentLoans
                .map((loan: any) => {
                  loan.percentFromLiquidation =
                    Number(loan?.currentCollateralPercent) -
                    Number(loan?.terms.collateralRatio / 100) +
                    collateralBuffer;
                  return loan;
                })
                .sort((a: any, b: any) => {
                  return a.percentFromLiquidation - b.percentFromLiquidation;
                })
                .map((loan: any, i: number) => {
                  const percentFromLiquidaton = Number(loan?.percentFromLiquidation);
                  const borderRadius =
                    i === 0
                      ? "4px 4px 0px 0px"
                      : i === currentLoans.length - 1
                      ? " 0px 0px 4px 4px"
                      : "0px";
                  const borderTop = i > 0 ? "none" : "1px solid #95C5C4";
                  const percentFromLiquidationString = `${Math.round(
                    percentFromLiquidaton,
                  )} % from liquidation`;

                  return (
                    <div
                      key={loan?.id}
                      style={
                        Math.round(percentFromLiquidaton) < 0
                          ? {
                              border: "1px solid #FF8363",
                              borderRadius: `${borderRadius}`,
                              marginTop: "-1px",
                            }
                          : {
                              border: "1px solid #95C5C4",
                              borderTop: `${borderTop}`,
                              borderRadius: `${borderRadius}`,
                            }
                      }
                    >
                      <TableRow title={loan?.id}>
                        <div className="flex-2 flex flex-row justify-between align-items-center">
                          <div className="text-xs text-lightest-gray my-auto">
                            {percentFromLiquidationString}
                          </div>
                          <CustomSubmenuLink
                            title={`${roundToFourDecimals(loan?.collateralAmountNumber)} ${
                              loan?.collateralToken
                            }`}
                            CTA={() => {
                              setSelectedLoan(loan);
                            }}
                          />
                        </div>
                      </TableRow>
                    </div>
                  );
                })}
          </div>
        </div>
      )}
    </div>
  );
};

export default DepositMainSection;

const SelectedLoan = () => {
  const {
    setSuccess,
    setDepositing,
    selectedLoan,
    addCollateralSubmenu,
    setAddCollateralSubmenu,
    collateral,
    setCollateral,
    setNewCollateralPercent,
    newCollateralPercent,
  } = useContext(BorrowDepositContext);
  const approvedAmount = useAllowance(selectedLoan?.collateralToken);
  const [needsApproval, setNeedsApproval] = useState(false);
  useEffect(() => {
    if (
      (!approvedAmount || Number(selectedLoan?.collateralAmount) > Number(approvedAmount)) &&
      selectedLoan?.collateralToken != "ETH"
    ) {
      console.log("needs approval");
      setNeedsApproval(true);
    } else {
      console.log("does not need approval ");
      setNeedsApproval(false);
    }
  }, [selectedLoan, approvedAmount]);

  const { collateralBuffer } = useTellerContext();
  const token = selectedLoan != undefined ? selectedLoan.token : null;
  const collateralToken = selectedLoan != undefined ? selectedLoan.collateralToken : null;
  const selectedLoanCopy = copy.pages.dashboard["borrow-deposit"].selectedLoan;

  // hooks
  const borrowedTokenPrice = useTokenPrice(token);
  const collateralTokenPrice = useTokenPrice(collateralToken);
  const collateralBalance = useBalance(collateralToken);
  const { depositCollateral } = useDepositCollateral(collateralToken);
  const { approve } = useApprove(collateralToken);
  const approveTransactionHandler = useTransactionHandler("approve", () => {
    setNeedsApproval(false);
  });

  const transactionHandler = useTransactionHandler(
    "depositCollateral",
    (hash: string) => {
      setSuccess(hash);
      setCollateral(0);
      setNewCollateralPercent(0);
    },
    setDepositing,
  );
  const deposit = async () => {
    if (!selectedLoan) return;
    setDepositing("loading");
    await depositCollateral(transactionHandler, collateral.toString(), selectedLoan?.IDNumber);
  };

  const approveCollateral = async () => {
    await approve(approveTransactionHandler, selectedLoan?.collateralAmount);
  };

  const onInputChange = (e: any) => {
    if (!selectedLoan) return;
    let value = e.target.value.replace(/[^0-9.]/g, "");
    const split = value.split(".");
    if (split[1] && split[1].length > 4) {
      value = `${split[0]}.${split[1].substring(0, 4)}`;
    }
    if (isNaN(value)) {
      value = "";
    }
    setCollateral(value);

    const newCollateralPercent = calculateCollateralPercent(
      collateralTokenPrice,
      borrowedTokenPrice,
      Number(selectedLoan?.collateralAmountNumber) + Number(value),
      selectedLoan?.borrowedAmountNumber,
    );
    setNewCollateralPercent(newCollateralPercent);
  };
  const onInputBlur = (e: any) => {
    let value = parseFloat(e.target.value);
    if (isNaN(value)) {
      value = 0;
    }
    setCollateral(value);
  };

  const totalPercentOwned = useMemo(() => {
    return selectedLoan
      ? (50 *
          (selectedLoan?.collateralAmountNumber * collateralTokenPrice +
            selectedLoan?.totalValueNumber * borrowedTokenPrice)) /
          (selectedLoan?.borrowedAmountNumber * borrowedTokenPrice)
      : null;
  }, [selectedLoan, collateralTokenPrice, borrowedTokenPrice]);

  const percentOfLoanInEscrow = useMemo(() => {
    return selectedLoan
      ? Math.round(100 * (selectedLoan?.totalValueNumber / selectedLoan?.borrowedAmountNumber))
      : null;
  }, [selectedLoan]);

  const amountFromLiquidationString = useMemo(() => {
    return selectedLoan
      ? ` ${roundToFourDecimals(
          (((selectedLoan?.currentCollateralPercent -
            selectedLoan?.terms.collateralRatio / 100 +
            collateralBuffer) /
            100) *
            selectedLoan?.borrowedAmountNumber *
            borrowedTokenPrice) /
            collateralTokenPrice,
        )} ${selectedLoan?.collateralToken}`
      : "";
  }, [selectedLoan, collateralBuffer, borrowedTokenPrice, collateralTokenPrice]);

  const collateralExceedsBalance = selectedLoan
    ? Boolean(collateral > Number(collateralBalance))
    : null;
  const depositDisabled = selectedLoan ? !(collateral > 0) || collateralExceedsBalance : null;

  return (
    <>
      {addCollateralSubmenu ? (
        <SubmenuCard
          title={selectedLoanCopy.addCollateral}
          onCloseAction={() => {
            setAddCollateralSubmenu(false);
          }}
        >
          <div className="flex flex-col items-center	">
            <CurrencyIcon currency={selectedLoan?.collateralToken} className="my-2 h-5" />
            <CustomInput
              onChangeFunction={onInputChange}
              value={collateral ? collateral.toString() : ""}
              type="string"
              onBlur={onInputBlur}
              placeholder="0.0000"
            />
            <div className="text-lightest-gray text-lg xs:text-md ">
              {newCollateralPercent || selectedLoan?.currentCollateralPercent}%
            </div>
            <div
              className="py-1 px-3 my-4 mx-auto border-thin pointer text-black"
              onClick={() => {
                setAddCollateralSubmenu(false);
              }}
            >
              Enter
            </div>
          </div>
        </SubmenuCard>
      ) : (
        <div>
          <div className=" border-thin mb-4 mt-3">
            <TableRow title={selectedLoanCopy.loanSize}>
              <div className="font-medium">
                {roundToFourDecimals(selectedLoan?.borrowedAmountNumber)} {selectedLoan?.token}
              </div>
            </TableRow>
            <BR />
            {selectedLoan?.escrow != NULL_ADDRESS && (
              <>
                <TableRow title={selectedLoanCopy.currentEscrowValue}>
                  <div className="font-medium">
                    {roundToFourDecimals(selectedLoan?.totalValueNumber)} {selectedLoan?.token}
                  </div>
                </TableRow>
                <BR />
                <TableRow title={selectedLoanCopy.percentOfOriginalLoan}>
                  <div className="font-medium">{percentOfLoanInEscrow}%</div>
                </TableRow>
                <BR />
              </>
            )}
            {selectedLoan?.collateralAmount > 0 && (
              <>
                <TableRow title={selectedLoanCopy.currentCollateralPercent}>
                  <div className="font-medium">
                    {Math.round(selectedLoan?.currentCollateralPercent)}%
                  </div>
                </TableRow>
                <BR />
                <TableRow title={selectedLoanCopy.collateralAmount}>
                  <div className="font-medium">
                    {roundToFourDecimals(selectedLoan?.collateralAmountNumber)}{" "}
                    {selectedLoan?.collateralToken}
                  </div>
                </TableRow>
                <BR />
                <TableRow title={selectedLoanCopy.percentAwayFromLiquidation}>
                  <div className="font-medium">
                    {roundToFourDecimals(
                      Math.round(
                        selectedLoan?.currentCollateralPercent -
                          selectedLoan?.terms.collateralRatio / 100 +
                          collateralBuffer,
                      ),
                    )}
                    %
                  </div>
                </TableRow>
                <BR />
                <TableRow title={selectedLoanCopy.amountAwayFromLiquidation}>
                  <div className="font-medium">{amountFromLiquidationString}</div>
                </TableRow>
              </>
            )}
            {selectedLoan?.escrow != NULL_ADDRESS && (
              <>
                <BR />
                <TableRow title={selectedLoanCopy.percentOfLoanAmountOwned}>
                  <div className="font-medium">{Math.round(totalPercentOwned)}%</div>
                </TableRow>
              </>
            )}
          </div>

          <div className="border-thin mb-4 mt-3">
            <TableRow title={selectedLoanCopy.addCollateral}>
              <CustomSubmenuLink
                title={`${roundToFourDecimals(collateral)} ${selectedLoan?.collateralToken}`}
                CTA={() => {
                  setAddCollateralSubmenu(true);
                }}
              />
            </TableRow>
            <BR />
            <TableRow title={selectedLoanCopy.newCollateralPercent}>
              <div className="font-medium">
                {newCollateralPercent ? `${Math.round(newCollateralPercent)}%` : "-"}
              </div>
            </TableRow>
          </div>
          <ViewContractLink hash={selectedLoan?.escrow} />
          <div>
            {needsApproval == true && <PrimaryButton text="Approve" onClick={approveCollateral} />}
            {needsApproval == false && (
              <PrimaryButton
                text={selectedLoanCopy.CTA}
                onClick={() => {
                  deposit();
                }}
                disabled={depositDisabled}
              />
            )}
          </div>
          <div className="mt-2">
            {collateralExceedsBalance && (
              <FormValidationWarning
                message={
                  collateralExceedsBalance
                    ? `Not enough ${selectedLoan?.collateralToken} balance.`
                    : null
                }
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};
