import { useCallback } from "react";
import { createNewTransactionWithHandler } from "actions/utils";
import { useWeb3StateContext } from "contexts/global/web3StateContext";
import { AvailableLendingTokens } from "contexts/types";
import { useTellerContext } from "contexts/global/tellerContext";
import { useToken } from "hooks/useToken";
import { ethers } from "ethers";
import { mergeV2IDsToBalances } from "utils/mergeV2IDsToBalances";
import BigNumber from "bignumber.js";
import moment from "moment";

export const useTakeOutLoan = (assetID: AvailableLendingTokens) => {
  const { tellerDiamondContract } = useTellerContext();
  const { userAddress } = useWeb3StateContext();
  const { tokenAddress } = useToken(assetID);
  /**
   * Takes out a loan from the Teller protocol
   * @param {*} depositTransactionHandler
   * @param {number} collateralAmount The ID of the loan being taken out
   * @param {*} nodeResponses Request and Response object
   * @param {string} collateralTokenAddress The address of the collateral token
   */
  const takeOutLoan = useCallback(
    async (
      depositTransactionHandler: any,
      collateralAmount: number,
      nodeResponses: any,
      collateralTokenAddress: string,
    ) => {
      await createNewTransactionWithHandler(
        () =>
          tellerDiamondContract.methods
            .takeOutLoan(nodeResponses, collateralTokenAddress, collateralAmount)
            .send({ from: userAddress, value: assetID == "ETH" ? collateralAmount : undefined }),
        depositTransactionHandler,
      );
    },
    [tellerDiamondContract, userAddress, assetID],
  );

  /**
   * Takes out a loan with NFTs from the Teller protocol
   * @param {*} depositTransactionHandler
   * @param selectedNFTIDs Array of NFT IDs we want to link
   * @param {*} nodeResponses Request and Response object
   */
  // web3.encode({1, web3.encode})
  const takeOutLoanWithNFTs = useCallback(
    async (
      depositTransactionHandler: any,
      selectedNFTIDs: any[],
      loanAmount: BigNumber,
      loanTerm: number,
    ) => {
      const nftObjectsV1 = [];
      const nftObjectsV2 = [];

      const coder = ethers.utils.defaultAbiCoder;
      for (let index = 0; index < selectedNFTIDs.length; index++) {
        const { id, type } = selectedNFTIDs[index];
        if (type === "V1") {
          nftObjectsV1.push(id);
        } else if (type === "V2") {
          nftObjectsV2.push(id);
        }
      }

      let tokenData: string;
      if (nftObjectsV1.length > 0 && nftObjectsV2.length == 0) {
        tokenData = coder.encode(
          ["uint16", "bytes"],
          [1, coder.encode(["uint256[]"], [nftObjectsV1])],
        );
      } else if (nftObjectsV1.length == 0 && nftObjectsV2.length > 0) {
        const v2NFTs = mergeV2IDsToBalances(nftObjectsV2);
        tokenData = coder.encode(
          ["uint16", "bytes"],
          [2, coder.encode(["uint256[]", "uint256[]"], [v2NFTs.ids, v2NFTs.balances])],
        );
      } else if (nftObjectsV1.length > 0 && nftObjectsV2.length > 0) {
        const v2NFTs = mergeV2IDsToBalances(nftObjectsV2);
        tokenData = coder.encode(
          ["uint16", "bytes"],
          [
            3,
            coder.encode(
              ["uint256[]", "uint256[]", "uint256[]"],
              [nftObjectsV1, v2NFTs.ids, v2NFTs.balances],
            ),
          ],
        );
      } else {
        depositTransactionHandler.onError();
        return;
      }

      const duration = moment.duration(loanTerm, "day");

      await createNewTransactionWithHandler(
        () =>
          tellerDiamondContract.methods
            .takeOutLoanWithNFTs(
              tokenAddress,
              loanAmount.toFixed(0).toString(),
              duration.asSeconds(),
              tokenData,
            )
            .send({ from: userAddress }),
        depositTransactionHandler,
      );
    },
    [tellerDiamondContract, userAddress, tokenAddress],
  );

  return { takeOutLoan, takeOutLoanWithNFTs };
};
