import { useWeb3StateContext } from "contexts/global/web3StateContext";
import { AvailableTokens } from "contexts/types";
import React, { useMemo } from "react";
import { useExchangeCurrency } from "./useExchangeCurrency";

export const useTokenPrice = (assetID: AvailableTokens) => {
  // console.log("useTokenPrice");
  const { network } = useWeb3StateContext();
  let asset = assetID;
  if (assetID == "MATIC") {
    asset = "WMATIC";
  }
  if (assetID == "ETH") {
    asset = "WETH";
  }
  if (assetID == "USDT" && (network.name == "kovan" || network.name == "mainnet")) {
    asset = "USDC";
  }
  const baseCurrency = network.name == "kovan" || network.name == "mainnet" ? "USDC" : "USDT";

  const price = useExchangeCurrency(asset, baseCurrency, 1);
  return price;
};
