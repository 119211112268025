import { useTellerContext } from "contexts/global/tellerContext";
import { useWeb3StateContext } from "contexts/global/web3StateContext";
import { createNewTransactionWithHandler } from "./utils";
import React, { useCallback } from "react";
import BigNumber from "bignumber.js";

export const useRepayLoan = () => {
  const { tellerDiamondContract } = useTellerContext();
  const { userAddress } = useWeb3StateContext();

  const repayLoan = useCallback(
    async (loanID: string, totalOwed: BigNumber, hasEscrow: boolean, transactionHandler: any) => {
      await createNewTransactionWithHandler(() => {
        if (!hasEscrow) {
          return tellerDiamondContract.methods
            .repayLoan(loanID, totalOwed)
            .send({ from: userAddress });
        } else {
          return tellerDiamondContract.methods
            .escrowRepay(loanID, totalOwed)
            .send({ from: userAddress });
        }
      }, transactionHandler);
    },
    [tellerDiamondContract, userAddress],
  );

  return { repayLoan };
};
