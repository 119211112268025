import React, { useContext } from "react";

import SuccessScreen from "components/SuccessScreen/SuccessScreen";
import TransactionHashLink from "components/UI/TransactionHashLink";
import { SushiswapContext } from "contexts/dashboard/sushiswapContext";

const SushiswapSuccessScreen = () => {
  const { success, setSuccess, setSelectedLoan } = useContext(SushiswapContext);

  return (
    <SuccessScreen
      title="Transaction Submitted"
      onButtonClick={() => {
        setSuccess(null);
        setSelectedLoan(null);
      }}
      message={
        <div>
          <>View your transaction status {"  "}</>
          <TransactionHashLink hash={success} />
        </div>
      }
      CTA="Go back"
      fullScreen={false}
    />
  );
};

export default SushiswapSuccessScreen;
