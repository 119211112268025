import React, { createContext, useContext, useEffect, useMemo, useState } from "react";

import { getNFTsFromDistribution } from "components/ClaimModal/distributions";

import { useWeb3StateContext } from "./web3StateContext";
import { NFTInterface } from "contexts/types";
import { NFTStatusEnum, NFTType } from "pages/NFTDashboard/types";
import { useTransactionsContext } from "./transactionsContext";
import { useTellerContext } from "./tellerContext";
import { useLoansContext } from "./loansContext";
import { useDeepCompareEffect } from "react-use";
import { useNetworkConfig } from "hooks/useNetworkConfig";
const CID = require("cids");

const defaultNFTsContext = {
  allClaimedNFTs: [
    {
      id: 1,
      credit: "",
      selected: false,
      tier: "",
      hasStaked: false,
      status: "",
      attributes: {
        Base_Loan_Asset: "",
        Base_Loan_Size: "",
        Contribution_Asset: "",
        Contribution_Multiplier: "",
        Contribution_Size: "",
        Tier: "",
      },
      image_url: "",
      isVideo: false,
    },
    {
      id: 2,
      credit: "",
      selected: false,
      tier: "",
      hasStaked: false,
      status: "",
      attributes: {
        Base_Loan_Asset: "",
        Base_Loan_Size: "",
        Contribution_Asset: "",
        Contribution_Multiplier: "",
        Contribution_Size: "",
        Tier: "",
      },
      image_url: "",
      isVideo: false,
    },
    {
      id: 3,
      credit: "",
      selected: false,
      tier: "",
      hasStaked: false,
      status: "",
      attributes: {
        Base_Loan_Asset: "",
        Base_Loan_Size: "",
        Contribution_Asset: "",
        Contribution_Multiplier: "",
        Contribution_Size: "",
        Tier: "",
      },
      image_url: "",
      isVideo: false,
    },
    {
      id: 4,
      credit: "",
      selected: false,
      tier: "",
      hasStaked: false,
      status: "",
      attributes: {
        Base_Loan_Asset: "",
        Base_Loan_Size: "",
        Contribution_Asset: "",
        Contribution_Multiplier: "",
        Contribution_Size: "",
        Tier: "",
      },
      image_url: "",
      isVideo: false,
    },
  ],
  allNFTsFromDistribution: null as null,
  claimedNFTs: null as null,
  ownedNFT: null as null,
  ownedNFTsV2: null as null,
  stakedNFTs: null as null,
  totalClaimed: null as null,
  totalUnclaimed: null as null,
  unclaimedNFTs: null as null,
  unstakedNFTs: null as null,
};

type NFTsContextInterface = {
  allNFTsFromDistribution: NFTInterface[];
  claimedNFTs: NFTInterface[];
  ownedNFTs: NFTInterface[];
  ownedNFTsV2: NFTInterface[];
  stakedNFTs: NFTInterface[];
  totalClaimed: 0;
  totalUnclaimed: 0;
  unclaimedNFTs: NFTInterface[];
  unstakedNFTs: NFTInterface[];
  allClaimedNFTs: NFTInterface[];
  bridgingTo: null | "polygon" | "mainnet";
  setBridgingTo: (value: null | "polygon" | "mainnet") => void;
};
const NFTsContext = createContext<any>(defaultNFTsContext);

export const NFTsContextProvider = ({ children }: any) => {
  const [bridgingTo, setBridgingTo] = useState<null | "polygon" | "mainnet">(null);
  const {
    allNFTsFromDistribution,
    claimedNFTs,
    ownedNFTs,
    ownedNFTsV2,
    stakedNFTs,
    totalClaimed,
    totalUnclaimed,
    unclaimedNFTs,
    unstakedNFTs,
    allClaimedNFTs,
    setAllClaimedNFTs,
  } = useNFTs();

  return (
    <NFTsContext.Provider
      value={{
        allNFTsFromDistribution,
        claimedNFTs,
        ownedNFTs,
        ownedNFTsV2,
        stakedNFTs,
        totalClaimed,
        totalUnclaimed,
        unclaimedNFTs,
        unstakedNFTs,
        allClaimedNFTs,
        setAllClaimedNFTs,
        bridgingTo,
        setBridgingTo,
      }}
    >
      {children}
    </NFTsContext.Provider>
  );
};

const useNFTs = () => {
  const { userAddress, web3 } = useWeb3StateContext();
  const {
    tellerDiamondContract,
    NFTDistributorContract,
    tellerNFTContract,
    tellerNFTV2Contract,
    tellerNFTDictionaryContract,
  } = useTellerContext();
  const { linkedNFTs } = useLoansContext();
  const { transactions } = useTransactionsContext();
  const [nftsDataFromContract, setNftsDataFromContract] = useState({
    allNFTsFromDistribution: [],
    ownedNFTs: null,
    ownedNFTsV2: null,
    stakedNFTs: { ids: [], details: [] },
    stakedNFTsV2: { ids: [], details: [] },
    unclaimedNFTs: null,
    claimedNFTs: null,
    unstakedNFTs: null,
    unstakedNFTsV2: null,
  });
  const [allClaimedNFTs, setAllClaimedNFTs] = useState(defaultNFTsContext.allClaimedNFTs);
  const [linkedNFTsDetailed, setLinkedNFTsDetailed] = useState([]);
  const [stakeDates, setStakeDates] = useState(null);
  const { firstBlock } = useNetworkConfig();
  const { network } = useWeb3StateContext();
  useEffect(() => {
    if (!tellerNFTContract || !userAddress || !tellerDiamondContract) return;
    (async () => {
      const stakedNFTsData = await tellerNFTContract.getPastEvents("Transfer", {
        filter: { to: tellerDiamondContract._address, from: userAddress },
        fromBlock: firstBlock,
        toBlock: "latest",
      });
      const nftStakeDates = {};

      await Promise.all(
        stakedNFTsData.map(async (n) => {
          nftStakeDates[n.returnValues.tokenId] =
            Number((await web3.eth.getBlock(n.blockNumber)).timestamp) * 1000;
        }),
      );
      setStakeDates(nftStakeDates);
    })();
  }, [tellerNFTContract, userAddress, web3, firstBlock, tellerDiamondContract, transactions]);

  useEffect(() => {
    if (!linkedNFTs || !tellerNFTContract || !stakeDates) return;
    (async () => {
      try {
        const linkedNFTsDetailed = await getLinkedNFTsDetailed(
          linkedNFTs,
          tellerNFTContract,
          tellerNFTV2Contract,
          stakeDates,
        );
        setLinkedNFTsDetailed(linkedNFTsDetailed);
      } catch (err) {
        console.log({ err });
      }
    })();
  }, [linkedNFTs, tellerNFTContract, tellerNFTV2Contract, stakeDates, transactions]);

  useDeepCompareEffect(() => {
    if (
      !tellerNFTContract ||
      !tellerDiamondContract ||
      !NFTDistributorContract ||
      !userAddress ||
      !stakeDates ||
      !tellerNFTV2Contract
    )
      return;
    (async () => {
      const { allNFTsFromDistribution, unclaimedNFTs } = await getNFTsFromDistribution(
        userAddress,
        NFTDistributorContract,
      );
      // nfts V1
      const ownedNFTs = await tellerNFTContract.methods.getOwnedTokens(userAddress).call();
      const stakedNftIDs = await tellerDiamondContract.methods.getStakedNFTs(userAddress).call();
      const stakeKebab = await getStakeKebab(
        stakedNftIDs,
        tellerNFTContract,
        tellerNFTDictionaryContract,
        stakeDates,
        true,
      );
      let unstakedNFTs: any[];
      if (network.id == 42) {
        unstakedNFTs = [];
      } else {
        unstakedNFTs = await getUnstakedNFTs(
          ownedNFTs,
          stakedNftIDs,
          tellerNFTContract,
          tellerNFTDictionaryContract,
          true,
        );
      }

      const ownedNFTsV2 = await tellerNFTV2Contract?.methods.getOwnedTokens(userAddress).call();
      const stakedNftIDsV2 = await tellerDiamondContract.methods
        .getStakedNFTsV2(userAddress)
        .call();

      const stakeKebabV2 = await getStakeKebab(
        stakedNftIDsV2.staked_,
        tellerNFTV2Contract,
        tellerNFTDictionaryContract,
        stakeDates,
        false,
      );
      const unstakedNFTsV2 = await getUnstakedNFTs(
        ownedNFTsV2,
        stakedNftIDsV2.staked_,
        tellerNFTV2Contract,
        tellerNFTDictionaryContract,
        false,
      );

      setNftsDataFromContract({
        allNFTsFromDistribution,
        ownedNFTs: ownedNFTs,
        ownedNFTsV2: ownedNFTsV2,
        stakedNFTs: { ids: stakedNftIDs, details: stakeKebab },
        stakedNFTsV2: { ids: stakedNftIDsV2, details: stakeKebabV2 },
        unclaimedNFTs,
        claimedNFTs,
        unstakedNFTs,
        unstakedNFTsV2,
      });
    })();
  }, [
    tellerNFTContract,
    tellerDiamondContract,
    NFTDistributorContract,
    userAddress,
    transactions,
    stakeDates,
    tellerNFTV2Contract,
  ]);

  const allNFTsFromDistribution = nftsDataFromContract?.allNFTsFromDistribution;

  const claimedNFTs = useMemo(() => {
    return allNFTsFromDistribution && allNFTsFromDistribution.length > 0
      ? allNFTsFromDistribution.filter((nft: any) => {
          return nft.isClaimed == true;
        })
      : null;
  }, [allNFTsFromDistribution]);

  const unclaimedNFTs = nftsDataFromContract?.unclaimedNFTs;
  const totalUnclaimed = useMemo(() => {
    return unclaimedNFTs
      ? Number(
          unclaimedNFTs.reduce((acc: any, val: any) => {
            return Number(acc) + val.amount;
          }, 0),
        )
      : 0;
  }, [unclaimedNFTs]);
  const totalClaimed = useMemo(() => {
    return claimedNFTs
      ? Number(
          claimedNFTs.reduce((acc: any, val: any) => {
            return Number(acc) + val.amount;
          }, 0),
        )
      : 0;
  }, [claimedNFTs]);

  useDeepCompareEffect(() => {
    if (
      !nftsDataFromContract ||
      nftsDataFromContract.unstakedNFTs == null ||
      nftsDataFromContract.stakedNFTs == null
    )
      return;
    const allClaimedNFTs = [
      ...nftsDataFromContract.unstakedNFTs,
      ...nftsDataFromContract.stakedNFTs.details,
      ...nftsDataFromContract.unstakedNFTsV2,
      ...nftsDataFromContract.stakedNFTsV2.details,
    ].sort(function (a, b) {
      return a.id - b.id;
    });
    const linkedNFTsDetailedID: [] = linkedNFTsDetailed.reduce((initial, nft) => {
      initial.push(nft.id);
      return initial;
    }, []);

    const unLinkedNfts = allClaimedNFTs?.filter((claimedNFT) => {
      const linkedIndex = linkedNFTsDetailedID.indexOf(claimedNFT.id);
      if (linkedIndex == -1) {
        return claimedNFT;
      }
    });

    const allClaimedNFTsFiltered = [...unLinkedNfts, ...linkedNFTsDetailed].sort(function (a, b) {
      return a.id - b.id;
    });

    setAllClaimedNFTs(allClaimedNFTsFiltered);
  }, [nftsDataFromContract, linkedNFTsDetailed]);

  return useMemo(() => {
    return {
      totalUnclaimed,
      totalClaimed,
      ...nftsDataFromContract,
      allClaimedNFTs,
      setAllClaimedNFTs,
    };
  }, [nftsDataFromContract, totalUnclaimed, totalClaimed, allClaimedNFTs]);
};

const getUnstakedNFTs = async (
  ownedNFTs: any,
  stakedNFTs: any,
  tellerNFTContract: any,
  tellerNFTDictionaryContract: any,
  isV1: boolean,
) => {
  const result: NFTInterface[] = [];
  for (let i = 0; i < ownedNFTs.length; i++) {
    let hasStaked = false;
    for (let j = 0; j < stakedNFTs.length; j++) {
      if (ownedNFTs[i] == stakedNFTs[j]) {
        hasStaked = true;
      }
    }
    let uri: any;
    if (isV1) {
      const uri1 = await tellerNFTContract.methods.tokenURI(ownedNFTs[i]).call();
      const uri2 = await fetch(uri1);
      uri = await uri2.json();
    } else {
      const uri1 = await tellerNFTContract.methods.uri(ownedNFTs[i]).call();
      const uri2 = await fetch(uri1);
      uri = await uri2.json();
    }
    const attributes = {
      Base_Loan_Asset: "",
      Base_Loan_Size: "",
      Contribution_Asset: "",
      Contribution_Multiplier: "",
      Contribution_Size: "",
      Tier: "",
    };
    uri.attributes.map((attribute: any) => (attributes[attribute.trait_type] = attribute.value));
    let credit: any;
    if (isV1) {
      credit = await tellerNFTDictionaryContract.methods.tokenBaseLoanSize(ownedNFTs[i]).call();
      result[i] = {
        id: ownedNFTs[i],
        credit,
        selected: false,
        tier: attributes.Tier,
        hasStaked: false,
        status: NFTStatusEnum.unstaked,
        attributes,
        image_url: getImageURL(uri.image),
        isVideo: Boolean(uri.animation_url),
        type: NFTType.V1,
      };
    } else {
      credit = await tellerNFTContract.methods.tokenBaseLoanSize(ownedNFTs[i]).call();
      result[i] = {
        id: ownedNFTs[i],
        credit,
        selected: false,
        tier: attributes.Tier,
        hasStaked: false,
        status: NFTStatusEnum.unstaked,
        attributes,
        image_url: getImageURL(uri.image),
        isVideo: Boolean(uri.animation_url),
        type: NFTType.V2,
      };
    }
  }
  return result;
};

const getStakeKebab = async (
  stakedNFTs: any,
  tellerNFTContract: any,
  tellerNFTDictionaryContract: any,
  nftStakeDates: any,
  isV1: boolean,
) => {
  const result: NFTInterface[] = [];
  for (let i = 0; i < stakedNFTs.length; i++) {
    let uri: any;
    if (isV1) {
      const uri1 = await tellerNFTContract.methods.tokenURI(stakedNFTs[i]).call();
      const uri2 = await fetch(uri1);
      uri = await uri2.json();
    } else {
      const uri_ = await tellerNFTContract.methods.uri(stakedNFTs[i]).call();
      const ipfs = uri_.replace("https://gateway.pinata.cloud", "https://ipfs.io");
      const uri1 = await fetch(ipfs);
      uri = await uri1.json();
    }
    const attributes = {
      Base_Loan_Asset: "",
      Base_Loan_Size: "",
      Contribution_Asset: "",
      Contribution_Multiplier: "",
      Contribution_Size: "",
      Tier: "",
    };
    uri.attributes.map((attribute: any) => (attributes[attribute.trait_type] = attribute.value));
    let credit: any;
    if (isV1) {
      credit = await tellerNFTDictionaryContract.methods.tokenBaseLoanSize(stakedNFTs[i]).call();
      result.push({
        id: stakedNFTs[i],
        credit,
        selected: false,
        tier: attributes.Tier,
        hasStaked: false,
        status: NFTStatusEnum.staked,
        attributes,
        image_url: getImageURL(uri.image),
        isVideo: Boolean(uri.animation_url),
        stakeTimestamp: nftStakeDates[stakedNFTs[i]],
        type: NFTType.V1,
      });
    } else {
      credit = await tellerNFTContract.methods.tokenBaseLoanSize(stakedNFTs[i]).call();
      result.push({
        id: stakedNFTs[i],
        credit,
        selected: false,
        tier: attributes.Tier,
        hasStaked: false,
        status: NFTStatusEnum.staked,
        attributes,
        image_url: getImageURL(uri.image),
        isVideo: Boolean(uri.animation_url),
        stakeTimestamp: nftStakeDates[stakedNFTs[i]],
        type: NFTType.V2,
      });
    }
  }
  return result;
};

const getLinkedNFTsDetailed = async (
  linkedNFTs: any[],
  tellerNFTContract: any,
  tellerNFTV2Contract: any,
  stakeDates: any,
) => {
  const result: any[] = [];
  for (let i = 0; i < linkedNFTs.length; i++) {
    let tokenURI;
    if (linkedNFTs[i].type == "V1") {
      tokenURI = await tellerNFTContract.methods.tokenURI(linkedNFTs[i].id).call();
    } else {
      tokenURI = await tellerNFTV2Contract.methods.uri(linkedNFTs[i].id).call();
    }
    const tokenData = await fetch(tokenURI);
    const uri = await tokenData.json();

    const attributes = {
      Base_Loan_Asset: "",
      Base_Loan_Size: "",
      Contribution_Asset: "",
      Contribution_Multiplier: "",
      Contribution_Size: "",
      Tier: "",
    };
    uri.attributes.map((attribute: any) => (attributes[attribute.trait_type] = attribute.value));
    result.push({
      id: linkedNFTs[i].id,
      selected: false,
      tier: attributes.Tier,
      hasStaked: true,
      type: NFTType.V2,
      status: NFTStatusEnum.linked,
      attributes,
      image_url: getImageURL(uri.image),
      isVideo: Boolean(uri.animation_url),
      linkedLoanAddress: linkedNFTs[i].loanAddress,
      linkedLoanID: linkedNFTs[i].loanID,
      stakeTimestamp: stakeDates[linkedNFTs[i].id],
      timeTillExpires: linkedNFTs[i].timeTillExpires,
      loanExpirationTimestamp: linkedNFTs[i].loanExpirationTimestamp,
    });
  }
  return result;
};

export const useNFTsContext: () => NFTsContextInterface = () => {
  const context = useContext(NFTsContext);
  if (context === undefined) {
    throw new Error("useNFTsContext must be used within a useNFTsContextProvider");
  }
  return context;
};

const getImageURL = (ipfs: string) => {
  const v1 = new CID(ipfs.split("//").pop()).toV1().toString();
  const url = `https://${v1}.ipfs.dweb.link/`;

  return url;
};
