import React from "react";

import success from "assets/loader_complete.svg";
import CloseModalIcon from "assets/x.svg";
import ProcessingAnimation from "../UI/ProcessingAnimation";

type ModalProps = {
  title: string;
  body: React.ReactNode;
  CTA: React.ReactNode;
  footerText: React.ReactNode;
  loading?: boolean;
  showModal: boolean;
  setModalOpened: any;
  showSuccessScreen?: boolean;
  successScreenMainText?: React.ReactNode | string;
  successScreenTitle?: string;
};

const Modal = ({
  title,
  body,
  CTA,
  footerText,
  loading = false,
  showModal,
  setModalOpened,
  showSuccessScreen = false,
  successScreenMainText = "",
  successScreenTitle = "",
}: ModalProps) => {
  return (
    <>
      {showModal && (
        <div className="fixed z-40 inset-0 overflow-y-scroll">
          <div className="flex items-center sm:items-end justify-center min-h-screen sm:pt-4 sm:px-4 sm:pb-20 text-center sm:block sm:p-0 overflow-y-scroll">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-black opacity-70"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <div
              className="inline-block align-bottom bg-white rounded text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full whitespace-normal"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="bg-white">
                {/* px-4 pt-5 pb-4 sm:p-6 sm:pb-4 md:p-10 */}
                <div className="sm:flex sm:items-start">
                  <div className="text-left flex-1">
                    <div className="flex flex-row flex-nowrap justify-between bg-green-06 items-center px-4 sm:px-6 md:px-10 ">
                      <h3
                        className="type-h4 text-left font-medium text-primary"
                        id="modal-headline"
                      >
                        {title}
                      </h3>
                      <div
                        className="link pointer my-6 sm:my-10"
                        onClick={() => {
                          setModalOpened(false);
                        }}
                      >
                        <img src={CloseModalIcon} className="h-5" />
                      </div>
                    </div>
                    {loading ? (
                      <div className="my-10">
                        <ProcessingAnimation height={100} />
                      </div>
                    ) : showSuccessScreen ? (
                      <div className="flex flex-col justify-center text-center my-24">
                        <img src={success} className="h-44 w-44 mb-4 mx-auto" />
                        <div className="text-green-02 type-h4 font-medium mx-auto text-left">
                          {successScreenTitle}
                        </div>
                        <div className="type-p-sm text-black mt-2 font-light">
                          {successScreenMainText}
                        </div>
                      </div>
                    ) : (
                      <>{body}</>
                    )}
                    <div
                      className="flex flex-row flex-wrap justify-between bg-grey-04
                     items-center px-4  sm:px-6  md:px-10 "
                    >
                      {footerText}
                      <div className="my-6">{CTA}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
