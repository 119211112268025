export interface BadgeProps {
  badgeType: BadgeTypeEnum;
  text: string;
}

export enum BadgeTypeEnum {
  green = "green",
  orange = "orange",
  red = "red",
  normal = "normal",
}
