import React, { useContext, useMemo } from "react";

import BR from "components/UI/BR";
import { CustomDropdown } from "components/UI/CustomDropdown";
import TableRow from "components/UI/TableRow";
import { BorrowPageContext } from "contexts/borrow/borrowContext";

import BorrowFormStage1FormCollateralPercentSelection from "./BorrowFormStage1FormCollateralPercentSelection";
import BorrowFormStage1FormLoanSizeSelection from "./BorrowFormStage1FormLoanSizeSelection";
import BorrowFormStage1FormLoanTermSelection from "./BorrowFormStage1FormLoanTermSelection";
import BorrowFormStage1FormNFTButton from "./BorrowFormStage1FormNFTButton";
import copy from "constants/copy.json";
import { useTellerContext } from "contexts/global/tellerContext";
const BorrowFormStage1Table = () => {
  const { collateralTokens, lendingTokens } = useTellerContext();
  const { borrowRequest, setBorrowRequest, NFTs } = useContext(BorrowPageContext);
  const isSecured = Boolean(borrowRequest.loanType === "Secured");
  const pageCopy = copy.pages.borrow.main.form.step2;

  const collateralTokenOptions = useMemo(() => {
    return collateralTokens.filter((token) => token != borrowRequest.lendWith);
  }, [borrowRequest.lendWith, collateralTokens]);
  const lendingTokenOptions = useMemo(() => {
    return lendingTokens.filter((token) => !isSecured || token != borrowRequest.collateralWith);
  }, [borrowRequest.collateralWith, lendingTokens, isSecured]);

  const selectedNFTs = NFTs
    ? NFTs.filter((NFT: any) => {
        return NFT.selected == true;
      })
    : null;

  return (
    <div>
      <p className="font-medium mb-4 text-2xl xs:text-xl">Borrow</p>
      <div>{pageCopy.description}</div>
      <div className="border-thin my-5">
        <TableRow title={pageCopy.borrow.title}>
          <CustomDropdown
            options={lendingTokenOptions}
            selected={borrowRequest.lendWith}
            handleSelect={(eventKey: any) => {
              setBorrowRequest({
                ...borrowRequest,
                lendWith: eventKey,
              });
            }}
          />
        </TableRow>
        <BR />
        <TableRow title={pageCopy.loanPeriod.title}>
          <BorrowFormStage1FormLoanTermSelection />
        </TableRow>
        {isSecured && (
          <>
            <BR />
            <TableRow title={pageCopy.collateralAsset.title}>
              <CustomDropdown
                options={collateralTokenOptions}
                selected={borrowRequest.collateralWith}
                handleSelect={(eventKey: any) => {
                  setBorrowRequest({
                    ...borrowRequest,
                    collateralWith: eventKey,
                  });
                }}
              />
            </TableRow>
          </>
        )}
        {!isSecured && (
          <>
            <BR />
            {/* <TableRow
              title={`${pageCopy.bank.title} ${isSecured ? "(optional)" : ""}`}
              tooltip_text="Connecting your bank account will determine your creditworthiness and loan terms."
            >
              <BorrowFormStage1FormPlaidButton />
            </TableRow> */}
            {/* <BR /> */}
            <TableRow
              title="Link NFT"
              tooltip_text="Linking your NFT will increase your base loan size. Please note, if your unsecured loan defaults you will have 30 days to repay your loan before linked NFT will be auctioned."
            >
              <BorrowFormStage1FormNFTButton page="borrow" />
            </TableRow>
          </>
        )}
        {selectedNFTs && selectedNFTs.length > 0 && !isSecured && (
          <>
            <BR />
            <TableRow title={pageCopy.loanSize.title}>
              <BorrowFormStage1FormLoanSizeSelection />
            </TableRow>
          </>
        )}
        {!selectedNFTs && isSecured && (
          <>
            <BR />
            <TableRow title={pageCopy.loanSize.title}>
              <BorrowFormStage1FormLoanSizeSelection />
            </TableRow>
          </>
        )}
        {isSecured && (
          <>
            <BR />
            <TableRow
              tooltip_text="Non-U.S. residents require a minimum of 20% collateral to be approved for a loan."
              title={pageCopy.collateralPercent.title}
            >
              <BorrowFormStage1FormCollateralPercentSelection />
            </TableRow>
          </>
        )}
      </div>
    </div>
  );
};

export default BorrowFormStage1Table;
