import React, { useEffect, useState } from "react";

import Button from "components/Button";

import { NFTStatusEnum, NFTType } from "./types";
import { useTellerContext } from "contexts/global/tellerContext";
import { useEtherscanLink } from "hooks/useEtherscanLink";
import { useStakeNFT } from "actions/StakeNFT";
import { useTransactionHandler } from "hooks/useTransactionHandler";
import { useNotificationsContext } from "contexts/global/notificationsContext";
import ApproveTransactionModal from "./ApproveTransactionModal";
import useNFT from "hooks/useNFT";
import { useNFTsContext } from "../../contexts/global/nftsContext";
import { useBridgeNFT } from "../../actions/BridgeNFT";
import { useApproveNFT } from "actions/ApproveNFT";
import { useWeb3StateContext } from "contexts/global/web3StateContext";

const NFTDashboardAccordionRowHeaderCTA = ({
  status,
  type = "nostroke",
  id,
  nftType,
}: {
  id: string;
  status: NFTStatusEnum;
  type?: "primary" | "secondary" | "tertiary" | "nostroke";
  nftType: NFTType;
}) => {
  const [isApproved, setApproved] = useState(false);
  const { isNFTApproved } = useApproveNFT();
  const { bridgingTo } = useNFTsContext();
  const { linkedLoanAddress } = useNFT(id);
  const address = useEtherscanLink(linkedLoanAddress, "address");
  const [approveModal, setApproveModal] = useState({ display: false, type: null });

  const [loading, setLoading] = useState(false);

  const [updatedNFTStatus, setUpdatedNFTStatus] = useState(null);
  const checkApproved = async () => {
    const approved = await isNFTApproved("V1");
    setApproved(approved);
  };
  useEffect(() => {
    checkApproved();
  }, [approveModal]);

  const closeModal = () => {
    setApproveModal({ display: false, type: null });
    checkApproved();
  };
  const statusToCheck = updatedNFTStatus ? updatedNFTStatus : status;
  return (
    <>
      {approveModal.display && (
        <ApproveTransactionModal
          closeModal={closeModal}
          successCTACopy="Done"
          multipleTransactionsWarning={false}
          setLoading={setLoading}
        />
      )}
      {statusToCheck == NFTStatusEnum.defaulted || statusToCheck == NFTStatusEnum.linked ? (
        <Button
          href={address}
          color="green"
          type={type}
          text="View Loan"
          size="md"
          disabled={false}
        />
      ) : statusToCheck == NFTStatusEnum.staked && bridgingTo == null ? (
        <UnstakeNFTButton
          type={nftType}
          id={id}
          updateNFTStatus={() => {
            setUpdatedNFTStatus(NFTStatusEnum.unstaked);
          }}
          loading={loading}
          setLoading={setLoading}
        />
      ) : statusToCheck == NFTStatusEnum.unstaked && bridgingTo == null ? (
        <StakeNFTButton
          id={id}
          type={nftType}
          setApproveModal={setApproveModal}
          updateNFTStatus={() => {
            setUpdatedNFTStatus(NFTStatusEnum.staked);
          }}
          isApproved={nftType == "V1" ? isApproved : true}
          loading={loading}
          setLoading={setLoading}
        />
      ) : (
        //     : (statusToCheck == NFTStatusEnum.unstaked || statusToCheck == NFTStatusEnum.staked) &&
        // bridgingTo != null &&
        // statusToCheck != "Bridged" ? (
        // <BridgeNFTButton
        //   id={id}
        //   setApproveModal={setApproveModal}
        //   updateNFTStatus={() => {
        //     setUpdatedNFTStatus("Bridged");
        //   }}
        //   nftType={nftType}
        //   isApproved={nftType == "V1" ? isApproved : true}
        //   loading={loading}
        //   setLoading={setLoading}
        // />
        //     )
        <></>
      )}
    </>
  );
};

export default NFTDashboardAccordionRowHeaderCTA;

const StakeNFTButton = ({
  id,
  type,
  setApproveModal,
  updateNFTStatus,
  isApproved,
  loading,
  setLoading,
}: {
  id: any;
  type: any;
  setApproveModal: any;
  updateNFTStatus: any;
  isApproved: boolean;
  loading: any;
  setLoading: any;
}) => {
  const { addNotification } = useNotificationsContext();
  const { stakeNFT } = useStakeNFT();

  const stakeTransactionHandler = useTransactionHandler(
    "stakeNFT",
    () => {
      addNotification({ copy: "NFT staked.", variant: "success" });
      updateNFTStatus();
    },
    setLoading,
  );

  const stakeCTA = async () => {
    setLoading(true);
    if (isApproved) {
      stakeNFT(stakeTransactionHandler, [id], type);
    } else {
      setApproveModal({
        display: true,
        type,
      });
    }
  };

  return (
    <>
      <Button
        CTA={stakeCTA}
        color="green"
        type="primary"
        text={loading && !isApproved ? "Approving" : loading && isApproved ? "Staking" : "Stake"}
        size="md"
        disabled={loading}
      />
    </>
  );
};

const UnstakeNFTButton = ({
  type,
  id,
  updateNFTStatus,
  loading,
  setLoading,
}: {
  type: any;
  id: any;
  updateNFTStatus: any;
  loading: any;
  setLoading: any;
}) => {
  const { addNotification } = useNotificationsContext();
  const { unstakeNFT } = useStakeNFT();

  const unstakeTransactionHandler = useTransactionHandler(
    "unstakeNFT",
    () => {
      addNotification({ copy: "NFT unstaked.", variant: "success" });
      updateNFTStatus();
    },
    setLoading,
  );
  const unstake = async () => {
    setLoading(true);
    await unstakeNFT(unstakeTransactionHandler, [id], type);
  };
  const unstakeCTA = async () => {
    setLoading(true);
    unstake();
  };
  return (
    <Button
      CTA={unstakeCTA}
      color="orange"
      type="secondary"
      text={loading ? "Unstaking.." : "Unstake"}
      size="md"
      disabled={loading}
    />
  );
};

const BridgeNFTButton = ({
  id,
  nftType,
  setApproveModal,
  updateNFTStatus,
  isApproved,
  loading,
  setLoading,
}: {
  id: any;
  nftType: any;
  setApproveModal: any;
  updateNFTStatus: any;
  isApproved: boolean;
  loading: any;
  setLoading: any;
}) => {
  const { bridgeNFT } = useBridgeNFT();

  const { addNotification } = useNotificationsContext();
  const bridgeTransactionHandler = useTransactionHandler(
    "bridgeNFT",
    () => {
      addNotification({ copy: "NFT bridged.", variant: "success" });
      updateNFTStatus();
    },
    setLoading,
  );

  const bridge = async () => {
    setLoading(true);
    if (!isApproved) {
      await setApproveModal({ display: true, type: nftType });
    } else {
      await bridgeNFT(bridgeTransactionHandler, id, nftType);
    }
  };
  return (
    <Button
      CTA={bridge}
      color="green"
      type="primary"
      text={loading && !isApproved ? "Approving" : loading && isApproved ? "Bridging" : "Bridge"}
      size="md"
      disabled={loading}
    />
  );
};
