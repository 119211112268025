import BigNumber from "bignumber.js";
import React, { useContext } from "react";

import compound from "assets/compound-full-logo.svg";
import uniswap from "assets/uniswap-full-logo.svg";
import PrimaryButton from "components/UI/PrimaryButton";
import copy from "constants/copy.json";
import { BorrowPageContext } from "contexts/borrow/borrowContext";

import LoanSelectCard from "./BorrowFormStage0FormLoanCard";

BigNumber.config({ EXPONENTIAL_AT: 1e9 });

const BorrowFormStage0 = () => {
  const { unsecuredLoanCard, securedLoanCard, description } = copy.pages.borrow.main.form.step1;

  const {
    stage,
    setStage,
    borrowRequest,
    setBorrowRequest,
    loanTypeSelected,
    setLoanTypeSelected,
  } = useContext(BorrowPageContext);

  const onLoanSelectionConfirm = () => {
    setStage(stage + 1);
    setBorrowRequest({
      ...borrowRequest,
      collateralPercent: loanTypeSelected === "Secured" ? 135 : 20,
      loanType: loanTypeSelected,
    });
    setLoanTypeSelected(null);
  };

  const onUnsecuredLoanSelect = () => {
    setLoanTypeSelected("Unsecured");
  };
  const onSecuredLoanSelect = () => {
    setLoanTypeSelected("Secured");
  };

  return (
    <>
      {loanTypeSelected ? (
        <div className={"flex flex-row text-center items-center"}>
          <div className="mr-4 ml-2">
            <div className="text-left font-medium text-xl xs:text-lg">
              {loanTypeSelected == "Unsecured" ? unsecuredLoanCard.title : securedLoanCard.title}
            </div>
            <div className="mt-3 mb-6 text-left">
              {/* connect their bank account and */}
              {loanTypeSelected == "Unsecured"
                ? `These loans allow users to borrow without collateral. Unsecured loans are deposited to an escrow contract for use via Teller’s whitelisted applications, as seen below.`
                : `These loans allow users to provide collateral and borrow without restrictions. Secured loans are deposited to users’ personal wallets and do not require users to connect their bank account.`}
            </div>
            {/* {loanTypeSelected == "Unsecured" && (
              <div className="mt-3 mb-4 text-gray text-left">
                Note: Bank connection is currently limited to a whitelist. You can whitelist your
                wallet{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={"https://tellerfinance.typeform.com/to/KzwxNWfD"}
                  className="link text-gray"
                >
                  <u>here</u>
                </a>
              </div>
            )}{" "} */}
            <div className="mb-10">
              {loanTypeSelected == "Secured" && (
                <div className="flex flex-row items-center justify-start">
                  <div>
                    <img style={{ height: "24px" }} src={compound} className="mr-3" />
                  </div>
                  <div>
                    <img style={{ height: "24px" }} src={uniswap} />
                  </div>
                </div>
              )}{" "}
            </div>
            <div className="flex justify-items-start">
              <PrimaryButton text="Next" onClick={onLoanSelectionConfirm} />
            </div>
          </div>
        </div>
      ) : (
        <div className="">
          <div className="mb-6">{description}</div>
          <LoanSelectCard
            className="pointer"
            onClick={onUnsecuredLoanSelect}
            title={unsecuredLoanCard.title}
            subTitle={unsecuredLoanCard.description}
            logos={[""]}
          />
          <LoanSelectCard
            className="pointer mt-4"
            onClick={onSecuredLoanSelect}
            title={securedLoanCard.title}
            subTitle={securedLoanCard.description}
            // logos={["compound", "uniswap"]}
            logos={["aave", "sushiswap"]}
          />
        </div>
      )}
    </>
  );
};

export default BorrowFormStage0;
