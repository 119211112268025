import React, { useContext } from "react";

import Card from "components/UI/Card";
import { DashboardContext } from "contexts/dashboard/dashboardContext";

import Claim from "./Claim";
import Portfolio from "./Portfolio";
import Withdraw from "./Withdraw";

const Lend = () => {
  const { onPage } = useContext(DashboardContext);
  const subSection = onPage.split("-")[1];
  return (
    <Card
      className="main-card bg-teal-lightest  text-center  "
      title={subSection.charAt(0).toUpperCase() + subSection.slice(1)}
      dashboard
    >
      <div className="">
        {subSection == "withdraw" && <Withdraw />}
        {subSection == "portfolio" && <Portfolio />}
      </div>
    </Card>
  );
};
export default Lend;
