import { useAllowance } from "hooks/useAllowance";
import { useCallback } from "react";

import { createNewTransactionWithHandler } from "actions/utils";
import { useWeb3StateContext } from "contexts/global/web3StateContext";
import { useTellerContext } from "contexts/global/tellerContext";
import { useBNMethods } from "hooks/useBNMethods";
import { AvailableCollateralTokens } from "contexts/types";

/**
 * Deposits collateral into a created loan
 */
export const useDepositCollateral = (collateralWith: AvailableCollateralTokens) => {
  const { tellerDiamondContract } = useTellerContext();
  const { userAddress } = useWeb3StateContext();
  const { convertToBNString } = useBNMethods(collateralWith);

  const depositCollateral = useCallback(
    async (transactionHandler: any, amount: string, loanID: string) => {
      const amountBNString = convertToBNString(amount);
      await createNewTransactionWithHandler(
        () =>
          tellerDiamondContract.methods.depositCollateral(loanID, amountBNString).send({
            from: userAddress,
            value: collateralWith == "ETH" ? amountBNString : undefined,
          }),
        transactionHandler,
      );
    },
    [convertToBNString, tellerDiamondContract, userAddress],
  );

  return { depositCollateral };
};
