import { useTellerContext, useTToken } from "contexts/global/tellerContext";
import { useTransactionsContext } from "contexts/global/transactionsContext";
import { useWeb3StateContext } from "contexts/global/web3StateContext";
import { AvailableLendingTokens } from "contexts/types";
import React, { useState, useMemo } from "react";
import { useDeepCompareEffect } from "react-use";
import { useBNMethods } from "./useBNMethods";

export const useSuppliedBalance = (assetID: AvailableLendingTokens) => {
  // console.log("useSuppliedBalance");

  const [balance, setBalance] = useState<any>(null);
  const { transactions } = useTransactionsContext();
  const { userAddress, network } = useWeb3StateContext();
  const ID = assetID == "ETH" ? "WETH" : assetID;
  const tToken = useTToken(ID);
  const { convertToString } = useBNMethods(ID);

  useDeepCompareEffect(() => {
    if (!tToken.tTokenContract || !convertToString) return;
    let isMounted = true;

    (async () => {
      try {
        const suppliedBalanceBN = await tToken.tTokenContract.methods
          .balanceOfUnderlying(userAddress)
          .call();

        const suppliedBalance = suppliedBalanceBN ? convertToString(suppliedBalanceBN) : null;
        if (!suppliedBalance) return;
        if (isMounted) setBalance(suppliedBalance);
      } catch (error) {
        console.log({ error, assetID });
      }
    })();
    return () => {
      isMounted = false;
    };
  }, [transactions, tToken.tTokenContract, userAddress, network, convertToString]);

  return useMemo(() => {
    return balance;
  }, [balance]);
};
