import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useCallback } from "react";
import { useDeepCompareEffect } from "react-use";
import { useWeb3StateContext } from "./web3StateContext";

type BlockNumberType = string | null;
type BlockNumberContextType = {
  blockNumber: BlockNumberType;
  triggerUpdateBlockNumber: () => void;
};

const defaultBlockNumberState = {
  blockNumber: null as null,
  triggerUpdateBlockNumber: () => null as null,
};

const BlockNumberContext = createContext<BlockNumberContextType>(defaultBlockNumberState);

export const BlockNumberContextProvider = ({ children }: any) => {
  const { network, web3 } = useWeb3StateContext();
  const [blockNumber, setBlockNumber] = useState(null);

  // const resetSubscription = () => {
  //   if (!web3) return;
  //   // web3.eth.clearSubscriptions(() => null);
  // };
  const setUpSubscription = useCallback(() => {
    if (!web3 || !blockNumber) return;
    web3.eth
      .subscribe("newBlockHeaders", (error: any) => {
        if (!error) {
          return;
        }
        console.error({ error });
      })
      .on("data", (blockHeader: any) => {
        if (blockNumber >= blockHeader.number) return;
        setBlockNumber(blockHeader.number.toString());
      })
      .on("error", console.error);
  }, [blockNumber, web3]);

  const updateBlockNumber = useCallback(
    async (currentBlockNumber: BlockNumberType) => {
      if (network.name === "unknown" || !web3) return;
      const blockNumber = (await web3.eth.getBlockNumber()).toString();
      if (currentBlockNumber != blockNumber) {
        setBlockNumber(blockNumber);
      }
    },
    [network, web3],
  );

  const triggerUpdateBlockNumber = useCallback(() => {
    updateBlockNumber(blockNumber);
    setUpSubscription();
  }, [blockNumber, updateBlockNumber, setUpSubscription]);

  useDeepCompareEffect(() => {
    if (!network.name || network.name == "unknown" || !web3) return;
    triggerUpdateBlockNumber();
  }, [network, web3]);

  const value = useMemo(() => {
    return { blockNumber, triggerUpdateBlockNumber };
  }, [blockNumber, triggerUpdateBlockNumber]);

  return <BlockNumberContext.Provider value={value}>{children}</BlockNumberContext.Provider>;
};

/**
 *  Custom context hook that subscribes to the current block number updates
 * @returns blocknumber - current latest block
 * @returns triggerUpdateBlockNumber() - call this to manually trigger a block number update
 */
export const useBlockNumberContext = () => {
  const context = useContext(BlockNumberContext);
  if (context === undefined) {
    throw new Error("useBlockNumber must be used within a useBlockNumberProvider");
  }
  return context;
};
