import React from "react";

import useNFT from "hooks/useNFT";
export const NFTImage = ({ id }: { id: string }) => {
  const nft = useNFT(id);

  return (
    <>
      {nft.isVideo ? (
        <>
          <video
            width="320"
            height="240"
            autoPlay={true}
            controls={false}
            muted={true}
            className="xl:w-96 lg:w-64 h-auto rounded object-cover xl:m-6 lg:m-4 w-max flex-1"
          >
            <source src={nft.image_url} type="video/mp4" />
          </video>
        </>
      ) : (
        <img
          src={nft.image_url}
          className="xl:w-96 lg:w-64 h-auto rounded object-cover xl:m-6 lg:m-4 w-max flex-1"
        />
      )}
    </>
  );
};

export default NFTImage;
