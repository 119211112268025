import { useWeb3StateContext } from "contexts/global/web3StateContext";
import { useBNMethods } from "hooks/useBNMethods";
import { useSuppliedBalance } from "hooks/useSuppliedBalance";
import { createNewTransactionWithHandler } from "./utils";
import React, { useCallback } from "react";
import { AvailableLendingTokens } from "contexts/types";
import { useTellerContext, useTToken } from "contexts/global/tellerContext";

export const useWithdraw = (lendingToken: AvailableLendingTokens) => {
  const totalUserBalance = useSuppliedBalance(lendingToken);
  const { tTokenContract } = useTToken(lendingToken);
  const { convertToBNString } = useBNMethods(lendingToken);
  const { userAddress } = useWeb3StateContext();

  /**
   * Withdraw the given amount from the selected ATM. Currently only supports the ETH
   * pools. After, update the remaining balance.
   *
   * @param selectedAmount Amount of token to withdraw from the pool.
   * @param maxSelected Boolean value that determines if we want to withdraw all available amount
   * @param transactionHandler
   */
  const withdraw = useCallback(
    async (selectedAmount, maxSelected, transactionHandler) => {
      if (!tTokenContract) return;
      await createNewTransactionWithHandler(
        () =>
          tTokenContract.methods
            .redeemUnderlying(convertToBNString(maxSelected ? totalUserBalance : selectedAmount))
            .send({ from: userAddress }),
        transactionHandler,
      );
    },
    [tTokenContract, convertToBNString, totalUserBalance, userAddress],
  );

  return { withdraw };
};
