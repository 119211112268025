import Notify from "bnc-notify";

const getWeb3Notify = async (networkId: number) => {
  const notify = await Notify({
    dappId: process.env.REACT_APP_NOTIFY_ID,
    networkId,
    desktopPosition: "topRight",
  });
  notify.config({
    txStallConfirmedTimeout: 20000,
  });

  return notify;
};
export default getWeb3Notify;
