import React from "react";
interface primaryButtonProps {
  text: string;
  onClick?: () => void;
  disabled?: boolean;
  type?: any;
  variant?: string;
  mt?: string;
}
const PrimaryButton = ({
  text,
  onClick = () => null,
  disabled = false,
  type = "",
  variant = "primary",
  mt = "mt-4",
}: primaryButtonProps) => {
  return (
    <button
      type={disabled ? "" : type}
      onClick={() => {
        if (disabled) return;
        onClick();
      }}
      className="primarybutton hover:border-none"
    >
      <div
        style={{ minWidth: "152px" }}
        className={`xs:whitespace-normal xs:px-3 rounded sm:py-4 sm:px-8 py-4 mx-auto sm:w-min ${mt} font-medium whitespace-nowrap type-button
        ${disabled ? "disabled" : "pointer"}
        ${disabled && variant == "primary" && "text-white bg-red-light"}
        ${disabled && variant == "secondary" && "text-gray bg-gray-lighter"}
        ${!disabled && variant == "primary" && "text-white bg-tangerine-02 hover:bg-tangerine-03"}
        ${!disabled && variant == "secondary" && "bg-gray-lighter"}
        }`}
      >
        {text}
      </div>
    </button>
  );
};

export default PrimaryButton;
