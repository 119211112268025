import { NFTStatusEnum, NFTType } from "pages/NFTDashboard/types";
import { PBorrow } from "utils/getNodeSignaturesForBorrowing";
import { PublicTokenResponsePayload } from "utils/data-provider/public-token";
import { Dispatch } from "react";
import BigNumber from "bignumber.js";

export interface LoanInterface {
  IDNumber?: string;
  allowance?: any;
  amountBorrowed: any;
  collateralAmount?: any;
  collateralDeposits: null | CollateralDepositInterface[];
  collateralToken: string;
  collateralWithdrawns: null | CollateralWithdrawInterface[];
  compound?: any;
  currentCollateralPercent?: number;
  endDate: number;
  escrow?: string;
  escrowBalances?: any;
  id: string;
  loanType?: string;
  repayments: null | LoanRepaymentInterface[];
  startDate: number;
  status: StatusTypes;
  statusName?: string;
  terms: TermsInterface;
  timeTillExpires?: number;
  token: string;
  totalCollateralDepositsAmount: any;
  totalCollateralWithdrawalsAmount: any;
  totalOwedAmount: any;
  totalRepaidAmount: any;
  totalValue?: any;
  transactionHash: string;
}

export interface CollateralWithdrawInterface {
  amount: number;
}
export interface CollateralDepositInterface {
  amount: number;
}

export interface LoanNFT {
  id: string;
  type: "V1" | "V2";
}
export interface TermsInterface {
  collateralAmount: number;
  collateralRatio: number;
  duration: number;
  expiryAt: number;
  interestRate: number;
  maxLoanAmount: number;
}

export interface LoanRepaymentInterface {
  amount: number;
}

export interface DashboardContextInterface {
  loanTransactions: string[];
  loans: null | LoanInterface[];
  loansLoadingStatus: string;
  navigationMap: NavigationInterface;
  onPage: PageTypes;
  setLoans: () => void;
  setLoansLoadingStatus: () => void;
  setOnPage: () => void;
}

export interface NavigationInterface {
  [key: string]: SubNavInterface;
}
export interface SubNavInterface {
  [key: string]: string;
}
export interface LendPageContextInterface {
  selectedAmount: number;
  selectedCurrency: AvailableLendingTokens;
  setSelectedAmount: () => void;
  setSelectedCurrency: () => void;
  setTokensApproved: () => void;
  tokensApproved: boolean;
}

export interface BorrowRequest {
  approved: boolean;
  bankConnected: boolean;
  borrowerAddress: string;
  collateralAmount: null | number;
  collateralPercent: number;
  collateralWith: BaseTokens;
  lendWith: AvailableLendingTokens;
  loanSize: number;
  loanTerm: number;
  loanType: string;
  requestHash: any;
  requestNonce: number;
  requestTime: number;
  transferred: boolean;
}

export interface Signature {
  signerNonce: number;
  r: Buffer;
  s: Buffer;
  v: number;
}

// export interface BorrowPageContextInterface {
//  collateralRatio: string;
//  consensusAddress: string;
//  responseTime: string;
//  interestRate: string;
//  requestHash: string;
//  minCollateralNeeded: string;
//  maxLoanAmount: string;
//  signature: string;
//  signer: string;
//  signerNonce: string;
//  chainId: string;
//  message?: string;
//  loanTypeSelected: string | null;
//  setLoanTypeSelected: any;
// }

export interface BorrowProcessInterface {
  isSubmitting: null | string;
  requestingLoan: null | string;
  requestingTerms: null | string;
  setRequestingLoan: () => void;
  setRequestingTerms: () => void;
  setStageChangeWarning: () => void;
  setSubmitting: () => void;
  setSuccess: () => void;
  stageChangeWarning: null | number;
  success: null | string;
}

export interface BorrowPageContextInterface {
  NFTs: any;
  borrowProcessState: null | BorrowProcessInterface;
  borrowRequest: BorrowRequest;
  lendingApp: PBorrow;
  loanTerms: LoanTerms[];
  loanTypeSelected: any;
  resetBorrowRequest: any;
  setBorrowRequest: () => void;
  setLendingApp: () => void;
  setLoanTerms: () => void;
  setLoanTypeSelected: any;
  setNFTs: () => void;
  setStage: () => void;
  setSubmenu: () => void;
  stage: number;
  submenu: null | string;
  nodeResponses: any;
  setNodeResponses: any;
}

export interface LendClaimStateInterface {
  assetChangeWarning: null | string;
  assetClaimed: null | string;
  isClaiming: null | string;
  isCollecting: null | string;
  setAssetChangeWarning: () => void;
  setAssetClaimed: () => void;
  setClaiming: () => void;
  setCollecting: () => void;
  setSuccess: () => void;
  success: null | string;
}

export interface LendWithdrawStateInterface {
  isWithdrawing: string | null;
  selectedCurrency: AvailableLendingTokens;
  setSelectedCurrency: () => void;
  setSuccess: () => void;
  setWarningMessage: () => void;
  setWithdrawing: () => void;
  success: string | null;
  warningMessage: string;
}
export interface BorrowRepayStateInterface {
  isRepaying: string | null;
  repaySuccess: string | null;
  selectedLoan: null | LoanInterface;
  setRepaySuccess: () => void;
  setRepaying: () => void;
  setSelectedLoan: () => void;
}
export interface BorrowDepositStateInterface {
  addCollateralSubmenu: null | boolean;
  collateral: null | number;
  isDepositing: null | string;
  newCollateralPercent: null | number;
  selectedLoan: null | LoanInterface;
  setAddCollateralSubmenu: () => void;
  setCollateral: () => void;
  setDepositing: () => void;
  setNewCollateralPercent: () => void;
  setSelectedLoan: () => void;
  setSuccess: () => void;
  success: null | string;
}
export interface BorrowWithdrawStateInterface {
  isWithdrawing: string | null;
  newCollateralPercent: null | number;
  selectedLoan: null | LoanInterface;
  setNewCollateralPercent: () => void;
  setSelectedLoan: () => void;
  setSuccess: () => void;
  setWithdrawAmount: () => void;
  setWithdrawCollateralSubmenu: () => void;
  setWithdrawing: () => void;
  success: string | null;
  withdrawAmount: number;
  withdrawCollateralSubmenu: boolean;
}
export interface UseCompoundStateInterface {
  amount: number;
  amountSubmenu: boolean;
  isSupplying: string;
  isWithdrawing: string | null;
  selectedLoan: null | LoanInterface;
  setAmount: () => void;
  setAmountSubmenu: () => void;
  setSelectedLoan: () => void;
  setSuccess: () => void;
  setSuccessMessage: () => void;
  setSupplying: () => void;
  setWithdrawing: () => void;
  success: string | null;
  successMessage: string | null;
  useCompAPY: (symbol: AvailableTokens) => void;
}

interface UniswapValue {
  amount: string;
  currency: string;
}
export interface UniswapStateInterface {
  escrowBalances: any;
  isExactIn: boolean;
  options: string[];
  processing: string | null;
  selectedLoan: null | LoanInterface;
  setEscrowBalances: () => void;
  setIsExactIn: () => void;
  setPairs: () => void;
  setProcessing: () => void;
  setSelectedLoan: () => void;
  setSuccess: () => void;
  setSwapValues: () => void;
  setTokenSelectionDropdown: () => void;
  setTrade: () => void;
  setValues: () => void;
  success: string | null;
  swapValues: any;
  tokenKeys: any;
  tokenSelectionDropdown: any;
  trade: any;
  values: any;
}

export interface TokenInterface {
  price: string;
  supplyAPY: string;
}
export interface TokenDataInterface {
  [key: string]: TokenInterface;
}
export enum PageTypes {
  "borrow-deposit" = "borrow-deposit",
  "borrow-repay" = "borrow-repay",
  "borrow-terms" = "borrow-terms",
  "borrow-withdraw" = "borrow-withdraw",
  // "deposit-claim" = "deposit-claim",
  // "deposit-portfolio" = "deposit-portfolio",
  // "deposit-withdraw" = "deposit-withdraw",
  "spend-compound" = "spend-Compound",
  "spend-aave" = "spend-Aave",
  // "spend-poolTogether" = "spend-PoolTogether",
  "spend-sushiswap" = "spend-Sushiswap",
  "spend-uniswap" = "spend-uniswap",
}

export enum StatusTypes {
  "Active" = "Active",
  "Closed" = "Closed",
  "TermsSet" = "TermsSet",
}

export interface ContextProps {
  children: React.ReactNode;
  Web3StateContextMockValues?: any;
}

interface DataProviderResponseInterface {
  providerTokens?: PublicTokenResponsePayload;
}
interface LoginModal {
  show: boolean;
}
interface PlaidInterface {
  metadata: any;
  publicKey: string;
}
export interface PlaidState {
  plaid: { loggedIn: any; dataProviderResponse: any };
  setPlaid: Dispatch<any>;
}

export type AvailableTTokens = "TUSDT" | "TDAI";

export type AvailableCollateralTokens = "MATIC" | "ETH" | "DAI" | "USDC" | "USDT" | "WBTC" | "LINK";

export type AvailableLendingTokens = "MATIC" | "ETH" | "DAI" | "USDC" | "USDT" | "WBTC" | "LINK";

export type AvailableERC20Tokens =
  | "AAVE"
  | "LINK"
  | "MKR"
  | "SNX"
  | "USDC"
  | "DAI"
  | "USDT"
  | "WBTC"
  | "WETH"
  | "YFI";

export type AvailableDapps = "compound" | "uniswap" | "aave" | "pooltogether" | "sushiswap";

export type AvailableTokens =
  | AvailableERC20Tokens
  | AvailableLendingTokens
  | AvailableCollateralTokens;

export interface ATMData {
  availableLiquidity: any | null;
  borrowedToken: BigNumber | null;
  cTokenInstance: any;
  collateral_buffer: any;
  escrow: any | null;
  lenderInstance: any | null;
  lendingPool: any | null;
  loansInstance: any | null;
  nftDistributor: any | null;
  oneDayAPY: any;
  suppliedBalance: BigNumber | null;
  tToken: any | null; // Our Token used for accruing lending
  tTokenAddress: string | null;
  terms: any;
  totalBorrowOutstanding: string | null;
  totalInterestEarned: any;
  totalSupplied: any | null;
  web3CompoundContract: any | null;
  web3UniswapContract: any | null;
}

export type TellerContractsLendingATM = {
  [key in TellerTokens]: ATMData;
};

export type TellerContractsBorrowingATM = {
  [key in BaseTokens]: TellerContractsLendingATM;
};

export interface TellerState {
  approved_dai: null | number;
  collateral_buffer: null | number;
  contracts: TellerContractsBorrowingATM | any;
  nfts: any;
  userWalletBalance: null | UserWalletBalanceInterface;
}

export type NFT = NFTInterface;
export interface NFTInterface {
  merkleIndex?: number | null;
  nodeIndex?: string | null;
  amount?: number | null;
  merkleProof?: string | null;
  isClaimed?: boolean | null;
  exists?: boolean | null;
  tierIndex?: number | null;
  id: string;
  status: NFTStatusEnum;
  credit?: string | null;
  uri?: any;
  attributes?: any;
  type?: NFTType;
  balance?: any;
}

export type V2BalanceArray = [string[], string[]];
export interface V2BalanceObj {
  ids: string[];
  balances: string[];
}
export type V2Balances = V2BalanceArray & V2BalanceObj;

export interface FicoState {
  score: number | null;
}
export interface TokenDataInterface {
  [key: string]: string;
}
export interface UserWalletBalanceInterface {
  [key: string]: BigNumber | null;
}
export interface AppContextState {
  chainLink: any;
  claimModal: ClaimModal;
  dataProviderResponse: DataProviderResponseInterface;
  errorModal: ErrorModal;
  explorerMode: boolean;
  fico: FicoState;
  interestData: InterestModals;
  loginModal: LoginModal;
  plaid: PlaidState;
  subgraphOn: boolean | null;
  teller: TellerState;
  tokenData: any;
  transactions: any;
  UIHelpers: {
    borrowingStarted: boolean;
    leaveBorrowProcessModal: string | null;
    onSubmenuClose: any;
    showClaimModal: boolean;
    showLogOutModal: boolean;
  };
}
export interface InterestModals {
  [key: string]: InterestModal;
}
export interface InterestModal {
  claimedInterest: any;
  interestRequest: any;
  lastClaimTime: any;
  nodeResponses: any;
  totalInterest: BigNumber | null;
  unclaimedInterest: any;
}

export interface ClaimModal {
  unclaimedNFTs: any;
  claimedNFTs: any;
}

export interface AppContextInterface {
  state: AppContextState;
}

export interface LoanTakenOut {
  loanID: string;
  borrower: string;
  amountBorrowed: string;
  withNFT: boolean;
}

export interface LoanEvents {
  address: string;
  blockHash: string;
  blockNumber: number;
  event: string;
  logIndex: 2;
  removed: false;
  returnValues: LoanTakenOut;
  signature: string;
  transactionHash: string;
}
