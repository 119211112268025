import React from "react";
import Lottie from "react-lottie";

import animationData from "assets/processing_animation.json";

const ProcessingAnimation = ({ height = 200 }: any) => {
  return (
    <div className="-my-2 primarybutton" style={{ cursor: "default !important" }}>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        width={215}
        height={height}
        isClickToPauseDisabled
      />
    </div>
  );
};

export default ProcessingAnimation;
