import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import ClaimModal from "components/ClaimModal";
import Footer from "components/Footer/Footer";
import Navigation from "components/Navigation/Navigation";
import NetworkBar from "components/NetworkBar";
import NotificationsBar from "components/NotificationsBar";
import TermsModal from "components/TermsModal";
import Borrow from "pages/Borrow/Borrow";
import Dashboard from "pages/Dashboard/Dashboard";
import Lend from "pages/Lend/Lend";

import NFTDashboard from "./NFTDashboard";
import Stats from "./Stats";
import ManageLiquidity from "./ManageLiquidity";
import useDarkNav from "hooks/useDarkNav";

export const Routing = () => {
  return (
    <div className={`app xs:text-sm`}>
      <Router>
        <Routes />
      </Router>
    </div>
  );
};

export const Routes = () => {
  const { darkNav } = useDarkNav();
  return (
    <>
      <NotificationsBar />
      <div className={`min-h-screen flex flex-col ${darkNav ? "bg-green-01" : ""}`}>
        <TermsModal />
        <NetworkBar />
        <Navigation />
        <div className="main-content flex flex-row justify-center items-center xs:my-4 sm:my-4 md:my-10 xl:mt-16 2xl:mt-20">
          <Switch>
            <Route path="/borrow">
              <Borrow />
            </Route>
            <Route path="/dashboard">
              <Dashboard />
            </Route>
            {/* <Route path="/deposit">
              <div className="cards-container-wrapper lg:self-start ">
                <Lend />
              </div>
            </Route> */}
            <Route path="/stats">
              <Stats />
            </Route>
            <Route path="/nft-dashboard">
              <div className="max-w-4xl mx-auto sm:px-12 md:px-0 flex-1 self-start">
                <NFTDashboard />
              </div>
            </Route>
            <Route path="/liquidity">
              <div className="cards-container-wrapper lg:self-stretch flex-col w-full justify-center md:-mb-10 -m-4 mt-10 sm:mt-0">
                <ManageLiquidity />
              </div>
            </Route>
            <Route path="/">
              <Borrow />
            </Route>
          </Switch>
        </div>
        <Footer />
      </div>
    </>
  );
};
