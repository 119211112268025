import React from "react";

import LoaderPT1 from "assets/loader-pt1.svg";
import LoaderPT2 from "assets/loader-pt2.svg";
import LoaderPT3 from "assets/loader-pt3.svg";

const LoadingAnimation = () => {
  return (
    <div className="relative h-44 d-block mb-2">
      <img
        alt="Loader Processing"
        src={LoaderPT1}
        height="178"
        width="178"
        className="absolute top-1/2 left-1/2 animate-spin-slow"
        style={{ marginTop: "-89px", marginLeft: "-89px" }}
      />
      <img
        alt="Loader Processing"
        src={LoaderPT2}
        height="96"
        width="96"
        className="absolute top-1/2 left-1/2 animate-spin-v-slow"
        style={{ marginTop: "-48px", marginLeft: "-48px" }}
      />{" "}
      <img
        alt="Loader Processing"
        src={LoaderPT3}
        height="34"
        width="30"
        className="absolute top-1/2 left-1/2"
        style={{ marginTop: "-17px", marginLeft: "-15px" }}
      />
    </div>
  );
};

export default LoadingAnimation;
