import React from "react";

const TestnetBanner = () => {
  return (
    // <div className="alpha-banner bg-red-lightest mb-3 py-4 px-5 text-left rounded-lg xs:text-sm">
    //   Teller is currently in alpha. Please use at your own risk.
    // </div>
    <></>
  );
};
export default TestnetBanner;
