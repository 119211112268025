import BigNumber from "bignumber.js";
import React, { createContext, useCallback, useContext, useMemo, useState } from "react";

import { useTellerContext } from "contexts/global/tellerContext";
import { useTransactionsContext } from "contexts/global/transactionsContext";
import { useWeb3StateContext } from "contexts/global/web3StateContext";
// Import modules
import { LoanInterface, LoanNFT } from "contexts/types";
import { calculateCollateralPercent } from "utils/helperFunctions";
import { useBalance } from "hooks/useBalance";
import { useDeepCompareEffect } from "react-use";
import { useToken } from "hooks/useToken";
import { useTokenPrice } from "hooks/useTokenPrice";
import { allContractAddresses } from "constants/constants";
import { useNetworkConfig } from "hooks/useNetworkConfig";

const defaultLoansState = {
  loans: null as null,
  loansLoading: false,
  loanIDs: [] as null,
  linkedNFTs: null as null,
};

export const LoansContext = createContext<{
  loans: any[];
  loansLoading: boolean;
  loanIDs: string[];
  linkedNFTs: null;
}>(defaultLoansState);

export const LoansContextProvider = ({ children }: any) => {
  const { loans, loansLoading, loanIDs, linkedNFTs } = useLoans();
  const value = useMemo(() => {
    return { loans, loansLoading, loanIDs, linkedNFTs };
  }, [loans, loansLoading, loanIDs, linkedNFTs]);

  return <LoansContext.Provider value={value}>{children}</LoansContext.Provider>;
};

export const useLoansContext = () => {
  const context = useContext(LoansContext);
  if (context === undefined) {
    throw new Error("useLoans must be used within a useLoansProvider");
  }
  return context;
};

export const useLoans = () => {
  const tellerContext = useTellerContext();
  const { tellerDiamondContract, collateralBuffer, networkSupported } = tellerContext;
  const { userAddress, network } = useWeb3StateContext();
  const [loans, setLoans] = useState(null);
  const { transactions } = useTransactionsContext();
  const [loanIDs, setLoanIDs] = useState([]);
  const [loansLoading, setLoansLoading] = useState(true);
  const [linkedNFTs, setLinkedNFTs] = useState(null);

  const tokens = {};
  const collateralTokenPrices = {};
  const borrrowedTokenPrices = {};
  const zeroAddress = "0x0000000000000000000000000000000000000000";

  tokens["ETH"] = useToken("ETH");
  tokens["DAI"] = useToken("DAI");
  tokens["USDT"] = useToken("USDT");
  tokens["USDC"] = useToken("USDC");
  tokens["WBTC"] = useToken("WBTC");
  tokens["LINK"] = useToken("LINK");

  // Show collateral token prices for all collateralized tokens
  collateralTokenPrices["ETH"] = useTokenPrice("ETH");
  collateralTokenPrices["DAI"] = useTokenPrice("DAI");
  collateralTokenPrices["USDC"] = useTokenPrice("USDC");
  collateralTokenPrices["USDT"] = useTokenPrice("USDT");
  collateralTokenPrices["WBTC"] = useTokenPrice("WBTC");
  collateralTokenPrices["LINK"] = useTokenPrice("LINK");

  borrrowedTokenPrices["DAI"] = useTokenPrice("DAI");
  borrrowedTokenPrices["USDC"] = useTokenPrice("USDC");
  borrrowedTokenPrices["USDT"] = useTokenPrice("USDT");
  borrrowedTokenPrices["WBTC"] = useTokenPrice("WBTC");
  borrrowedTokenPrices["LINK"] = useTokenPrice("LINK");
  borrrowedTokenPrices["ETH"] = useTokenPrice("ETH");

  const tokenAddresses = useMemo(() => {
    return network.name && network.name != "unknown"
      ? allContractAddresses[network.name].tokens
      : {};
  }, [network]);

  const fetchLoans = useCallback(async () => {
    const currentTime = Date.now();
    try {
      setLoansLoading(true);
      const loanIDs = await tellerDiamondContract.methods.getBorrowerLoans(userAddress).call();
      if (!loanIDs || loanIDs.length < 1) {
        setLoansLoading(false);
        return [];
      }
      setLoanIDs(loanIDs);
      const allLoansData = await Promise.all(
        loanIDs.map((loanID: number) => tellerDiamondContract.methods.getLoan(loanID).call()),
      );
      const nftLinked = [];
      const loans: Array<LoanInterface> = await Promise.all(
        allLoansData.map(async (loan: any) => {
          try {
            loan = { ...loan };
            const borrowedTokenAddress = loan.lendingToken;
            const lendingToken = Object.keys(tokenAddresses).find(
              (key) => tokenAddresses[key].toLowerCase() === borrowedTokenAddress.toLowerCase(),
            );
            const borrowedToken = lendingToken;

            loan.isTerms = Boolean(Number(loan.loanStartTime) == 0);

            loan.IDNumber = Number(loan.id);
            loan.token = borrowedToken;
            const loanTerms = await tellerDiamondContract.methods
              .getLoanTerms(loan.IDNumber)
              .call();

            loan.startDate = loan.loanStartTime;
            loan.endDate = Number(loan.loanStartTime) + Number(loan.duration);
            loan.timeTillExpires = Math.round(
              (Number(loan.endDate) - Number(currentTime / 1000)) / (60 * 60 * 24),
            );

            loan.escrow = await tellerDiamondContract.methods.getLoanEscrow(loan.IDNumber).call();
            const [v1NFTs, v2NFTs] = await Promise.all([
              tellerDiamondContract.methods.getLoanNFTs(loan.IDNumber).call(),
              tellerDiamondContract.methods.getLoanNFTsV2(loan.IDNumber).call(),
            ]);
            const loanNfts: Array<LoanNFT> = [];
            v2NFTs.loanNFTs_.map((nft: string) => {
              loanNfts.push({ id: nft, type: "V2" });
            });
            v1NFTs.map((nft: string) => {
              loanNfts.push({ id: nft, type: "V1" });
            });
            loan.nfts = loanNfts;
            if (loan.nfts.length > 0 && loan.status !== "3") {
              loan.nfts.map((nft: LoanNFT) => {
                nftLinked.push({
                  id: nft.id,
                  type: nft.type,
                  loanID: loan.IDNumber,
                  loanAddress: loan.escrow,
                  timeTillExpires: loan.timeTillExpires,
                  loanExpirationTimestamp: loan.endDate * 1000,
                });
              });
            }
            const collateralTokenAddress = loan.collateralToken;
            let collateralToken;
            loan.collateralTokenDecimals = 0;
            loan.collateralAmount = 0;
            loan.collateralDecimalsMultiplier = 0;
            // checks the loan type and adjusts the loan object accordingly
            if (collateralTokenAddress && collateralTokenAddress != zeroAddress) {
              const collateralTokenID = Object.keys(tokenAddresses).find(
                (key) => tokenAddresses[key].toLowerCase() === collateralTokenAddress.toLowerCase(),
              );
              collateralToken = collateralTokenID == "WETH" ? "ETH" : collateralTokenID;
              loan.collateralDecimalsMultiplier = Math.pow(
                10,
                tokens[collateralToken].tokenDecimals,
              );
              loan.collateralTokenDecimals = tokens[collateralToken].tokenDecimals;
              loan.collateralAmount = await tellerDiamondContract.methods
                .getLoanCollateral(loan.IDNumber)
                .call();
            } else if (loan.nfts.length > 0) {
              collateralToken = `NFT`;
            } else {
              collateralToken = `BANK`;
            }
            loan.id = `${collateralToken}-${borrowedToken}-${loan.id}`;
            loan.collateralToken = collateralToken;

            loan.lendingDecimalsMultiplier = Math.pow(10, tokens[lendingToken].tokenDecimals);
            loan.lendingTokenDecimals = tokens[lendingToken].tokenDecimals;

            loan.terms = {
              interestRate: loan.interestRate,
              collateralRatio: loan.collateralRatio,
              maxLoanAmount: loanTerms.maxLoanAmount,
              duration: loan.duration.toString(),
              expiryAt: Number(loanTerms.termsExpiry),
              collateralAmount: loan.collateralAmount,
            };
            loan.loanTerms = loan.terms;

            loan.totalOwedAmount = await tellerDiamondContract.methods
              .getTotalOwed(loan.IDNumber)
              .call();
            loan.totalOwedAmountNumber = loan.totalOwedAmount / loan.lendingDecimalsMultiplier;
            const collateralInfo =
              loan.escrow == zeroAddress
                ? {}
                : await tellerDiamondContract.methods.getCollateralNeededInfo(loan.IDNumber).call();
            loan.collateralInfo = { ...collateralInfo };
            loan.collateralInfo.neededInCollateralTokens = new BigNumber(
              loan.collateralInfo.neededInCollateralTokens,
            )
              .times(1.01)
              .toFixed(0);

            loan.statusName =
              loan.status === "3"
                ? "Repaid"
                : loan.timeTillExpires >= 0
                ? "Outstanding"
                : "Overdue";

            loan.loanType =
              loan.terms.collateralRatio >= Number(collateralBuffer) ? "Secured" : "Unsecured";
            loan.lendingPoolAddress = tellerDiamondContract._address;
            loan.totalValue = loan.collateralInfo.escrowLoanValue;
            loan.totalValueNumber = loan.totalValue / loan.lendingDecimalsMultiplier;
            loan.expired = Boolean(
              Number(loan.terms.expiryAt * 1000) <= Number(currentTime) - 60000 * 3,
            );
            loan.IDstring = loan.id;
            loan.amountBorrowed = loan.borrowedAmount;

            loan.collateralAmountNumber = loan.collateralAmount / loan.collateralDecimalsMultiplier;
            loan.borrowedAmountNumber = loan.borrowedAmount / loan.lendingDecimalsMultiplier;
            loan.currentCollateralPercent =
              collateralToken && loan.collateralAmountNumber > 0
                ? await calculateCollateralPercent(
                    collateralTokenPrices[loan.collateralToken],
                    borrrowedTokenPrices[loan.token],
                    loan.collateralAmountNumber,
                    loan.borrowedAmountNumber,
                  )
                : 0;
            return loan;
          } catch (err) {
            console.log({ err, loan });
          }
        }),
      );
      setLoansLoading(false);
      setLinkedNFTs(nftLinked);
      return loans;
    } catch (err) {
      setLoansLoading(false);
      return null;
    }
  }, [
    collateralBuffer,
    tellerDiamondContract,
    userAddress,
    tokens,
    borrrowedTokenPrices,
    collateralTokenPrices,
    tokenAddresses,
  ]);

  useDeepCompareEffect(() => {
    setLoansLoading(true);

    if (!networkSupported) {
      setLoansLoading(false);
      return;
    }
    let isMounted = true;
    (async () => {
      if (
        !transactions ||
        !userAddress ||
        !network.id ||
        !tellerDiamondContract ||
        !collateralBuffer ||
        (network.name == "polygon" && !collateralTokenPrices["MATIC"]) ||
        !tokens["ETH"] ||
        !tokens["DAI"] ||
        !tokens["USDT"] ||
        !tokens["USDC"] ||
        !tokens["WBTC"] ||
        !tokens["LINK"] ||
        !collateralTokenPrices["ETH"] ||
        !collateralTokenPrices["DAI"] ||
        !collateralTokenPrices["USDC"] ||
        !collateralTokenPrices["USDT"] ||
        !collateralTokenPrices["WBTC"] ||
        !collateralTokenPrices["LINK"] ||
        !borrrowedTokenPrices["DAI"] ||
        !borrrowedTokenPrices["USDC"] ||
        !borrrowedTokenPrices["USDT"] ||
        !borrrowedTokenPrices["WBTC"] ||
        !borrrowedTokenPrices["LINK"] ||
        !borrrowedTokenPrices["ETH"]
      ) {
        setLoansLoading(false);
        return;
      }
      const loans = await fetchLoans();
      if (isMounted) setLoans(loans);
    })();

    return () => {
      isMounted = false;
    };
  }, [
    transactions,
    userAddress,
    network.id,
    tellerDiamondContract,
    collateralBuffer,
    collateralTokenPrices,
    borrrowedTokenPrices,
    tokens,
  ]);

  return useMemo(() => {
    return { loans, loansLoading, loanIDs, linkedNFTs };
  }, [loans, loansLoading, loanIDs, linkedNFTs]);
};

export const useEscrowBalances = (escrowContractAddress: string) => {
  const DAI = useBalance("DAI", escrowContractAddress);
  const YFI = useBalance("YFI", escrowContractAddress);
  const WETH = useBalance("WETH", escrowContractAddress);
  const USDC = useBalance("USDC", escrowContractAddress);
  const LINK = useBalance("LINK", escrowContractAddress);
  const SNX = useBalance("SNX", escrowContractAddress);
  const MKR = useBalance("MKR", escrowContractAddress);
  const AAVE = useBalance("AAVE", escrowContractAddress);
  const WBTC = useBalance("WBTC", escrowContractAddress);
  const USDT = useBalance("USDT", escrowContractAddress);

  return useMemo(() => {
    if (!escrowContractAddress) return null;
    return { DAI, YFI, WETH, USDC, LINK, SNX, MKR, AAVE, WBTC, USDT };
  }, [DAI, YFI, WETH, USDC, LINK, SNX, MKR, AAVE, WBTC, USDT]);
};
