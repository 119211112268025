import { useTellerContext, useTToken } from "contexts/global/tellerContext";
import BigNumber from "bignumber.js";
import { useEffect, useMemo, useState } from "react";
import { useBNMethods } from "./useBNMethods";
import { useToken } from "./useToken";

export const useMarketState = (assetID = null) => {
  const tellerContext = useTellerContext();
  const lendingToken = assetID ? assetID : tellerContext.lendingToken;
  const tToken = useTToken(lendingToken);

  const [marketState, setMarketState] = useState<any>(null);
  const [totalSuppliedBN, setTotalSuppliedBN] = useState<any>(null);

  const { convertToString } = useBNMethods(lendingToken);

  // const token = useToken(lendingToken);
  // const { tellerDiamondContract } = useTellerContext();

  // useEffect(() => {
  //   if (!token?.tokenAddress || !tellerDiamondContract) return;
  //   (async () => {
  //     const maxLoanAmountFromContractBN = await tellerDiamondContract.methods
  //       .getAssetMaxLoanAmount(token.tokenAddress)
  //       .call();
  //     const maxLoanAmountFromContract = maxLoanAmountFromContractBN
  //       ? convertToString(maxLoanAmountFromContractBN)
  //       : 0;

  //     setMaxLoanAmountFromContract(maxLoanAmountFromContract);
  //   })();
  // }, [tellerDiamondContract, token, convertToString]);

  useEffect(() => {
    if (!tToken.tTokenContract) return;
    let isMounted = true;
    (async () => {
      const marketState = await tToken.tTokenContract.methods.getMarketState().call();
      const supplied = await tToken.tTokenContract.methods.totalUnderlyingSupply().call();
      if (isMounted) {
        setTotalSuppliedBN(new BigNumber(supplied));
        setMarketState(marketState);
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [tToken]);

  const maxLoanAmount = useMemo(() => {
    if (!marketState || !totalSuppliedBN) return null;
    const maxSupplyToDebt = 5000; // TODO: Get this value from contract
    const totalSupplied = new BigNumber(marketState.totalSupplied);
    const totalOnLoan = new BigNumber(marketState.totalOnLoan);
    return totalSupplied
      .plus(totalOnLoan)
      .multipliedBy(maxSupplyToDebt)
      .dividedBy(10000)
      .toNumber();
  }, [marketState, totalSuppliedBN]);

  const totalBorrowOutstandingBN = marketState
    ? new BigNumber(marketState.totalBorrowed).minus(new BigNumber(marketState.totalRepaid))
    : null;

  const totalBorrowOutstanding = totalBorrowOutstandingBN
    ? convertToString(totalBorrowOutstandingBN)
    : null;

  const totalSupplied = totalSuppliedBN ? convertToString(totalSuppliedBN) : null;

  const totalBorrowed =
    marketState && marketState.totalBorrowed ? convertToString(marketState.totalBorrowed) : null;
  const totalRepaid =
    marketState && marketState.totalRepaid ? convertToString(marketState.totalRepaid) : null;

  const availableLiquidityBN =
    totalSuppliedBN && totalBorrowOutstandingBN
      ? totalSuppliedBN.plus(totalBorrowOutstandingBN).dividedBy(2).minus(totalBorrowOutstandingBN)
      : null;

  const availableLiquidity = availableLiquidityBN ? convertToString(availableLiquidityBN) : null;

  return useMemo(() => {
    return {
      totalBorrowOutstanding,
      totalSupplied,
      totalBorrowed,
      totalRepaid,
      availableLiquidity,
      maxLoanAmount,
    };
  }, [
    totalBorrowOutstanding,
    totalSupplied,
    totalBorrowed,
    totalRepaid,
    availableLiquidity,
    maxLoanAmount,
  ]);
};
