import React from "react";

import tableDropdownBG from "assets/tableDropdownBG.svg";

import { AccordionHeaderProps } from "components/Accordion/types";
import NFTDashboardAccordionRowExpandTable from "./NFTDetailsTable";
import NFTImage from "./NFTImage";
import { useOpenseaLink } from "hooks/useEtherscanLink";

const NFTDashboardAccordionRowExpand = ({ id }: AccordionHeaderProps) => {
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${tableDropdownBG})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          minWidth: "100%",
        }}
        className="p-12 xl:p-16 z-0 flex flex-row justify-between space-x-8"
      >
        <div className="flex flex-1 flex-col justify-between">
          <div>
            <div className="type-p font-medium text-green-02">NFT Details</div>
            <NFTDashboardAccordionRowExpandTable id={id} />
          </div>
          <div className="flex flex-row flex-wrap type-p-sm font-medium">
            <div className="text-green-02 whitespace-pre xs:whitespace-normal">
              View on{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href={useOpenseaLink(id)}
                className="link text-gray"
              >
                <span className="text-green-03">OpenSea</span>
              </a>
            </div>
          </div>
        </div>
        <div className="bg-white rounded flex">
          <NFTImage id={id} />{" "}
        </div>
      </div>
    </>
  );
};

export default NFTDashboardAccordionRowExpand;
