import React, { useState } from "react";

import grayCheck from "assets/check-gray.svg";
import greenCheck from "assets/check-green.svg";
import LoadingAnimation from "components/UI/LoadingAnimation";

interface customSubmitProps {
  CTA: () => void;
  disabled?: boolean;
  loading?: boolean;
  approved: boolean;
  text: string;
}

export const CustomSubmitButton = ({
  CTA,
  disabled,
  loading,
  approved,
  text,
}: customSubmitProps) => {
  return (
    <div className="flex flex-row items-center font-medium">
      {!disabled && (
        <div
          className={`py-2 px-4 mr-3 sm:-my-2  font-medium rounded ${
            disabled ? "disabled text-teal-lightest bg-teal-light" : "bg-teal pointer text-white"
          }`}
          onClick={() => CTA()}
        >
          {text}
        </div>
      )}
      {loading ? (
        <div style={{ width: "14px" }}>
          <LoadingAnimation isStopped={!loading} />
        </div>
      ) : (
        <img src={approved ? greenCheck : grayCheck} height={14} width={14} />
      )}
    </div>
  );
};

interface mockSubmitType {
  text: string;
}

export const MockSubmitButton = ({ text }: mockSubmitType) => {
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [approved, setApproved] = useState(false);

  function delay(time: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, time);
    });
  }
  const CTA = async () => {
    if (disabled) return;
    setLoading(true);
    await delay(1000);
    setApproved(true);
    setLoading(false);
    setDisabled(true);
  };
  return (
    <CustomSubmitButton
      CTA={() => {
        CTA();
      }}
      disabled={disabled}
      loading={loading}
      approved={approved}
      text={text}
    />
  );
};
