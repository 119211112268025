import "./borrow.scss";

import React, { useContext, useEffect, useState } from "react";
import BorrowMetrics from "./BorrowMetrics";
import TestnetBanner from "components/UI/TestnetBanner";
import { BorrowPageContext } from "contexts/borrow/borrowContext";
import Card from "components/UI/Card";
import copy from "constants/copy.json";
import BorrowFormStage0 from "./BorrowFormStage0";
import BorrowFormStage1 from "./BorrowFormStage1";
import BorrowFormStage3 from "./BorrowFormStage3";
import Submenu from "./Submenu";
import BorrowMetricsProTip from "./BorrowMetricsProTip";

const Borrow = () => {
  const {
    stage,
    setStage,
    borrowProcessState,
    loanTypeSelected,
    setLoanTypeSelected,
    resetBorrowRequest,
    submenu,
  } = useContext(BorrowPageContext);

  const showWarning = (s: number) => {
    borrowProcessState.setStageChangeWarning(s);
  };
  const { header } = copy.pages.borrow.main;

  const resetLoan = () => {
    setStage(0);
    resetBorrowRequest();
  };
  return (
    <div className="borrow lg:self-start">
      <TestnetBanner />
      <div className="cards-container ">
        <Card
          className="main-card bg-teal-lightest text-center"
          title={header}
          goBack={
            stage === 0
              ? loanTypeSelected
                ? () => {
                    setLoanTypeSelected("");
                  }
                : null
              : () => {
                  stage === 1 ? resetLoan() : showWarning(1);
                }
          }
          headerRightColumnValue={stage > 0 ? <StageNumber number={stage} /> : <></>}
        >
          {submenu ? (
            <Submenu variant={submenu} />
          ) : (
            <div className="">
              {stage === 0 && <BorrowFormStage0 />}
              {stage === 1 && <BorrowFormStage1 />}
              {stage === 3 && <BorrowFormStage3 />}
            </div>
          )}
        </Card>
        {stage !== 0 ? (
          <BorrowMetrics />
        ) : (
          <div className="self-start">
            <Card className="metrics-card bg-gray-lightest  my-1 " title="Pro tip">
              <BorrowMetricsProTip />
            </Card>
          </div>
        )}
      </div>
    </div>
  );
};

export default Borrow;

const StageNumber = ({ number }: any) => {
  const displayNumber = number == 3 ? 2 : number;
  return (
    <div>
      Step <span className="font-medium">{displayNumber}</span> of 2
    </div>
  );
};
