import Axios from "axios";
import BigNumber from "bignumber.js";
import { AvailableTokens } from "contexts/types";
import { useEffect, useState } from "react";

const yearnApiUrl = "https://api.yearn.tools/";

export const useYearnAPY = (symbol: AvailableTokens) => {
  const [APY, setAPY] = useState("");

  useEffect(() => {
    let mounted = true;

    (async () => {
      const response = await Axios.get(`${yearnApiUrl}/vaults/apy`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      const token = response.data.find((token) => token.symbol == symbol);
      if (token) {
        const { value: apyLoanscan } = token.apyLoanscan; // for the underlying asset (dai) apy of yDAI
        const apy = new BigNumber(apyLoanscan).shiftedBy(2).toString();
        console.log(apy);
        if (mounted) setAPY(apy);
      } else {
        if (mounted) setAPY("0");
      }
    })();

    return () => {
      mounted = false;
    };
  }, []);

  return APY;
};
