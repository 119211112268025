import "./arrow.scss";

import React from "react";

import dropdown from "assets/arrow-right.svg";

interface arrowProps {
  direction: string;
  onClick?: () => null;
}
const Arrow = ({ direction, onClick = () => null }: arrowProps) => {
  return (
    <img className={`pointer ${direction}`} height={14} src={dropdown} onClick={() => onClick()} />
  );
};
export default Arrow;
