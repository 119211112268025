import useNFT from "hooks/useNFT";
import React, { useMemo } from "react";
import darkGreenBG from "assets/darkGreenBG.svg";
import Tooltip from "components/UI/Tooltip";
import BR from "components/UI/BR";
import CurrencyIcon from "components/UI/CurrencyIcon";
import Badge from "components/Badge";
import { NFTStatusBadgeStyles } from "./types";
import { convertTimestampToHumanDate } from "utils/helperFunctions";
import Countdown from "react-countdown";
import { NFT_CONTRIBUTION_MULTIPLIER } from "constants/constants";

const NFTDetailsTable = ({ id, className = "" }: { id: string; className?: string }) => {
  const { attributes, status, tier, stakeTimestamp, loanExpirationTimestamp } = useNFT(id);

  const stakeDate = useMemo(() => {
    if (!stakeTimestamp) return "";
    return convertTimestampToHumanDate(stakeTimestamp);
  }, [stakeTimestamp]);

  return (
    <div
      className={`flex flex-1 flex-col lg:max-w-md lg:my-5 lg:mr-6 font-light text-green-01 ${className} whitespace-nowrap`}
    >
      {status == "Defaulted" && (
        <>
          <div
            className="flex flex-1 rounded mb-3  xs:rounded-none"
            style={{
              backgroundImage: `url(${darkGreenBG})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              minWidth: "100%",
            }}
          >
            <div
              className="py-8  flex-1 flex text-white justify-center items-center flex-col"
              style={{ background: "rgba(0,0,0,.2)" }}
            >
              <div className="font-medium type-p">2 Days</div>
              <div className="type-p-sm">Past Due</div>
            </div>
            <div className="flex-1 flex text-white justify-center items-center flex-col">
              <div className="font-medium type-p">-</div>
              <div className="type-p-sm">Auction Date</div>
            </div>
          </div>
        </>
      )}

      <TableRow className="lg:hidden" FirstCol="NFT ID" SecondCol={id} />
      <BR variant="green" className="lg:hidden" />
      <TableRow
        className="lg:hidden"
        FirstCol="Status"
        SecondCol={<Badge text={status} badgeType={NFTStatusBadgeStyles[status]} />}
      />
      <BR variant="green" className="lg:hidden" />

      <TableRow
        className="lg:hidden"
        FirstCol={
          <TableTextWithIcon
            text="Loan Size"
            icon={
              <Tooltip
                white={true}
                header="Loan Size"
                text="The loan amount that this NFT is allowed to take out"
              />
            }
          />
        }
        SecondCol={
          <TableTextWithIcon
            text={(attributes.Base_Loan_Size * NFT_CONTRIBUTION_MULTIPLIER).toLocaleString()}
            icon={<CurrencyIcon currency={attributes.Base_Loan_Asset} />}
          />
        }
      />
      <BR variant="green" className="lg:hidden" />

      <>
        <TableRow
          FirstCol={
            <TableTextWithIcon
              text="Contribution Asset"
              icon={
                <Tooltip
                  white={true}
                  header="Contribution Asset"
                  text="Asset used to mint the NFT"
                />
              }
            />
          }
          SecondCol={
            <TableTextWithIcon
              text={attributes.Contribution_Asset}
              icon={<CurrencyIcon currency={attributes.Contribution_Asset} />}
            />
          }
        />
        <BR variant="green" />
        <TableRow
          FirstCol={
            <TableTextWithIcon
              text="Contribution Size"
              icon={
                <Tooltip
                  white={true}
                  header="Contribution Size"
                  text="Amount of the contribution asset used to mint the NFT"
                />
              }
            />
          }
          SecondCol={attributes.Contribution_Size}
        />
        <BR variant="green" />
        <TableRow
          FirstCol={
            <TableTextWithIcon
              text="Contribution Multiplier"
              icon={
                <Tooltip
                  white={true}
                  header="Contribution Multiplier"
                  text="Multiplier applied to the base loan size"
                />
              }
            />
          }
          SecondCol={"4x"}
        />
        <BR variant="green" />
        <TableRow
          FirstCol={
            <TableTextWithIcon
              text="Tier"
              icon={
                <Tooltip
                  white={true}
                  header="Tier"
                  text="The tier indicates the properties that are tied to the NFT"
                />
              }
            />
          }
          SecondCol={tier}
        />
        <BR variant="green" />
      </>
      {/*{status !== "Unstaked" && (*/}
      {/*  <>*/}
      {/*    <TableRow*/}
      {/*      FirstCol={*/}
      {/*        <TableTextWithIcon*/}
      {/*          text="Stake Date"*/}
      {/*          icon={*/}
      {/*            <Tooltip*/}
      {/*              white={true}*/}
      {/*              header="Stake Date"*/}
      {/*              text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitatio"*/}
      {/*            />*/}
      {/*          }*/}
      {/*        />*/}
      {/*      }*/}
      {/*      SecondCol={stakeDate}*/}
      {/*    />*/}
      {/*    <BR variant="green" />*/}
      {/*    <TableRow*/}
      {/*      FirstCol={*/}
      {/*        <TableTextWithIcon*/}
      {/*          text="Remaining Stake"*/}
      {/*          icon={*/}
      {/*            <Tooltip*/}
      {/*              white={true}*/}
      {/*              header="Remaining Stake"*/}
      {/*              text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitatio"*/}
      {/*            />*/}
      {/*          }*/}
      {/*        />*/}
      {/*      }*/}
      {/*      SecondCol="20 Days"*/}
      {/*    />*/}
      {/*    <BR variant="green" />*/}
      {/*    <TableRow*/}
      {/*      FirstCol={*/}
      {/*        <TableTextWithIcon*/}
      {/*          text="APY Earned"*/}
      {/*          icon={*/}
      {/*            <Tooltip*/}
      {/*              white={true}*/}
      {/*              header="APY Earned"*/}
      {/*              text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitatio"*/}
      {/*            />*/}
      {/*          }*/}
      {/*        />*/}
      {/*      }*/}
      {/*      SecondCol={*/}
      {/*        <TableTextWithIcon text="100.000 DAI" icon={<CurrencyIcon currency="DAI" />} />*/}
      {/*      }*/}
      {/*    />*/}
      {/*    <BR variant="green" />*/}
      {/*  </>*/}
      {/*)}*/}
      {status == "Linked" && (
        <>
          <TableRow
            FirstCol={
              <TableTextWithIcon
                text="Loan Expiration"
                icon={
                  <Tooltip
                    white={true}
                    header="Loan Expiration"
                    text="The amount of time that is remaining on the loan this NFT is linked to"
                  />
                }
              />
            }
            SecondCol={
              <Countdown
                overtime
                date={loanExpirationTimestamp}
                renderer={({ days, hours, minutes, seconds }) => {
                  let str = "";

                  if (days > 0) {
                    str += `${days} days ${hours} hours`;
                  } else {
                    if (hours > 0) {
                      str += `${hours} hours `;
                    }

                    str += `${minutes} minutes `;
                    if (hours == 0) {
                      str += `${seconds} seconds `;
                    }
                  }

                  return <span>{str}</span>;
                }}
              />
            }
          />
        </>
      )}
    </div>
  );
};

export default NFTDetailsTable;

const TableRow = ({
  FirstCol = "",
  SecondCol = "",
  className = "",
}: {
  FirstCol: string | React.ReactNode;
  SecondCol: string | React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={`flex flex-row flex-1 justify-between py-5 ${className}`}>
      <div className="flex flex-1 items-center">{FirstCol}</div>
      <div className="flex flex-1 items-center justify-end">{SecondCol}</div>
    </div>
  );
};

const TableTextWithIcon = ({ text, icon }: { text: string; icon: React.ReactNode }) => {
  return (
    <>
      {text}
      <span className="ml-1">{icon}</span>
    </>
  );
};
