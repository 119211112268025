import React from "react";

import { NetworkNameEnum, useWeb3StateContext } from "contexts/global/web3StateContext";
import { allContractAddresses } from "constants/constants";

export const useEtherscanLink = (hash: string, variant: "address" | "tx") => {
  // console.log("useEtherscanLink");

  const { network } = useWeb3StateContext();
  if (network.name == NetworkNameEnum.mainnet) return `https://etherscan.io/${variant}/${hash}`;
  else return `https://${network.name}.etherscan.io/${variant}/${hash}`;
};

export const useOpenseaLink = (id: string) => {
  // console.log("useOpenseaLink");

  const { network } = useWeb3StateContext();
  if (!id || !network.name) return null;
  const TellerNFTAddress = allContractAddresses[network.name].TellerNFT;
  return `https://opensea.io/assets/${TellerNFTAddress}/${id}`;
};
