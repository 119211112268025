import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import ArtFramesLG from "assets/ArtFramesLG.svg";
import ArtFramesSM from "assets/ArtFramesSM.svg";
import LoaderComplete from "assets/LoaderComplete.svg";
import ModalFooterText from "components/Modal/ModalFooterText";
import { useNFTsContext } from "contexts/global/nftsContext";
import { useWeb3StateContext } from "contexts/global/web3StateContext";
import { useClaimNFT } from "actions/ClaimNFT";
import { useEtherscanLink } from "hooks/useEtherscanLink";
import { useTransactionHandler } from "hooks/useTransactionHandler";

import Button from "components/Button";
import FullScreenModal from "components/FullScreenModal";
import { useTellerContext } from "contexts/global/tellerContext";
import { useDeepCompareEffect } from "react-use";
import { ActionLoading, ActionSuccess } from "components/FullScreenModal/FullScreenModal";
import CardWithTable from "components/Modal/CardWithTable";

const ClaimNFTModal = ({ closeModal }: { closeModal: () => void }) => {
  const {
    allClaimed,
    allNFTsFromDistribution,
    claimCTA,
    claimCTADisabled,
    claiming,
    justClaimed,
    openSeaLink,
    totalClaimed,
    totalUnclaimed,
    unclaimedNFTs,
  } = useClaimModal();

  const etherscanURL = useEtherscanLink(claiming, "tx");

  return (
    <FullScreenModal
      onClose={() => {
        closeModal();
      }}
      footerCTA={
        <Button
          CTA={
            allClaimed
              ? () => {
                  closeModal();
                }
              : claimCTA
          }
          color="green"
          type="primary"
          text={allClaimed ? "Done" : claiming ? "Claiming.." : "Claim"}
          size="md"
          disabled={claimCTADisabled}
        />
      }
      footerCopy={
        claiming && claiming != "loading" ? (
          <>
            <a target="_blank" rel="noreferrer" href={etherscanURL} className="link text-gray">
              <u>View on Etherscan</u>
            </a>
          </>
        ) : allClaimed ? (
          <ModalFooterText text="View your NFTs on" highlightedText="OpenSea" href={openSeaLink} />
        ) : (
          <div></div>
        )
      }
      mainContent={
        <div className="justify-center items-center lg:text-center flex-1 min-h-screen ">
          {allClaimed && justClaimed && (
            <>
              <div className="min-h-screen flex flex-col justify-center items-center mx-auto">
                <img
                  alt="Loader Complete"
                  src={LoaderComplete}
                  height="177"
                  width="177"
                  className="d-inline-block align-top mx-auto mb-5"
                />
                <div className="type-h4 text-green-02 type-medium text-center mb-3">
                  NFT Claimed
                </div>
                <div className="type-p-sm type-light text-black text-center lg:max-w-sm mx-auto whitespace-normal">
                  Your NFT is claimed. View your transaction on Etherscan.{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={etherscanURL}
                    className="link text-gray"
                  >
                    <u>View your transaction on Etherscan.</u>
                  </a>
                </div>
              </div>
              <ActionSuccess
                title="NFT Claimed"
                subTitle={
                  <>
                    {" "}
                    Your NFT is claimed. View your transaction on Etherscan.{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={etherscanURL}
                      className="link text-gray"
                    >
                      <u>View your transaction on Etherscan.</u>
                    </a>
                  </>
                }
              />
            </>
          )}
          {allClaimed && !justClaimed && (
            <div className="flex flex-col lg:flex-row flex-1  max-w-screen-2xl mx-auto">
              <div className="flex-1 text-left ">
                <div>
                  {!!totalClaimed && totalClaimed > 0 && (
                    <>
                      <div className="hidden lg:block type-h3 text-green-02  mb-3">
                        {totalClaimed} Total <span className="text-green-03">NFTs Claimed</span>
                      </div>
                      <div className="block lg:hidden type-h4 text-green-02  mb-3">
                        {totalClaimed} Total <span className="text-green-03">NFTs Claimed</span>
                      </div>
                      <div className="lg:max-w-sm mb-10 text-black whitespace-normal">
                        You’ve successfully claimed your NFT. View your NFT on OpenSea. It may take
                        a few minutes for your NFT to show.{" "}
                      </div>
                    </>
                  )}
                </div>
                <div className="mt-10">
                  <ClaimCardWithTable
                    totalUnclaimed={totalUnclaimed}
                    unclaimedNFTs={unclaimedNFTs}
                    allNFTsFromDistribution={allNFTsFromDistribution}
                  />
                </div>
              </div>
              <div className="flex-1 mt-10 lg:mt-0 w-screen overflow-hidden flex justify-center relative">
                <img
                  alt="Teller NFTs"
                  src={ArtFramesSM}
                  width="543"
                  height="216"
                  className="d-inline-block align-top object-cover fixed left-0 lg:hidden overflow-hidden w-screen"
                />
                <img
                  alt="Teller NFTs"
                  src={ArtFramesLG}
                  height="auto"
                  width="auto"
                  className="d-inline-block align-top mx-auto hidden lg:block overflow-hidden fixed pl-10	left-1/2 h-screen object-cover"
                  style={{ maxHeight: "900px" }}
                />
              </div>
            </div>
          )}
          {!allClaimed && claiming && (
            <ActionLoading
              title="Claiming Your NFT"
              subTitle="Your NFT is currently being claimed."
            />
          )}
          {!allClaimed && !claiming && (
            <div className="flex flex-col lg:flex-row flex-1 max-w-screen-2xl	text-left mx-auto overflow-scroll">
              <div className="flex-1 flex flex-col items-start">
                <div className="block lg:hidden type-h3 text-green-02  mb-3">
                  Claim <span className="text-green-03">Your NFT</span>
                </div>
                <div className="hidden lg:block type-h2 text-green-02 mb-3">
                  Claim <span className="text-green-03">Your NFT</span>
                </div>
                <div className="lg:max-w-sm mb-10 text-black whitespace-normal">
                  Looks like you haven’t claimed your Fortune Teller NFT yet.
                </div>
              </div>
              <div className="flex-1 -mx-6 md:mx-0">
                <ClaimCardWithTable
                  totalUnclaimed={totalUnclaimed}
                  unclaimedNFTs={unclaimedNFTs}
                  allNFTsFromDistribution={allNFTsFromDistribution}
                />
              </div>
            </div>
          )}
        </div>
      }
    />
  );
};

export default ClaimNFTModal;

const ClaimCardWithTable = ({
  allNFTsFromDistribution,
  totalUnclaimed,
}: {
  allNFTsFromDistribution: any;
  unclaimedNFTs: any;
  totalUnclaimed: number;
}) => {
  const allClaimed = !(totalUnclaimed && totalUnclaimed > 0);

  return (
    <CardWithTable
      header={
        allClaimed ? null : (
          <>
            <div className="mb-2">Total NFTs</div>
            <div className="text-4xl">{`${totalUnclaimed} NFT`}</div>
          </>
        )
      }
      rows={[
        ...allNFTsFromDistribution.map((tier, i) => {
          let amount = 0;
          if (allClaimed && tier.isClaimed) {
            amount = tier.amount;
          } else if (!allClaimed && !tier.isClaimed) {
            amount = tier.amount;
          }
          const row = {
            left: `Tier ${tier.tierIndex + 1}`,
            right: `${amount} NFT`,
            hidden: false,
          };

          if (i == 4) {
            row.left = "Chainlink Raffle";
          } else if (i == 5) {
            row.left = "Giveaway winners";
          } else if (i > 5) {
            row.left = `Bonus Tier ${tier.tierIndex + 1}`;
            row.hidden = Boolean(amount == 0);
          }
          return row;
        }),
      ]}
    />
  );
};

const useClaimModal = () => {
  const { totalUnclaimed, unclaimedNFTs, allNFTsFromDistribution, totalClaimed } = useNFTsContext();
  const { userAddress } = useWeb3StateContext();
  const { NFTDistributorContract } = useTellerContext();
  const claimNFT = useClaimNFT();

  const [allClaimed, setAllClaimed] = useState(unclaimedNFTs && totalUnclaimed === 0);
  const [claiming, setClaiming] = useState(null);
  const [justClaimed, setJustClaimed] = useState(null);

  const onSuccess = (hash: string) => {
    setClaiming(null);
    setJustClaimed(hash);
    setAllClaimed(true);
  };
  const claimTransactionHandler = useTransactionHandler("claimNFT", onSuccess, setClaiming);

  useDeepCompareEffect(() => {
    if (!allClaimed || totalUnclaimed > 0) return;
    let isMounted = true;

    (async () => {
      await new Promise((resolve) => setTimeout(resolve, 3000));
      if (isMounted) setJustClaimed(false);
    })();
    return () => {
      isMounted = false;
    };
  }, [allClaimed, totalUnclaimed]);

  const claimCTADisabled = Boolean(claiming || !NFTDistributorContract || claimNFT == undefined);
  const openSeaLink = `https://opensea.io/accounts/${userAddress}?search[sortAscending]=false&search[sortBy]=LAST_TRANSFER_DATE`;

  const claimCTA = async () => {
    if (claiming || !NFTDistributorContract) return;
    setClaiming("loading");
    await claimNFT(claimTransactionHandler);
  };

  return {
    allClaimed,
    allNFTsFromDistribution,
    claimCTA,
    claimCTADisabled,
    claiming,
    justClaimed,
    openSeaLink,
    totalClaimed,
    totalUnclaimed,
    unclaimedNFTs,
  };
};
