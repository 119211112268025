import React, { useContext, useEffect, useState } from "react";

import copy from "constants/copy.json";
import { BorrowPageContext } from "contexts/borrow/borrowContext";
import SubmenuCard from "components/UI/SubmenuCard";
import FormValidationWarning from "components/UI/FormValidationWarning";
import CustomSlider from "components/UI/CustomSlider";
import CustomSubmenuLink from "components/UI/CustomSubmenuLink";

export const CollateralPercentSubmenu = () => {
  const { borrowRequest, setBorrowRequest, setSubmenu } = useContext(BorrowPageContext);
  const [value, setValue] = useState(`${borrowRequest.collateralPercent}%`);
  const [warning, setWarning] = useState("");
  const { title, description } = copy.pages.borrow.main.form.step2.collateralPercent.submenu;
  const minPercent = borrowRequest.loanType == "Secured" ? 20 : 0;
  const maxPercent = 150;
  const inputRangeWarning = `Please enter a number between ${minPercent}-${maxPercent}`;

  const onBlur = () => {
    setValue(`${value || minPercent}%`);
  };
  const onFocus = () => {
    setValue(`${value.replace(/[^0-9.]/g, "")}`);
  };

  const onChange = (e: any) => {
    let value = e.target.value;
    value = value.replace(/[^0-9.]/g, "");
    const split = value.split(".");
    if (split[1] && split[1].length > 4) {
      value = `${split[0]}.${split[1].substring(0, 4)}`;
    }
    if (isNaN(value)) {
      value = "";
    }

    setValue(value);
  };
  const onSubmit = () => {
    let valueNum = parseFloat(value.replace(/[^0-9.]/g, ""));
    if (valueNum > maxPercent) {
      valueNum = maxPercent;
    } else if (valueNum < minPercent) {
      valueNum = minPercent;
    }
    setBorrowRequest({
      ...borrowRequest,
      collateralPercent: valueNum,
    });
    setSubmenu(null);
  };
  const onSliderChange = (e: any) => {
    const sliderValue = e.target.value;
    setValue(`${Math.round(sliderValue).toString()}%`);
  };
  useEffect(() => {
    if (parseFloat(value) > maxPercent || parseFloat(value) < minPercent) {
      setWarning(inputRangeWarning);
    } else {
      setWarning("");
    }
  }, [value]);

  return (
    <SubmenuCard
      title={title}
      onCloseAction={() => {
        setSubmenu(null);
      }}
    >
      <div className="-my-2 flex flex-col px-4">
        <div className="mb-4">{description}</div>
        <input
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          className="input text-5xl xs:text-4xl font-medium text-black text-center bg-teal-lightest"
          value={value}
          name="amount"
          onChange={onChange}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
          onBlur={onBlur}
          onFocus={onFocus}
        />
        <CustomSlider
          min={minPercent}
          max={maxPercent}
          onChange={onSliderChange}
          value={Number(value.substring(0, value.length - 1))}
        />
        <div className="py-1 px-3 my-4 mx-auto border-thin pointer text-black" onClick={onSubmit}>
          Enter
        </div>
        <FormValidationWarning message={warning} />
      </div>
    </SubmenuCard>
  );
};

const CollateralPercentSelection = () => {
  const { borrowRequest, setSubmenu } = useContext(BorrowPageContext);

  return (
    <CustomSubmenuLink
      title={`${borrowRequest.collateralPercent || "-"}%`}
      CTA={() => {
        setSubmenu("CollateralPercent");
      }}
    />
  );
};
export default CollateralPercentSelection;
