import "./card.scss";

import React from "react";

import Arrow from "./Arrow";
import { useUIContext } from "contexts/global/uiContext";

interface CardProps {
  title: string;
  children: React.ReactNode;
  className: string;
  goBack?: null | any;
  logo?: React.ReactNode;
  dashboard?: boolean;
  headerRightColumnValue?: React.ReactNode;
  onSubmenuClose?: any;
}

const Card = ({
  title,
  children,
  className,
  goBack,
  logo,
  dashboard = false,
  headerRightColumnValue = <></>,
}: CardProps) => {
  const { onSubmenuClose } = useUIContext();

  const isSideCard = Boolean(title == "Summary" || title == "Pro tip");
  const goBackFunction = !isSideCard ? onSubmenuClose || goBack : null;

  return (
    <div
      className={`sm:mx-1 my-2 md:m-2 ${isSideCard && ""} ${dashboard && "w-full lg:w-auto"} ${
        !isSideCard && !dashboard && "min-w-xs"
      }} `}
    >
      {goBackFunction && (
        <div className="main-card bg-teal-lightest border-none flex flex-col justify-center rounded-t-lg">
          <div className="goback-header rounded-t-lg xs:p-4 flex justify-between items-center flex-row p-6">
            <Arrow
              onClick={() => {
                goBackFunction();
              }}
              direction="left"
            />
            <div className="mx-auto flex flex-row items-center text-teal">
              {headerRightColumnValue}
            </div>
          </div>
          <div />
        </div>
      )}
      <div
        className={`flex flex-col p-6 ${
          goBackFunction ? " mt-0 rounded-b-lg" : " rounded-lg"
        }  ${className} ${
          isSideCard
            ? "self-start sm:px-6 md:px-7 md:py-8 "
            : "main-card self-start xs:px-4 md:px-8 lg:px-16 xl:px-20 xs:py-8 sm:py-8 md:py-12 xl:py-14 flex-1"
        } `}
      >
        {!goBackFunction && (
          <div
            className={`${
              isSideCard ? "mx-0" : "mx-auto"
            } self-center flex flex-row justify-items-center `}
            style={isSideCard ? { maxWidth: "344px", width: "100%" } : {}}
          >
            {logo && <div className="mr-1">{logo}</div>}
            <p
              className={`${
                isSideCard ? "text-lg xs:text-md" : "text-2xl xs:text-xl"
              }  m-0 font-medium ${dashboard ? "mb-10" : "pb-5"}`}
            >
              {title}
            </p>
          </div>
        )}
        <div className="flex flex-row justify-center">
          <div style={dashboard ? {} : { maxWidth: "344px", width: "100%" }} className="flex-1">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
