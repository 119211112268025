import { useApprove } from "actions/Approve";
import { useDeposit } from "actions/Deposit";
import Button from "components/Button";
import FullScreenModal from "components/FullScreenModal";
import { ActionLoading, ActionSuccess } from "components/FullScreenModal/FullScreenModal";
import CardWithTable from "components/Modal/CardWithTable";
import CurrencyIcon from "components/UI/CurrencyIcon";
import { useAllowance } from "hooks/useAllowance";
import { useBalance } from "hooks/useBalance";
import { useTransactionHandler } from "hooks/useTransactionHandler";
import useTxReducer, { txActionTypes } from "hooks/useTxReducer";
import React, { useEffect, useMemo, useState } from "react";
import { roundToFourDecimals } from "utils/helperFunctions";
import { CustomDropdown } from "components/UI/CustomDropdown";
import { useTellerContext } from "contexts/global/tellerContext";
import { useTokenPrice } from "hooks/useTokenPrice";
import formatNumberForDisplay from "utils/formatNumberForDisplay";
import { useOneDayAPY } from "hooks/useOneDayApy";

const ManageLiquidityDepositModal = ({
  setDepositModalOpened,
  tellerBalanceString,
  selectedCurrency,
  setSelectedCurrency,
}: {
  setDepositModalOpened: any;
  tellerBalanceString: any;
  selectedCurrency: any;
  setSelectedCurrency: any;
}) => {
  const { deposit } = useDeposit(selectedCurrency);
  const [amountExceeded, setAmountExceeded] = useState("");
  const assetPrice = useTokenPrice(selectedCurrency);
  const walletBalance = useBalance(selectedCurrency);
  const approvedAmount = useAllowance(selectedCurrency, "tToken");
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [state, dispatch] = useTxReducer();
  const maxSelectedAmount = useMemo(() => {
    return walletBalance && walletBalance > 0 ? walletBalance : 0;
  }, [walletBalance]);
  const [needsApproval, setNeedsApproval] = useState(false);
  const [approving, setApproving] = useState(false);
  const { approve } = useApprove(selectedCurrency, "tToken");
  const oneDayAPY = useOneDayAPY(selectedCurrency);
  // calls back when our children call it back
  const selectCurrency = (eventKey: any) => {
    setSelectedCurrency(eventKey);
  };

  const Icon = () => {
    <CurrencyIcon currency={selectedCurrency} className="h-5 w-5" />;
  };

  const selectAmount = (eventKey: any) => {
    setSelectedAmount(eventKey);
  };

  useEffect(() => {
    if (walletBalance == "-") return;
    if (selectedCurrency == "ETH") {
      setNeedsApproval(false);
    } else if (!approvedAmount || Number(selectedAmount) > Number(approvedAmount)) {
      setNeedsApproval(true);
    } else {
      setNeedsApproval(false);
    }

    if (Number(selectedAmount) > Number(walletBalance)) {
      setAmountExceeded(
        `You cannot deposit more than ${roundToFourDecimals(walletBalance)} ${selectedCurrency}`,
      );
    } else setAmountExceeded("");
  }, [selectedAmount, walletBalance, approvedAmount, selectedCurrency]);

  const depositCTADisabled = useMemo(() => {
    return Boolean(
      state.progressState == "loading" ||
        !approvedAmount ||
        !selectedAmount ||
        isNaN(Number(selectedAmount)) ||
        !(Number(selectedAmount) > 0) ||
        (selectedCurrency != "ETH" && Number(approvedAmount) < Number(selectedAmount)) ||
        !!amountExceeded,
    );
  }, [state.progressState, approvedAmount, selectedAmount, amountExceeded, selectedCurrency]);

  const approveCTADisabled = useMemo(() => {
    return Boolean(
      state.progressState == "loading" ||
        !selectedAmount ||
        isNaN(Number(selectedAmount)) ||
        !(Number(selectedAmount) > 0) ||
        !!amountExceeded,
    );
  }, [state.progressState, selectedAmount, amountExceeded]);

  const setProcessing = async (hash) => {
    dispatch({
      type: txActionTypes.setTxHash,
      payload: {
        txHash: hash,
      },
    });
  };

  const onSuccess = async () => {
    dispatch({
      type: txActionTypes.justCompleted,
    });
    await new Promise((resolve) => setTimeout(resolve, 5000));
    dispatch({
      type: txActionTypes.txSuccess,
    });
  };
  const onError = async () => {
    dispatch({
      type: txActionTypes.txError,
    });
  };

  const depositTransactionHandler = useTransactionHandler(
    "deposit",
    onSuccess,
    setProcessing,
    onError,
  );

  const onDepositClick = async (amount: any) => {
    dispatch({
      type: txActionTypes.startTxProcess,
    });
    await deposit(depositTransactionHandler, amount);
  };

  const approveTransactionHandler = useTransactionHandler(
    "approve",
    () => {
      setApproving(false);
      setNeedsApproval(false);
    },
    setApproving,
  );
  const onApproveClick = async (amount: number) => {
    await approve(approveTransactionHandler, amount);
  };

  const setMaxAmount = () => {
    setSelectedAmount(maxSelectedAmount);
  };
  const StyledButton = ({
    CTA = () => {
      return null;
    },
    CTACopy,
    CTADisabled,
  }: {
    CTA?: any;
    CTACopy: string;
    CTADisabled: boolean;
  }) => {
    return (
      <Button
        CTA={CTA}
        color="green"
        type="primary"
        text={CTACopy}
        size="md"
        disabled={CTADisabled}
      />
    );
  };
  const CTA_STATES = useMemo(() => {
    return {
      default: approving ? (
        <StyledButton CTACopy={"Approving..."} CTADisabled={true} />
      ) : needsApproval ? (
        <StyledButton
          CTA={() => {
            onApproveClick(selectedAmount);
          }}
          CTACopy={"Approve"}
          CTADisabled={false}
        />
      ) : (
        <StyledButton
          CTA={() => {
            onDepositClick(selectedAmount);
          }}
          CTACopy={"Deposit"}
          CTADisabled={depositCTADisabled}
        />
      ),
      loading: <StyledButton CTACopy={"Depositing..."} CTADisabled={true} />,
      justCompleted: <StyledButton CTACopy={"Depositing..."} CTADisabled={true} />,
      txSuccess: (
        <StyledButton
          CTA={() => {
            setDepositModalOpened(false);
          }}
          CTACopy={"Done"}
          CTADisabled={false}
        />
      ),
    };
  }, [approving, approveCTADisabled, depositCTADisabled, needsApproval, selectedAmount]);

  const MAIN_CONTENT_STATES = useMemo(() => {
    return {
      default: (
        <div className="flex flex-col lg:flex-row flex-1 max-w-screen-2xl	text-left mx-auto overflow-scroll">
          <div className="flex-1 flex flex-col items-start p-6">
            <div className="block lg:hidden type-h3 text-green-02  mb-3">
              Deposit <span className="text-green-03">Liquidity</span>
            </div>
            <div className="hidden lg:block type-h2 text-green-02 mb-3">
              Deposit <span className="text-green-03">Liquidity</span>
            </div>
            <div className="lg:max-w-sm mb-10 text-black whitespace-normal">
              Select an amount to deposit from your Teller account.
            </div>
          </div>
          <div className="flex-1 -mx-6 md:mx-0">
            <CardWithTable
              variant="darkGreen"
              header={
                <>
                  <div className="mb-2">Deposit amount</div>
                  <DepositInputField
                    selectAmount={selectAmount}
                    selectedAmount={selectedAmount}
                    setMaxAmount={setMaxAmount}
                    selectCurrency={selectCurrency}
                    selectedCurrency={selectedCurrency}
                  />
                </>
              }
              rows={[
                {
                  left: "Wallet Balance",
                  right: `${formatNumberForDisplay(walletBalance)} ${selectedCurrency}`,
                },
                {
                  left: "Teller Balance",
                  right: (
                    <div className="flex flex-row">
                      <div className="mr-2">
                        {tellerBalanceString} {selectedCurrency}
                      </div>
                      <CurrencyIcon currency={selectedCurrency} className="h-5 w-5" />
                    </div>
                  ),
                },
                { left: "Deposit APY", right: `${formatNumberForDisplay(oneDayAPY * 365)} %` },
                {
                  left: "Asset Price",
                  right: `${assetPrice ? `$${formatNumberForDisplay(assetPrice)}` : "-"}`,
                },
              ]}
            />
          </div>
        </div>
      ),
      loading: (
        <ActionLoading
          title="Deposit in progress"
          subTitle={
            <div>
              You are currently depositing {selectedAmount} {selectedCurrency} from your Wallet to
              your Teller account.
            </div>
          }
        />
      ),
      justCompleted: (
        <ActionSuccess
          title="Deposit Success"
          subTitle={`You've successfully deposited ${selectedAmount} ${selectedCurrency} from your Wallet to
              your Teller account.`}
        />
      ),
      txSuccess: (
        <div className="flex flex-col lg:flex-row flex-1 max-w-screen-2xl	text-left mx-auto overflow-scroll">
          <div className="flex-1 flex flex-col items-start">
            <div className="block lg:hidden type-h3 text-green-02  mb-3">
              Successfully <span className="text-green-03">Deposited</span>
            </div>
            <div className="hidden lg:block type-h2 text-green-02 mb-3">
              Successfully <span className="text-green-03">Deposited</span>
            </div>
            <div className="lg:max-w-sm mb-10 text-black whitespace-normal">
              You've successfully deposited {selectedAmount} {selectedCurrency} from your Wallet to
              your Teller account.
            </div>
          </div>
          <div className="flex-1 -mx-6 md:mx-0">
            <CardWithTable
              variant="darkGreen"
              header={
                <>
                  <div className="mb-2">Deposit amount</div>
                  <div className="mb-2 text-3xl">
                    <span className="font-medium">{selectedAmount}</span>
                    {"   "}
                    <span className="font-light">{selectedCurrency}</span>
                  </div>
                </>
              }
              rows={[
                {
                  left: "Wallet Balance",
                  right: (
                    <div>
                      {formatNumberForDisplay(walletBalance)} {selectedCurrency} {Icon}
                    </div>
                  ),
                },
                {
                  left: "Teller Balance",
                  right: (
                    <div>
                      {tellerBalanceString} {selectedCurrency} {Icon}
                    </div>
                  ),
                },
                { left: "Deposit APY", right: `${formatNumberForDisplay(oneDayAPY * 365)} %` },
                {
                  left: "Asset Price",
                  right: `${assetPrice ? `$${formatNumberForDisplay(assetPrice)}` : "-"}`,
                },
              ]}
            />
          </div>
        </div>
      ),
    };
  }, [selectedAmount, oneDayAPY, walletBalance, assetPrice, tellerBalanceString, selectedCurrency]);

  return (
    <FullScreenModal
      onClose={() => {
        setDepositModalOpened(false);
      }}
      footerCTA={CTA_STATES[state.progressState]}
      mainContent={MAIN_CONTENT_STATES[state.progressState]}
    />
  );
};

export default ManageLiquidityDepositModal;

const DepositInputField = ({
  selectAmount,
  selectedAmount,
  setMaxAmount,
  selectedCurrency,
  selectCurrency,
}: {
  selectAmount: any;
  selectedAmount: any;
  setMaxAmount: any;
  selectCurrency: any;
  selectedCurrency: any;
}) => {
  const { lendingTokens } = useTellerContext();
  // setSelectedCurrency(lendingTokens[0]);
  return (
    <div className="flex flex-row flex-1">
      <div className="bg-green-06 flex flex-row flex-1 rounded-l">
        <div className="flex-1">
          <input
            className="rounded-l bg-green-06 placeholder-green-04 text-green-03 type-button w-full h-full px-4 focus:outline-none "
            placeholder="Enter Amount"
            type="number"
            step={0.01}
            value={selectedAmount}
            onChange={(e) => {
              selectAmount(e.target.value);
            }}
          />
        </div>
        <div className="my-auto mx-3">
          <Button color="green" type="secondary" text="MAX" size="xs" CTA={setMaxAmount} />
        </div>
      </div>
      <div className="flex bg-white p-4 rounded-r">
        <CurrencyIcon currency={selectedAmount} className="h-5 w-5" />
        <CustomDropdown
          options={lendingTokens}
          selected={selectedCurrency}
          handleSelect={(eventKey: any) => {
            selectCurrency(eventKey);
          }}
        />
      </div>
    </div>
  );
};
