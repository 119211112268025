import React from "react";
import chevronUpPrimary from "assets/chevronUpPrimary.svg";
import { AccordionHeaderProps } from "components/Accordion/types";
import Badge from "components/Badge";
import BR from "components/UI/BR";
import CurrencyIcon from "components/UI/CurrencyIcon";
import useNFT from "hooks/useNFT";
import NFTDashboardAccordionRowHeaderCTA from "pages/NFTDashboard/NFTDashboardAccordionRowHeaderCTA";
import { NFTStatusBadgeStyles, NFTType, NFTTypeBadgeStyles } from "./types";
import { NFT_CONTRIBUTION_MULTIPLIER } from "constants/constants";

const NFTDashboardAccordionRowHeader = ({ isSelected, id, selectRow }: AccordionHeaderProps) => {
  const { status, attributes, type } = useNFT(id);
  const onSelectRowClick = () => {
    if (!attributes) return;
    selectRow(id, isSelected);
  };
  let tokenBalance = 0;

  if (type === "V1") {
    tokenBalance = 1;
  } else if (type === "V2") {
    // tokenBalance = balance;
  }

  return (
    <>
      <BR variant="grey" />
      <div
        className="z-10 flex flex-row py-6 px-10 items-center justify-between text-grey-01 type-p-sm font-light space-x-1"
        style={isSelected ? { boxShadow: "0px 1px 4px rgba(116,195,193,0.3)" } : {}}
      >
        {/* TOKEN ID */}
        <div className="flex-1 text-left">{id}</div>
        {/* TOKEN STATUS */}
        <div className="flex-1 text-left">
          <LoadingWrapper controlVar={status}>
            <Badge text={status} badgeType={NFTStatusBadgeStyles[status]} />
          </LoadingWrapper>
        </div>
        {/* TOKEN TYPE */}
        <div className="flex-1 text-left">
          <LoadingWrapper controlVar={type}>
            <Badge text={type} badgeType={NFTTypeBadgeStyles[type]} />
          </LoadingWrapper>
        </div>
        {/* TOKEN LOAN SIZE */}
        <div className="flex-1 text-left flex flex-row items-center">
          <LoadingWrapper controlVar={status}>
            <>
              {Number(attributes.Base_Loan_Size * NFT_CONTRIBUTION_MULTIPLIER).toLocaleString()}{" "}
              {attributes.Base_Loan_Asset}
              <span>
                <CurrencyIcon currency={attributes.Base_Loan_Asset} className="ml-2" />
              </span>
            </>
          </LoadingWrapper>
        </div>
        {/* TOKEN BALANCE */}
        {/* <div className="flex-1 text-center">{tokenBalance}</div> */}
        {/* STAKE/UNSTAKE BUTTON */}
        <div className="flex-1 flex items-center ">
          <LoadingWrapper controlVar={status}>
            <NFTDashboardAccordionRowHeaderCTA id={id} status={status} nftType={type} />
          </LoadingWrapper>
        </div>
        <div className="flex items-center justify-end">
          <div onClick={onSelectRowClick} className="cursor-pointer">
            <img
              src={chevronUpPrimary}
              className={`cursor-pointer h-5 inline ${
                isSelected ? "" : "transform rotate-180"
              }  h-5 w-5 `}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default NFTDashboardAccordionRowHeader;

type LoadingWrapperProps = {
  controlVar: any;
  height?: number;
  width?: number;
  children: React.ReactNode;
};

/**
 *
 * @param controlVar  - Variable that is used to check if data was loaded
 * @param height - Optional - pre-defined height of an element. Defaults to 8px
 * @param width  -Optional - width of an element. Defaults to 112ps
 * @param children - Component we want to render
 * @returns Replaces the element with e grey box until control var is loaded
 */
const LoadingWrapper = ({ controlVar, height = 8, width = 112, children }: LoadingWrapperProps) => {
  return (
    <>
      {controlVar ? (
        children
      ) : (
        <div
          className="animate-pulse py-1 px-5 rounded  text-grey-01 bg-grey-button-bg"
          style={{ height: `${height}px`, width: `${width}px` }}
        ></div>
      )}
    </>
  );
};
