import React from "react";

import aave from "assets/aave-logo.svg";
import btc from "assets/btc-logo.svg";
import comp from "assets/comp-logo.svg";
import dai from "assets/dai-logo.svg";
import eth from "assets/eth-logo.svg";
import link from "assets/link-logo.svg";
import mkr from "assets/mkr-logo.svg";
import snx from "assets/snx-logo.svg";
import teller from "assets/teller-small-logo.svg";
import usdc from "assets/usdc-logo.svg";
import usdt from "assets/usdt-logo.svg";
import yfi from "assets/yfi-logo.svg";
import matic from "assets/matic-token-icon.svg";

interface IconProps {
  currency:
    | "DAI"
    | "USDT"
    | "USDC"
    | "ETH"
    | "LINK"
    | "WBTC"
    | "SNX"
    | "MKR"
    | "YFI"
    | "AAVE"
    | "COMP"
    | "TLR"
    | "WETH"
    | "POLYGON";
  className?: string;
}
const CurrencyIcon = ({ currency, className = "" }: IconProps) => {
  return (
    <>
      {currency === "DAI" && <img src={dai} className={`h-5 w-5 ${className}`} />}
      {currency === "USDT" && <img src={usdt} className={`h-5 w-5 ${className}`} />}
      {currency === "USDC" && <img src={usdc} className={`h-5 w-5 ${className}`} />}
      {currency === "LINK" && <img src={link} className={`h-5 w-5 ${className}`} />}
      {currency === "WBTC" && <img src={btc} className={`h-5 w-5 ${className}`} />}
      {currency === "SNX" && <img src={snx} className={`h-5 w-5 ${className}`} />}
      {currency === "MKR" && <img src={mkr} className={`h-5 w-5 ${className}`} />}
      {currency === "YFI" && <img src={yfi} className={`h-5 w-5 ${className}`} />}
      {currency === "AAVE" && <img src={aave} className={`h-5 w-5 ${className}`} />}
      {currency === "COMP" && <img src={comp} className={`h-5 w-5 ${className}`} />}
      {currency === "TLR" && <img src={teller} className={`h-5 w-5 ${className}`} />}
      {(currency === "ETH" || currency === "WETH") && (
        <img src={eth} className={`h-5 w-5 ${className}`} />
      )}
      {currency === "POLYGON" && <img src={matic} className={`h-5 w-5 ${className}`} />}
    </>
  );
};

export default CurrencyIcon;
