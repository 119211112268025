import Button from "components/Button";
import { useNFTsContext } from "contexts/global/nftsContext";
import { useStakeNFT } from "actions/StakeNFT";
import { useTransactionHandler } from "hooks/useTransactionHandler";
import React, { useEffect, useState } from "react";
import { useNotificationsContext } from "../../contexts/global/notificationsContext";
import ApproveTransactionModal from "./ApproveTransactionModal";
import { useApproveNFT } from "actions/ApproveNFT";

const StakeAllCTA = () => {
  const { displayApproveModal, closeModal, ctaDisabled, stakeAllCTA, ownedNFTs, ownedNFTsV2 } =
    useStakeAll();

  return (
    <>
      {displayApproveModal && (
        <ApproveTransactionModal
          closeModal={closeModal}
          successCTACopy="Stake"
          multipleTransactionsWarning={Boolean(ownedNFTs?.length > 0 || ownedNFTsV2?.length > 0)}
        />
      )}
      {(ownedNFTs?.length > 0 || ownedNFTsV2?.length > 0) && (
        <div className="sm:mb-6 mb-0 text-green-03 type-h4 type-medium xs:flex-1">
          <Button
            CTA={stakeAllCTA}
            color="green"
            type="secondary"
            text={`Stake All (${ownedNFTs.length + ownedNFTsV2.length})`}
            size="md"
            disabled={ctaDisabled}
          />
        </div>
      )}
    </>
  );
};

export default StakeAllCTA;

const useStakeAll = () => {
  const { addNotification } = useNotificationsContext();
  const { ownedNFTs, ownedNFTsV2, setAllClaimedNFTs } = useNFTsContext();
  const { stakeNFT } = useStakeNFT();
  const [loading, setLoading] = useState(false);
  const { isNFTApproved } = useApproveNFT();
  const stakeTransactionHandler = useTransactionHandler(
    "stakeNFT",
    () => {
      addNotification({ copy: "V1 NFTs staked.", variant: "success" });
      setAllClaimedNFTs((AllClaimedNFTs) => {
        const updatedAllClaimedNFTs = AllClaimedNFTs.map((nft) => {
          if (nft.status == "Unstaked" && nft.type == "V1") {
            nft.status = "Staked";
          }
          return nft;
        });
        return updatedAllClaimedNFTs;
      });
      setLoading(true);
    },
    setLoading,
  );
  const stakeTransactionV2Handler = useTransactionHandler(
    "stakeNFT",
    () => {
      addNotification({ copy: "V2 NFT staked.", variant: "success" });
      setAllClaimedNFTs((AllClaimedNFTs) => {
        const updatedAllClaimedNFTs = AllClaimedNFTs.map((nft) => {
          if (nft.status == "Unstaked" && nft.type == "V2") {
            nft.status = "Staked";
          }
          return nft;
        });
        return updatedAllClaimedNFTs;
      });
      setLoading(true);
    },
    setLoading,
  );

  const stake = async () => {
    if (ownedNFTs && ownedNFTs.length > 0) await stakeNFT(stakeTransactionHandler, ownedNFTs, "V1");
    if (ownedNFTsV2 && ownedNFTsV2.length > 0)
      await stakeNFT(stakeTransactionV2Handler, ownedNFTsV2, "V2");
  };
  const [isApproved, setIsApproved] = useState(false);
  useEffect(() => {
    let isMounted = true;
    (async () => {
      const isApproved = await isNFTApproved("V1");
      if (isMounted) {
        setIsApproved(isApproved);
      }
    })();
    return () => {
      isMounted = false;
    };
  }, []);

  const stakeAllCTA = async () => {
    setLoading(true);
    const isApproved = await isNFTApproved("V1");
    if (!isApproved) {
      setDisplayApproveModal(true);
    } else {
      stake();
    }
  };
  const ctaDisabled = Boolean(
    (!ownedNFTs && !ownedNFTsV2) || (ownedNFTs.length < 1 && ownedNFTsV2.length < 1) || loading,
  );
  const [displayApproveModal, setDisplayApproveModal] = useState(false);
  const closeModal = async (approved: boolean) => {
    setDisplayApproveModal(false);
    if (approved) {
      await stake();
    }
    setLoading(false);
  };

  return {
    displayApproveModal,
    closeModal,
    ctaDisabled,
    stakeAllCTA,
    ownedNFTs,
    ownedNFTsV2,
    isApproved,
  };
};
