import React from "react";

interface MetricProps {
  title: string;
  value?: string;
  values?: string[];
}

const Metric = ({ title, value = "", values }: MetricProps) => {
  return (
    <div className="my-3 flex flex-row flex-wrap text-sm justify-between font-medium space-x-2">
      <div className="text-gray font-light text-teal-darker flex-1">{title}</div>
      {values &&
        values.map((v) => (
          <div key={v} className="flex-1">
            {v}
          </div>
        ))}
      <div className="">{value}</div>
    </div>
  );
};

export default React.memo(Metric);
