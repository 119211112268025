// USE-UNISWAP

import React, { useEffect, useState } from "react";

import {
  getBestTradeExactIn,
  getBestTradeExactOut,
  getUniswapTokenKeys,
} from "utils/uniswapTrades";

import { ContextProps, LoanInterface, UniswapStateInterface } from "../types";
import { useWeb3StateContext } from "contexts/global/web3StateContext";
import { useBlockNumberContext } from "contexts/global/blockNumberContext";
import { useMemo } from "react";
import { useTellerContext } from "contexts/global/tellerContext";
import { useBNMethods } from "hooks/useBNMethods";

const defaultUniswapState: UniswapStateInterface = {
  selectedLoan: null,
  setSelectedLoan: () => null,
  success: null,
  setSuccess: () => null,
  processing: null,
  setProcessing: () => null,
  trade: null,
  setTrade: () => null,
  options: null,
  tokenSelectionDropdown: null,
  setTokenSelectionDropdown: () => null,
  isExactIn: null,
  setIsExactIn: () => null,
  swapValues: null,
  setSwapValues: () => null,
  values: null,
  setValues: () => null,
  setPairs: () => null,
  escrowBalances: null,
  setEscrowBalances: () => null,
  tokenKeys: null,
};
export const UniswapContext = React.createContext(defaultUniswapState);

export const UniswapContextProvider = ({ children }: ContextProps) => {
  const { blockNumber } = useBlockNumberContext();
  const { network } = useWeb3StateContext();
  const { lendingToken } = useTellerContext();
  const [options, setOptions] = useState(
    network.id == 1
      ? ["DAI", "USDC", "USDT", "MKR", "SNX", "LINK", "YFI", "AAVE", "WBTC", "WETH"]
      : ["DAI", "WETH", "USDC", "LINK"],
  );

  const defaultSwapValues = {
    selectedAmount: "0",
    tokenIn: "",
    tokenOut: "",
  };
  const defaultValues = {
    input: { amount: "0", token: lendingToken },
    output: { amount: "0", token: "" },
  };

  const [selectedLoan, setSelectedLoan] = useState<null | LoanInterface>(null);
  const [success, setSuccess] = useState(null);
  const [processing, setProcessing] = useState(null);
  const [tokenSelectionDropdown, setTokenSelectionDropdown] = useState(null);
  const [trade, setTrade] = useState(null);
  const [isExactIn, setIsExactIn] = useState(true);
  const [swapValues, setSwapValues] = useState(defaultSwapValues);
  const [values, setValues] = useState(defaultValues);
  const { input, output } = values;
  const [pairs, setPairs] = useState(null);
  const [escrowBalances, setEscrowBalances] = useState(null);
  const [tokenKeys, setTokenKeys] = useState(null);
  const { convertToString } = useBNMethods(selectedLoan?.token);

  useEffect(() => {
    if (!pairs) return;
    const allOptions: any[] = [];
    pairs.forEach((pair: any) => {
      allOptions.push(pair.tokenAmounts[0].currency.symbol, pair.tokenAmounts[1].currency.symbol);
    });
    const newOptions = Array.from(new Set(allOptions));

    setOptions(newOptions);
  }, [pairs]);

  useEffect(() => {
    if (!network.id) return;
    const tokenKeys = getUniswapTokenKeys(network);
    setTokenKeys(tokenKeys);
  }, [network]);

  useEffect(() => {
    if (!selectedLoan) return;
    setValues({
      ...defaultValues,
      input: {
        amount: convertToString(selectedLoan.amountBorrowed),
        token: selectedLoan.token,
      },
    });
  }, [selectedLoan]);

  useEffect(() => {
    const selectedAmount = isExactIn ? input.amount : output.amount;
    const tokenIn = isExactIn ? input.token : output.token;
    const tokenOut = isExactIn ? output.token : input.token;
    setSwapValues({ selectedAmount, tokenIn, tokenOut });
  }, [values, isExactIn, blockNumber]);

  useEffect(() => {
    let isMounted = true;

    if (
      !swapValues.tokenIn ||
      !swapValues.tokenOut ||
      !swapValues.selectedAmount ||
      !pairs ||
      !tokenKeys
    )
      return;
    const getTrade = async () => {
      let trade = null;
      if (isExactIn) {
        trade = await getBestTradeExactIn(
          swapValues.tokenIn,
          swapValues.tokenOut,
          swapValues.selectedAmount,
          pairs,
          tokenKeys,
        );
      } else {
        trade = await getBestTradeExactOut(
          swapValues.tokenIn,
          swapValues.tokenOut,
          swapValues.selectedAmount,
          pairs,
          tokenKeys,
        );
      }
      if (isMounted) {
        setTrade(trade);
      }
    };
    getTrade();
    return () => {
      isMounted = false;
    };
  }, [swapValues, pairs, tokenKeys, network, isExactIn]);

  const value = useMemo(() => {
    return {
      selectedLoan,
      setSelectedLoan,
      success,
      setSuccess,
      trade,
      setTrade,
      options,
      tokenSelectionDropdown,
      setTokenSelectionDropdown,
      isExactIn,
      setIsExactIn,
      swapValues,
      setValues,
      setSwapValues,
      values,
      setPairs,
      escrowBalances,
      setEscrowBalances,
      processing,
      setProcessing,
      tokenKeys,
    };
  }, [
    selectedLoan,
    success,
    trade,
    options,
    tokenSelectionDropdown,
    isExactIn,
    swapValues,
    values,
    escrowBalances,
    processing,
    tokenKeys,
  ]);

  return <UniswapContext.Provider value={value}>{children}</UniswapContext.Provider>;
};
