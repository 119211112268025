const formatNumberForDisplay = (value, decimals = 2) => {
  const number = Number(value);
  let returnValue = "-";
  if (isNaN(number)) {
    returnValue = "-";
  } else if (number === 0) {
    returnValue = "0.00";
  } else if (number > 1000) {
    returnValue = Math.round(number).toLocaleString();
  } else if (number * 10 ** decimals < 1) {
    returnValue = "<" + 1 / 10 ** decimals;
  } else {
    returnValue = number.toFixed(decimals);
  }

  return returnValue;
};

export default formatNumberForDisplay;
