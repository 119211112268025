import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDeepCompareEffect } from "react-use";
import Web3 from "web3";

const defaultWeb3State = {
  web3: null as null,
  setWeb3: () => null as null,
  userAddress: null as null,
  updateUserAddress: (address?: string) => null as null,
  network: {
    id: null as null,
    name: null as null,
  },
  setNetwork: null as null,
};
export type NetworkInterface = {
  id: number | null;
  name: NetworkNameEnum | null;
};

type web3StateContextType = {
  web3: Web3 | null;
  setWeb3: any;
  userAddress: string | null;
  /**
   * Triggers user address update
   *@param {string} [address = null] New address to set.

   If no params specified, new address will be requested from provider
   */
  updateUserAddress: any;
  network: NetworkInterface;
  setNetwork: any;
};

const Web3StateContext = createContext<web3StateContextType>(defaultWeb3State);

export const Web3StateContextProvider = ({
  children,
  Web3StateContextMockValues = null,
}: {
  children: React.ReactNode;
  Web3StateContextMockValues?: web3StateContextType | null;
}) => {
  // if (Web3StateContextMockValues)
  //   return (
  //     <Web3StateContext.Provider
  //       value={{
  //         ...Web3StateContextMockValues,
  //         setWeb3: () => {
  //           console.log("Warning: cannot change web3 in test env");
  //         },
  //         updateUserAddress: () => {
  //           console.log("Warning: cannot update userAddress in test env");
  //         },
  //         setNetwork: () => {
  //           console.log("Warning: cannot update network in test env");
  //         },
  //       }}
  //     >
  //       {children}
  //     </Web3StateContext.Provider>
  //   );

  const [web3, setWeb3] = useState<web3StateContextType["web3"]>(defaultWeb3State.web3);
  const [userAddress, setUserAddress] = useState<web3StateContextType["userAddress"]>(
    defaultWeb3State.userAddress,
  );
  const [network, setNetworkState] = useState<web3StateContextType["network"]>(
    defaultWeb3State.network,
  );

  const setNetwork = useCallback((id: number) => {
    const network = {
      id: id,
      name: mapNetworkIDToName(id),
    };
    setNetworkState(network);
  }, []);

  const updateUserAddress: (address?: string | null) => any = useCallback(
    async (address = null) => {
      if (address) {
        setUserAddress(address);
      }
      if (!web3) return;
      const accounts = await web3.eth.getAccounts();
      const newUserAddress = accounts[0].toLowerCase();

      if (newUserAddress != userAddress) {
        setUserAddress(newUserAddress);
      }
    },
    [],
  );

  const value = useMemo(() => {
    return {
      web3,
      setWeb3,
      userAddress,
      updateUserAddress,
      network,
      setNetwork,
    };
  }, [web3, userAddress, updateUserAddress, network]);

  return <Web3StateContext.Provider value={value}>{children}</Web3StateContext.Provider>;
};

export enum NetworkNameEnum {
  kovan = "kovan",
  mainnet = "mainnet",
  rinkeby = "rinkeby",
  ropsten = "ropsten",
  polygon = "polygon",
  unknown = "unknown",
}
export type networkIDType = 1 | 3 | 4 | 42 | 137 | 80001;

const mapNetworkIDToName = (id: number) => {
  if (id == 1) return NetworkNameEnum.mainnet;
  if (id == 3) return NetworkNameEnum.ropsten;
  if (id == 4) return NetworkNameEnum.rinkeby;
  if (id == 42) return NetworkNameEnum.kovan;
  if (id == 31337) return NetworkNameEnum.mainnet;
  if (id == 137) return NetworkNameEnum.polygon;
  else return NetworkNameEnum.unknown;
};

export const useWeb3StateContext: () => {
  web3: Web3 | null;
  setWeb3: any;
  userAddress: string | null;
  updateUserAddress: any;
  network: NetworkInterface;
  setNetwork: any;
} = () => {
  const context = useContext(Web3StateContext);

  if (context === undefined) {
    throw new Error("useWeb3State must be used within a useWeb3StateProvider");
  }
  return context;
};

const useAllDataLoadedChecker = (web3: any, userAddress: any, network: any) => {
  const [allDataLoaded, setAllDataLoaded] = useState(false);

  /**
   * Helper function to avoid unnecessary re-renders
   */
  useDeepCompareEffect(() => {
    if (!web3 || !userAddress || !network.id) return;
    setAllDataLoaded(true);
  }, [web3, userAddress, network]);

  return allDataLoaded;
};
