import { useWeb3StateContext } from "contexts/global/web3StateContext";
import { AvailableTokens } from "contexts/types";
import EthDater from "ethereum-block-by-date";
import { useEffect, useState } from "react";
import { useNetworkConfig } from "./useNetworkConfig";
import { useTToken } from "contexts/global/tellerContext";

export const useOneDayAPY = (ID: AvailableTokens) => {
  const assetID = ID == "ETH" ? "WETH" : ID;
  const [APY, setAPY] = useState<null | number>(null);
  const { tTokenAddress, tTokenContract } = useTToken(assetID);
  const { web3 } = useWeb3StateContext();
  const { firstBlock } = useNetworkConfig();
  useEffect(() => {
    if (!tTokenAddress || !web3 || !firstBlock || !tTokenContract) return;
    let isMounted = true;

    (async () => {
      try {
        let daysPassed = 5;
        // object turns into `Object` when logging name of constructor on netlify build, however,
        // it returns a regular `Web3` name on localhost compiling. Package looks for web3, if it's not
        // then it sets an object with { eth: web3 }
        const dater = new EthDater(web3.eth);
        const today = new Date();
        const oneHour = 60 * 60 * 1000; // minutes*seconds*milliseconds
        const firstBlockTimestamp = parseInt(
          (await web3.eth.getBlock(firstBlock)).timestamp.toString(),
        );
        const firstBlockDate = new Date(firstBlockTimestamp * 1000);
        const hoursPassed = (today - firstBlockDate) / oneHour;
        daysPassed = hoursPassed / 24;
        const exchangeRateNow = await tTokenContract.methods.exchangeRate().call();
        if (daysPassed > 1) {
          const days = Math.floor(daysPassed);
          const priorDate = new Date().setDate(today.getDate() - days);
          const blockObj = await dater.getDate(priorDate, true);
          const blockNDaysAgo = blockObj.block.toString();
          const exchangeRateNDaysAgo = await tTokenContract.methods
            .exchangeRate()
            .call({}, blockNDaysAgo);
          const APY = (100 * (exchangeRateNow / exchangeRateNDaysAgo - 1)) / days;
          if (isMounted) setAPY(APY);
        } else if (hoursPassed > 1) {
          const hours = Math.floor(hoursPassed);
          const priorDate = new Date().setHours(today.getHours() - hours);
          const blockObj = await dater.getDate(new Date(priorDate), true);
          const blockNHoursAgo = blockObj.block.toString();
          const exchangeRateNHoursAgo = await tTokenContract.methods
            .exchangeRate()
            .call({}, blockNHoursAgo);

          const APY = (24 * 100 * (exchangeRateNow / exchangeRateNHoursAgo - 1)) / hours;

          if (isMounted) setAPY(APY);
        } else return null;
      } catch (error) {
        console.log("useOneDayAPY error: ", { error, assetID });
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [tTokenAddress, web3, firstBlock, tTokenContract, assetID]);

  return APY;
};
