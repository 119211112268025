import React, { createContext, useContext, useMemo, useReducer } from "react";
import { useCallback } from "react";

type notificationType = {
  copy: string | React.ReactNode;
  variant: "success" | "error";
  id: number;
};

const defaultNotificationsState = {
  notifications: null as null,
  addNotification: () => null as null,
  removeNotification: () => null as null,
};

const NotificationsContext = createContext<{
  notifications: any;
  addNotification: (notification: {
    copy: string | React.ReactNode;
    variant: "success" | "error";
  }) => void;
  removeNotification: (id: number) => void;
}>(defaultNotificationsState);

export const NotificationsContextProvider = ({ children }: any) => {
  const [s, dispatch] = useReducer(reducer, initialState);
  const addNotification = useCallback(
    (notification: { copy: string | React.ReactNode; variant: "success" | "error" }) => {
      const id = s.count + 1;
      dispatch({ type: "addNotification", payload: notification });
      setTimeout(() => {
        dispatch({ type: "removeNotification", payload: id });
      }, 5000);
    },
    [s.count],
  );

  const removeNotification = useCallback((id: number) => {
    dispatch({ type: "removeNotification", payload: id });
  }, []);
  const notifications = s.notifications;

  const value = useMemo(() => {
    return { notifications, addNotification, removeNotification };
  }, [notifications, addNotification, removeNotification]);

  return <NotificationsContext.Provider value={value}>{children}</NotificationsContext.Provider>;
};

const initialState: { notifications: notificationType[]; count: number } = {
  notifications: [],
  count: 0,
};
const reducer = (
  state: { notifications: notificationType[]; count: number },
  action: {
    type: "addNotification" | "removeNotification";
    payload: any;
  },
) => {
  switch (action.type) {
    case "addNotification": {
      const id = state.count + 1;
      return {
        notifications: [...state.notifications, { ...action.payload, id }],
        count: state.count + 1,
      };
    }
    case "removeNotification": {
      const filteredNotifications = state.notifications.filter(
        (notification: notificationType) => notification.id != action.payload,
      );
      return { ...state, notifications: filteredNotifications };
    }
    default:
      return state;
  }
};

export const useNotificationsContext = () => {
  const context = useContext(NotificationsContext);
  if (context === undefined) {
    throw new Error("useNotifications must be used within a useNotificationsProvider");
  }
  return context;
};
