// import { wrap } from "comlink";

import "./index.d";
import "./index.scss";
import "./styles/main.css";
import "./styles/type.scss";

import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

function AppWrapper() {
  // add webworker
  // const initializeWorker = () => {
  //   console.log("testing worker");
  //   const worker = new Worker("./zk-cra-webworker", {
  //     name: "zk-cra-worker",
  //     type: "module",
  //   });
  //   const workerApi = wrap<import("./zk-cra-webworker/").ZKCRAWorker>(worker);
  //   workerApi.initZokrates();
  // };

  // React.useEffect(() => {
  //   initializeWorker();
  // }, []);

  return (
    <div>
      <App />
    </div>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <AppWrapper />
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
