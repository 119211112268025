import BigNumber from "bignumber.js";
import React, { useContext, useMemo, useState } from "react";

import { useTakeOutLoan } from "actions/TakeOutLoan";
import PrimaryButton from "components/UI/PrimaryButton";
import copy from "constants/copy.json";
import { BorrowPageContext } from "contexts/borrow/borrowContext";
import { useUIContext } from "contexts/global/uiContext";
import { useBalance } from "hooks/useBalance";
import { useTransactionHandler } from "hooks/useTransactionHandler";
import BorrowFormStage3Table from "./BorrowFormStage3Form";
import { useToken } from "hooks/useToken";
import { useAddress } from "hooks/useContract";
import { useApprove } from "actions/Approve";
import { useAllowance } from "hooks/useAllowance";
import { useEffect } from "react";

BigNumber.config({ EXPONENTIAL_AT: 1e9 });

const BorrowFormStage3 = () => {
  const [needsApproval, setNeedsApproval] = useState(false);
  const [approving, setApproving] = useState(false);
  const [collateralAmount, setCollateralAmount] = useState(0);
  const { borrowRequest, borrowProcessState, NFTs, nodeResponses } = useContext(BorrowPageContext);
  const { setRequestingLoan, setSuccess } = borrowProcessState;
  const approvedAmount = useAllowance(borrowRequest.collateralWith);
  const collateralAvailable = useBalance(borrowRequest.collateralWith);
  const { tokenDecimals } = useToken(borrowRequest.collateralWith);
  const decimalsMultiplier = Math.pow(10, tokenDecimals);

  const notEnoughCollateral = useMemo(() => {
    return (
      borrowRequest.collateralAmount > 0 &&
      Number(collateralAvailable) <= Number(borrowRequest.collateralAmount / decimalsMultiplier)
    );
  }, [borrowRequest.collateralAmount, collateralAvailable, decimalsMultiplier]);

  useEffect(() => {
    if (!borrowRequest || !decimalsMultiplier) {
      setNeedsApproval(false);
      return;
    }
    let newCollateralAmount;
    // if collateral amount exists, check if it's greater than collateralTokensNeeded / decimalsMultiplier
    // if it's greater, then collateralAmount = collateralAmount * decimals multiplier. Else, it's equal to the
    // collateral tokens needed (minimum collateral required). otherwise, if no collateralAmount exists on the
    // borrow request object, then we just set collateralAmount = 0

    if (
      Number(borrowRequest.collateralAmount) >
      Number(borrowRequest.collateralInfo.collateralTokensNeeded / decimalsMultiplier)
    ) {
      newCollateralAmount = new BigNumber(borrowRequest.collateralAmount * decimalsMultiplier)
        .toFixed(0)
        .toString();
    } else {
      newCollateralAmount = borrowRequest.collateralInfo.collateralTokensNeeded;
    }
    setCollateralAmount(newCollateralAmount);
    if (!approvedAmount || Number(newCollateralAmount) > Number(approvedAmount)) {
      setNeedsApproval(true);
    } else {
      setNeedsApproval(false);
    }
    if (borrowRequest.collateralWith == "ETH") {
      setNeedsApproval(false);
    }
  }, [borrowRequest, approvedAmount, collateralAmount, decimalsMultiplier]);

  const requestLoanButtonDisabled =
    collateralAmount > 0
      ? Number(collateralAvailable) <= Number(collateralAmount / decimalsMultiplier)
      : false;

  const requestLoanTransactionHandler = useTransactionHandler(
    "takeOutLoan",
    setSuccess,
    setRequestingLoan,
  );
  const approveTransactionHandler = useTransactionHandler(
    "approve",
    () => {
      setApproving(false);
      setNeedsApproval(false);
    },
    setApproving,
  );

  const { approve } = useApprove(borrowRequest.collateralWith);

  const collateralTokenAddress = useAddress(
    borrowRequest.collateralWith == "ETH"
      ? `W${borrowRequest.collateralWith}`
      : `${borrowRequest.collateralWith}`,
    "token",
  );
  const { setBorrowingStarted } = useUIContext();

  const approveCollateral = async () => {
    setApproving(true);
    await approve(approveTransactionHandler, collateralAmount);
  };
  const { takeOutLoan } = useTakeOutLoan(borrowRequest.collateralWith);
  const requestLoan = async () => {
    setRequestingLoan("loading");
    try {
      await takeOutLoan(
        requestLoanTransactionHandler,
        collateralAmount,
        nodeResponses,
        collateralTokenAddress,
      );
    } catch (err) {
      console.log(err);
      setRequestingLoan(null);
    }
    setBorrowingStarted(true);
  };

  return (
    <div>
      <BorrowFormStage3Table page="borrow" />
      {needsApproval == true && (
        <PrimaryButton
          text={approving ? "Approving..." : "Approve"}
          onClick={approveCollateral}
          disabled={approving}
        />
      )}

      {needsApproval == false && (
        <PrimaryButton
          disabled={requestLoanButtonDisabled}
          text={copy.pages.borrow.main.form.step4.CTA}
          onClick={requestLoan}
        />
      )}

      {notEnoughCollateral && (
        <div className="mt-2 text-gray-light text-xs">
          Not enough {borrowRequest.collateralWith} balance
        </div>
      )}
    </div>
  );
};

export default BorrowFormStage3;
