import React, { useContext } from "react";

import TableRow from "components/UI/TableRow";
import { roundToFourDecimals } from "utils/helperFunctions";
import { useTellerContext } from "contexts/global/tellerContext";
import { useSuppliedBalance } from "hooks/useSuppliedBalance";

const Portfolio = () => {
  const { oneDayAPY, lendingToken } = useTellerContext();
  const suppliedBalance = useSuppliedBalance(lendingToken);

  const APYStr = oneDayAPY && oneDayAPY > 0 ? `${roundToFourDecimals(oneDayAPY * 365)} %` : "-";

  const suppliedBalanceStr = suppliedBalance
    ? `${roundToFourDecimals(suppliedBalance)} ${lendingToken}`
    : "-";

  return (
    <div className="">
      <div className="border-thin mt-3">
        <TableRow title="Supply balance">
          <div className="font-medium">{suppliedBalanceStr}</div>
        </TableRow>
      </div>
      <div className="border-thin mb-4 mt-3">
        <TableRow title="Current APY">
          <div className="font-medium">{APYStr} </div>
        </TableRow>
      </div>
    </div>
  );
};

export default Portfolio;
