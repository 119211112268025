import "./borrow.scss";

import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

import ProcessingScreen from "components/ProcessingScreen/ProcessingScreen";
import SuccessScreen from "components/SuccessScreen/SuccessScreen";
import WarningModal from "components/UI/WarningModal";
import copy from "constants/copy.json";
import BorrowPageContextProvider, { BorrowPageContext } from "contexts/borrow/borrowContext";
import { useUIContext } from "contexts/global/uiContext";
import BorrowForm from "./BorrowForm";

const Borrow = () => {
  const { setStage, borrowProcessState, submenu } = useContext(BorrowPageContext);
  const {
    isSubmitting,
    requestingLoan,
    requestingTerms,
    success,
    stageChangeWarning,
    setStageChangeWarning,
  } = borrowProcessState;

  const pageCopy = copy.pages.borrow.main.form;
  const goBackWarning = pageCopy.goBackWarning;
  const requestingTermsLoadingScreen = pageCopy.step2.requestingTermsLoadingScreen;
  const submittingTermsLoadingScreen = pageCopy.step3.submittingTermsLoadingScreen;
  const requestingLoanLoadingScreen = pageCopy.step4.requestingLoanLoadingScreen;

  const successScreen = pageCopy.successScreen;
  const history = useHistory();
  const { leaveBorrowProcessModalRequest, setLeaveBorrowProcessModalRequest, setBorrowingStarted } =
    useUIContext();

  return (
    <div className="borrow lg:self-start">
      {
        <WarningModal
          show={stageChangeWarning !== null || leaveBorrowProcessModalRequest !== null}
          text={
            leaveBorrowProcessModalRequest || stageChangeWarning == 1
              ? "Are you sure you want to exit the loan process? Leaving will cause you to restart the entire process."
              : goBackWarning
          }
          proceed={async () => {
            if (leaveBorrowProcessModalRequest) {
              history.push(leaveBorrowProcessModalRequest);
              setLeaveBorrowProcessModalRequest(null);
              setBorrowingStarted(false);
            } else {
              setStage(stageChangeWarning);
              setStageChangeWarning(null);
            }
          }}
          cancel={() => {
            if (leaveBorrowProcessModalRequest) {
              setLeaveBorrowProcessModalRequest(null);
            } else {
              setStageChangeWarning(null);
            }
          }}
          proceedText="Leave"
          header={
            leaveBorrowProcessModalRequest || stageChangeWarning == 1
              ? "Incomplete loan request"
              : "Hold Up"
          }
        />
      }
      {isSubmitting && (
        <ProcessingScreen hash={isSubmitting} title={submittingTermsLoadingScreen.title} />
      )}
      {requestingLoan && (
        <ProcessingScreen hash={requestingLoan} title={requestingLoanLoadingScreen.title} />
      )}
      {requestingTerms && (
        <ProcessingScreen hash={null as null} title={requestingTermsLoadingScreen.title} />
      )}
      {success && !isSubmitting && !requestingLoan && !requestingTerms && (
        <SuccessScreen
          redirect="/dashboard/borrow-repay"
          title={successScreen.title}
          message={
            <div>
              <div>Go to dashboard to view your borrow balance.</div>
            </div>
          }
        />
      )}
      {!isSubmitting && !requestingLoan && !requestingTerms && !success && (
        <div>
          <BorrowForm />
        </div>
      )}
    </div>
  );
};

const BorrowContextWrapper = () => {
  return (
    <BorrowPageContextProvider>
      <Borrow />
    </BorrowPageContextProvider>
  );
};
export default BorrowContextWrapper;
