import * as React from "react";

import { Routing } from "pages/Routing";
import { GlobalContextProvider } from "contexts/global/globalContextWrapper";

const App = ({ Web3StateContextMockValues = null }: { Web3StateContextMockValues?: any }) => {
  return (
    <div className="App">
      <GlobalContextProvider Web3StateContextMockValues={Web3StateContextMockValues}>
        <Routing />
      </GlobalContextProvider>
    </div>
  );
};

export default App;
