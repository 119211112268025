import React, { useContext, useEffect } from "react";

import logo from "assets/uniswap-logo.svg";
import ProcessingScreen from "components/ProcessingScreen/ProcessingScreen";
import Card from "components/UI/Card";
import { UniswapContext } from "contexts/dashboard/uniswapContext";
import { getPairs } from "utils/uniswapTrades";

import UniswapForm from "./UniswapForm";
import UniswapLoansList from "./UniswapLoansList";
import UniswapSuccessScreen from "./UniswapSuccessScreen";
import { useWeb3StateContext } from "contexts/global/web3StateContext";
import { useBlockNumberContext } from "contexts/global/blockNumberContext";

const UniswapLogo = () => {
  return <img className="mr-3" src={logo} height="31" />;
};

const UniswapMainSection = () => {
  const {
    selectedLoan,
    setSelectedLoan,
    success,
    processing,
    setPairs,
    tokenKeys,
    tokenSelectionDropdown,
    setTokenSelectionDropdown,
  } = useContext(UniswapContext);
  const { blockNumber } = useBlockNumberContext();
  const title = selectedLoan ? `ID ${selectedLoan.id}` : "Uniswap";
  const logo = selectedLoan ? null : <UniswapLogo />;
  const goBack = selectedLoan
    ? () => {
        if (tokenSelectionDropdown) {
          setTokenSelectionDropdown(null);
        } else {
          setSelectedLoan(null);
        }
      }
    : null;
  const { network, web3 } = useWeb3StateContext();
  useEffect(() => {
    let isMounted = true;
    if (!tokenKeys) return;
    const getPairsData = async () => {
      const pairsData = await getPairs(network, web3, tokenKeys);
      setPairs(pairsData);
    };
    if (isMounted) getPairsData();
    return () => {
      isMounted = false;
    };
  }, [tokenKeys, blockNumber]);

  return (
    <Card
      dashboard
      className="main-card bg-teal-lightest  text-center  "
      title={title}
      logo={logo}
      goBack={goBack}
      headerRightColumnValue={selectedLoan ? `ID ${selectedLoan.id}` : ""}
    >
      {!success && !processing && !selectedLoan && <UniswapLoansList />}
      {!success && !processing && selectedLoan && <UniswapForm />}
      {success && <UniswapSuccessScreen />}
      {processing && <ProcessingScreen fullScreen={false} hash={processing} title="Swapping" />}
    </Card>
  );
};

export default UniswapMainSection;
