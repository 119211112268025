import React, { useContext, useState } from "react";

import CloseModalIcon from "assets/x.svg";
import PrimaryButton from "components/UI/PrimaryButton";
import { useIsUserLoggedIn } from "hooks/useIsUserLoggedIn";
import { useOnboardContext } from "contexts/global/onboardContext";

const useTermsAccepted = () => {
  const alreadyLoggedIn = useIsUserLoggedIn();
  const { callWeb3FromProvider } = useOnboardContext();
  const onAccept = async () => {
    const walletSigned = alreadyLoggedIn ? true : await callWeb3FromProvider();
    if (walletSigned) {
      localStorage.setItem("termsAccepted", "true");
      setTermsAccepted(true);
    }
  };
  const [termsAccepted, setTermsAccepted] = useState<boolean>(() =>
    Boolean(localStorage.getItem("termsAccepted")),
  );
  return { termsAccepted, onAccept };
};

const TermsModal = () => {
  const { termsAccepted, onAccept } = useTermsAccepted();

  return (
    <div>
      {!termsAccepted && (
        <div className="fixed z-20 inset-0 overflow-y-auto">
          <div className="flex items-center sm:items-end justify-center min-h-screen sm:pt-4 sm:px-4 sm:pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-black opacity-70" />
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <div
              className="inline-block align-bottom bg-white rounded text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 md:p-10">
                <div className="sm:flex sm:items-start">
                  <div className="text-left">
                    <div className="flex flex-row justify-between">
                      <h3
                        className="type-h4 font-medium text-green-01 mb-10 text-left"
                        id="modal-headline"
                      >
                        By using Teller App You Agree to:
                      </h3>
                      <a rel="noreferrer" href="https://teller.finance" className="link pointer">
                        <img src={CloseModalIcon} className="h-5" />
                      </a>
                    </div>
                    <p className="type-h5 font-medium text-green-01 mb-6">
                      When using Teller I understand that:
                    </p>
                    <BulletPoint text="The Teller Protocol is risky and highly experimental." />
                    <BulletPoint text="The Teller Protocol is currently ALPHA software, which may have undiagnosed bugs." />
                    <BulletPoint text="The code deployed as the Teller Protocol (“The Protocol”) is still undergoing several security audits and is not yet finalized." />
                    <BulletPoint text="All funds may be lost through a security breach, malfunction, or code exploit." />
                    <BulletPoint text="I will not be able to recoup gas fees paid to the Ethereum network to use The Protocol." />
                    <p className="type-h5 font-medium text-green-01 my-7">
                      To protect the Teller community, I will not:
                    </p>
                    <BulletPoint text="Act with malicious intent when utilizing The Protocol." />
                    <BulletPoint text="Take any action that may harm the The Protocol, users, or funds." />
                    <BulletPoint text="Falsify my identity or attempt to manipulate financial data." />
                    <div className="mb-7" />
                    <p className="type-h5 font-medium text-green-01 mb-12">
                      As a user, I have read and agree to the{" "}
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://teller.finance/terms-and-conditions"
                        className="link text-green-01"
                      >
                        <u>terms of service</u>
                      </a>
                    </p>
                    <div className="mb-3 sm:mb-0">
                      <PrimaryButton text="Agree" type="button" onClick={() => onAccept()} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TermsModal;

interface BulletPointProps {
  text: string;
}

const BulletPoint = ({ text }: BulletPointProps) => {
  return (
    <div className="flex flex-row items-start justify-start mb-3">
      <div className="mt-2 w-2 h-2 bg-green-04 mx-3 flex-shrink-0" />
      <div className="type-p-xs text-grey-01 ">{text}</div>
    </div>
  );
};
