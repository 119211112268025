import React, { useMemo } from "react";
import { AvailableTokens } from "contexts/types";
import { useTokensContext } from "contexts/global/tokensContext";

export const useToken = (tokenID: AvailableTokens) => {
  const tokens = useTokensContext();
  return useMemo(() => {
    return tokens[tokenID]
      ? tokens[tokenID]
      : { tokenAddress: null, tokenDecimals: null, tokenContract: null };
  }, [tokens, tokens[tokenID], tokenID]);
};
