import "./borrow.scss";

import React, { useContext } from "react";

import copy from "constants/copy.json";
import { BorrowPageContext } from "contexts/borrow/borrowContext";
import Card from "components/UI/Card";
import Metric from "components/UI/Metric";
import BorrowMetricsProTip from "./BorrowMetricsProTip";
import { useMarketState } from "hooks/useMarketState";
import { useBalance } from "hooks/useBalance";
import { useTokenPrice } from "hooks/useTokenPrice";
import formatNumberForDisplay from "utils/formatNumberForDisplay";

const BorrowMetrics = () => {
  const { borrowRequest } = useContext(BorrowPageContext);
  const { collateralWith, lendWith } = borrowRequest;
  const assetPrice = useTokenPrice(lendWith);
  const walletBalance = useBalance(lendWith);
  const collateralAvailable = useBalance(collateralWith);
  const { availableLiquidity } = useMarketState(lendWith);

  const {
    assetPriceTitle,
    walletBalanceTitle,
    collateralAvailableTitle,
    header,
    liquidityAvailableTitle,
  } = copy.pages.borrow.metrics;

  return (
    <div className="self-start">
      <Card className="metrics-card bg-gray-lightest" title={header}>
        <Metric
          title={assetPriceTitle}
          value={assetPrice ? `$${formatNumberForDisplay(assetPrice, 4)}` : "-"}
        />
        <Metric
          title={walletBalanceTitle}
          value={walletBalance ? `${formatNumberForDisplay(walletBalance)} ${lendWith}` : "-"}
        />
        <Metric
          title={collateralAvailableTitle}
          value={
            collateralAvailable
              ? `${formatNumberForDisplay(collateralAvailable)} ${collateralWith}`
              : "-"
          }
        />
        <Metric
          title={liquidityAvailableTitle}
          value={
            availableLiquidity ? `${formatNumberForDisplay(availableLiquidity)} ${lendWith}` : "-"
          }
        />
      </Card>
      <Card className="metrics-card bg-gray-lightest  my-1 " title="Pro tip">
        <BorrowMetricsProTip />
      </Card>
    </div>
  );
};

export default BorrowMetrics;
