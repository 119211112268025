import React, { useContext, useState } from "react";

import copy from "constants/copy.json";
import { BorrowPageContext } from "contexts/borrow/borrowContext";
import SubmenuCard from "components/UI/SubmenuCard";
import CustomSlider from "components/UI/CustomSlider";
import PrimaryButton from "components/UI/PrimaryButton";
import FormValidationWarning from "components/UI/FormValidationWarning";
import CustomSubmenuLink from "components/UI/CustomSubmenuLink";

export const LoanTermSubmenu = () => {
  const { borrowRequest, setBorrowRequest, setSubmenu } = useContext(BorrowPageContext);
  const [value, setValue] = useState(borrowRequest.loanTerm);
  const [warning, setWarning] = useState("");

  const min = 1;
  const max = 60;
  const errorMessage = `Please enter a number between ${min}-${max}`;

  const onChange = (e: any) => {
    let value = e.target.value;
    value = value.replace(/[^0-9]/g, "");
    if (isNaN(value)) {
      value = "0";
    }
    if (value > max || value < min) {
      setWarning(errorMessage);
    } else {
      setWarning("");
    }
    setValue(value);
  };
  const onSubmit = () => {
    let newValue = value;
    if (value > max) {
      newValue = max;
    }
    if (value < min) {
      newValue = min;
    }
    setBorrowRequest({
      ...borrowRequest,
      loanTerm: newValue,
    });
    setSubmenu(null);
  };
  const onSliderChange = (e: any) => {
    const value = e.target.value;
    if (value > max || value < min) {
      setWarning(errorMessage);
    } else {
      setWarning("");
    }
    setValue(value);
  };
  const pageCopy = copy.pages.borrow.main.form.step2.loanPeriod.submenu;

  return (
    <SubmenuCard
      title={pageCopy.title}
      onCloseAction={() => {
        setSubmenu(null);
      }}
    >
      <div className="-my-2 flex flex-col px-4">
        <div className="mb-4">{pageCopy.description}</div>
        <input
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          className="input text-5xl xs:text-4xl font-medium text-black text-center bg-teal-lightest"
          value={value}
          name="amount"
          onChange={onChange}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
        />
        <div className="text-lg xs:text-md text-gray">{value == 1 ? "day" : "days"}</div>
        <CustomSlider min={min} max={max} onChange={onSliderChange} value={value} />
        <div className=" my-4 mx-auto ">
          <PrimaryButton onClick={onSubmit} text={pageCopy.CTA} />
        </div>
        <FormValidationWarning message={warning} />
      </div>
    </SubmenuCard>
  );
};

const LoanTermSelection = () => {
  const { borrowRequest, setSubmenu } = useContext(BorrowPageContext);

  return (
    <CustomSubmenuLink
      title={`${borrowRequest.loanTerm} ${borrowRequest.loanTerm == 1 ? "day" : "days"}`}
      CTA={() => {
        setSubmenu("LoanTerm");
      }}
    />
  );
};

export default LoanTermSelection;
