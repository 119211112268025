import React, { useContext, useEffect } from "react";
import { HashRouter as Router, NavLink, Route, Switch } from "react-router-dom";

import Card from "components/UI/Card";
import ProcessingAnimation from "components/UI/ProcessingAnimation";
import TestnetBanner from "components/UI/TestnetBanner";
import copy from "constants/copy.json";
import DashboardContextProvider, { DashboardContext } from "contexts/dashboard/dashboardContext";
import { useUIContext } from "contexts/global/uiContext";
import { useWeb3StateContext } from "contexts/global/web3StateContext";

import LoginButton from "../../components/LoginButton/LoginButton";
import Borrow from "./Borrow";
import DashboardNav from "./DashboardNav";
import Lend from "./Lend";
import Use from "./Use";

const Dashboard = () => {
  const { loggedOutMessage } = copy.pages.dashboard;
  const { userAddress } = useWeb3StateContext();

  return (
    <div className="lg:self-start">
      {userAddress ? (
        <>
          <TestnetBanner />
          <div className="cards-container ">
            <DashboardNav className="hidden md:block" />
            <div className=" ">
              <Route path={"/dashboard/:onPage"} component={DashboardContent} />
            </div>
          </div>
        </>
      ) : (
        <div className="flex justify-center items-center	 flex-col text-center ">
          <div className="mb-4">{loggedOutMessage}</div>
          <LoginButton />
        </div>
      )}
    </div>
  );
};
const DashboardContent = ({ match }: any) => {
  const { onPage, setOnPage, loans, loansLoading } = useContext(DashboardContext);
  const { setExplorerMode } = useUIContext();
  const pageParams = onPage.split("-");
  const section = pageParams[0];
  useEffect(() => {
    const pageParams = match.params.onPage.split("-");

    if (pageParams.length > 2 && pageParams[2] == "explorer") {
      setExplorerMode(true);
    }
    setOnPage(match.params.onPage);
  }, [match.params.onPage]);

  return (
    <>
      {(!loansLoading && loans) || section === "deposit" ? (
        <>
          {section === "deposit" && <Lend />}
          {section === "borrow" && <Borrow />}
          {section === "spend" && <Use />}
        </>
      ) : (
        <Card
          title={loansLoading ? "Loading data" : ""}
          className="main-card bg-teal-lightest text-center xs:my-16"
        >
          {!loansLoading && loans == [] && section !== "deposit" && <div>No loans</div>}
          {loansLoading && <ProcessingAnimation height={150} />}
          {/* {loansLoading && loanIDs.length > 0 && (
            <div>
              <div className="mb-4">
                <div className=" border-thin mb-4 mt-3">
                  {loanIDs.map((id: number, i: number) => {
                    return (
                      <div key={id}>
                        <TableRow title={id.toString()}>
                          <div className="flex-2 flex flex-row justify-between align-items-center">
                            <DataLoadingWrapper controlVar={null} bg="bg-teal-button-hover">
                              <div></div>
                            </DataLoadingWrapper>
                            <CustomSubmenuLink title={""} CTA={() => null} />
                          </div>
                        </TableRow>
                        {i !== loanIDs.length - 1 && <BR />}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )} */}
        </Card>
      )}
    </>
  );
};
const DashboardContextWrapper = () => {
  return (
    <DashboardContextProvider>
      <Dashboard />
    </DashboardContextProvider>
  );
};
export default DashboardContextWrapper;
