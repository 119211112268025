import { useMemo } from "react";

import { allContractAddresses } from "constants/constants";
import { NetworkNameEnum, useWeb3StateContext } from "contexts/global/web3StateContext";
import ERC20Interface from "contracts/abis/ERC20Detailed.json";
import { AvailableTokens } from "contexts/types";
import { useNetworkConfig } from "./useNetworkConfig";

export const useAddress = (id: AvailableTokens | string, type: "contract" | "token") => {
  const { network } = useWeb3StateContext();
  const { networkSupported } = useNetworkConfig();

  return useMemo(() => {
    if (!network.name || !networkSupported || network.name == "unknown") return null;
    const res =
      type == "contract"
        ? allContractAddresses[network.name][id]
        : allContractAddresses[network.name].tokens[id];
    return res;
  }, [network, type, id, networkSupported]);
};

export const useContract: (
  contractName: string | null,
  ABI: any,
  type: "contract" | "token",
  options?: any,
  address?: string,
) => any = (contractName, ABI, type, options, address) => {
  // console.log("useContract", contractName);

  const { web3 } = useWeb3StateContext();
  const contractAddress = useAddress(contractName, type);

  return useMemo(() => {
    if (!contractAddress || !ABI || !web3) return null;
    return new web3.eth.Contract(ABI, contractAddress, options);
  }, [contractAddress, web3, ABI, options]);
};

export const useERC20TokenContract = (tokenID: AvailableTokens) =>
  useContract(tokenID, ERC20Interface.abi, "token");
