import React from "react";

import { SushiswapContextProvider } from "contexts/dashboard/sushiswapContext";

import SushiswapMainSection from "./SushiswapLegos/SushiswapMainSection";

const SushiswapPage = () => {
  return (
    <div>
      <SushiswapMainSection />
    </div>
  );
};

const Sushiswap = () => {
  return (
    <SushiswapContextProvider>
      <SushiswapPage />
    </SushiswapContextProvider>
  );
};

export default Sushiswap;
