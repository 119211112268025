import { useTellerContext } from "contexts/global/tellerContext";
import { useWeb3StateContext } from "contexts/global/web3StateContext";
import { useCallback } from "react";
import { createNewTransactionWithHandler } from "./utils";

export const useCompoundClaimComp = () => {
  const { tellerDiamondContract } = useTellerContext();
  const { userAddress } = useWeb3StateContext();

  const compoundClaimComp = useCallback(
    async (compoundClaimCompTransactionHandler: any, loanID: any) => {
      await createNewTransactionWithHandler(
        () => tellerDiamondContract.methods.compoundClaimComp(loanID).send({ from: userAddress }),
        compoundClaimCompTransactionHandler,
      );
    },
    [tellerDiamondContract, userAddress],
  );

  return { compoundClaimComp };
};
