import React, { useContext, useMemo } from "react";

import PrimaryButton from "components/UI/PrimaryButton";
import { SushiswapContext } from "contexts/dashboard/sushiswapContext";
import { useSwap } from "actions/Swap";
import { computeTradePriceBreakdown, warningSeverity } from "utils/sushiswapTrades";
import { useTransactionHandler } from "hooks/useTransactionHandler";

const SushiswapButtonGroup = () => {
  const { CTAConfig } = useSushiswapButtonGroup();
  return (
    <>
      <div className="flex flex-row justify-center">
        <PrimaryButton variant={CTAConfig.variant} text={CTAConfig.copy} onClick={CTAConfig.CTA} />
      </div>
    </>
  );
};

export default SushiswapButtonGroup;

const useSushiswapButtonGroup = () => {
  const { setSuccess, setProcessing, trade, values, selectedLoan, escrowBalances } =
    useContext(SushiswapContext);
  const { input, output } = values;

  const priceImpact = useMemo(() => {
    return computeTradePriceBreakdown(trade).priceImpactWithoutFee;
  }, [trade]);
  const impactSeverity = useMemo(() => {
    return warningSeverity(priceImpact);
  }, [priceImpact]);

  const id = selectedLoan.IDNumber;

  const transactionHandler = useTransactionHandler("swap", setSuccess, setProcessing);
  const { swap } = useSwap();
  const onSwapClick = () => {
    setProcessing("loading");
    swap(trade, id, transactionHandler);
  };

  const CTAConfig = useMemo(() => {
    const CTAConfig = {
      copy: "",
      variant: "secondary",
      CTA: () => null,
    };
    if (parseInt(values) == 0) {
      CTAConfig.copy = "Enter an amount";
    } else if (!output.token) {
      CTAConfig.copy = "Select a token";
    } else if (!trade) {
      CTAConfig.copy = "Insufficient liquidity for this trade.";
    } else if (Number(escrowBalances[input.token]) < Number(input.amount)) {
      CTAConfig.copy = `Insufficient ${input.token} balance`;
    } else if (impactSeverity > 3) {
      CTAConfig.copy = "Price Impact too high";
    } else {
      CTAConfig.copy = `Swap ${impactSeverity > 3 ? "anyway" : ""}`;
      CTAConfig.CTA = onSwapClick;
      CTAConfig.variant = "primary";
    }
    return CTAConfig;
  }, [values, input, output, trade, impactSeverity, escrowBalances]);
  return { CTAConfig };
};
