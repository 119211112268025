import { getStoreKeyName } from "@apollo/client/utilities";
import { BadgeTypeEnum } from "components/Badge/types";

export enum NFTStatusEnum {
  unstaked = "Unstaked",
  linked = "Linked",
  staked = "Staked",
  defaulted = "Defaulted",
}

export type NFTInterface = {
  id: string;
  status: NFTStatusEnum;
  loanSize: string;
  stakeDate: string | null;
  contributionAsset: string | null;
  contributionSize: number | null;
  contributionMultiplier: number | null;
  tier: number;
};

export const NFTStatusBadgeStyles: { [key in NFTStatusEnum]: BadgeTypeEnum } = {
  Unstaked: BadgeTypeEnum.normal,
  Linked: BadgeTypeEnum.green,
  Staked: BadgeTypeEnum.normal,
  Defaulted: BadgeTypeEnum.red,
};

export const NFTTypeBadgeStyles: { [key in NFTType]: BadgeTypeEnum } = {
  V1: BadgeTypeEnum.normal,
  V2: BadgeTypeEnum.green,
};

export type NFT1155 = {
  id: string;
  amount: string;
};

export enum NFTType {
  V1 = "V1",
  V2 = "V2",
}
