import FullScreenModal from "components/FullScreenModal";
import React, { useState, useEffect } from "react";
import Button from "components/Button";
import { useStakeNFT } from "actions/StakeNFT";
import { useTransactionHandler } from "hooks/useTransactionHandler";
import LoaderComplete from "assets/LoaderComplete.svg";
import LoadingAnimation from "components/LoadingAnimation";
import { useApproveNFT } from "actions/ApproveNFT";

const ApproveTransactionModal = ({
  closeModal,
  successCTACopy = "Stake",
  multipleTransactionsWarning = false,
  setLoading = () => null,
}: {
  closeModal: (approved: boolean) => void;
  successCTACopy?: string;
  multipleTransactionsWarning: boolean;
  setLoading?: any;
}) => {
  const type = "V1";
  const [approved, setApproved] = useState(false);
  const [approving, setApproving] = useState(false);
  const { approveNFT } = useApproveNFT();
  const approveTransactionHandler = useTransactionHandler(
    "approve",
    () => {
      setApproving(false);
      setApproved(true);
      setLoading(false);
    },
    (e: any) => {
      setApproving(e);
      setLoading(e);
    },
  );

  const approve = async () => {
    await approveNFT(approveTransactionHandler, type);
  };

  const approveCTA = async () => {
    setApproving(true);
    await approve();
  };

  // useEffect(() => {
  //   let isCanceled = false;
  //   if (approved) {
  //     (async () => {
  //       // await new Promise((resolve) => setTimeout(resolve, 10000));
  //       // if (!isCanceled) {
  //       //   closeModal(approved);
  //       // }
  //     })();
  //   }
  //   return () => {
  //     isCanceled = true;
  //   };
  // }, [approved]);

  return (
    <FullScreenModal
      onClose={() => {
        closeModal(approved);
        setLoading(false);
      }}
      footerCTA={
        <Button
          CTA={
            approved
              ? () => {
                  closeModal(approved);
                }
              : approveCTA
          }
          color="green"
          type="primary"
          text={approved ? successCTACopy : approving ? "Approving.." : "Approve NFT"}
          size="md"
          disabled={approving}
        />
      }
      footerCopy={<div></div>}
      mainContent={
        <div className="flex justify-center items-center text-center max-w-xs mx-auto">
          {approved && (
            <div>
              <img
                alt="Loader Complete"
                src={LoaderComplete}
                height="177"
                width="177"
                className="d-inline-block align-top mx-auto"
              />
              <div className="type-h4 text-green-02 type-medium text-center mb-4">
                Transaction Approved
              </div>
              <div className="type-p-sm type-light text-black text-center">
                Your NFTs are ready to be staked.
              </div>
              {multipleTransactionsWarning && (
                <div className="type-p-sm type-light text-black text-center mt-2">
                  {multipleTransactionsWarning &&
                    " Note: If you currently hold both V1 and V2 NFTs, 2 transactions will be signed"}
                </div>
              )}
            </div>
          )}
          {!approved && approving && (
            <div>
              <LoadingAnimation />
              <div className="type-h4 text-green-02 type-medium text-center mb-2">
                Approving transaction
              </div>
              <div className="type-p-sm type-light text-black text-center">
                Your transaction is currently being approved.
              </div>
            </div>
          )}
          {!approved && !approving && (
            <div>
              <div className="block lg:hidden type-h3 text-green-02 text-center mb-2">
                Approve <span className="text-green-03">Transaction</span>
              </div>
              <div className="hidden lg:block type-h2 text-green-02 text-center mb-2">
                Approve <span className="text-green-03">Transaction</span>
              </div>

              <div>It's your first time staking NFTs, transaction needs to be approved.</div>
            </div>
          )}
        </div>
      }
    />
  );
};

export default ApproveTransactionModal;
