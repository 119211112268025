import React from "react";
import Lottie from "react-lottie";

import loading_animation from "assets/loading_animation.json";
import success_animation from "assets/success_animation.json";

interface animationProps {
  isStopped: boolean;
  animation?: string;
}

const LoadingAnimation = ({ isStopped, animation = "loading" }: animationProps) => {
  const animationData = animation === "loading" ? loading_animation : success_animation;
  const isLooping = animation === "loading";

  return (
    <div style={animation === "loading" ? { margin: "-8px" } : {}}>
      <Lottie
        options={{
          loop: isLooping,
          autoplay: true,
          animationData,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        isClickToPauseDisabled
        width={animation === "loading" ? 36 : 65}
        height={animation === "loading" ? 36 : "auto"}
        isStopped={isStopped}
      />
    </div>
  );
};

export default LoadingAnimation;
