import React from "react";

interface customInputProps {
  value: string;
  onChangeFunction: () => void;
  type?: string;
  onBlur?: () => void;
  placeholder?: string;
  className?: string;
}
const CustomInput = ({
  value,
  onChangeFunction,
  type,
  onBlur = () => null,
  placeholder = "",
  className = "input text-5xl xs:text-4xl font-medium text-black",
}: customInputProps) => {
  return (
    <input
      placeholder={placeholder}
      autoComplete="off"
      autoCorrect="off"
      autoCapitalize="off"
      spellCheck="false"
      type={type}
      className={`bg-teal-lightest placeholder-teal placeholder-opacity-25	 ${className}`}
      value={value}
      name="amount"
      onChange={(e: any) => {
        onChangeFunction(e);
      }}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          onBlur(e);
        }
      }}
      onBlur={(e: any) => {
        onBlur(e);
      }}
    />
  );
};

export default CustomInput;
