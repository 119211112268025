import React from "react";
interface CardProps {
  title: string;
  children: React.ReactNode;
  onCloseAction: () => void;
  className?: string;
}
const SubmenuCard = ({ title, children, onCloseAction, className = "" }: CardProps) => {
  return (
    <div className={`flex flex-col ${className}`}>
      <p className="m-0 align-center font-medium mb-4 text-2xl xs:text-xl">{title}</p>
      <div className="flex flex-row  justify-center">
        <div style={{ width: "80%" }} className="">
          {children}
        </div>
      </div>
    </div>
  );
};

export default SubmenuCard;
