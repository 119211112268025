import "./WarningModal.scss";

import React from "react";

import PrimaryButton from "./PrimaryButton";
import Modal from "components/Modal";
import Button from "components/Button";
interface WarningModalProps {
  show: boolean;
  proceed?: () => void;
  cancel?: () => void;
  text?: string;
  header?: string;
  children?: React.ReactNode;
  cancelText?: string;
  proceedText?: string;
}

const WarningModal = ({
  show,
  proceed = null,
  cancel = null,
  text = "",
  header = "Hold Up",
  children = <></>,
  cancelText = "Cancel",
  proceedText = "Yes",
}: WarningModalProps) => {
  if (!show) return <></>;
  return (
    <Modal
      title={header}
      body={
        <div className="my-20 mx-10">
          <div className="opacity-80 text-black mb-4">{text}</div>
          {children}
        </div>
      }
      CTA={
        proceed ? (
          <Button
            CTA={() => {
              proceed();
            }}
            text={proceedText}
            color="orange"
            type="primary"
            size="md"
          />
        ) : (
          <></>
        )
      }
      footerText={
        cancel ? (
          <Button
            CTA={() => {
              cancel();
            }}
            text={cancelText}
            color="orange"
            type="secondary"
            size="md"
          />
        ) : (
          <></>
        )
      }
      showModal={show}
      setModalOpened={() => null}
    />
  );
};

export default WarningModal;
