import { useReducer } from "react";

export enum txActionTypes {
  startTxProcess,
  setTxHash,
  justCompleted,
  txSuccess,
  txError,
}

enum progressStateTypes {
  default = "default",
  loading = "loading",
  justCompleted = "justCompleted",
  txSuccess = "txSuccess",
}
const useTxReducer = () => {
  interface stateInterface {
    txHash: null | string;
    progressState: progressStateTypes;
  }
  const initialState: stateInterface = {
    txHash: null as null,
    progressState: progressStateTypes.default,
  };

  const txReducer = (
    state: stateInterface,
    action:
      | { type: txActionTypes.startTxProcess }
      | { type: txActionTypes.setTxHash; payload: { txHash: string } }
      | { type: txActionTypes.justCompleted }
      | { type: txActionTypes.txSuccess }
      | { type: txActionTypes.txError },
  ) => {
    switch (action.type) {
      case txActionTypes.startTxProcess:
        return {
          ...state,
          progressState: progressStateTypes.loading,
        };
      case txActionTypes.setTxHash:
        return {
          ...state,
          txHash: action.payload.txHash,
        };
      case txActionTypes.justCompleted:
        return {
          ...state,
          progressState: progressStateTypes.justCompleted,
        };
      case txActionTypes.txSuccess:
        return {
          ...state,
          progressState: progressStateTypes.txSuccess,
        };
      case txActionTypes.txError:
        return {
          ...state,
          progressState: progressStateTypes.default,
          txHash: null as null,
        };
      default:
        return state;
    }
  };
  const [state, dispatch] = useReducer(txReducer, initialState);

  return [state, dispatch] as const;
};

export default useTxReducer;
