import getWeb3Notify from "utils/web3Notify";
import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useWeb3StateContext } from "./web3StateContext";

const defaultNotifyState = {
  notify: null as null,
  setNotify: () => null as null,
};

export const NotifyContext =
  createContext<{ notify: any; setNotify: (notify: any) => void }>(defaultNotifyState);

export const NotifyContextProvider = ({ children }: any) => {
  const [notify, setNotify] = useState(null);

  const { network } = useWeb3StateContext();
  useEffect(() => {
    if (!network.id) return;
    (async () => {
      const notify = await getWeb3Notify(Number(network.id));
      setNotify(notify);
    })();
  }, [network.id]);

  const value = useMemo(() => {
    return {
      notify,
      setNotify,
    };
  }, [notify]);

  return <NotifyContext.Provider value={value}>{children}</NotifyContext.Provider>;
};

export const useNotifyContext = () => {
  const context = useContext(NotifyContext);
  if (context === undefined) {
    throw new Error("useNotify must be used within a useNotifyProvider");
  }
  return context;
};
