import React, { useState, useContext, createContext, useMemo } from "react";
import { PlaidState } from "../types";

const defaultPlaidState = {
  plaid: { dataProviderResponse: {}, loggedIn: null as null },
  setPlaid: () => null as null,
};
const PlaidContext = createContext<PlaidState>(defaultPlaidState);

export const PlaidContextProvider = ({ children }: any) => {
  const [plaid, setPlaid] = useState(defaultPlaidState.plaid);

  const value = useMemo(() => {
    return {
      plaid,
      setPlaid,
    };
  }, [plaid]);

  return <PlaidContext.Provider value={value}>{children}</PlaidContext.Provider>;
};

export const usePlaidContext = () => {
  const context = useContext(PlaidContext);
  if (context === undefined) {
    throw new Error("usePlaid must be used within a usePlaidProvider");
  }
  return context;
};
