import React, { useContext, useEffect } from "react";

import CurrencyIcon from "components/UI/CurrencyIcon";
import SubmenuCard from "components/UI/SubmenuCard";
import { SushiswapContext } from "contexts/dashboard/sushiswapContext";

import SushiswapArrow from "./SushiswapArrow";
import SushiswapButtonGroup from "./SushiswapButtonGroup";
import SushiswapInputField from "./SushiswapInputField";
import SushiswapMetrics from "./SushiswapMetrics";
import SushiswapOutputField from "./SushiswapOutputField";
import SushiswapPrice from "./SushiswapPrice";
import { useEscrowBalances } from "contexts/global/loansContext";
import { formatSmallNumber } from "utils/helperFunctions";
import { useDeepCompareEffect } from "react-use";

const SushiswapForm = () => {
  const { trade, tokenSelectionDropdown, selectedLoan, setEscrowBalances } =
    useContext(SushiswapContext);

  const escrow = useEscrowBalances(selectedLoan.escrow);
  useDeepCompareEffect(() => {
    if (!escrow) return;
    let mounted = true;

    (async () => {
      if (mounted) {
        setEscrowBalances(escrow);
      }
    })();
    return () => {
      mounted = false;
    };
  }, [escrow]);

  return (
    <>
      {tokenSelectionDropdown ? (
        <SushiswapTokenDropdown />
      ) : (
        <div className="flex flex-col xs:text-xs">
          <>
            <SushiswapInputField />
            <SushiswapArrow />
            <SushiswapOutputField />
            {trade && <SushiswapPrice />}
            <SushiswapButtonGroup />
            {trade && <SushiswapMetrics />}
          </>
        </div>
      )}
    </>
  );
};

export default SushiswapForm;

const SushiswapTokenDropdown = () => {
  const { tokenSelectionDropdown, setTokenSelectionDropdown, values, setValues, escrowBalances } =
    useContext(SushiswapContext);
  const { tokens } = tokenSelectionDropdown;

  const onTokenClick = (token: any) => {
    const newValues = { ...values };
    if (tokenSelectionDropdown.type === "First") {
      newValues.input.token = token;
    } else {
      newValues.output.token = token;
    }
    setValues(newValues);
    setTokenSelectionDropdown(null);
  };

  return (
    <SubmenuCard
      title="Select a token"
      onCloseAction={() => {
        setTokenSelectionDropdown(null);
      }}
      className=""
    >
      {tokens.map((token: string) => {
        const balance = escrowBalances ? formatSmallNumber(escrowBalances[token]) : "-";
        return (
          <div
            key={token}
            className="text-lg xs:text-md font-medium flex flex-row justify-between items-center my-4 pointer"
            onClick={() => onTokenClick(token)}
          >
            <div className="flex flex-row items-center">
              <CurrencyIcon currency={token} className="mr-2" />
              <div className="mx-3">{token}</div>
            </div>
            <div>{balance}</div>
          </div>
        );
      })}
    </SubmenuCard>
  );
};
