import React, { useState } from "react";

import { Transition } from "@headlessui/react";

import { AccordionBodyProps, AccordionHeaderProps } from "./types";

const Accordion = ({
  rowIDs,
  HeaderComponent,
  BodyComponent,
  divider = <></>,
}: {
  rowIDs: string[];
  HeaderComponent: (props: AccordionHeaderProps) => any;
  BodyComponent: (props: AccordionBodyProps) => any;
  divider?: React.ReactNode | null;
}) => {
  const [expandedRowID, selectRow] = useAccordion();
  return (
    <>
      {rowIDs.map((thisRowID, i) => {
        const isSelected = Boolean(expandedRowID == thisRowID);
        return (
          <div key={thisRowID}>
            <AccordionRowHeader isSelected={isSelected} id={thisRowID}>
              <HeaderComponent isSelected={isSelected} id={thisRowID} selectRow={selectRow} />
            </AccordionRowHeader>
            <AccordionRowBody isSelected={isSelected} id={thisRowID} selectRow={selectRow}>
              <BodyComponent id={thisRowID} isSelected={isSelected} selectRow={selectRow} />
            </AccordionRowBody>
            {divider && rowIDs.length > i + 1 && divider}
          </div>
        );
      })}
    </>
  );
};
const AccordionRowHeader = (props: {
  isSelected: boolean;
  id: string;
  children: React.ReactNode;
}) => {
  return <>{props.children}</>;
};

const AccordionRowBody = (props: { isSelected: boolean; children: React.ReactNode }) => {
  return (
    <>
      <Transition
        enter="transition ease-out"
        enterFrom="transform opacity-0"
        enterTo="transform opacity-100"
        show={props.isSelected}
      >
        {props.children}
      </Transition>
    </>
  );
};

const useAccordion = () => {
  const [expandedRowID, setExpandedRowID] = useState(null);
  const selectRow = (id: string, isSelected: boolean) => {
    isSelected ? setExpandedRowID(null) : setExpandedRowID(id);
  };

  return [expandedRowID, selectRow];
};

export default Accordion;
