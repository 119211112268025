import "./custom-dropdown.scss";

import React, { useState, Fragment } from "react";

import dropdown from "assets/arrow-down.svg";
import CurrencyIcon from "components/UI/CurrencyIcon";

import { AvailableTokens } from "contexts/types";
import BR from "./BR";
interface dropdownProps {
  selected: AvailableTokens;
  handleSelect: () => void;
  options: AvailableTokens[];
  customDropdownIcon?: any;
}

export const CustomDropdown = ({
  selected,
  handleSelect,
  options,
  customDropdownIcon = null,
}: dropdownProps) => {
  const displayDropdownIcon = Boolean(options.length > 1);
  const [displayDropdown, setDisplayDropdown] = useState(false);

  return (
    <>
      <div className="relative z-1">
        <a
          onClick={(e) => {
            if (!displayDropdownIcon) return;
            e.preventDefault();
            setDisplayDropdown(!displayDropdown);
          }}
          className={`${
            displayDropdownIcon ? "pointer" : ""
          } dropdown font-medium text-gray dropdown-title dropdown-title flex flex-row items-center	`}
        >
          <div
            id="toggle"
            className="dropdown font-medium text-gray dropdown-title dropdown-title flex flex-row items-center	"
          >
            {customDropdownIcon ? (
              customDropdownIcon
            ) : (
              <>
                <div className="mr-3 text-black">{`${selected}   `}</div>
                <CurrencyIcon className="" currency={selected} />
                {displayDropdownIcon && (
                  <img className="ml-3" height={14} width={14} src={dropdown} />
                )}
              </>
            )}
          </div>
        </a>

        {displayDropdown && (
          <div className="dropdown-menu absolute border-thin p-4 rounded bg-white min-w-max z-10">
            {options.map((option: AvailableTokens) => {
              if (option !== selected) {
                return (
                  <div
                    key={option}
                    onClick={() => {
                      handleSelect(option);
                      setDisplayDropdown(false);
                    }}
                    className="dropdown-title flex flex-row items-center space-between"
                  >
                    <div className="pointer text-black mr-3 font-medium">{option}</div>
                    <CurrencyIcon className="" currency={option} />
                  </div>
                );
              }
            })}
          </div>
        )}
      </div>
    </>
  );
};
