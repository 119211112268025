import Accordion from "components/Accordion";
import Button from "components/Button";
import { NavLoginButton } from "components/LoginButton/LoginButton";
import { CardHeader } from "components/Modal/CardWithTable";
import BR from "components/UI/BR";
import CurrencyIcon from "components/UI/CurrencyIcon";
import UserTellerTokenData from "components/UserTellerTokenData/UserTellerTokenData";
import { useTToken } from "contexts/global/tellerContext";
import { useWeb3StateContext } from "contexts/global/web3StateContext";
import { useBalance } from "hooks/useBalance";
import { useNetworkConfig } from "hooks/useNetworkConfig";
import { useSuppliedBalance } from "hooks/useSuppliedBalance";

import React, { useEffect, useRef, useState } from "react";
import formatNumberForDisplay from "utils/formatNumberForDisplay";
import ManageLiquidityDepositModal from "./ManageLiquidityDepositModal";
import ManageLiquidityWithdrawModal from "./ManageLiquidityWithdrawModal";
import { BrowserRouter as Router, Link, NavLink, Route, Switch } from "react-router-dom";
import { useTransactionsContext } from "contexts/global/transactionsContext";

const ManageLiquidity = () => {
  const { lendingTokens } = useNetworkConfig();
  const { userAddress } = useWeb3StateContext();
  const [depositModalOpened, setDepositModalOpened] = useState<boolean>(false);
  const [withdrawModalOpened, setWithdrawModalOpened] = useState<boolean>(false);
  const [totalUSD, setTotalUSD] = useState(0.0);
  const [selectedCurrency, setSelectedCurrency] = useState(lendingTokens[0]);
  const tellerBalance = useSuppliedBalance(selectedCurrency);
  const tellerBalanceString = formatNumberForDisplay(tellerBalance);
  const walletBalance = useBalance(selectedCurrency);
  const walletBalanceString = formatNumberForDisplay(walletBalance);

  useEffect(() => {
    if (!lendingTokens) return;
    setSelectedCurrency(lendingTokens[0]);
  }, [lendingTokens]);
  const calculateTotalUSD = (amount: number) => {
    setTotalUSD(totalUSD + amount);
  };

  const renderTokens = lendingTokens.map((token, index) => {
    return <UserTellerTokenData token={token} calculateTotalUSD={calculateTotalUSD} key={index} />;
  });
  const [transactions, setTransactions] = useState([]);

  const allPastTransactions = [
    ...new Set(transactions.sort((a, b) => Number(b.timestamp) - Number(a.timestamp))),
  ];
  const first4Transactions =
    allPastTransactions && allPastTransactions.length > 4
      ? allPastTransactions.slice(0, 4)
      : allPastTransactions;

  const [transactionsListExpanded, setTransactionsListExpanded] = useState(false);

  const pastTransactionsToRender = transactionsListExpanded
    ? allPastTransactions
    : first4Transactions;

  const [tTokens, setTTokens] = useState({});
  const allPastTransactionsContext = useTransactionsContext();

  return (
    <>
      {lendingTokens.map((token) => (
        <LendingToken
          tTokens={tTokens}
          setTTokens={setTTokens}
          token={token}
          key={token}
          transactions={transactions}
          setTransactions={setTransactions}
          allPastTransactionsContext={allPastTransactionsContext?.transactions}
        />
      ))}
      <div className="bg-green-01">
        <div className="max-w-4xl mx-auto px-6 sm:px-0">
          <div className="text-white type-h2 font-bold mb-3">Manage Your Liquidity</div>
          <div className="text-white type-p-sm mb-40 max-w-lg">
            Manage, deposit, and borrow select digital assets through the Teller native smart
            contracts.
          </div>
        </div>
      </div>
      <div className="bg-white pb-16">
        <div className="max-w-4xl mx-auto">
          <CardHeader
            variant="lightGreen"
            className="flex-1	flex flex-row flex-wrap justify-between md:h-60 py-6 sm:py-10 px-6 sm:px-12 rounded-t"
            style={{ marginTop: "-120px" }}
          >
            <div className="flex flex-col justify-between mb-6 sm:mb-0">
              <div className="flex flex-col justify-between text-white">
                <div className="type-label text-white">Teller Balance</div>
                <div className="flex flex-row text-white items-center space-x-2">
                  <div className="font-medium type-h1-sans ">
                    {formatNumberForDisplay(totalUSD)}
                  </div>
                  <div className="text-white type-h1-sans font-light mr-3">USD</div>
                </div>
              </div>
              <div className="flex flex-row type-p-sm">
                <div></div>
              </div>
            </div>

            {userAddress ? (
              <div className="flex flex-row flex-wrap sm:flex-col justify-between">
                <Button
                  color="green"
                  size="lg"
                  type="secondary"
                  className="border-thin p-20"
                  CTA={() => {
                    setDepositModalOpened(true);
                  }}
                  disabled={false}
                  text="Deposit"
                />
                {!!totalUSD && Number(totalUSD) > 0 && (
                  <Button
                    color="green"
                    size="lg"
                    type="secondary"
                    className="border-thin p-20"
                    CTA={() => {
                      setWithdrawModalOpened(true);
                    }}
                    disabled={false}
                    text="Withdraw"
                  />
                )}
              </div>
            ) : (
              <NavLoginButton />
            )}
          </CardHeader>
          {renderTokens}
        </div>
      </div>
      {pastTransactionsToRender && pastTransactionsToRender.length > 0 && (
        <div className="bg-green-06 ">
          <div className="mt-24 mb-20 px-6 max-w-4xl mx-auto flex flex-row justify-between">
            <div>
              <div className="type-h3 text-green-02 font-bold mb-3">
                Recent Liquidity Transactions
              </div>
              <div className="type-p-sm text-black font-light mb-16">
                View a historical record of previous deposits and withdrawals, stored indefinitely
                on the Ethereum blockchain.{" "}
                {allPastTransactions && allPastTransactions.length > 4 && (
                  <span
                    className="font-medium text-green-02 cursor-pointer"
                    onClick={() => {
                      setTransactionsListExpanded(!transactionsListExpanded);
                    }}
                  >
                    {transactionsListExpanded ? "See Recent Transactions" : "See All Transactions"}
                  </span>
                )}
              </div>
              <div>
                {pastTransactionsToRender.map((tx, i) => {
                  return (
                    <div key={i}>
                      <div className="flex flex-row type-p-sm text-green-01 py-5">
                        <div className="flex-1 font-light">{tx.date}</div>
                        <div className="text-left flex-1 font-light hidden sm:block">{tx.type}</div>
                        <div
                          className={`flex-1 justify-end flex flex-row ${
                            tx.type == "Deposit" ? "text-black" : "text-tangerine-01"
                          }`}
                        >
                          <div className="mr-2">
                            {tx.type == "Deposit" ? "+" : "-"} {tx.amount} {tx.token}{" "}
                          </div>
                          <CurrencyIcon currency={tx.token} />
                        </div>
                      </div>
                      {pastTransactionsToRender.length != i + 1 && <BR />}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="hidden w-80 h-96 bg-green-01 ml-16 md:flex flex-col px-10 py-8 rounded justify-between">
              <div>
                <div className="text-green-03 type-h4 mb-1">Borrow</div>
                <div className="text-white type-p-sm">
                  Apply for a partially collateralized or NFT-backed loan through the Teller smart
                  contract platform.
                </div>
              </div>
              <Link to={"/borrow"}>
                <div className="text-white rounded bg-green-03 py-4 px-16 whitespace-pre">
                  Apply Now
                </div>
              </Link>
            </div>
          </div>
        </div>
      )}
      <div className="bg-white flex-1 -mt-1 px-6">
        <div className="my-12 sm:my-36 max-w-4xl mx-auto">
          <div className="text-green-02 type-h3 font-medium mb-16">Frequently Asked Questions.</div>
          <FAQAccordion />
        </div>
      </div>
      {depositModalOpened && (
        <ManageLiquidityDepositModal
          setDepositModalOpened={setDepositModalOpened}
          tellerBalanceString={tellerBalanceString}
          walletBalanceString={walletBalanceString}
          selectedCurrency={selectedCurrency}
          setSelectedCurrency={setSelectedCurrency}
        />
      )}
      {withdrawModalOpened && (
        <ManageLiquidityWithdrawModal
          setWithdrawModalOpened={setWithdrawModalOpened}
          tellerBalanceString={tellerBalanceString}
          walletBalanceString={walletBalanceString}
          selectedCurrency={selectedCurrency}
          setSelectedCurrency={setSelectedCurrency}
        />
      )}
    </>
  );
};

export default ManageLiquidity;

const FAQAccordion = () => {
  const FAQs = [
    {
      id: "0",
      question: "What are average loan terms?",
      answer:
        "You are in charge of the amount, duration, and borrowing asset associated with your loan. On submission, our risk algorithm will determine interest rates as low as 0%.",
    },
    {
      id: "1",
      question: "Where do I receive my loan funds?",
      answer:
        "Borrowed funds are transferred to a pre-determined escrow contract. You can interact with these funds in the whitelisted protocols as set forth in the smart contracts.",
    },
    {
      id: "2",
      question: "Can I use my loan with any protocol?",
      answer:
        "Only select, whitelisted protocols can be used with the loan. To-date, these dapps include Compound (Mainnet), Uniswap (Mainnet), Aave (Polygon), Pool Together (Polygon), and Sushiswap (Polygon).",
    },
    {
      id: "3",
      question: "What happens if I default on a loan?",
      answer:
        "Liquidations due to default are dependent on the type of loan borrowed. In the case of an NFT-backed loan, the NFT will be forfeited. In the case of a partially collateralized loan, the collateral will be liquidated and the loan will be closed out.",
    },
    {
      id: "4",
      question: "When will I be liquidated?",
      answer:
        "If funds were borrowed from an NFT-backed loan, defaults occur when a user fails to repay by the due date.",
      expanded: [
        "If funds were borrowed from a partially collateralized loan, defaults occur when the value of the collateral + borrowed asset drops below the original loan value, or the loan is past due.",
      ],
    },
  ];

  return (
    <Accordion
      rowIDs={[
        ...FAQs.map((q) => {
          return q.id;
        }),
      ]}
      HeaderComponent={({ id, selectRow, isSelected }) => {
        const onSelectRowClick = () => {
          selectRow(id, isSelected);
        };
        return (
          <div>
            <div className="pointer mt-10 text-green-02 font-medium type-p">
              {FAQs[id].question}
            </div>
            <div className="type-p-sm text-black font-light pt-2">
              {!isSelected && FAQs[id].answer}
              {"  "}
              {!isSelected && FAQs[id].expanded != null && (
                <span
                  onClick={onSelectRowClick}
                  className="text-green-02 font-medium cursor-pointer"
                >
                  Read More
                </span>
              )}
            </div>
          </div>
        );
      }}
      BodyComponent={({ id, selectRow, isSelected }) => {
        const onSelectRowClick = () => {
          selectRow(id, isSelected);
        };
        return (
          <div className="flex flex-col">
            {FAQs[id].expanded.map((p, i) => {
              return (
                <div key={i} className="type-p-sm text-black font-light pt-2">
                  {p}
                </div>
              );
            })}
            {isSelected && (
              <span onClick={onSelectRowClick} className="text-green-02 font-medium cursor-pointer">
                Close
              </span>
            )}
          </div>
        );
      }}
      divider={<BR variant="grey" className="my-10" />}
    />
  );
};

const LendingToken = ({
  tTokens,
  setTTokens,
  transactions,
  setTransactions,
  token,
  allPastTransactionsContext,
}: {
  tTokens: any;
  setTTokens: any;
  token: any;
  setTransactions: any;
  transactions: any;
  allPastTransactionsContext: any;
}) => {
  const tToken = useTToken(token);
  const { userAddress, web3 } = useWeb3StateContext();

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };
  const prevTransactions = usePrevious(allPastTransactionsContext);

  useEffect(() => {
    let isMounted = true;
    if (!tToken?.tTokenContract) return;
    const updatedtTokens = { ...tTokens };
    if (!updatedtTokens[token] || prevTransactions !== allPastTransactionsContext) {
      updatedtTokens[token] = tToken;
      setTTokens(updatedtTokens);
      (async () => {
        let withdrawTXs = await tToken.tTokenContract
          .getPastEvents(
            "Redeem",
            {
              filter: { sender: userAddress.toLowerCase() },
              fromBlock: 0,
              toBlock: "latest",
            },
            function (error, events) {
              return events;
            },
          )
          .then(function (events) {
            return events;
          });

        let depositTXs = await tToken.tTokenContract
          .getPastEvents(
            "Mint",
            {
              filter: { sender: userAddress.toLowerCase() },
              fromBlock: 0,
              toBlock: "latest",
            },
            function (error, events) {
              return events;
            },
          )
          .then(function (events) {
            return events;
          });
        const getDate = (timestamp) => {
          const date = new Date(timestamp * 1000);
          return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
        };
        depositTXs = await Promise.all(
          depositTXs.map(async (tx) => {
            const timestamp = (await web3.eth.getBlock(tx.blockNumber)).timestamp;
            return {
              timestamp: timestamp,
              date: getDate(timestamp),
              type: "Deposit",
              amount: formatNumberForDisplay(
                tx.returnValues.underlyingAmount / 10 ** tToken.tTokenDecimals,
              ),
              transactionHash: tx.transactionHash,
              token: token,
            };
          }),
        );

        withdrawTXs = await Promise.all(
          withdrawTXs.map(async (tx) => {
            const timestamp = (await web3.eth.getBlock(tx.blockNumber)).timestamp;
            return {
              timestamp: timestamp,
              date: getDate(timestamp),
              type: "Withdraw",
              amount: formatNumberForDisplay(
                tx.returnValues.underlyingAmount / 10 ** tToken.tTokenDecimals,
              ),
              token: token,
              transactionHash: tx.transactionHash,
            };
          }),
        );
        if (isMounted) {
          setTransactions((st) => [...st, ...depositTXs, ...withdrawTXs]);
        }
      })();
    }
    return () => {
      isMounted = false;
    };
  }, [tToken, web3, allPastTransactionsContext]);

  return <div key={token}></div>;
};
