import { useTellerContext } from "contexts/global/tellerContext";
import { useUIContext } from "contexts/global/uiContext";
import BigNumber from "bignumber.js";
import React, { createContext, useCallback, useEffect, useMemo, useState } from "react";

import { BorrowPageContextInterface, ContextProps } from "../types";
import { isStablecoin } from "utils/helperFunctions";
import { useMarketState } from "hooks/useMarketState";
import { useTokenPrice } from "hooks/useTokenPrice";
import copy from "constants/copy.json";
import { NFT_CONTRIBUTION_MULTIPLIER } from "constants/constants";

BigNumber.config({ EXPONENTIAL_AT: 1e9 });

export const DAYS = 86400; // Seconds per day

export const defaultBorrowRequest = {
  approved: false,
  bankConnected: false,
  borrowerAddress: "",
  collateralAmount: 0,
  collateralPercent: null as null,
  collateralWith: "",
  lendWith: "",
  loanSize: null as null,
  loanTerm: 1,
  loanType: "Secured",
  requestHash:
    "C5D2460186F7233C927E7DB2DCC703C0E500B653CA82273B7BFAD8045D85A470 [xdJGAYb3IzySfn2y3McDwOUAtlPKgic7e/rYBF2FpHA=]",
  requestNonce: 1,
  requestTime: Math.floor(Date.now() / 1000),
  transferred: false,
};

const LoanTerms = [
  {
    chainId: null as string,
    collateralRatio: null as null,
    consensusAddress: null as string,
    interestRate: null as null,
    maxLoanAmount: null as null,
    minCollateralNeeded: null as string,
    requestHash: null as string,
    responseTime: null as string,
    signature: null as string,
    signer: null as string,
    signerNonce: null as string,
  },
];

const LendingApp = {
  assetReportSignature: null as string,
  assetReportStringified: null as string,
  borrowedAsset: null as string,
  collateralAsset: null as string,
  collateralRatioEntered: null as string, // 100% === 10000
  ethereumWallet: null as string,
  loanTermLength: null as string, // seconds
  loanUse: null as string,
  requestNonce: null as string,
  requestTime: null as string, // seconds at least 100 in the past
  requestedLoanSize: null as string,
};

export const BorrowPageContext = createContext<BorrowPageContextInterface>({
  NFTs: [],
  borrowProcessState: null,
  borrowRequest: defaultBorrowRequest,
  lendingApp: null as any,
  loanTerms: null as [],
  loanTypeSelected: null,
  resetBorrowRequest: () => null,
  setBorrowRequest: () => null,
  setLendingApp: () => null,
  setLoanTerms: () => null,
  setLoanTypeSelected: () => null,
  setNFTs: () => null,
  setStage: () => null,
  setSubmenu: () => null,
  stage: 0,
  submenu: null as null,
  nodeResponses: [],
  setNodeResponses: null as null,
});

const BorrowPageContextProvider = ({ children }: ContextProps) => {
  const { collateralToken, lendingToken } = useTellerContext();
  const [NFTs, setNFTs] = useState(null);
  const [borrowRequest, setBorrowRequest] = useState(defaultBorrowRequest);
  const [isSubmitting, setSubmitting] = useState(null);
  const [lendingApp, setLendingApp] = useState(LendingApp);
  const [loanTerms, setLoanTerms] = useState(LoanTerms);
  const [loanTypeSelected, setLoanTypeSelected] = useState(null);
  const [requestingLoan, setRequestingLoan] = useState(null);
  const [requestingTerms, setRequestingTerms] = useState(null);
  const [stage, setStage] = useState(0);
  const [stageChangeWarning, setStageChangeWarning] = useState(null);
  const [submenu, setSubmenu] = useState(null as null);
  const [success, setSuccess] = useState(null);
  const [nodeResponses, setNodeResponses] = useState([]);
  const { setOnSubmenuClose } = useUIContext();
  const [maxAmountExceeded, setMaxAmountExceeded] = useState<string>("");
  const [maxLoanAmount, setMaxLoanAmount] = useState(0);
  const pageCopy = copy.pages.borrow.main.form.step2.loanSize.submenu;
  const isSecured = Boolean(borrowRequest.loanType === "Secured");

  useEffect(() => {
    setBorrowRequest((st) => {
      return {
        ...st,
        lendWith: lendingToken,
        collateralWith: collateralToken,
      };
    });
  }, [lendingToken, collateralToken]);

  useEffect(() => {
    const fn = submenu
      ? () => {
          setSubmenu(null);
        }
      : null;
    setOnSubmenuClose(() => fn);
  }, [submenu, setOnSubmenuClose]);

  const resetBorrowRequest = useCallback(() => {
    setBorrowRequest({
      ...defaultBorrowRequest,
      lendWith: lendingToken,
      collateralWith: collateralToken,
    });
    setLoanTerms(LoanTerms);
    setNFTs(null);
  }, [lendingToken, collateralToken]);

  const borrowProcessState = useMemo(() => {
    return {
      isSubmitting,
      requestingLoan,
      requestingTerms,
      setRequestingLoan,
      setRequestingTerms,
      setStageChangeWarning,
      setSubmitting,
      setSuccess,
      stageChangeWarning,
      success,
    };
  }, [isSubmitting, requestingLoan, requestingTerms, stageChangeWarning, success]);

  useEffect(() => {
    if (isSecured) return;
    setBorrowRequest((borrowRequest: any) => {
      return {
        ...borrowRequest,
        loanSize: maxLoanAmount,
      };
    });
  }, [borrowRequest.lendWith, maxLoanAmount, isSecured]);

  const [nftMaxAmount, setNftMaxAmount] = useState(borrowRequest.loanSize);
  const tokenPrice = useTokenPrice(borrowRequest.lendWith);
  const { availableLiquidity } = useMarketState(borrowRequest.lendWith);

  const [value, setValue] = useState(0);
  useEffect(() => {
    const value = Number(borrowRequest.loanSize);

    const selectedNFTs = NFTs
      ? NFTs.filter((NFT: any) => {
          return NFT.selected == true;
        })
      : null;

    let nftAmount = 0;
    if (selectedNFTs && selectedNFTs.length > 0) {
      for (let i = 0; i < selectedNFTs.length; i++) {
        nftAmount =
          nftAmount + Number((selectedNFTs[i].credit * NFT_CONTRIBUTION_MULTIPLIER) / tokenPrice);
      }

      setNftMaxAmount(nftAmount);
      if (Number(value) > Number(nftAmount)) {
        setMaxAmountExceeded(`Exceeded max loan amount of ${nftAmount} ${borrowRequest.lendWith}.`);
      } else {
        setMaxAmountExceeded("");
      }
    } else if (
      !availableLiquidity ||
      (availableLiquidity && Number(value) > Number(availableLiquidity))
    ) {
      const displayMaxAmount =
        availableLiquidity && Number(availableLiquidity) >= 0 ? availableLiquidity : 0;

      setMaxAmountExceeded(
        `${pageCopy.supplyToDebtExceededWarning} ${displayMaxAmount} ${borrowRequest.lendWith}. Please try again later or supply more ${borrowRequest.lendWith} to increase the ratio`,
      );
    } else {
      setMaxAmountExceeded("");
    }
  }, [availableLiquidity, NFTs, borrowRequest, tokenPrice]);

  useEffect(() => {
    const stableCoinMultiplier = isStablecoin(borrowRequest.lendWith) ? 1 : 0.995;
    const maxNFTLoanValue = Math.min(nftMaxAmount, Number(availableLiquidity));
    const maxCollateralLoanValue = Number(availableLiquidity);
    const maxLoanAmount =
      !isSecured && Number(nftMaxAmount) > 0
        ? maxNFTLoanValue * stableCoinMultiplier
        : !maxCollateralLoanValue
        ? 1
        : maxCollateralLoanValue > 10
        ? Math.floor(maxCollateralLoanValue)
        : Math.floor(maxCollateralLoanValue * 1000) / 1000;
    setMaxLoanAmount(maxLoanAmount);
  }, [isSecured, nftMaxAmount, borrowRequest.lendWith, availableLiquidity]);

  useEffect(() => {
    setValue(borrowRequest.loanSize);
  }, [borrowRequest.loanSize]);

  const v = useMemo(() => {
    return {
      NFTs,
      borrowProcessState,
      borrowRequest,
      lendingApp,
      loanTerms,
      loanTypeSelected,
      resetBorrowRequest,
      setBorrowRequest,
      setLendingApp,
      setLoanTerms,
      setLoanTypeSelected,
      setNFTs,
      setStage,
      setSubmenu,
      stage,
      submenu,
      nodeResponses,
      setNodeResponses,
      maxAmountExceeded,
      setMaxAmountExceeded,
      maxLoanAmount,
      setMaxLoanAmount,
      value,
      setValue,
      isSecured,
    };
  }, [
    NFTs,
    borrowProcessState,
    borrowRequest,
    lendingApp,
    loanTerms,
    loanTypeSelected,
    resetBorrowRequest,
    stage,
    submenu,
    nodeResponses,
    maxAmountExceeded,
    maxLoanAmount,
    value,
    isSecured,
  ]);
  return <BorrowPageContext.Provider value={v}>{children}</BorrowPageContext.Provider>;
};
export default BorrowPageContextProvider;
