import React from "react";

import Arrow from "components/UI/Arrow";

interface customSubmenuLinkProps {
  title: string;
  CTA: () => void;
}
const CustomSubmenuLink = ({ title, CTA }: customSubmenuLinkProps) => {
  return (
    <div
      onClick={() => {
        CTA();
      }}
      className="flex flex-row items-center	 pointer"
    >
      <div className="mr-3 font-medium">{title}</div>
      <Arrow direction="right" />
    </div>
  );
};
export default CustomSubmenuLink;
