import { useWeb3StateContext } from "contexts/global/web3StateContext";
import React from "react";
import Button from "../../components/Button";
import { useNFTsContext } from "../../contexts/global/nftsContext";

import NFTDashboardAccordion from "./NFTDashboardAccordion";
// import NFTDashboardClaimAPYCard from "./NFTDashboardClaimAPYCard";

const NFTDashboard = () => {
  const { bridgingTo } = useNFTsContext();
  return (
    <div className="flex-1 px-4">
      <NFTDashboardHeader />
      {/* {bridgingTo == null && <NFTDashboardClaimAPYCard />} */}
      <NFTDashboardAccordion />
    </div>
  );
};
export default NFTDashboard;

const NFTDashboardHeader = () => {
  const { ownedNFTs, setBridgingTo, bridgingTo } = useNFTsContext();
  const displayBridgingCTA = false; //TODO !!(ownedNFTs?.length > 0);
  const { network } = useWeb3StateContext();

  const openBridgingMenu = () => {
    bridgingTo == null
      ? setBridgingTo(network.name == "polygon" ? "mainnet" : "polygon")
      : setBridgingTo(null);
  };
  const bridgingCTADisabled = true;
  return (
    <div className="xs:mx-4 sm:mx-auto flex flex-row flex-wrap justify-between">
      <>
        <div className="flex-col">
          <div className="type-h2 text-green-02">
            Manage <span className="text-green-03">Your NFT</span>
          </div>
          <div className="text-p-sm text-black mt-3 max-w-sm font-light">
            Unlock the full potential of your NFT(s). Access unsecured loans, and bonus base loan
            size.
          </div>
        </div>
      </>
      <>
        {displayBridgingCTA && (
          <div className="type-h4 type-medium self-end mt-8 md:mt-0">
            <Button
              CTA={openBridgingMenu}
              color="green"
              type="secondary"
              text={bridgingTo == null ? "Bridge your NFT" : "↩"}
              size="md"
              disabled={bridgingCTADisabled}
              className="w-60"
            />
          </div>
        )}
      </>
    </div>
  );
};
