import React from "react";

import { ButtonProps } from "./types";

const Button = ({
  color,
  type,
  text,
  size,
  CTA = () => null,
  href = null,
  disabled = false,
  leftIcon = null,
  rightIcon = null,
  className = "",
}: ButtonProps) => {
  const isSmallButton = Boolean(size == "xs" || size == "xxs");
  let trueColor: "green" | "orange" | "darkGreen" = color;
  if (isSmallButton && color == "green") {
    trueColor = "darkGreen";
  }
  return (
    <a href={href} rel="noreferrer" target="_blank" className={`hover:no-underline xs:space-x-2`}>
      <div
        onClick={() => {
          if (disabled) return;
          CTA();
        }}
        className={`
          text-center justify-around my-auto flex flex-row font-medium rounded whitespace-nowrap px-2
      ${buttonStyles[trueColor][type][disabled ? "disabled" : "default"]}
      ${buttonStyles[size]}
      ${disabled ? "opacity-40 cursor-not-allowed" : "cursor-pointer"} ${className}
      `}
      >
        {leftIcon}
        <div className="my-auto">{text}</div>
        {rightIcon}
      </div>
    </a>
  );
};
const buttonStyles = {
  lg: "py-6 w-60 type-button",
  md: "py-3 w-32 type-button-sm",
  sm: "h-8 w-28 type-p-xs",
  xs: "h-7 w-min px-5 type-caption",
  xxs: "h-5 w-min px-3 type-p-xxs",

  green: {
    primary: {
      default: "text-white bg-green-03 hover:bg-primary border border-green-03",
      disabled: "text-white bg-green-03",
    },
    secondary: {
      default: "text-green-03 bg-white border-green-03 border hover:bg-green-06",
      disabled: "text-green-03 border-green-03 border",
    },
    tertiary: {
      default: "border border-green-05 text-green-02 bg-green-06 hover:bg-teal-button-hover",
      disabled: "border border-green-05 text-green-02 bg-green-06",
    },
    nostroke: { default: "text-green-03 hover:bg-green-06", disabled: "text-green-03" },
  },
  darkGreen: {
    primary: {
      default:
        "text-white hover:text-white bg-green-02 hover:bg-green-button-hover border border-green-02",
      disabled: "text-white bg-green-02",
    },
    secondary: {
      default: "border border-green-02 text-green-02 bg-white hover:bg-green-06",
      disabled: "border border-green-02 text-green-02 bg-white ",
    },
    tertiary: {
      default: "border border-green-05 text-green-01 bg-green-06 hover:bg-teal-button-hover",
      disabled: "border border-green-05 text-green-02 bg-green-06",
    },
    nostroke: {
      default: "text-green-02 bg-white hover:bg-green-06",
      disabled: "text-green-02 bg-white ",
    },
  },
  orange: {
    primary: {
      default:
        "text-white bg-tangerine-02 hover:bg-tangerine-button-hover border border-tangerine-02",
      disabled: "text-white bg-tangerine-02",
    },
    secondary: {
      default: "border border-tangerine-01 bg-white text-tangerine-01 hover:bg-tangerine-06",
      disabled: "border border-tangerine-01 text-tangerine-01",
    },
    tertiary: {
      default: "border border-tangerine-01 bg-white text-tangerine hover:bg-tangerine-06",
      disabled: "border border-tangerine-01 text-tangerine",
    },
    nostroke: {
      default: "bg-white text-tangerine hover:bg-tangerine-06",
      disabled: "text-tangerine",
    },
  },
};

export default Button;
