import React, { useContext } from "react";

import disabledArrow from "assets/full-gray-arrow-down.svg";
import activeArrow from "assets/full-primary-arrow-down.svg";
import { UniswapContext } from "contexts/dashboard/uniswapContext";

const UniswapArrow = () => {
  const { trade, isExactIn, setIsExactIn } = useContext(UniswapContext);

  return (
    <div
      className="mt-2 mx-auto"
      onClick={() => {
        // setIsExactIn(!isExactIn);
      }}
    >
      <img height={14} src={trade ? activeArrow : disabledArrow} />
    </div>
  );
};

export default UniswapArrow;
