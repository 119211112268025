import { useTellerContext, useTToken } from "contexts/global/tellerContext";
import { useTransactionsContext } from "contexts/global/transactionsContext";
import { useWeb3StateContext } from "contexts/global/web3StateContext";
import { AvailableTokens } from "contexts/types";
import React, { useState, useEffect, useMemo } from "react";
import { useDeepCompareEffect } from "react-use";
import { useBNMethods } from "./useBNMethods";
import { useToken } from "./useToken";

export const useAllowance = (assetID: AvailableTokens, contractToCheck = "tellerDiamond") => {
  const [allowance, setAllowance] = useState(null);
  const { tellerDiamondContract } = useTellerContext();
  const { userAddress } = useWeb3StateContext();
  const { tokenContract } = useToken(assetID);
  const { convertToString } = useBNMethods(assetID);
  const { transactions } = useTransactionsContext();
  const tToken = useTToken(assetID);
  const contract =
    contractToCheck == "tellerDiamond" ? tellerDiamondContract : tToken?.tTokenContract;

  useDeepCompareEffect(() => {
    if (!contract || !userAddress || !convertToString || !tokenContract) return;
    let isMounted = true;
    (async () => {
      const allowanceBN = await tokenContract.methods
        .allowance(userAddress, contract._address)
        .call();
      const tokenDecimals = await tokenContract.methods.decimals.call();
      const allowance = convertToString(allowanceBN);
      if (isMounted) setAllowance(allowance);
    })();

    return () => {
      isMounted = false;
    };
  }, [userAddress, contract, convertToString, transactions, tokenContract]);

  return useMemo(() => {
    return allowance;
  }, [allowance]);
};
