import React, { useContext, useState } from "react";

import compoundDaiTutorial from "assets/compoundDaiTutorial.png";
import copy from "constants/copy.json";
import { BorrowPageContext } from "contexts/borrow/borrowContext";
import WarningModal from "components/UI/WarningModal";
import { useWeb3StateContext } from "contexts/global/web3StateContext";
import { useMarketState } from "hooks/useMarketState";

const ProTip = () => {
  const { submenu, stage, borrowRequest } = useContext(BorrowPageContext);

  const isSecured = Boolean(borrowRequest.loanType === "Secured");
  const zeroCollateral = Boolean(borrowRequest.collateralPercent === 0);
  const proTips = copy.pages.borrow.main.form.proTips;

  const MAX_LOAN_AMOUNT_CONST = 999;

  const { availableLiquidity } = useMarketState();

  const supplyToDebtLow = !!(
    availableLiquidity && Boolean(Number(availableLiquidity) < MAX_LOAN_AMOUNT_CONST)
  );
  const [proTipModalMessage, setProTipModalMessage] = useState(null);
  const { network } = useWeb3StateContext();

  return (
    <div className="text-sm font-light color-teal-darker -mt-2">
      {proTipModalMessage && (
        <WarningModal
          show={Boolean(proTipModalMessage)}
          proceed={() => {
            setProTipModalMessage("");
          }}
          cancel={null}
          text={proTipModalMessage}
          header="How to get faucet tokens "
        />
      )}
      {stage <= 1 && (
        <div>
          {supplyToDebtLow && network.id == 4 && (
            <>
              You can obtain more testnet tokens from Compound faucet.
              <br />
              Click{" "}
              <u
                onClick={() => {
                  setProTipModalMessage(
                    <div>
                      <div>
                        1. Go to{" "}
                        <u>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://app.compound.finance"
                            className="link text-gray"
                          >
                            app.compound.finance
                          </a>
                        </u>{" "}
                        on Rinkeby.
                      </div>
                      <div>2. Click on DAI under "Supply Markets".</div>
                      <div>3. Navigate to the Withdraw tab and click on "Faucet" at the bottom</div>
                      <img className="mt-4 mb-2" src={compoundDaiTutorial} width="220" />
                    </div>,
                  );
                }}
                className="link text-gray"
              >
                here
              </u>{" "}
              to learn more
            </>
          )}
          {((!supplyToDebtLow && (submenu === null || submenu === "LoanSize")) ||
            (supplyToDebtLow && network.id != 4)) &&
            proTips[1]}
          {!supplyToDebtLow && submenu === "LoanTerm" && proTips[2]}
          {!supplyToDebtLow && submenu === "CollateralPercent" && proTips[3]}
        </div>
      )}
      {stage === 2 && (
        <div>
          {!isSecured && zeroCollateral && proTips[4]}
          {!isSecured && !zeroCollateral && proTips[5]}
          {isSecured && !zeroCollateral && proTips[6]}
        </div>
      )}
      {stage === 3 && (
        <div>
          {!submenu && zeroCollateral && proTips[7]}
          {!submenu && !zeroCollateral && proTips[8]}
          {submenu === "CollateralAmount" && proTips[9]}
        </div>
      )}
    </div>
  );
};

export default ProTip;
