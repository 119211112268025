import Axios from "axios";
import BigNumber from "bignumber.js";
import { useEffect, useState } from "react";

const aaveApiUrl = "https://aave-api-v2.aave.com";

export const useAaveDaiAPY = () => {
  const [APY, setAPY] = useState(null);

  useEffect(() => {
    let mounted = true;

    (async () => {
      const response = await Axios.get(`${aaveApiUrl}/data/liquidity/v2`, {
        params: {
          // TODO: move to constants? @tanya
          poolId: "0xd05e3E715d945B59290df0ae8eF85c1BdB684744", // should be aave lending pool address provider on polygon
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      const vIncentivesAPY = response.data[4].vIncentivesAPY; // for the underlying asset (dai) apy
      // TODO: include in apy ui
      const { value: aIncentivesAPY } = response.data[3].aIncentivesAPY; // for the matic / stkAAVE apy
      const apy = new BigNumber(vIncentivesAPY).shiftedBy(2).toString();
      if (mounted) setAPY(apy);
    })();

    return () => {
      mounted = false;
    };
  }, []);

  return APY;
};
