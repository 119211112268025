import React, { useContext } from "react";

import BR from "components/UI/BR";
import CustomSubmenuLink from "components/UI/CustomSubmenuLink";
import TableRow from "components/UI/TableRow";
import copy from "constants/copy.json";
import { BorrowRepayContext, DashboardContext } from "contexts/dashboard/dashboardContext";
import { LoanInterface } from "contexts/types";
import { roundToFourDecimals } from "utils/helperFunctions";

const RepayForm = () => {
  const { loans } = useContext(DashboardContext);
  const overdueLoans = loans
    ? loans.filter((loan: LoanInterface) => {
        return loan?.statusName === "Overdue";
      })
    : null;
  const outstandingLoans = loans
    ? loans.filter((loan: LoanInterface) => {
        return loan?.statusName === "Outstanding";
      })
    : null;
  const repaidLoans = loans
    ? loans.filter((loan: LoanInterface) => {
        return loan?.statusName === "Repaid";
      })
    : null;

  const { setSelectedLoan } = useContext(BorrowRepayContext);

  const pageCopy = copy.pages.dashboard["borrow-repay"];

  if (!loans) {
    return (
      <div>
        <p>No Loans!</p>
      </div>
    );
  }
  return (
    <div>
      {outstandingLoans && outstandingLoans.length > 0 && (
        <div className="mb-4">
          <div className="text-left">{pageCopy.outstandingLoansTitle}</div>
          <div className=" border-thin mb-4 mt-3">
            {outstandingLoans.map((loan: LoanInterface, i: number) => {
              return (
                <div key={loan.id}>
                  <TableRow title={loan.id}>
                    <div className="flex-2 flex flex-row justify-between align-items-center">
                      <div className="text-xs text-lightest-gray my-auto">
                        {`${loan.timeTillExpires} ${
                          loan.timeTillExpires == 1 ? "day" : "days"
                        } remaining`}
                      </div>
                      <CustomSubmenuLink
                        title={`${roundToFourDecimals(loan.borrowedAmountNumber)} ${loan.token}`}
                        CTA={() => {
                          setSelectedLoan(loan);
                        }}
                      />
                    </div>
                  </TableRow>
                  {i !== outstandingLoans.length - 1 && <BR />}
                </div>
              );
            })}
          </div>
        </div>
      )}
      {overdueLoans && overdueLoans.length > 0 && (
        <div className="mb-4">
          <div className="text-left">{pageCopy.overdueLoansTitle}</div>
          <div className=" border-thin mb-4 mt-3">
            {overdueLoans.map((loan: LoanInterface, i: number) => {
              return (
                <div key={loan.id}>
                  <TableRow title={loan.id}>
                    <div className="flex-2 flex flex-row justify-between align-items-center">
                      <div className="text-xs text-lightest-gray my-auto">
                        {`${-loan.timeTillExpires} ${
                          loan.timeTillExpires == 1 ? "day" : "days"
                        } overdue`}
                      </div>
                      <CustomSubmenuLink
                        title={`${roundToFourDecimals(loan.borrowedAmountNumber)} ${loan.token}`}
                        CTA={() => {
                          setSelectedLoan(loan);
                        }}
                      />
                    </div>
                  </TableRow>
                  {i !== overdueLoans.length - 1 && <BR />}
                </div>
              );
            })}
          </div>
        </div>
      )}
      {repaidLoans && repaidLoans.length > 0 && (
        <div className="mb-4">
          <div className="text-left">{pageCopy.closedLoansTitle}</div>
          <div className=" border-thin mb-4 mt-3">
            {repaidLoans.map((loan: LoanInterface, i: number) => {
              return (
                <div key={loan.id}>
                  <TableRow title={loan.id}>
                    {/* {loan.unclaimedTokens.length > 0 && (
                      <span className="flex h-3 w-3 relative">
                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary opacity-75"></span>
                        <span className="relative inline-flex rounded-full h-3 w-3 bg-primary"></span>
                      </span>
                    )} */}
                    {/* {{ loan }} */}
                    <CustomSubmenuLink
                      title={`${roundToFourDecimals(loan.borrowedAmountNumber)} ${loan.token}`}
                      CTA={() => {
                        setSelectedLoan(loan);
                      }}
                    />
                  </TableRow>
                  {i !== repaidLoans.length - 1 && <BR />}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
export default RepayForm;
