import BigNumber from "bignumber.js";

import { allContractAddresses } from "constants/constants";
import IUniswapV2Pair from "contracts/abis/IUniswapV2Pair.json";

import {
  ChainId,
  CurrencyAmount,
  JSBI,
  Pair,
  Percent,
  Token,
  TokenAmount,
  Trade,
  WETH,
} from "@uniswap/sdk";

import { roundToFourDecimals } from "utils/helperFunctions";

BigNumber.config({ EXPONENTIAL_AT: 1e9 });

export const getUniswapTokenKeys = (network: any) => {
  const tokens = allContractAddresses[network.name].tokens;
  const networkid = Number(network.id);
  const DAI = tokens.DAI ? new Token(networkid, tokens.DAI, 18, "DAI", "DAI") : null;
  const USDC = tokens.USDC ? new Token(networkid, tokens.USDC, 6, "USDC", "USDC") : null;
  const USDT = tokens.USDT ? new Token(networkid, tokens.USDT, 6, "USDT", "USDT") : null;
  const MKR = tokens.MKR ? new Token(networkid, tokens.MKR, 18, "MKR", "Maker") : null;
  const SNX = tokens.SNX ? new Token(networkid, tokens.SNX, 18, "SNX", "SNX") : null;
  const LINK = tokens.LINK ? new Token(networkid, tokens.LINK, 18, "LINK", "LINK") : null;
  const YFI = tokens.YFI ? new Token(networkid, tokens.YFI, 18, "YFI", "YFI") : null;
  const AAVE = tokens.AAVE ? new Token(networkid, tokens.AAVE, 18, "AAVE", "AAVE") : null;
  const WBTC = tokens.WBTC ? new Token(networkid, tokens.WBTC, 8, "WBTC", "WBTC") : null;
  const WETH = tokens.ETH ? new Token(networkid, tokens.WETH, 18, "WETH", "WETH") : null;
  return { DAI, USDC, USDT, MKR, SNX, LINK, YFI, AAVE, WBTC, WETH };
};

// used to construct intermediary pairs for trading

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50;
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20;

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000));
export const BIPS_BASE = JSBI.BigInt(10000);

const getPairCombinations = async (network: any, tokenKeys: any) => {
  const BASES_TO_CHECK_TRADES_AGAINST = {
    [ChainId.KOVAN]: [tokenKeys.WETH, tokenKeys.DAI, tokenKeys.USDC, tokenKeys.LINK],
    [ChainId.RINKEBY]: [tokenKeys.WETH, tokenKeys.DAI, tokenKeys.USDC, tokenKeys.LINK],
    [ChainId.MAINNET]: [
      tokenKeys.WETH,
      tokenKeys.DAI,
      tokenKeys.USDC,
      tokenKeys.LINK,
      // tokenKeys.USDT,
      tokenKeys.AAVE,
      tokenKeys.WBTC,
      tokenKeys.YFI,
      tokenKeys.SNX,
      // tokenKeys.MKR,
    ],
  };
  const networkId = network.id == "31337" ? 1 : network.id;
  const array = BASES_TO_CHECK_TRADES_AGAINST[networkId];
  const results = [];
  for (let i = 0; i < array.length - 1; i++) {
    for (let j = i + 1; j < array.length; j++) {
      if (array[j] != undefined && array[i] != undefined) {
        const sortedTokens = [array[i], array[j]].sort((a: Token, b: Token) =>
          a.address.toLowerCase() < b.address.toLowerCase() ? -1 : 1,
        );
        const address = await Pair.getAddress(array[j], array[i]);
        results.push({
          address: address.toLowerCase(),
          token0: sortedTokens[0],
          token1: sortedTokens[1],
        });
      }
    }
  }
  return results;
};

export const getPairs = async (network: any, web3: any, tokenKeys: any) => {
  const pairCombinations = await getPairCombinations(network, tokenKeys);
  // const reserves = await fetchReserves();
  let validPairs: Pair[] = [];

  validPairs = (
    await Promise.all(
      pairCombinations.map(async (pair) => {
        try {
          const { address, token0, token1 } = pair;
          const pairContract = new web3.eth.Contract(IUniswapV2Pair.abi, address, {});
          const pairReserves = await pairContract.methods.getReserves().call();
          if (!pairReserves) return;
          const { reserve0, reserve1 } = pairReserves;
          if (reserve0 * reserve1 === 0) return;
          // const decimals0 = token0.decimals;
          // const decimals1 = token1.decimals;
          const reserve0BigInt = new BigNumber(reserve0).toString();
          const reserve1BigInt = new BigNumber(reserve1).toString();
          const newPair = new Pair(
            new TokenAmount(token0, reserve0BigInt),
            new TokenAmount(token1, reserve1BigInt),
          );
          return newPair;
        } catch (err) {
          // console.log(err);
        }
      }),
    )
  ).filter((pair: any) => {
    return pair != undefined;
  });
  return validPairs;
};

export const getBestTradeExactIn = async (
  currencyIn: string,
  currencyOut: string,
  amountIn: string,
  pairs: any,
  tokenKeys: any,
) => {
  try {
    const { decimals } = tokenKeys[currencyIn];
    const bestTrade = await Trade.bestTradeExactIn(
      pairs,
      new TokenAmount(
        tokenKeys[currencyIn],
        new BigNumber(amountIn).shiftedBy(decimals).toString(),
      ),
      tokenKeys[currencyOut],
      { maxHops: 3 },
    );
    return bestTrade[0];
  } catch (err) {
    console.log(err);
  }
};

export const getBestTradeExactOut = async (
  currencyIn: string,
  currencyOut: string,
  amountOut: string,
  pairs: any,
  tokenKeys: any,
) => {
  const { decimals } = tokenKeys[currencyOut];
  const bestTrade = await Trade.bestTradeExactOut(
    pairs,
    tokenKeys[currencyIn],
    new TokenAmount(
      tokenKeys[currencyOut],
      new BigNumber(amountOut).shiftedBy(decimals).toString(),
    ),
    { maxHops: 3 },
  );
  return bestTrade[0];
};

const basisPointsToPercent = (num: number): Percent => {
  return new Percent(JSBI.BigInt(num), JSBI.BigInt(10000));
};
export const computeSlippageAdjustedAmounts = (trade: Trade | undefined) => {
  const pct = basisPointsToPercent(INITIAL_ALLOWED_SLIPPAGE);
  return trade.minimumAmountOut(pct);
};
const ONE_HUNDRED_PERCENT = new Percent(JSBI.BigInt(10000), JSBI.BigInt(10000));
const BASE_FEE = new Percent(JSBI.BigInt(30), JSBI.BigInt(10000));
const INPUT_FRACTION_AFTER_FEE = ONE_HUNDRED_PERCENT.subtract(BASE_FEE);

// computes price breakdown for the trade
export const computeTradePriceBreakdown = (trade?: Trade) => {
  // for each hop in our trade, take away the x*y=k price impact from 0.3% fees
  // e.g. for 3 tokens/2 hops: 1 - ((1 - .03) * (1-.03))
  const realizedLPFee = !trade
    ? undefined
    : ONE_HUNDRED_PERCENT.subtract(
        trade.route.pairs.reduce(
          (currentFee) => currentFee.multiply(INPUT_FRACTION_AFTER_FEE),
          ONE_HUNDRED_PERCENT,
        ),
      );

  // remove lp fees from price impact
  const priceImpactWithoutFeeFraction =
    trade && realizedLPFee ? trade.priceImpact.subtract(realizedLPFee) : undefined;

  // the x*y=k impact
  const priceImpactWithoutFeePercent = priceImpactWithoutFeeFraction
    ? new Percent(
        priceImpactWithoutFeeFraction?.numerator,
        priceImpactWithoutFeeFraction?.denominator,
      )
    : undefined;

  // the amount of the input that accrues to LPs
  const realizedLPFeeAmount =
    realizedLPFee &&
    trade &&
    (trade.inputAmount instanceof TokenAmount
      ? new TokenAmount(
          trade.inputAmount.token,
          realizedLPFee.multiply(trade.inputAmount.raw).quotient,
        )
      : CurrencyAmount.ether(realizedLPFee.multiply(trade.inputAmount.raw).quotient));

  return {
    priceImpactWithoutFee: priceImpactWithoutFeePercent,
    realizedLPFee: realizedLPFeeAmount,
  };
};

export const getRoute = (trade: Trade) => trade.route.path.map((token: Token) => token.symbol);

// if the price slippage exceeds this number, force the user to type "confirm" to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(
  JSBI.BigInt(1000),
  BIPS_BASE,
); // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE); // 15%

// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE); // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE); // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE); // 5%

export function formatExecutionPrice(trade?: Trade, inverted?: boolean): string {
  if (!trade) {
    return "";
  }
  return inverted
    ? `${trade.executionPrice.invert().toSignificant(6)} ${trade.inputAmount.currency.symbol} / ${
        trade.outputAmount.currency.symbol
      }`
    : `${trade.executionPrice.toSignificant(6)} ${trade.outputAmount.currency.symbol} / ${
        trade.inputAmount.currency.symbol
      }`;
}
export default function FormattedPriceImpact({ priceImpact }: { priceImpact?: Percent }) {
  return priceImpact
    ? priceImpact.lessThan(ONE_BIPS)
      ? "<0.01%"
      : `${roundToFourDecimals(priceImpact)}%`
    : "-";
}
export function warningSeverity(priceImpact: Percent | undefined): 0 | 1 | 2 | 3 | 4 {
  if (!priceImpact?.lessThan(BLOCKED_PRICE_IMPACT_NON_EXPERT)) return 4;
  if (!priceImpact?.lessThan(ALLOWED_PRICE_IMPACT_HIGH)) return 3;
  if (!priceImpact?.lessThan(ALLOWED_PRICE_IMPACT_MEDIUM)) return 2;
  if (!priceImpact?.lessThan(ALLOWED_PRICE_IMPACT_LOW)) return 1;
  return 0;
}
