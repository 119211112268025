import BigNumber from "bignumber.js";
import React, { useContext, useMemo, useState } from "react";

import CurrencyIcon from "components/UI/CurrencyIcon";
import CustomInput from "components/UI/CustomInput";
import CustomSubmenuLink from "components/UI/CustomSubmenuLink";
import FormValidationWarning from "components/UI/FormValidationWarning";
import PrimaryButton from "components/UI/PrimaryButton";
import SubmenuCard from "components/UI/SubmenuCard";
import WarningModal from "components/UI/WarningModal";
import copy from "constants/copy.json";
import { BorrowPageContext } from "contexts/borrow/borrowContext";
import { useTokenPrice } from "hooks/useTokenPrice";
import { useBNMethods } from "hooks/useBNMethods";

import { roundToFourDecimals } from "utils/helperFunctions";

export const CollateralAmountSubmenu = () => {
  const collateralSelectionScreen = copy.pages.borrow.main.form.step4.collateralSelectionScreen;
  const { borrowRequest, setBorrowRequest, setSubmenu, loanTerms } = useContext(BorrowPageContext);
  const currentLoan = loanTerms[0];

  const { collateralAmount, collateralWith, lendWith } = borrowRequest;
  const lendingBNMethods = useBNMethods(lendWith);
  const collateralBNMethods = useBNMethods(collateralWith);

  const loanSizeNum = useMemo(() => {
    const loanSize = lendingBNMethods.convertToString(currentLoan.maxLoanAmount);
    return loanSize;
  }, [currentLoan.maxLoanAmount, lendingBNMethods]);

  const lendTokenPrice = useTokenPrice(lendWith);
  const collateralTokenPrice = useTokenPrice(collateralWith);

  const lendToCollateralExchangeRate = useMemo(() => {
    return lendTokenPrice / collateralTokenPrice;
  }, [lendTokenPrice, collateralTokenPrice]);

  const minCollateralAmount = collateralBNMethods.convertToString(
    borrowRequest.collateralInfo.collateralTokensNeeded,
  );

  const [newCollateralAmount, setNewCollateralAmount] = useState(
    collateralAmount || minCollateralAmount,
  );

  const percents = useMemo(() => {
    return newCollateralAmount && lendToCollateralExchangeRate && loanSizeNum
      ? Math.round(
          (Number(newCollateralAmount) / loanSizeNum) *
            (1 / lendToCollateralExchangeRate) *
            1000000,
        ) / 10000
      : 0;
  }, [newCollateralAmount, lendToCollateralExchangeRate, loanSizeNum]);

  const [warning, setWarning] = useState("");
  const [showModal, setShowModal] = useState(false);

  const submitNewValue = () => {
    let newestCollateralAmount = newCollateralAmount;
    if (Number(newCollateralAmount) < Number(minCollateralAmount)) {
      newestCollateralAmount = minCollateralAmount;
    }
    setBorrowRequest({
      ...borrowRequest,
      collateralAmount: newestCollateralAmount,
      approved: false,
      transferred: false,
    });
    setSubmenu(null);
  };

  return (
    <SubmenuCard
      title={collateralSelectionScreen.title}
      onCloseAction={() => {
        setSubmenu(null);
      }}
    >
      <div className="mt-4 flex flex-col">
        <WarningModal
          show={showModal}
          proceed={submitNewValue}
          cancel={() => {
            setSubmenu(null);
          }}
          text={collateralSelectionScreen.collateralChangeWarningMessage}
        />
        <div className="mb-4">{collateralSelectionScreen.description}</div>

        <CurrencyIcon currency={collateralWith} className="h-5 mx-auto my-2" />
        <CustomInput
          onChangeFunction={(e: any) => {
            if (e.target.value < minCollateralAmount) {
              setWarning(
                `${collateralSelectionScreen.minimumAmountWarning} ${minCollateralAmount} ${collateralWith}`,
              );
            } else setWarning("");
            setNewCollateralAmount(parseFloat(e.target.value) || 0);
          }}
          value={newCollateralAmount.toString()}
          type="number"
        />
        <div className="text-lightest-gray text-lg xs:text-md ">{percents}%</div>
        <div className="my-4">
          <PrimaryButton
            onClick={() => {
              if (borrowRequest.approved) {
                setShowModal(true);
              } else {
                submitNewValue();
              }
            }}
            text="Enter"
          />
        </div>
        <FormValidationWarning message={warning} />
      </div>
    </SubmenuCard>
  );
};

type CollateralAmountProps = {
  page?: string;
};
export function CollateralAmountSelection(): JSX.Element {
  const { borrowRequest, setSubmenu } = useContext(BorrowPageContext);

  const { collateralAmount, collateralWith, collateralInfo } = borrowRequest;
  const collateralBNMethods = useBNMethods(collateralWith);

  const title = useMemo(() => {
    if (!collateralInfo) return;
    if (collateralAmount) {
      return `${
        Number(collateralAmount) ? roundToFourDecimals(collateralAmount) : "-"
      } ${collateralWith}`;
    } else {
      return roundToFourDecimals(
        collateralBNMethods.convertToString(collateralInfo.collateralTokensNeeded),
      );
    }
  }, [collateralAmount, collateralInfo]);

  return (
    <CustomSubmenuLink
      title={title}
      CTA={() => {
        setSubmenu("CollateralAmount");
      }}
    />
  );
}
export default CollateralAmountSelection;
