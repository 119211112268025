import BigNumber from "bignumber.js";
import React, { useContext, useEffect, useMemo, useState } from "react";

import BR from "components/UI/BR";
import { CustomSubmitButton } from "components/UI/CustomSubmitButton";
import PrimaryButton from "components/UI/PrimaryButton";
import TableRow from "components/UI/TableRow";
import { NULL_ADDRESS } from "constants/constants";
import copy from "constants/copy.json";
import { DAYS } from "contexts/borrow/borrowContext";
import { BorrowRepayContext } from "contexts/dashboard/dashboardContext";
import { roundToFourDecimals } from "utils/helperFunctions";

import ViewContractLink from "../ViewContractLink";
import { useTellerContext } from "contexts/global/tellerContext";
import { useTransactionHandler } from "hooks/useTransactionHandler";
import { useApprove } from "actions/Approve";
import { useBalance } from "hooks/useBalance";
import { useRepayLoan } from "actions/RepayLoan";
import { useEscrowBalances } from "contexts/global/loansContext";
import { useDeepCompareEffect } from "react-use";
import { useBNMethods } from "hooks/useBNMethods";
import { useAllowance } from "hooks/useAllowance";

BigNumber.config({ EXPONENTIAL_AT: 1e9 });

const RepaySelectedLoan = () => {
  const { setRepaying, selectedLoan, setRepaySuccess, setSelectedLoan } =
    useContext(BorrowRepayContext);
  const {
    terms,
    amountBorrowed,
    token,
    collateralToken,
    totalOwedAmount,
    statusName,
    timeTillExpires,
    currentCollateralPercent,
    collateralAmount,
    loanType,
    IDNumber,
  } = selectedLoan;

  const collateralTokenBNMethods = useBNMethods(collateralToken);
  const lendingTokenBNMethods = useBNMethods(token);
  const userWalletBalance = useBalance(token);

  const totalOwedAmountBN = lendingTokenBNMethods.convertToShortBN(totalOwedAmount);
  const amountBorrowedBN = lendingTokenBNMethods.convertToShortBN(amountBorrowed);
  const collateralAmountBN = collateralTokenBNMethods.convertToShortBN(collateralAmount);

  const { collateralBuffer } = useTellerContext();

  const escrow = useEscrowBalances(selectedLoan ? selectedLoan.escrow : null);
  useDeepCompareEffect(() => {
    if (!escrow) return;
    let mounted = true;

    (async () => {
      if (mounted) {
        setSelectedLoan({ ...selectedLoan, escrowBalances: escrow });
      }
    })();
    return () => {
      mounted = false;
    };
  }, [escrow]);

  const allowance = useAllowance(token);
  const allowanceBN = allowance ? new BigNumber(allowance) : new BigNumber(0);

  const [approved, setApproved] = useState(false);

  useEffect(() => {
    if (!allowanceBN || !totalOwedAmountBN) return;
    setApproved(allowanceBN.isGreaterThanOrEqualTo(totalOwedAmountBN));
  }, [allowanceBN, totalOwedAmountBN]);

  const transactionHandler = useTransactionHandler("repayLoan", setRepaySuccess, setRepaying);
  const { repayLoan } = useRepayLoan();
  const hasEscrow = !(selectedLoan.escrow == NULL_ADDRESS);

  const onRepayLoan = async () => {
    setRepaying("loading");
    try {
      await repayLoan(IDNumber, totalOwedAmount, hasEscrow, transactionHandler);
    } catch (err) {
      console.log(err);
      setRepaying(null);
    }
  };
  const notEnoughBalance = hasEscrow
    ? selectedLoan.escrowBalances
      ? Boolean(
          new BigNumber(selectedLoan.escrowBalances[selectedLoan.token])
            .plus(userWalletBalance)
            .isLessThan(amountBorrowedBN),
        )
      : false
    : Boolean(new BigNumber(userWalletBalance).isLessThan(amountBorrowedBN));

  const pageCopy = copy.pages.dashboard["borrow-repay"];

  // const unclaimedTokens = [];
  // if (selectedLoan.escrow != NULL_ADDRESS && selectedLoan.escrowBalances) {
  //   for (const [key, value] of Object.entries(selectedLoan.escrowBalances)) {
  //     if (value && value > 0) {
  //       unclaimedTokens.push({ key, value });
  //     }
  //   }
  // }

  return (
    <div>
      <div className="text-left">Loan terms</div>
      <div className="border-thin mb-4 mt-3">
        <TableRow title="Daily interest rate">
          <div className="font-medium">
            {(Number(terms.interestRate) / 100 / 365).toFixed(2)} %{" "}
          </div>
        </TableRow>
        <BR />
        <TableRow
          title="Yearly interest rate"
          tooltip_text="The total interest accrued over the duration of your loan term."
        >
          <div className="font-medium"> {Number(terms.interestRate) / 100} % </div>
        </TableRow>
        <BR />
        {terms.collateralRatio > 0 && (
          <>
            <TableRow title="Collateral ratio">
              <div className="font-medium">{`${Number(terms.collateralRatio) / 100} %`}</div>
            </TableRow>
            <BR />
            <TableRow
              title="Liquidation ratio"
              tooltip_text="Loans that fall below the liquidation ratio, or the total value of the outstanding loan, will be automatically liquidated."
            >
              <div className="font-medium">
                {`${Math.round(Number(terms.collateralRatio) / 100 - 15)} %`}
              </div>
            </TableRow>
            <BR />
          </>
        )}
        <TableRow title={pageCopy.selectedLoan.loanSize}>
          <div className="font-medium">
            {roundToFourDecimals(Number(amountBorrowedBN))} {token}
          </div>
        </TableRow>
        <BR />
        <TableRow title={pageCopy.selectedLoan.loanTerm}>
          <div className="font-medium">
            {terms.duration / DAYS} {terms.duration / DAYS == 1 ? "day" : "days"}
          </div>
        </TableRow>
        <BR />
        <TableRow title={pageCopy.selectedLoan.loanType}>
          <div className="font-medium">{loanType}</div>
        </TableRow>
        {selectedLoan.nfts.length > 0 && (
          <>
            <BR />
            <TableRow title="Linked NFTs">
              <div className="font-medium flex flex-col">
                {selectedLoan.nfts.map((nft) => {
                  return <div key={nft.id}>{nft.id}</div>;
                })}
              </div>
            </TableRow>
          </>
        )}
      </div>

      <div className="text-left mt-12">Loan health</div>
      <div className=" border-thin mb-4 mt-3">
        <TableRow title={pageCopy.selectedLoan.status}>
          <div className="font-medium">{statusName}</div>
        </TableRow>
        <BR />
        {statusName !== "Repaid" && (
          <div>
            <TableRow title="Time remaining" tooltip_text="Time remaining on loan term to date.">
              {statusName === "Overdue" && (
                <div className="font-medium">
                  {`${-timeTillExpires} ${timeTillExpires == 1 ? "day" : "days"} overdue`}
                </div>
              )}
              {statusName === "Outstanding" && (
                <div className="font-medium">
                  {`${timeTillExpires} ${timeTillExpires == 1 ? "day" : "days"} remaining`}
                </div>
              )}
            </TableRow>
            <BR />
          </div>
        )}
        {selectedLoan.statusName !== "Repaid" && collateralAmountBN.isGreaterThan(0) && (
          <>
            <TableRow title="Collateral value">
              <div className="font-medium">{Math.round(currentCollateralPercent)} %</div>
            </TableRow>
            <BR />
            <TableRow
              title={pageCopy.selectedLoan.collateralAmount}
              tooltip_text="You can increase your collateral amount to reduce liquidation risk."
            >
              <div className="font-medium">
                {roundToFourDecimals(collateralAmountBN.toNumber())} {collateralToken}
              </div>
            </TableRow>
            <BR />
            <TableRow title="Away from liquidation">
              <div className="font-medium">
                {roundToFourDecimals(
                  Math.round(
                    selectedLoan.currentCollateralPercent -
                      selectedLoan.terms.collateralRatio / 100 +
                      collateralBuffer,
                  ),
                )}
                %
              </div>
            </TableRow>
            <BR />
          </>
        )}

        {statusName !== "Repaid" && (
          <>
            <TableRow
              title={pageCopy.selectedLoan.amountOwed}
              tooltip_text="Total amount owed from interest accrued."
            >
              <div className="font-medium">
                {totalOwedAmountBN.isLessThan(0.0001) && totalOwedAmountBN.isGreaterThan(0)
                  ? "<0.0001"
                  : roundToFourDecimals(totalOwedAmountBN.toFixed(4, 1))}{" "}
                {token}
              </div>
            </TableRow>
            <BR />
            <ApproveButton setApproved={setApproved} approved={approved} />
            {notEnoughBalance && (
              <div className="text-xs text-gray text-right pr-4 pb-2">
                {hasEscrow
                  ? `Not enough balance in escrow. Please convert other assets into ${token}`
                  : "Not enough balance"}
              </div>
            )}
          </>
        )}
      </div>
      <ViewContractLink hash={selectedLoan.escrow} />
      {statusName !== "Repaid" && (
        <div>
          {/* <FormValidationWarning message="Withdraw assets from Compound and/or sell on Uniswap." /> */}
          <PrimaryButton
            text={pageCopy.selectedLoan.CTA}
            onClick={() => {
              if (approved) {
                onRepayLoan();
              }
            }}
            disabled={!approved || notEnoughBalance}
          />
        </div>
      )}

      {/* {statusName == "Repaid" && selectedLoan.unclaimedTokens.length > 0 && !claimed && (
        <>
          <div>
            <div className="text-left mt-12">Unclaimed Tokens</div>
            <div className=" border-thin mb-4 mt-3">
              {selectedLoan.unclaimedTokens.map((token) => {
                return (
                  <div key={token.key}>
                    <TableRow title={token.key}>
                      <div className="font-medium">{roundToFourDecimals(token.value)}</div>
                    </TableRow>
                    <BR />
                  </div>
                );
              })}
            </div>
          </div>
          <PrimaryButton
            text="Claim tokens"
            onClick={() => {
              setClaiming(true);
              ClaimTokens(
                tellerDiamond,
                web3State.address,
                claimTransactionHandler,
                selectedLoan.IDNumber.toString(),
              );
            }}
            disabled={claiming}
          />
        </>
      )} */}
    </div>
  );
};

export default RepaySelectedLoan;

const ApproveButton = ({ setApproved, approved }: any) => {
  const [loading, setLoading] = useState(false);
  const { selectedLoan } = useContext(BorrowRepayContext);
  const { totalOwedAmount, token } = selectedLoan;
  const { approve } = useApprove(token);
  const transactionHandler = useTransactionHandler("approve", setApproved, setLoading);

  const onApproveClick = async () => {
    if (approveDisabled) return;
    setLoading(true);
    try {
      await approve(transactionHandler, totalOwedAmount);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  const approveDisabled = approved || loading;
  return (
    <>
      <TableRow title="Approve">
        <CustomSubmitButton
          text="Submit"
          CTA={onApproveClick}
          disabled={approveDisabled}
          loading={loading}
          approved={approved}
        />
      </TableRow>
    </>
  );
};
