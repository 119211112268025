import React, { useContext } from "react";

import Card from "components/UI/Card";
import { DashboardContext } from "contexts/dashboard/dashboardContext";

import CompoundFinance from "./CompoundFinance";
import Uniswap from "./Uniswap";

import Aave from "./Aave";
import PoolTogether from "./PoolTogether";
import Sushiswap from "./Sushiswap";
import Yearn from "./Yearn";
import { useWeb3StateContext } from "contexts/global/web3StateContext";
import { useNetworkConfig } from "hooks/useNetworkConfig";

const Use = () => {
  const { onPage, loans } = useContext(DashboardContext);
  const subSection = onPage.split("-")[1];

  const { dapps } = useNetworkConfig();

  return (
    <div>
      {loans && loans.length > 0 ? (
        <>
          {dapps.includes(subSection) && subSection == "compound" && <CompoundFinance />}
          {dapps.includes(subSection) && subSection == "yearn" && <Yearn />}
          {dapps.includes(subSection) && subSection == "aave" && <Aave />}
          {/* {dapps.includes(subSection) && subSection == "pooltogether" && <PoolTogether />} */}
          {dapps.includes(subSection) && subSection == "sushiswap" && <Sushiswap />}
          {dapps.includes(subSection) && subSection == "uniswap" && <Uniswap />}
        </>
      ) : (
        <Card title={""} className="main-card bg-teal-lightest  text-center  ">
          <div>No Loans</div>
        </Card>
      )}
    </div>
  );
};

export default Use;
