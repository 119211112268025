import BigNumber from "bignumber.js";
import { useWeb3StateContext } from "contexts/global/web3StateContext";
import { useEffect, useState } from "react";
import { api, util } from "@compound-finance/compound-js";
import { AvailableTokens } from "contexts/types";

export const useCompAPY = (symbol: AvailableTokens) => {
  const { network } = useWeb3StateContext();
  const [APY, setAPY] = useState(null);

  useEffect(() => {
    let mounted = true;

    (async () => {
      const cToken = await api.cToken({
        network: String(network.name),
        addresses: util.getAddress(`c${symbol.toUpperCase()}`, String(network.name)),
      });
      const { value: supplyRate } = cToken.cToken[0].supply_rate;
      const apy = new BigNumber(supplyRate).shiftedBy(2).toString();
      if (mounted) setAPY(apy);
    })();

    return () => {
      mounted = false;
    };
  }, [network]);

  return APY;
};
