import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { AvailableTokens } from "contexts/types";
import { useAddress, useERC20TokenContract } from "hooks/useContract";

const defaultTokensState = {
  DAI: null,
  USDC: null,
  LINK: null,
  WBTC: null,
  USDT: null,
  WETH: null,
  AAVE: null,
  WMATIC: null,
  MATIC: null,
  ETH: null,
  SNX: null,
  MKR: null,
  YFI: null,
};

export const TokensContext = createContext<any>(defaultTokensState);

export const TokensContextProvider = ({ children }: any) => {
  const DAI = useToken("DAI");
  const USDC = useToken("USDC");
  const LINK = useToken("LINK");
  const WBTC = useToken("WBTC");
  const USDT = useToken("USDT");
  const WETH = useToken("WETH");
  const ETH = useToken("ETH");
  const AAVE = useToken("AAVE");
  const WMATIC = useToken("WMATIC");
  const MATIC = useToken("MATIC");
  const SNX = useToken("SNX");
  const MKR = useToken("MKR");
  const YFI = useToken("YFI");

  const value = useMemo(() => {
    return {
      DAI,
      USDC,
      LINK,
      WBTC,
      USDT,
      WETH,
      AAVE,
      WMATIC,
      MATIC,
      ETH,
      SNX,
      MKR,
      YFI,
    };
  }, [DAI, USDC, LINK, WBTC, USDT, WETH, AAVE, WMATIC, MATIC, ETH, SNX, MKR, YFI]);
  return <TokensContext.Provider value={value}>{children}</TokensContext.Provider>;
};

export const useTokensContext = () => {
  const context = useContext(TokensContext);
  if (context === undefined) {
    throw new Error("useTokens must be used within a useTokensProvider");
  }
  return context;
};

const useToken = (tokenID: AvailableTokens) => {
  const tokenContract = useERC20TokenContract(tokenID);
  const tokenAddress = useAddress(tokenID, "token");
  const [tokenDecimals, setDecimals] = useState(null);

  useEffect(() => {
    let isMounted = true;
    if (
      tokenID == "ETH" ||
      tokenID == "WETH" ||
      tokenID == "AAVE" ||
      tokenID == "MATIC" ||
      tokenID == "WMATIC" ||
      tokenID == "DAI" ||
      tokenID == "LINK" ||
      tokenID == "MKR" ||
      tokenID == "SNX" ||
      tokenID == "YFI"
    ) {
      if (isMounted) setDecimals(18);
      return;
    }
    if (tokenID == "USDT" || tokenID == "USDC") {
      if (isMounted) setDecimals(6);
      return;
    }
    if (tokenID == "WBTC") {
      if (isMounted) setDecimals(8);
      return;
    }
    if (!tokenContract || !tokenAddress) return;
    (async () => {
      try {
        const decimals = await tokenContract.methods.decimals().call();
        if (isMounted) setDecimals(Number(decimals));
      } catch (err) {
        console.log(tokenID, " - cannot get decimals ");
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [tokenContract, tokenID, tokenAddress, tokenDecimals]);

  return useMemo(() => {
    return {
      tokenAddress,
      tokenDecimals,
      tokenContract,
    };
  }, [tokenAddress, tokenDecimals, tokenContract]);
};
