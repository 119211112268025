import { useUIContext } from "contexts/global/uiContext";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useDarkNav = () => {
  const { darkNav, setDarkNav } = useUIContext();
  const location = useLocation();

  useEffect(() => {
    if (!location) return;
    if (location.pathname == "/liquidity") {
      setDarkNav(true);
    } else {
      setDarkNav(false);
    }
  }, [location]);

  return { darkNav, setDarkNav };
};

export default useDarkNav;
