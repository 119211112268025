import { useNFTsContext } from "contexts/global/nftsContext";
import { useWeb3StateContext } from "contexts/global/web3StateContext";
import { createNewTransactionWithHandler } from "actions/utils";
import { useCallback } from "react";
import { useTellerContext } from "contexts/global/tellerContext";
import { BigNumber } from "ethers";

export const useStakeNFT = () => {
  const { tellerDiamondContract, tellerNFTV2Contract } = useTellerContext();
  const { userAddress } = useWeb3StateContext();

  const stakeNFT = useCallback(
    async (stakeTransactionHandler: any, IDs: string[], type: "V1" | "V2") => {
      if (type == "V1") {
        await createNewTransactionWithHandler(
          () => tellerDiamondContract.methods.stakeNFTs(IDs).send({ from: userAddress }),
          stakeTransactionHandler,
        );
      } else {
        const addresses = new Array(IDs.length).fill(userAddress);
        const balances = await tellerNFTV2Contract.methods.balanceOfBatch(addresses, IDs).call();
        await createNewTransactionWithHandler(
          () =>
            tellerNFTV2Contract.methods
              .safeBatchTransferFrom(
                userAddress,
                tellerDiamondContract.options.address,
                IDs,
                balances,
                "0x",
              )
              .send({ from: userAddress }),
          stakeTransactionHandler,
        );
      }
    },
    [tellerDiamondContract, tellerNFTV2Contract, userAddress],
  );

  const unstakeNFT = useCallback(
    async (unstakeTransactionHandler: any, IDs: string[], type: "V1" | "V2") => {
      if (type == "V1") {
        await createNewTransactionWithHandler(
          () => tellerDiamondContract.methods.unstakeNFTs(IDs).send({ from: userAddress }),
          unstakeTransactionHandler,
        );
      } else {
        const stakedV2balances = await tellerDiamondContract.methods
          .getStakedNFTsV2(userAddress)
          .call();
        const balances = IDs.map((ID) => {
          const index = stakedV2balances[0].findIndex((idBN: BigNumber) => idBN.toString() == ID);
          if (index < 0) throw new Error("Could not find staked index");
          return stakedV2balances[1][index];
        });
        await createNewTransactionWithHandler(
          () =>
            tellerDiamondContract.methods.unstakeNFTsV2(IDs, balances).send({ from: userAddress }),
          unstakeTransactionHandler,
        );
      }
    },
    [tellerDiamondContract, tellerNFTV2Contract, userAddress],
  );

  return {
    stakeNFT,
    unstakeNFT,
  };
};
