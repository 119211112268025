import "./processing-screen.scss";

import React, { useContext } from "react";

import ProcessingAnimation from "components/UI/ProcessingAnimation";
import TransactionHashLink from "components/UI/TransactionHashLink";

interface props {
  hash: string;
  title: string;
  fullScreen?: boolean;
}

const ProcessingScreen = ({ hash, title, fullScreen = true }: props) => {
  return (
    <div
      className={`${
        fullScreen ? "processing-screen xs:my-6 sm:my-10 md:my-24 lg:self-start" : "py-2"
      } align-self-center  flex items-center justify-center flex-col`}
    >
      <div className={`${fullScreen ? "text-5xl xs:text-4xl " : "-my-2 text-3xl xs:text-2xl "}`}>
        <ProcessingAnimation height={fullScreen ? 200 : 150} />
      </div>
      <div
        className={`${
          fullScreen ? "text-5xl xs:text-4xl " : "text-4xl xs:text-3xl"
        }  processing-text font-medium`}
      >
        {title}
      </div>
      {hash && hash !== "loading" && (
        <div className="text-gray m-3">
          <>{"View your transaction status  "}</>
          <TransactionHashLink hash={hash} />.
        </div>
      )}
    </div>
  );
};

export default ProcessingScreen;
