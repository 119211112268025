import { useEffect, useState } from "react";
import { AvailableTokens } from "contexts/types";
import { useTToken } from "contexts/global/tellerContext";
import { useBNMethods } from "./useBNMethods";

export const useUnderlyingBalance = (assetID: AvailableTokens) => {
  const [underlyingBalance, setUnderlyingBalance] = useState(null);
  const tToken = useTToken(assetID);
  const { convertToString } = useBNMethods(assetID);
  useEffect(() => {
    if (!tToken.tTokenContract) return;
    let mounted = true;
    (async () => {
      const underlyingBalanceBN = await tToken.tTokenContract.methods
        .totalUnderlyingSupply()
        .call();
      const underlyingBalance = convertToString(underlyingBalanceBN);
      if (mounted) {
        setUnderlyingBalance(underlyingBalance);
      }
    })();

    return () => {
      mounted = false;
    };
  }, [tToken, convertToString, assetID]);

  return underlyingBalance;
};
