import "./success-screen.scss";

import React from "react";
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";

import LoadingAnimation from "components/UI/LoadingAnimation";
import PrimaryButton from "components/UI/PrimaryButton";

interface successProps {
  title: string;
  message: React.ReactNode;
  onButtonClick?: any;
  fullScreen?: boolean;
  CTA?: string;
  redirect?: string;
}

const SuccessScreen = ({
  title,
  message,
  onButtonClick,
  fullScreen = true,
  CTA = "Go to dashboard",
  redirect = "",
}: successProps) => {
  return (
    <div
      className={`align-self-center flex items-center justify-center flex-col ${
        fullScreen
          ? "cards-container success-screen xs:my-6 sm:my-10 md:my-24 lg:self-start lg:justify-start"
          : "py-2"
      } "
       `}
    >
      <div className="flex items-center	 justify-center flex-col">
        <div className="text-5xl xs:text-4xl m-4">
          <LoadingAnimation isStopped={false} animation="success" />
        </div>
        <div
          className={`${fullScreen ? "text-5xl xs:text-4xl" : "text-4xl xs:text-3xl"} font-medium`}
        >
          {title}
        </div>
        <div className="text-gray m-3">{message}</div>
        {redirect ? (
          <Link to={redirect}>
            <PrimaryButton onClick={onButtonClick} text={CTA} />
          </Link>
        ) : (
          <PrimaryButton onClick={onButtonClick} text={CTA} />
        )}
      </div>
    </div>
  );
};

export default SuccessScreen;
