import { useNFTsContext } from "contexts/global/nftsContext";
import React from "react";
import NFTDashboardAccordionMobileCard from "./NFTDashboardAccordionMobileCard";

const NFTDashboardAccordionMobile = () => {
  const { allClaimedNFTs } = useNFTsContext();

  const nfts = allClaimedNFTs.map((nft: any) => nft.id);
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 grid-flow-row lg:hidden gap-x-2">
      {nfts.map((id: any) => {
        return <NFTDashboardAccordionMobileCard id={id} key={id} />;
      })}
    </div>
  );
};

export default NFTDashboardAccordionMobile;
