import React, { useContext } from "react";

import ProcessingScreen from "components/ProcessingScreen/ProcessingScreen";
import SuccessScreen from "components/SuccessScreen/SuccessScreen";
import Card from "components/UI/Card";
import TransactionHashLink from "components/UI/TransactionHashLink";
import copy from "constants/copy.json";
import {
  BorrowDepositContext,
  BorrowDepositContextProvider,
} from "contexts/dashboard/dashboardContext";

import DepositMainSection from "./DepositMainSection";

const DepositPage = () => {
  const { success, setSuccess, isDepositing, setSelectedLoan, selectedLoan } =
    useContext(BorrowDepositContext);
  const goBack = () => {
    setSelectedLoan(null);
  };
  const { header } = copy.pages.dashboard["borrow-deposit"];

  return (
    <div>
      <Card
        className="main-card bg-teal-lightest text-center"
        title={header}
        goBack={selectedLoan ? goBack : null}
        dashboard={true}
        headerRightColumnValue={selectedLoan ? `ID ${selectedLoan.id}` : ""}
      >
        {success && (
          <SuccessScreen
            fullScreen={false}
            title="Deposit accepted"
            onButtonClick={() => {
              setSuccess(null);
              setSelectedLoan(null);
            }}
            message={
              <div>
                <>View your transaction status {"  "}</>
                <TransactionHashLink hash={success} />
              </div>
            }
            CTA="Go back"
          />
        )}
        {isDepositing && (
          <ProcessingScreen hash={isDepositing} fullScreen={false} title="Depositing collateral" />
        )}
        {!success && !isDepositing && <DepositMainSection />}
      </Card>
    </div>
  );
};

const Deposit = () => {
  return (
    <BorrowDepositContextProvider>
      <DepositPage />
    </BorrowDepositContextProvider>
  );
};

export default Deposit;
