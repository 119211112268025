import BigNumber from "bignumber.js";
import { bufferToHex, fromRpcSig } from "ethereumjs-util";
import React, { useContext, useMemo } from "react";

import PrimaryButton from "components/UI/PrimaryButton";
import { allContractAddresses } from "constants/constants";
import copy from "constants/copy.json";
import { BorrowPageContext, DAYS } from "contexts/borrow/borrowContext";
import { usePlaidContext } from "contexts/global/plaidContext";
import { useWeb3StateContext } from "contexts/global/web3StateContext";
import { getNodeSignaturesForBorrowing, PBorrow } from "utils/getNodeSignaturesForBorrowing";
import BorrowFormStage1Table from "./BorrowFormStage1Form";
import LoginButton from "components/LoginButton/LoginButton";
import { useTellerContext } from "contexts/global/tellerContext";
import { useBNMethods } from "hooks/useBNMethods";
import { useTakeOutLoan } from "actions/TakeOutLoan";
import { useTransactionHandler } from "hooks/useTransactionHandler";
import FormValidationWarning from "components/UI/FormValidationWarning";

BigNumber.config({ EXPONENTIAL_AT: 1e9 });
const BorrowFormStage1 = () => {
  const {
    setStage,
    borrowRequest,
    setBorrowRequest,
    borrowProcessState,
    loanTerms,
    NFTs,
    setNodeResponses,
    setLoanTerms,
    maxAmountExceeded,
  } = useContext(BorrowPageContext);
  const { takeOutLoanWithNFTs } = useTakeOutLoan(borrowRequest.lendWith);
  const { setRequestingTerms } = borrowProcessState;
  const { tellerDiamondContract, tellerPriceAggregatorContract } = useTellerContext();
  const { plaid } = usePlaidContext();
  const { userAddress } = useWeb3StateContext();

  const loanTermsErrorMessage = loanTerms[0].message;
  const loggedIn = !!userAddress;
  const isSecured = !!(borrowRequest.loanType === "Secured");
  const plaidConnected = !!plaid.loggedIn;

  const requestTermsCTA = async () => {
    if (
      maxAmountExceeded ||
      !tellerDiamondContract ||
      !(borrowRequest.loanSize > 0) ||
      loanTermsErrorMessage === "OUTSTANDING_UNSECURED_LOAN" ||
      loanTermsErrorMessage === "BORROWER_NOT_WHITELISTED"
    )
      return;
    await requestTerms();
  };
  const nftIsSelected = NFTs ? NFTs.some((nft: any) => nft.selected == true) : false;

  const selectedNFTs = NFTs
    ? NFTs.filter((NFT: any) => {
        return NFT.selected == true;
      })
    : null;

  const NFTOrBankConnected = isSecured ? true : !!(nftIsSelected || plaidConnected);

  const { network } = useWeb3StateContext();
  const { convertToBNString, convertToBN } = useBNMethods(borrowRequest.lendWith);
  const { setRequestingLoan, setSuccess } = borrowProcessState;
  const requestLoanTransactionHandler = useTransactionHandler(
    "takeOutLoanWithNFT",
    setSuccess,
    setRequestingLoan,
  );
  const selectedNFTIDs = selectedNFTs
    ? selectedNFTs.map((nft: any) => {
        return { id: nft.id, type: nft.type };
      })
    : [];
  const loanTerm = Number(borrowRequest.loanTerm);
  const loanSizeBN = convertToBN(borrowRequest.loanSize);

  const requestLoanWithNFT = async () => {
    setRequestingLoan("loading");
    try {
      await takeOutLoanWithNFTs(
        requestLoanTransactionHandler,
        selectedNFTIDs,
        loanSizeBN,
        loanTerm,
      );
    } catch (err) {
      console.log(err);
      setRequestingLoan(null);
    }
  };

  const requestTerms = async () => {
    setRequestingTerms("loading");
    try {
      const requestTime = String(Math.floor(Date.now() / 1000 - 121));
      const lendingApplication = {
        borrowedAsset: borrowRequest.lendWith,
        collateralAsset: borrowRequest.collateralWith,
        requestedLoanSize: convertToBNString(borrowRequest.loanSize),
        collateralRatioEntered: String(borrowRequest.collateralPercent * 100),
        ethereumWallet: userAddress,
        providerTokens: plaid.dataProviderResponse.providerTokens,
        loanTermLength: String(borrowRequest.loanTerm * DAYS),
        loanUse: borrowRequest.loanType.toUpperCase(),
        requestTime,
      };

      setBorrowRequest({
        ...borrowRequest,
        requestTime: requestTime,
      });
      const requestNonce = new BigNumber(
        (await tellerDiamondContract.methods.getBorrowerLoans(userAddress).call()).length,
      ).toString();
      const nodeResponsesAll = await getNodeSignaturesForBorrowing(
        requestNonce,
        lendingApplication as PBorrow,
        network.id.toString(),
      );
      const nodeResponses = nodeResponsesAll;
      const successfulResponses = [];
      let loanTerms;
      if (nodeResponses[0].message && nodeResponses[0].message == "BORROWER_NOT_WHITELISTED") {
        successfulResponses.push({
          message: "BORROWER_NOT_WHITELISTED",
        });
        setRequestingTerms(null);
        loanTerms = successfulResponses;
        return;
      }
      if (nodeResponses[0].message && nodeResponses[0].message == "OUTSTANDING_UNSECURED_LOAN") {
        successfulResponses.push({
          message: "OUTSTANDING_UNSECURED_LOAN",
        });
        setRequestingTerms(null);
        loanTerms = successfulResponses;
        return;
      }
      for (let i = 0; i < nodeResponses.length; i++) {
        if (nodeResponses[i].requestHash) {
          successfulResponses.push(nodeResponses[i]);
        }
      }
      if (successfulResponses.length == 0) {
        successfulResponses.push({ message: "Nodes out of sync. Please try again." });
        setRequestingTerms(null);
        loanTerms = successfulResponses;
        return;
      }
      const updatedNodeResponses = successfulResponses.map((response: any) => {
        response.maxLoanAmount = new BigNumber(response.maxLoanAmount).toString();
        return response;
      });
      loanTerms = updatedNodeResponses;

      const updateCollateralNeededInfo = async () => {
        const collateralTokensNeeded =
          ((Number(lendingApplication.requestedLoanSize) *
            Number(nodeResponsesAll[0].collateralRatio)) /
            10000) *
          1.02;
        const addresses = allContractAddresses[network.name].tokens;

        let dst;
        if (borrowRequest.collateralWith === "ETH" || borrowRequest.collateralWith === "MATIC") {
          dst = `W${borrowRequest.collateralWith}`;
        } else {
          dst = borrowRequest.collateralWith;
        }

        const collateralTokensNeededConverted = await tellerPriceAggregatorContract.methods
          .getValueFor(
            addresses[borrowRequest.lendWith],
            addresses[dst],
            new BigNumber(collateralTokensNeeded).toString(),
          )
          .call();

        const collateralInfo = {
          escrowLoanValue: "",
          collateralTokensNeeded: collateralTokensNeededConverted,
          neededInLendingTokens: "",
        };
        setBorrowRequest({ ...borrowRequest, collateralInfo });
      };

      await updateCollateralNeededInfo();

      const request: any = {
        borrower: userAddress,
        assetAddress: nodeResponsesAll[0].consensusAddress,
        requestNonce: new BigNumber(
          (await tellerDiamondContract.methods.getBorrowerLoans(userAddress).call()).length,
        ).toString(),
        amount: lendingApplication.requestedLoanSize,
        duration: lendingApplication.loanTermLength,
        requestTime: lendingApplication.requestTime,
      };

      const { r, s, v } = fromRpcSig(nodeResponsesAll[0].signature);
      const responses: any = [
        {
          signer: nodeResponsesAll[0].signer,
          assetAddress: nodeResponsesAll[0].consensusAddress,
          responseTime: nodeResponsesAll[0].responseTime,
          interestRate: nodeResponsesAll[0].interestRate,
          collateralRatio: nodeResponsesAll[0].collateralRatio,
          maxLoanAmount: nodeResponsesAll[0].maxLoanAmount,
          signature: {
            v,
            r: bufferToHex(r),
            s: bufferToHex(s),
          },
        },
      ];

      setLoanTerms(loanTerms);
      setNodeResponses({ responses, request });
      setRequestingTerms(null);
      setStage(3);
      return true;
    } catch (err) {
      setRequestingTerms(null);
      console.log({ err });
      return null;
    }
  };

  return (
    <div>
      <BorrowFormStage1Table />
      {loanTermsErrorMessage && (
        <div className="mb-3">
          {loanTermsErrorMessage === "BORROWER_NOT_WHITELISTED" ? (
            <>
              <div className="mb-3">
                Your wallet is currently not whitelisted to borrow an unsecured loan. Whitelist your
                wallet{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={"https://tellerfinance.typeform.com/to/KzwxNWfD"}
                  className="link text-gray"
                >
                  <u>here</u>
                </a>
                .
              </div>
              <div>
                Note: Wallet whitelist only needed for Unsecured loans. Any wallet can access an
                undercollateralized loan.
              </div>
            </>
          ) : loanTermsErrorMessage === "OUTSTANDING_UNSECURED_LOAN" ? (
            <div>You can only have one unsecured loan at a time</div>
          ) : (
            <div>{loanTermsErrorMessage}</div>
          )}
        </div>
      )}
      {loggedIn ? (
        <>
          <PrimaryButton
            text={
              selectedNFTs && selectedNFTs.length > 0
                ? "Take Out Loan"
                : copy.pages.borrow.main.form.step2.CTA
            }
            disabled={
              maxAmountExceeded ||
              !NFTOrBankConnected ||
              !tellerDiamondContract ||
              !NFTOrBankConnected ||
              !(borrowRequest.loanSize > 0) ||
              loanTermsErrorMessage === "OUTSTANDING_UNSECURED_LOAN" ||
              loanTermsErrorMessage === "BORROWER_NOT_WHITELISTED"
            }
            onClick={selectedNFTs && selectedNFTs.length > 0 ? requestLoanWithNFT : requestTermsCTA}
          />
        </>
      ) : (
        <LoginButton />
      )}
      {maxAmountExceeded && (
        <div className="mt-6">
          <FormValidationWarning message={maxAmountExceeded} />
        </div>
      )}
    </div>
  );
};

export default BorrowFormStage1;
