import { useTellerContext } from "contexts/global/tellerContext";
import { AvailableTokens } from "contexts/types";
import React, { useState, useMemo } from "react";
import { useDeepCompareEffect } from "react-use";
import { useBNMethods } from "./useBNMethods";
import { useToken } from "./useToken";

export const useExchangeCurrency = (
  startCurrencyID: AvailableTokens,
  endCurrencyID: AvailableTokens,
  currencyAmount: number,
) => {
  const { tellerPriceAggregatorContract } = useTellerContext();
  const startCurrency = useToken(startCurrencyID);
  const endCurrency = useToken(endCurrencyID);
  const startCurrencyBNMethods = useBNMethods(startCurrencyID);
  const endCurrencyBNMethods = useBNMethods(endCurrencyID);

  const [result, setResult] = useState(0.0);
  useDeepCompareEffect(() => {
    if (startCurrencyID == endCurrencyID) {
      setResult(1 * currencyAmount);
      return;
    }
    if (
      !tellerPriceAggregatorContract ||
      !currencyAmount ||
      !startCurrency.tokenAddress ||
      !endCurrency.tokenAddress ||
      !startCurrencyBNMethods ||
      !endCurrencyBNMethods.convertToString ||
      !startCurrencyBNMethods.convertToBNString
    ) {
      setResult(null);
      return;
    }
    let isMounted = true;
    (async () => {
      if (!tellerPriceAggregatorContract) {
        setResult(null);
        return;
      }
      try {
        const currencyAmountBN = startCurrencyBNMethods.convertToBNString(currencyAmount);

        if (!currencyAmountBN) {
          setResult(null);
          return;
        }
        const priceBN = await tellerPriceAggregatorContract.methods
          .getValueFor(startCurrency.tokenAddress, endCurrency.tokenAddress, currencyAmountBN)
          .call();
        const result = priceBN ? endCurrencyBNMethods.convertToString(priceBN) : null;

        if (!result) {
          setResult(null);
          return;
        }
        if (isMounted) setResult(result);
      } catch (err) {
        console.log(err);
        setResult(null);
      }
    })();
    return () => {
      isMounted = false;
    };
  }, [
    tellerPriceAggregatorContract,
    currencyAmount,
    startCurrency,
    endCurrency,
    startCurrencyBNMethods,
  ]);

  return useMemo(() => {
    return result;
  }, [result]);
};
