import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useDeepCompareEffect } from "react-use";

import { useWeb3StateContext } from "contexts/global/web3StateContext";
import { AvailableLendingTokens } from "contexts/types";
import IAToken from "contracts/abis/IAToken.json";
import TellerNFT from "contracts/contracts-nft/TellerNFT.json";
import PrizePoolInterface from "contracts/abis/PrizePoolInterface.json";
import IERC20Detailed from "contracts/abis/IERC20Detailed.json";
import CErc20Interface from "contracts/abis/CErc20Interface.json";
import TellerNFTV2 from "contracts/contracts-nft/MainnetTellerNFT.json";
import TellerNFTDictionary from "contracts/contracts-nft/TellerNFTDictionary.json";
import NFTDistributor from "contracts/contracts-nft/TellerNFTDistributor.json";
import TTokenAbi from "contracts/diamonds/TToken_V1.json";
import PriceAggregator from "contracts/diamonds/PriceAggregator.json";
import { useBNMethods } from "hooks/useBNMethods";
import { useAddress, useContract } from "hooks/useContract";
import { useNetworkConfig } from "hooks/useNetworkConfig";
import { useToken } from "hooks/useToken";
import { useOneDayAPY } from "hooks/useOneDayApy";
import { useTellerDiamondContract } from "utils/tellerDaimondContract";

const defaultTellerState = {
  teller: {},
  setTeller: () => null as null,
};

const TellerContext = createContext<any>(defaultTellerState);

export const TellerContextProvider = ({ children }: any) => {
  const { networkSupported, collateralTokens, lendingTokens } = useNetworkConfig();
  const [collateralToken, setCollateralToken] = useState(
    collateralTokens.length > 0 ? collateralTokens[0] : null,
  );
  const [lendingToken, setLendingToken] = useState(
    lendingTokens.length > 0 ? lendingTokens[0] : null,
  );

  const defaultNavigationMap: any = {
    // DEPOSIT: { Portfolio: "deposit-portfolio", Withdraw: "deposit-withdraw" },
    BORROW: {
      Deposit: "borrow-deposit",
      Repay: "borrow-repay",
      Withdraw: "borrow-withdraw",
    },
    SPEND: {
      Compound: "spend-compound",
      Uniswap: "spend-uniswap",
      Yearn: "spend-yearn",
      Aave: "spend-aave",
      PoolTogether: "spend-pooltogether",
      Sushiswap: "spend-sushiswap",
    },
  };

  const { dapps } = useNetworkConfig();
  const [navigationMap, setNavigationMap] = useState({ ...defaultNavigationMap });

  useEffect(() => {
    const newNavigationMap = JSON.parse(JSON.stringify(defaultNavigationMap));

    Object.keys(newNavigationMap.SPEND).forEach((dapp) => {
      if (!dapps.includes(dapp.toLowerCase())) {
        delete newNavigationMap.SPEND[dapp];
      }
    });
    setNavigationMap(newNavigationMap);
  }, [dapps]);

  const { convertToString } = useBNMethods(lendingToken);
  const [teller, setTeller] = useState(null);
  const { network, web3 } = useWeb3StateContext();
  const NFTDistributorContract = useNFTDistributorContract();
  const tellerNFTContract = useTellerNFTContract();
  const tellerNFTV2Contract = useTellerNFTV2Contract();
  const tellerNFTDictionaryContract = useTellerNFTDictionaryContract();
  const tellerDiamondContract = useTellerDiamondContract();
  const tellerPriceAggregatorContract = useTellerPriceAggregatorContract();

  useEffect(() => {
    setLendingToken(lendingTokens.length > 0 ? lendingTokens[0] : null);
  }, [lendingTokens]);

  useEffect(() => {
    let newCollateralToken = collateralTokens[0];
    if (collateralTokens[0] == lendingTokens[0]) {
      newCollateralToken = collateralTokens[1];
    }
    setCollateralToken(newCollateralToken);
  }, [collateralTokens, lendingTokens]);

  const oneDayAPY = useOneDayAPY(lendingTokens[0]);
  useDeepCompareEffect(() => {
    if (!tellerDiamondContract || !convertToString || !network.id || !web3) return;
    let isMounted = true;
    (async () => {
      try {
        const collateralBuffer =
          (
            await tellerDiamondContract.methods
              .getPlatformSetting(web3.utils.soliditySha3("CollateralBuffer"))
              .call()
          ).value / 100;

        if (!collateralBuffer) return;
        if (isMounted) setTeller({ collateralBuffer, oneDayAPY });
      } catch (err) {
        console.log(err);
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [convertToString, network.id, tellerDiamondContract, web3]);

  const value = useMemo(() => {
    return {
      ...teller,
      NFTDistributorContract,
      tellerNFTContract,
      tellerNFTV2Contract,
      tellerNFTDictionaryContract,
      tellerDiamondContract,
      networkSupported,
      lendingTokens,
      lendingToken,
      tellerPriceAggregatorContract,
      collateralTokens,
      collateralToken,
      setLendingToken,
      navigationMap,
    };
  }, [
    teller,
    NFTDistributorContract,
    tellerNFTContract,
    tellerNFTV2Contract,
    tellerNFTDictionaryContract,
    tellerDiamondContract,
    networkSupported,
    tellerPriceAggregatorContract,
    lendingTokens,
    lendingToken,
    collateralTokens,
    collateralToken,
    navigationMap,
  ]);
  return <TellerContext.Provider value={value}>{children}</TellerContext.Provider>;
};

export const useTellerContext = () => {
  const context = useContext(TellerContext);
  if (context === undefined) {
    throw new Error("useTeller must be used within a useTellerProvider");
  }
  return context;
};

const useTellerNFTContract = () => useContract("TellerNFT", TellerNFT.abi, "contract");
const useTellerNFTDictionaryContract = () =>
  useContract("TellerNFTDictionary", TellerNFTDictionary.abi, "contract");
const useTellerNFTV2Contract = () => useContract("TellerNFT_V2", TellerNFTV2.abi, "contract");
const useTellerPriceAggregatorContract = () =>
  useContract("PriceAggregator", PriceAggregator.abi, "contract");

export const useTToken = (ID: AvailableLendingTokens) => {
  const tokenID = ID == "ETH" ? "WETH" : ID;
  const { tokenAddress, tokenDecimals } = useToken(tokenID);
  const [TToken, setTToken] = useState({
    tTokenAddress: null,
    tTokenDecimals: null,
    tTokenContract: null,
    id: null,
  });
  const { web3 } = useWeb3StateContext();
  const tellerDiamondContract = useTellerDiamondContract();

  useEffect(() => {
    let isMounted = true;
    if (!tokenAddress || !web3 || !tellerDiamondContract || !tokenID) return;
    (async () => {
      try {
        const tTokenAddress = await tellerDiamondContract.methods.getTTokenFor(tokenAddress).call();
        const tTokenContract = new web3.eth.Contract(TTokenAbi.abi, tTokenAddress, {});
        const assetID = `t${tokenID}`;
        if (isMounted) {
          setTToken({
            tTokenAddress,
            tTokenDecimals: tokenDecimals,
            tTokenContract,
            id: assetID,
          });
        }
      } catch (err) {
        console.log("error in useTToken()", err);
      }
    })();
    return () => {
      isMounted = false;
    };
  }, [tokenAddress, web3, tellerDiamondContract, tokenID, tokenDecimals]);

  return TToken;
};

export const useCToken = (borrowedAssetID: AvailableLendingTokens) => {
  const [cToken, setCToken] = useState({
    cTokenAddress: null,
    cTokenContract: null,
    cTokenDecimals: null,
    assetID: null,
  });

  const { web3 } = useWeb3StateContext();
  const tellerDiamond = useTellerDiamondContract();
  const borrowedAssetAddress = useAddress(borrowedAssetID, "token");
  const { dapps } = useNetworkConfig();

  useEffect(() => {
    if (!dapps.includes("compound") || !tellerDiamond || !borrowedAssetAddress || !web3) return;
    let isMounted = true;

    (async () => {
      const cTokenAddress = await tellerDiamond.methods.getAssetCToken(borrowedAssetAddress).call();
      const cTokenContract = new web3.eth.Contract(CErc20Interface.abi, cTokenAddress, {});
      const cTokenDecimals = await cTokenContract.methods.decimals().call();
      const assetID = `c${borrowedAssetID}`;
      if (isMounted) setCToken({ cTokenAddress, cTokenContract, cTokenDecimals, assetID });
    })();

    return () => {
      isMounted = false;
    };
  }, [tellerDiamond, borrowedAssetAddress, borrowedAssetID, dapps, web3]);

  return cToken;
};

export const useAToken = (borrowedAssetID: AvailableLendingTokens) => {
  const [aToken, setAToken] = useState({
    aTokenAddress: null,
    aTokenContract: null,
    aTokenDecimals: null,
    assetID: null,
  });
  const { dapps } = useNetworkConfig();

  const { web3 } = useWeb3StateContext();
  const tellerDiamond = useTellerDiamondContract();
  const borrowedAssetAddress = useAddress(borrowedAssetID, "token");

  useEffect(() => {
    if (!dapps.includes("aave") || !tellerDiamond || !borrowedAssetAddress) return;
    let isMounted = true;

    (async () => {
      const aTokenAddress = "0x639cB7b21ee2161DF9c882483C9D55c90c20Ca3e"; // amDAI

      const aTokenContract = new web3.eth.Contract(IAToken.abi, aTokenAddress, {});

      const aTokenDecimals = 18; //await aTokenContract.methods.decimals().call();
      const assetID = `a${borrowedAssetID}`;
      if (isMounted) setAToken({ aTokenAddress, aTokenContract, aTokenDecimals, assetID });
    })();

    return () => {
      isMounted = false;
    };
  }, [tellerDiamond, borrowedAssetAddress, borrowedAssetID, web3, dapps]);
  return aToken;
};

// No Pool on Mumbai
export const usePToken = (borrowedAssetID: AvailableLendingTokens) => {
  const [pToken, setPToken] = useState({
    pTokenAddress: null,
    pTokenContract: null,
    pTokenDecimals: null,
    assetID: null,
  });

  const { web3 } = useWeb3StateContext();
  const tellerDiamond = useTellerDiamondContract();
  const borrowedAssetAddress = useAddress(borrowedAssetID, "token");
  const { dapps } = useNetworkConfig();
  const { decimals } = useToken(borrowedAssetAddress);

  useEffect(() => {
    if (!dapps.includes("pooltogether") || !tellerDiamond || !borrowedAssetAddress || !web3) return;
    let isMounted = true;

    (async () => {
      const pPoolAddress = await tellerDiamond.methods.getAssetPPool(borrowedAssetAddress).call();
      const pPoolContract = new web3.eth.Contract(PrizePoolInterface.abi, pPoolAddress, {});
      const [, pTokenAddress] = await pPoolContract.methods.tokens().call();
      const pTokenContract = new web3.eth.Contract(IERC20Detailed.abi, pTokenAddress, {});
      const assetID = `p${borrowedAssetID}`;
      if (isMounted)
        setPToken({ pTokenAddress, pTokenContract, pTokenDecimals: decimals, assetID });
    })();

    return () => {
      isMounted = false;
    };
  }, [tellerDiamond, borrowedAssetAddress, borrowedAssetID, web3, dapps, decimals]);

  return pToken;
};

const useNFTDistributorContract = () =>
  useContract("NFTDistributor", NFTDistributor.abi, "contract");
