import React from "react";

import { NULL_ADDRESS } from "constants/constants";
import { useEtherscanLink } from "hooks/useEtherscanLink";

interface ContractLinkProps {
  hash: string;
}

const ViewContractLink = ({ hash }: ContractLinkProps) => {
  const isNullAddress = Boolean(hash == NULL_ADDRESS);
  const url = useEtherscanLink(hash, "address");
  if (isNullAddress) return <div />;
  return (
    <div className="text-right mb-5">
      <a
        className="link text-lightest-gray pl-4"
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <u>View contract</u>
      </a>
    </div>
  );
};

export default ViewContractLink;
