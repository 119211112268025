import React from "react";

import TopWarning from "components/UI/TopWarning";
import { useWeb3StateContext } from "contexts/global/web3StateContext";
import { useNetworkConfig } from "hooks/useNetworkConfig";

const NetworkBar = () => {
  const { network } = useWeb3StateContext();
  const { networkSupported } = useNetworkConfig();

  const networkName = network.name
    ? network.name == "mainnet"
      ? "Mainnet"
      : network.name == "polygon"
      ? "Polygon"
      : network.name == "polygon_mumbai"
      ? "Polygon Testnet"
      : `${network.name[0].toUpperCase() + network.name.substring(1)} Testnet`
    : "";

  const networkBannerText = `Note: ${
    network.name == "unknown"
      ? "You are currently on an unsupported network"
      : networkSupported
      ? `You are currently connected to ${networkName}`
      : `${networkName} is not supported`
  }`;

  return <>{network?.name && <TopWarning text={network ? networkBannerText : ""} />}</>;
};
export default React.memo(NetworkBar);
