import React from "react";

import { truncateAddress } from "utils/helperFunctions";
import { useUIContext } from "contexts/global/uiContext";
import { useOnboardContext } from "contexts/global/onboardContext";
import { useWeb3StateContext } from "contexts/global/web3StateContext";
import { useEtherscanLink } from "hooks/useEtherscanLink";
import Modal from "components/Modal";
import PrimaryButton from "components/UI/PrimaryButton";

const LogOutModal = () => {
  const walletName = localStorage.getItem("selectedWallet-1");

  const { showLogOutModal, setShowLogOutModal } = useUIContext();
  const { changeWallet } = useOnboardContext();
  const { userAddress } = useWeb3StateContext();

  const etherscanLink = useEtherscanLink(userAddress, "address");

  const switchAccount = () => {
    setShowLogOutModal(false);
    changeWallet();
  };

  if (!showLogOutModal) return <></>;
  return (
    <Modal
      title="Account"
      body={
        <div className="text-center my-16 mx-auto flex flex-col justify-center">
          <div className="mb-3 opacity-80 rounded-lg">Connected with {walletName}</div>
          <div className="text-lg xs:text-md text-teal font-medium login-button py-3 md:py-3 px-4 md:px-5 menu-button bg-teal-lightest rounded mx-auto">
            {userAddress && truncateAddress.apply(userAddress, [6])}
          </div>
          <div className="mt-3 mb-5">
            <a target="_blank" rel="noreferrer" href={etherscanLink} className="link text-gray">
              <u>View on Etherscan</u>
            </a>
          </div>
        </div>
      }
      showModal={showLogOutModal}
      setModalOpened={setShowLogOutModal}
      footerText={
        <PrimaryButton
          text="Go Back"
          onClick={() => {
            setShowLogOutModal(false);
          }}
          mt="0"
          variant="secondary"
        />
      }
      CTA={
        <PrimaryButton
          text="Switch Account"
          onClick={() => {
            switchAccount();
          }}
          mt="0"
        />
      }
    />
  );
};

export default LogOutModal;
