import React, { useContext } from "react";

import logo from "assets/yearn-logo.svg";
import ProcessingScreen from "components/ProcessingScreen/ProcessingScreen";
import SuccessScreen from "components/SuccessScreen/SuccessScreen";
import Card from "components/UI/Card";
import TransactionHashLink from "components/UI/TransactionHashLink";
import { UseYearnContext, UseYearnContextProvider } from "contexts/dashboard/dashboardContext";

import UseYearnMainSection from "./UseYearnMainSection";

const YearnLogo = () => {
  return <img className="mr-3" src={logo} height="31" />;
};

const YearnPage = () => {
  const {
    success,
    setSuccess,
    isWithdrawing,
    isSupplying,
    successMessage,
    selectedLoan,
    setSelectedLoan,
    setAmount,
  } = useContext(UseYearnContext);

  return (
    <Card
      dashboard
      className="main-card bg-teal-lightest  text-center  "
      title={selectedLoan ? `ID ${selectedLoan.id}` : "Yearn"}
      logo={selectedLoan ? null : <YearnLogo />}
      headerRightColumnValue={selectedLoan ? `ID ${selectedLoan.id}` : ""}
      goBack={
        selectedLoan
          ? () => {
              setSelectedLoan(null);
              setAmount(0);
            }
          : null
      }
    >
      {success && (
        <SuccessScreen
          fullScreen={false}
          title={successMessage}
          onButtonClick={() => {
            setSuccess(null);
            setSelectedLoan(null);
          }}
          message={
            <div>
              <>View your transaction status {"  "}</>
              <TransactionHashLink hash={success} />.
            </div>
          }
          CTA="Go back"
        />
      )}
      {isWithdrawing && (
        <ProcessingScreen hash={isWithdrawing} fullScreen={false} title="Withdrawing deposit" />
      )}
      {isSupplying && <ProcessingScreen hash={isSupplying} fullScreen={false} title="Depositing" />}
      {!success && !isWithdrawing && !isSupplying && <UseYearnMainSection />}
    </Card>
  );
};

const Yearn = () => {
  return (
    <UseYearnContextProvider>
      <YearnPage />
    </UseYearnContextProvider>
  );
};

export default Yearn;
