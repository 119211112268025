import React, { useContext } from "react";
import { Link } from "react-router-dom";

import ProcessingScreen from "components/ProcessingScreen/ProcessingScreen";
import SuccessScreen from "components/SuccessScreen/SuccessScreen";
import Card from "components/UI/Card";
import TransactionHashLink from "components/UI/TransactionHashLink";
import copy from "constants/copy.json";
import {
  BorrowRepayContext,
  BorrowRepayContextProvider,
} from "contexts/dashboard/dashboardContext";

import RepayForm from "./RepayForm";
import RepaySelectedLoan from "./RepaySelectedLoan";

const RepayPage = () => {
  const { selectedLoan, setRepaySuccess, repaySuccess, isRepaying, setSelectedLoan } =
    useContext(BorrowRepayContext);
  const goBack = () => {
    setSelectedLoan(null);
  };
  const pageCopy = copy.pages.dashboard["borrow-repay"];

  return (
    <div>
      <Card
        className="main-card bg-teal-lightest text-center"
        title={"Repay"}
        goBack={selectedLoan ? goBack : null}
        dashboard={true}
        headerRightColumnValue={selectedLoan ? `ID ${selectedLoan.id}` : ""}
      >
        {repaySuccess && (
          <SuccessScreen
            title={pageCopy.successScreenMessage}
            message={
              <div>
                <div>
                  <>View transaction status </>
                  {"  "}
                  <TransactionHashLink hash={repaySuccess} />.
                </div>
                {<div>Your collateral will be sent to your wallet.</div>}
              </div>
            }
            fullScreen={false}
            onButtonClick={() => {
              setRepaySuccess(null);
              setSelectedLoan(null);
            }}
            CTA="Go back"
          />
        )}
        {isRepaying && (
          <ProcessingScreen
            hash={isRepaying}
            title={pageCopy.processingScreenMessage}
            fullScreen={false}
          />
        )}
        {!repaySuccess && !isRepaying && selectedLoan && <RepaySelectedLoan />}
        {!repaySuccess && !isRepaying && !selectedLoan && <RepayForm />}
      </Card>
    </div>
  );
};

const Repay = () => {
  return (
    <BorrowRepayContextProvider>
      <RepayPage />
    </BorrowRepayContextProvider>
  );
};

export default Repay;
