import { useWeb3StateContext } from "contexts/global/web3StateContext";
import { useContract } from "hooks/useContract";
import IPolyDiamond from "contracts/diamonds/IPolyDiamond.json";
import IMainnetDiamond from "contracts/diamonds/IMainnetDiamond.json";

export const useTellerDiamondContract = () => {
  const { network } = useWeb3StateContext();
  let abi = IMainnetDiamond.abi;

  if (network.name == "polygon") {
    abi = IPolyDiamond.abi;
  }
  return useContract("TellerDiamond", abi, "contract");
};
