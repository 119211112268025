import { useCallback } from "react";

import { AvailableTokens } from "contexts/types";
import { useToken } from "hooks/useToken";
import { createNewTransactionWithHandler } from "./utils";
import { useBNMethods } from "hooks/useBNMethods";
import { useWeb3StateContext } from "contexts/global/web3StateContext";
import { useAllowance } from "hooks/useAllowance";
import { useTellerContext, useTToken } from "contexts/global/tellerContext";

export const useApprove = (assetID: AvailableTokens, contractToApprove = "tellerDiamond") => {
  const { tokenContract } = useToken(assetID);
  const { tTokenContract } = useTToken(assetID);
  const { tellerDiamondContract } = useTellerContext();
  const { convertToBN } = useBNMethods(assetID);
  const { userAddress } = useWeb3StateContext();
  const approvedAmount = useAllowance(assetID, contractToApprove);

  const approve = useCallback(
    async (approveTransactionHandler: any, amount: number) => {
      const contract =
        contractToApprove == "tellerDiamond" ? tellerDiamondContract : tTokenContract;

      if (
        !contract ||
        approvedAmount === null ||
        !userAddress ||
        !tokenContract ||
        !convertToBN ||
        !contractToApprove ||
        !tTokenContract ||
        !tellerDiamondContract
      ) {
        approveTransactionHandler.onError("");
        return;
      }
      const approvedAmountBN = convertToBN(approvedAmount);
      const amountBN = convertToBN(amount);
      if (amountBN.isLessThan(approvedAmountBN)) {
        approveTransactionHandler.onSuccess("");
        return;
      }

      const amountBNString = amountBN.times(10).toFixed(0).toString();

      await createNewTransactionWithHandler(
        () =>
          tokenContract.methods
            .approve(contract._address, amountBNString)
            .send({ from: userAddress }),
        approveTransactionHandler,
      );
    },
    [
      userAddress,
      tokenContract,
      approvedAmount,
      convertToBN,
      contractToApprove,
      tTokenContract,
      tellerDiamondContract,
    ],
  );

  return { approve };
};
