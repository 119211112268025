import React, { useContext, useEffect } from "react";

import CurrencyIcon from "components/UI/CurrencyIcon";
import SubmenuCard from "components/UI/SubmenuCard";
import { UniswapContext } from "contexts/dashboard/uniswapContext";

import UniswapArrow from "./UniswapArrow";
import UniswapButtonGroup from "./UniswapButtonGroup";
import UniswapInputField from "./UniswapInputField";
import UniswapMetrics from "./UniswapMetrics";
import UniswapOutputField from "./UniswapOutputField";
import UniswapPrice from "./UniswapPrice";
import { useEscrowBalances } from "contexts/global/loansContext";
import { formatSmallNumber } from "utils/helperFunctions";

const UniswapForm = () => {
  const { trade, tokenSelectionDropdown, selectedLoan, setEscrowBalances } =
    useContext(UniswapContext);

  const escrow = useEscrowBalances(selectedLoan.escrow);
  useEffect(() => {
    if (!escrow) return;
    let mounted = true;

    (async () => {
      if (mounted) {
        setEscrowBalances(escrow);
      }
    })();
    return () => {
      mounted = false;
    };
  }, [escrow]);

  return (
    <>
      {tokenSelectionDropdown ? (
        <UniswapTokenDropdown />
      ) : (
        <div className="flex flex-col xs:text-xs">
          <>
            <UniswapInputField />
            <UniswapArrow />
            <UniswapOutputField />
            {trade && <UniswapPrice />}
            <UniswapButtonGroup />
            {trade && <UniswapMetrics />}
          </>
        </div>
      )}
    </>
  );
};

export default UniswapForm;

const UniswapTokenDropdown = () => {
  const { tokenSelectionDropdown, setTokenSelectionDropdown, values, setValues, escrowBalances } =
    useContext(UniswapContext);
  const { tokens } = tokenSelectionDropdown;

  const onTokenClick = (token: any) => {
    const newValues = { ...values };
    if (tokenSelectionDropdown.type === "First") {
      newValues.input.token = token;
    } else {
      newValues.output.token = token;
    }
    setValues(newValues);
    setTokenSelectionDropdown(null);
  };

  return (
    <SubmenuCard
      title="Select a token"
      onCloseAction={() => {
        setTokenSelectionDropdown(null);
      }}
      className=""
    >
      {tokens.map((token: string) => {
        const balance = escrowBalances ? formatSmallNumber(escrowBalances[token]) : "-";
        return (
          <div
            key={token}
            className="text-lg xs:text-md font-medium flex flex-row justify-between items-center my-4 pointer"
            onClick={() => onTokenClick(token)}
          >
            <div className="flex flex-row items-center">
              <CurrencyIcon currency={token} className="mr-2" />
              <div className="mx-3">{token}</div>
            </div>
            <div>{balance}</div>
          </div>
        );
      })}
    </SubmenuCard>
  );
};
