import { useTellerContext } from "contexts/global/tellerContext";
import { useWeb3StateContext } from "contexts/global/web3StateContext";
import { createNewTransactionWithHandler } from "./utils";
import React, { useCallback } from "react";
import { computeSlippageAdjustedAmounts } from "utils/uniswapTrades";

export const useSwap = () => {
  const { tellerDiamondContract } = useTellerContext();
  const { userAddress } = useWeb3StateContext();

  const swap = useCallback(
    async (trade: any, id: any, transactionHandler: any) => {
      const path = trade.route.path.map((token: any) => {
        return token.address;
      });
      const sourceAmount = BigInt(trade.inputAmount.raw);
      const minDestination = BigInt(computeSlippageAdjustedAmounts(trade).raw);

      await createNewTransactionWithHandler(
        () =>
          tellerDiamondContract.methods
            .uniswapSwap(id, path, sourceAmount, minDestination)
            .send({ from: userAddress }),
        transactionHandler,
      );
    },
    [userAddress, tellerDiamondContract],
  );

  return { swap };
};
