import React, { useContext, useEffect } from "react";
import { BrowserRouter as Router, Link, NavLink, Route, Switch } from "react-router-dom";

import BR from "components/UI/BR";
import { DashboardContext } from "contexts/dashboard/dashboardContext";
import { useTellerContext } from "contexts/global/tellerContext";

interface DashboardNavTyps {
  className?: string;
  setExpanded?: () => void;
  isMobile?: boolean;
}
const DashboardNav = ({
  className = "",
  setExpanded = () => null,
  isMobile = false,
}: DashboardNavTyps) => {
  const { onPage } = useContext(DashboardContext);
  const { navigationMap } = useTellerContext();

  return (
    <div
      className={`metrics-card rounded-lg ${
        isMobile ? "mx-1" : "-mx-2 bg-gray-lightest py-7 md:py-8 px-6 md:px-7 sm:mx-0 md:m-2"
      } self-start ${className}`}
    >
      {Object.keys(navigationMap).map((section) => {
        return (
          <div className={`m-2 ${section !== "SPEND" ? "mb-5" : ""}`} key={section}>
            {Object.keys(navigationMap[section]).length > 0 && (
              <>
                <div className="mb-2 text-gray text-lg xs:text-md">{section}</div>
                <div className="mb-2 md:mr-5">
                  <BR />
                </div>
              </>
            )}
            {Object.keys(navigationMap[section]).map((subsection) => {
              return (
                <Link
                  style={{ textDecoration: "none" }}
                  key={subsection}
                  className={`md:font-medium text-lg xs:text-md mb-1 pointer ${
                    onPage === navigationMap[section][subsection] && !isMobile
                      ? "text-black"
                      : "text-lightest-gray"
                  }`}
                  to={`/dashboard/${navigationMap[section][subsection]}`}
                  onClick={() => {
                    setExpanded(false);
                  }}
                >
                  <div
                    className={`font-medium text-lg xs:text-md mb-1 pointer ${
                      onPage === navigationMap[section][subsection] && !isMobile
                        ? "text-black"
                        : "text-lightest-gray"
                    }`}
                  >
                    {subsection}
                  </div>
                </Link>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default DashboardNav;
