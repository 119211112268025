import { useTellerContext } from "contexts/global/tellerContext";
import { useWeb3StateContext } from "contexts/global/web3StateContext";
import { AvailableLendingTokens } from "contexts/types";
import { useBNMethods } from "hooks/useBNMethods";
import { useToken } from "hooks/useToken";
import { useCallback } from "react";
import { createNewTransactionWithHandler } from "./utils";

export const useAaveWithdraw = (lendingToken: AvailableLendingTokens) => {
  const { tellerDiamondContract } = useTellerContext();
  const { userAddress } = useWeb3StateContext();
  const { convertToBNString } = useBNMethods(lendingToken);
  const { tokenAddress } = useToken(lendingToken);

  const aaveWithdraw = useCallback(
    async (
      aaveWithdrawTransactionHandler: any,
      amount: number,
      totalSupplied: string,
      loanID: any,
      isMaxWithdraw: boolean,
    ) => {
      const amountBN = convertToBNString(amount);
      const totalAmountBN = convertToBNString(totalSupplied);

      await createNewTransactionWithHandler(
        () =>
          tellerDiamondContract.methods
            .aaveWithdraw(loanID, tokenAddress, isMaxWithdraw ? totalAmountBN : amountBN)
            .send({ from: userAddress }),
        aaveWithdrawTransactionHandler,
      );
    },
    [tellerDiamondContract, userAddress, convertToBNString, tokenAddress],
  );

  return { aaveWithdraw };
};
