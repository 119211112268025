import { AvailableTokens } from "contexts/types";

export const calculateCollateralPercent = (
  collateralTokenPrice: number,
  borrowedTokenPrice: number,
  collateralAmount: number,
  amountBorrowed: number,
) => {
  return (
    Math.round(
      ((collateralAmount * collateralTokenPrice) / (amountBorrowed * borrowedTokenPrice)) * 1000000,
    ) / 10000
  );
};

export const getSubgraphUrl = (network: string) => {
  if (network === "3") {
    return "https://api.thegraph.com/subgraphs/name/salazarguille/teller-protocol-subgraph-ropsten";
  } else if (network === "4") {
    return "https://api.thegraph.com/subgraphs/name/tpscrpt/teller-rinkeby";
  } else if (network === "42") {
    return "https://alpha-kovan.teller.finance/subgraph";
  } else if (network === "1") {
    return "http://diamond.teller.finance/subgraph";
  } else throw "Unsupported network";
};

export const getValidatorUrls = (network: string) => {
  if (network === "137") {
    return ["https://validator.teller.org/polygon"];
  } else if (network === "80001") {
    return ["https://848523385eaf.ngrok.io"];
  } else if (network === "4") {
    return ["https://8e3fa3cde99d.ngrok.io"];
  } else if (network === "42") {
    return ["https://validator.teller.org/kovan"];
  } else if (network === "31337") {
    return ["https://validator.teller.org/hardhat"];
  } else return ["https://validator.teller.org/"];
};
export const roundToFourDecimals = (value: any) => {
  if (!value) {
    return "-";
  }
  let res;
  const number = Number(value);
  if (number == 0) {
    return "0";
  }
  if (number < 0.0001) {
    res = "<0.0001";
  } else res = (Math.floor(Number(value) * 10000) / 10000).toString();
  return res;
};

// Add custom rules here to disable integrations
export const integrationsDisabled = (state: any) => false;

export function truncateAddress(n: number) {
  const { length } = this;
  if (length <= n) {
    return this;
  }
  return `${this.substr(0, n - 1)}...${this.substr(length - 4, length)}`;
}

export const convertTimestampToHumanDate = (timestamp: number) => {
  const date = new Date(timestamp);
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};

export const formatSmallNumber = (amount: string) => {
  return Number(amount) == 0
    ? 0
    : Number(amount) <= 0.0000000001 && Number(amount) > 0
    ? "<0.0000000001"
    : Number(amount)
        .toFixed(10)
        .replace(/\.?0+$/, "");
};

export const isStablecoin = (assetID: AvailableTokens) =>
  !!(assetID == "DAI" || assetID == "USDC" || assetID == "USDT");
