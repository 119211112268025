import Axios from "axios";

import { getValidatorUrls } from "utils/helperFunctions";
import { PublicTokenResponsePayload } from "./data-provider/public-token";
import { useTellerContext } from "contexts/global/tellerContext";

export type PBorrow = {
  requestedLoanSize: string; // wei
  borrowedAsset: string;
  collateralAsset: string;
  loanTermLength: string; // seconds
  collateralRatioEntered: string; // 100% === 10000
  loanUse: string;
  ethereumWallet: string;
  providerTokens?: PublicTokenResponsePayload;
  requestTime: string; // seconds at least 100 in the past
  requestNonce: string;
};
export type RBorrow = RArrowheadCRA[];

export type JSONRPCResponse<T> = {
  result: T;
};

export type RArrowheadCRA = {
  /**
   * Address of the validator's wallet
   */
  signer: string;
  /**
   * The interest rate for the loan, e.g. 3.8% === 380 (10000:1).
   */
  interestRate: string;
  /**
   * Hash of the fields provided in the original request.
   */
  requestHash: string;
  /**
   * Collateral ratio for the loan (10000:1).
   */
  collateralRatio: string;
  /**
   * Maximum loan amount in 18 decimal uint (X * 10 ** 18).
   */
  maxLoanAmount: string; // X * 1e18
  /**
   * On-chain consensus address.
   */
  consensusAddress: string; // address
  /**
   * UNIX timestamp of the response (flat amount over requestTime).
   */
  responseTime: string; // uint256 seconds
  /**
   * Chain ID where this signature is valid (1: mainnet, 3: ropsten)
   */
  chainId: string;
  signerNonce: string;
  signature: string;
  message?: string;
};

export function getNodeSignaturesForBorrowing(
  nonce: any,
  params: PBorrow,
  network: string,
  id = 1,
): Promise<any> {
  const nodeUrls = getValidatorUrls(network);
  return Promise.all(
    nodeUrls.map(async (nodeUrl) => {
      const nodeParams = {
        ...params,
        providerToken:
          params.providerTokens?.find(({ url }: any) => url === nodeUrl)?.providerToken ??
          undefined,
      };
      if (network == "31337") {
        nodeParams.hardhatNonce = nonce;
      }
      const response = await Axios.post<JSONRPCResponse<RArrowheadCRA>>(
        nodeUrl,
        {
          jsonrpc: "2.0",
          id,
          method: "arrowheadCRA",
          params: nodeParams,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Credentials": true,
          },
        },
      );
      return response.data.result;
    }),
  );
}
