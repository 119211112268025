import React, { useContext } from "react";

import Card from "components/UI/Card";
import { DashboardContext } from "contexts/dashboard/dashboardContext";

import Deposit from "./Deposit";
import Repay from "./Repay";
import Withdraw from "./Withdraw";

const Borrow = () => {
  const { onPage, loans } = useContext(DashboardContext);
  const subSection = onPage.split("-")[1];
  return (
    <div className="">
      {loans && loans.length > 0 ? (
        <>
          {subSection == "repay" && <Repay />}
          {subSection == "deposit" && <Deposit />}
          {subSection == "withdraw" && <Withdraw />}
        </>
      ) : (
        <Card className="main-card bg-teal-lightest text-center" title={""} dashboard={true}>
          <div>No Loans</div>
        </Card>
      )}
    </div>
  );
};

export default Borrow;
