import "./top-warning.scss";

import React from "react";

interface warningProps {
  text: string;
}
const TopWarning = ({ text }: warningProps) => {
  return <div className="top-warning px-5 py-3 xs:text-xs">{text}</div>;
};

export default TopWarning;
