import React, { createContext, useContext, useEffect, useMemo, useState } from "react";

const defaultUIState = {
  borrowingStarted: false,
  setBorrowingStarted: (value: boolean) => null as null,
  explorerMode: false,
  setExplorerMode: (value: boolean) => null as null,
  leaveBorrowProcessModalRequest: null as null,
  setLeaveBorrowProcessModalRequest: (value: boolean) => null as null,
  onSubmenuClose: null as null,
  setOnSubmenuClose: (value: any) => null as null,
  showLogOutModal: false,
  setShowLogOutModal: (value: boolean) => null as null,
  showLoginModal: false,
  setShowLoginModal: (value: boolean) => null as null,
  subgraphOn: false,
  setSubgraphOn: (value: boolean) => null as null,
  darkNav: false,
  setDarkNav: (value: boolean) => null as null,
};
const UIStateContext = createContext<any>(defaultUIState);

export const UIStateContextProvider = ({ children }: any) => {
  const [borrowingStarted, setBorrowingStarted] = useState(false);
  const [explorerMode, setExplorerMode] = useState(false);
  const [leaveBorrowProcessModalRequest, setLeaveBorrowProcessModalRequest] = useState(null);
  const [onSubmenuClose, setOnSubmenuClose] = useState(null);
  const [showLogOutModal, setShowLogOutModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [subgraphOn, setSubgraphOn] = useState(false);
  const [darkNav, setDarkNav] = useState(false);

  const values = useMemo(() => {
    return {
      borrowingStarted,
      setBorrowingStarted,

      setExplorerMode,
      explorerMode,

      leaveBorrowProcessModalRequest,
      setLeaveBorrowProcessModalRequest,

      onSubmenuClose,
      setOnSubmenuClose,

      showLoginModal,
      setShowLoginModal,

      subgraphOn,
      setSubgraphOn,

      setShowLogOutModal,
      showLogOutModal,

      darkNav,
      setDarkNav,
    };
  }, [
    borrowingStarted,
    explorerMode,
    leaveBorrowProcessModalRequest,
    onSubmenuClose,
    showLoginModal,
    subgraphOn,
    showLogOutModal,
    darkNav,
  ]);
  return <UIStateContext.Provider value={values}>{children}</UIStateContext.Provider>;
};

export const useUIContext = () => {
  const context = useContext(UIStateContext);
  if (context === undefined) {
    throw new Error("useUIState must be used within a useUIStateProvider");
  }
  return context;
};
