import { useCallback } from "react";

import { createNewTransactionWithHandler } from "actions/utils";
import { useWeb3StateContext } from "contexts/global/web3StateContext";
import { AvailableLendingTokens } from "contexts/types";
import { useTToken } from "contexts/global/tellerContext";
import { useBNMethods } from "hooks/useBNMethods";

export const useDeposit = (assetID: AvailableLendingTokens) => {
  const { userAddress } = useWeb3StateContext();
  const { convertToBNString } = useBNMethods(assetID);
  const tToken = useTToken(assetID);

  const deposit = useCallback(
    async (depositTransactionHandler: any, amount: number) => {
      if (!tToken?.tTokenContract) return;
      const amountBNString = convertToBNString(amount);
      await createNewTransactionWithHandler(
        () =>
          tToken.tTokenContract.methods.mint(amountBNString).send({
            from: userAddress,
            value: assetID == "ETH" ? amountBNString : undefined,
          }),
        depositTransactionHandler,
      );
    },
    [tToken, userAddress, convertToBNString, assetID],
  );

  return { deposit };
};
