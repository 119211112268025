import { useWithdraw } from "actions/WithdrawUnderlying";
import Button from "components/Button";
import FullScreenModal from "components/FullScreenModal";
import { ActionLoading, ActionSuccess } from "components/FullScreenModal/FullScreenModal";
import CardWithTable from "components/Modal/CardWithTable";
import CurrencyIcon from "components/UI/CurrencyIcon";
import { CustomDropdown } from "components/UI/CustomDropdown";
import { useMarketState } from "hooks/useMarketState";
import { useSuppliedBalance } from "hooks/useSuppliedBalance";
import { useTransactionHandler } from "hooks/useTransactionHandler";
import useTxReducer, { txActionTypes } from "hooks/useTxReducer";

import React, { useEffect, useMemo, useState } from "react";
import { useTellerContext } from "contexts/global/tellerContext";
import { useOneDayAPY } from "hooks/useOneDayApy";
import formatNumberForDisplay from "utils/formatNumberForDisplay";
import { useUnderlyingBalance } from "hooks/useUnderlyingBalance";

const ManageLiquidityWithdrawModal = ({
  setWithdrawModalOpened,
  selectedCurrency,
  setSelectedCurrency,
  tellerBalanceString,
  walletBalanceString,
}: {
  setWithdrawModalOpened: any;
  selectedCurrency: any;
  setSelectedCurrency: any;
  tellerBalanceString: any;
  walletBalanceString: any;
}) => {
  const { withdraw } = useWithdraw(selectedCurrency);
  const [amountExceeded, setAmountExceeded] = useState("");
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [state, dispatch] = useTxReducer();
  const [maxSelected, setMaxSelected] = useState(false);
  const tellerBalance = useSuppliedBalance(selectedCurrency);
  const oneDayAPY = useOneDayAPY(selectedCurrency);
  const selectCurrency = (eventKey: any) => {
    setSelectedCurrency(eventKey);
  };
  const underlyingLiquidity = useUnderlyingBalance(selectedCurrency);
  const maxWithdrawAmount = Math.min(Number(tellerBalance), Number(underlyingLiquidity));

  useEffect(() => {
    if (Number(selectedAmount) > Number(maxWithdrawAmount)) {
      setAmountExceeded(
        `Please input amount smaller than ${maxWithdrawAmount} ${selectedCurrency}`,
      );
    } else {
      setAmountExceeded("");
    }
  }, [selectedCurrency, maxWithdrawAmount, selectedAmount]);

  const withdrawCTADisabled =
    !selectedAmount ||
    Number(selectedAmount) == 0 ||
    Number(selectedAmount) > Number(maxWithdrawAmount) ||
    isNaN(Number(selectedAmount)) ||
    !!amountExceeded ||
    Number(underlyingLiquidity) < Number(selectedAmount);

  const setProcessing = async (hash) => {
    dispatch({
      type: txActionTypes.setTxHash,
      payload: {
        txHash: hash,
      },
    });
  };

  const onSuccess = async () => {
    dispatch({
      type: txActionTypes.justCompleted,
    });
    await new Promise((resolve) => setTimeout(resolve, 5000));
    dispatch({
      type: txActionTypes.txSuccess,
    });
  };
  const onError = async () => {
    dispatch({
      type: txActionTypes.txError,
    });
  };

  const withdrawTransactionHandler = useTransactionHandler(
    "withdrawLentTokens",
    onSuccess,
    setProcessing,
    onError,
  );

  const onWithdrawClick = async (amount: any) => {
    dispatch({
      type: txActionTypes.startTxProcess,
    });
    await withdraw(amount, maxSelected, withdrawTransactionHandler);
  };

  const setMaxAmount = () => {
    setMaxSelected(true);
    setSelectedAmount(maxWithdrawAmount);
  };

  const StyledButton = ({
    CTA = () => {
      return null;
    },
    CTACopy,
    CTADisabled,
  }: {
    CTA?: any;
    CTACopy: string;
    CTADisabled: boolean;
  }) => {
    return (
      <Button
        CTA={CTA}
        color="green"
        type="primary"
        text={CTACopy}
        size="md"
        disabled={CTADisabled}
      />
    );
  };
  const CTA_STATES = {
    default: (
      <StyledButton
        CTA={() => {
          onWithdrawClick(selectedAmount);
        }}
        CTACopy={"Withdraw"}
        CTADisabled={withdrawCTADisabled}
      />
    ),
    loading: <StyledButton CTACopy={"Withdrawing..."} CTADisabled={true} />,
    justCompleted: <StyledButton CTACopy={"Withdrawing..."} CTADisabled={true} />,
    txSuccess: (
      <StyledButton
        CTA={() => {
          setWithdrawModalOpened(false);
        }}
        CTACopy={"Done"}
        CTADisabled={false}
      />
    ),
  };

  const MAIN_CONTENT_STATES = useMemo(() => {
    return {
      default: (
        <div className="flex flex-col lg:flex-row flex-1 max-w-screen-2xl	text-left mx-auto overflow-scroll">
          <div className="flex-1 flex flex-col items-start">
            <div className="block lg:hidden type-h3 text-green-02  mb-3">
              Withdraw <span className="text-green-03">Liquidity</span>
            </div>
            <div className="hidden lg:block type-h2 text-green-02 mb-3">
              Withdraw <span className="text-green-03">Liquidity</span>
            </div>
            <div className="lg:max-w-sm mb-10 text-black whitespace-normal">
              Select an amount to withdraw from your Teller account.
            </div>
          </div>
          <div className="flex-1 -mx-6 md:mx-0">
            <CardWithTable
              variant="darkGreen"
              header={
                <>
                  <div className="mb-2">Withdraw amount</div>
                  <WithdrawInputField
                    setSelectedAmount={setSelectedAmount}
                    selectedAmount={selectedAmount}
                    setMaxAmount={setMaxAmount}
                    setMaxSelected={setMaxSelected}
                    selectedCurrency={selectedCurrency}
                    selectCurrency={selectCurrency}
                  />
                  {amountExceeded && <div className="mt-2">{amountExceeded}</div>}
                </>
              }
              rows={[
                { left: "Teller Balance", right: `${tellerBalanceString} ${selectedCurrency}` },
                { left: "Wallet Balance", right: `${walletBalanceString} ${selectedCurrency}` },
                { left: "Current APY", right: `${formatNumberForDisplay(oneDayAPY * 365)} %` },
                {
                  left: "Available liquidity",
                  right: `${formatNumberForDisplay(underlyingLiquidity)}  ${selectedCurrency}`,
                },
              ]}
            />
          </div>
        </div>
      ),
      loading: (
        <ActionLoading
          title="Withdraw in progress"
          subTitle={
            <div>
              You are currently withdrawing {selectedAmount} {selectedCurrency} from your Teller
              account
            </div>
          }
        />
      ),
      justCompleted: (
        <ActionSuccess
          title="Withdraw Success"
          subTitle={`You are currently withdrawing ${selectedAmount} ${selectedCurrency} from your Teller account`}
        />
      ),
      txSuccess: (
        <div className="flex flex-col lg:flex-row flex-1 max-w-screen-2xl	text-left mx-auto overflow-scroll">
          <div className="flex-1 flex flex-col items-start p-6">
            <div className="block lg:hidden type-h3 text-green-02  mb-3">
              Successfully <span className="text-green-03">Withdrawn</span>
            </div>
            <div className="hidden lg:block type-h2 text-green-02 mb-3">
              Successfully <span className="text-green-03">Withdrawn</span>
            </div>
            <div className="lg:max-w-sm mb-10 text-black whitespace-normal">
              You've successfully withdrawn {selectedAmount} {selectedCurrency} from your Teller
              account
            </div>
          </div>
          <div className="flex-1 -mx-6 md:mx-0">
            <CardWithTable
              variant="darkGreen"
              header={
                <>
                  <div className="mb-2">Withdraw amount</div>
                  <div className="mb-2 text-3xl">
                    <span className="font-medium">{selectedAmount}</span>
                    {"   "}
                    <span className="font-light">{selectedCurrency}</span>
                  </div>
                </>
              }
              rows={[
                { left: "Teller Balance", right: `${tellerBalanceString} ${selectedCurrency}` },
                { left: "Wallet Balance", right: `${walletBalanceString} ${selectedCurrency}` },
                { left: "Current APY", right: `${formatNumberForDisplay(oneDayAPY * 365)} %` },
              ]}
            />
          </div>
        </div>
      ),
    };
  }, [
    tellerBalanceString,
    walletBalanceString,
    oneDayAPY,
    selectedCurrency,
    selectedAmount,
    underlyingLiquidity,
    amountExceeded,
  ]);

  return (
    <FullScreenModal
      onClose={() => {
        setWithdrawModalOpened(false);
      }}
      footerCTA={CTA_STATES[state.progressState]}
      mainContent={MAIN_CONTENT_STATES[state.progressState]}
    />
  );
};

export default ManageLiquidityWithdrawModal;

const WithdrawInputField = ({
  setSelectedAmount,
  selectedAmount,
  setMaxAmount,
  setMaxSelected,
  selectedCurrency,
  selectCurrency,
}: {
  setSelectedAmount: any;
  selectedAmount: any;
  setMaxAmount: any;
  setMaxSelected: any;
  selectCurrency: any;
  selectedCurrency: any;
}) => {
  const { lendingTokens } = useTellerContext();
  return (
    <div className="flex flex-row flex-1">
      <div className="bg-green-06 flex flex-row flex-1 rounded-l">
        <div className="flex-1">
          <input
            className="rounded-l bg-green-06 placeholder-green-04 text-green-03 type-button w-full h-full px-4 focus:outline-none "
            placeholder="Enter Amount"
            type="number"
            step={0.01}
            value={selectedAmount}
            onChange={(e) => {
              setMaxSelected(false);
              setSelectedAmount(e.target.value);
            }}
          />
        </div>
        <div className="my-auto mx-3">
          <Button color="green" type="secondary" text="MAX" size="xs" CTA={setMaxAmount} />
        </div>
      </div>
      <div className="flex bg-white p-4 rounded-r">
        <CurrencyIcon currency={selectedAmount} className="h-5 w-5" />
        <CustomDropdown
          options={lendingTokens}
          selected={selectedCurrency}
          handleSelect={(eventKey: any) => {
            selectCurrency(eventKey);
          }}
        />
      </div>
    </div>
  );
};
