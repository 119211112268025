import React, { useContext } from "react";

import ProcessingScreen from "components/ProcessingScreen/ProcessingScreen";
import SuccessScreen from "components/SuccessScreen/SuccessScreen";
import Card from "components/UI/Card";
import TransactionHashLink from "components/UI/TransactionHashLink";
import {
  BorrowWithdrawContext,
  BorrowWithdrawContextProvider,
} from "contexts/dashboard/dashboardContext";

import WithdrawMainSection from "./WithdrawMainSection";

const WithdrawPage = () => {
  const { success, setSuccess, isWithdrawing, setSelectedLoan, selectedLoan } =
    useContext(BorrowWithdrawContext);

  const goBack = () => {
    setSelectedLoan(null);
  };
  return (
    <div>
      <Card
        className="text-center main-card bg-teal-lightest"
        title={"Withdraw Collateral"}
        goBack={selectedLoan ? goBack : null}
        dashboard={true}
        headerRightColumnValue={selectedLoan ? `ID ${selectedLoan.id}` : ""}
      >
        {success && (
          <SuccessScreen
            fullScreen={false}
            title="Withdraw accepted"
            onButtonClick={() => {
              setSuccess(null);
              setSelectedLoan(null);
            }}
            message={
              <div>
                <>View transaction status </>
                {"  "}
                <TransactionHashLink hash={success} />
              </div>
            }
            CTA="Go back"
          />
        )}
        {isWithdrawing && (
          <ProcessingScreen
            hash={isWithdrawing}
            fullScreen={false}
            title="Withdrawing collateral"
          />
        )}
        {!success && !isWithdrawing && <WithdrawMainSection />}
      </Card>
    </div>
  );
};

const Withdraw = () => {
  return (
    <BorrowWithdrawContextProvider>
      <WithdrawPage />
    </BorrowWithdrawContextProvider>
  );
};
export default Withdraw;
