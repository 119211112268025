import React, { useContext } from "react";

import ProcessingScreen from "components/ProcessingScreen/ProcessingScreen";
import SuccessScreen from "components/SuccessScreen/SuccessScreen";
import TransactionHashLink from "components/UI/TransactionHashLink";
import {
  LendWithdrawContext,
  LendWithdrawContextProvider,
} from "contexts/dashboard/dashboardContext";

const WithdrawPage = () => {
  const { isWithdrawing, success, setSuccess } = useContext(LendWithdrawContext);

  return (
    <div>
      {success && (
        <SuccessScreen
          fullScreen={false}
          onButtonClick={() => {
            setSuccess(null);
          }}
          title="Withdraw accepted"
          message={
            <div>
              <>View your transaction status {"  "}</>
              <TransactionHashLink hash={success} />
            </div>
          }
          CTA="Go back"
        />
      )}
      {isWithdrawing && (
        <ProcessingScreen hash={isWithdrawing} title="Withdrawing deposit" fullScreen={false} />
      )}
      {/* {!isWithdrawing && !success && <WithdrawMainSection />} */}
    </div>
  );
};
const Withdraw = () => {
  return (
    <LendWithdrawContextProvider>
      <WithdrawPage />
    </LendWithdrawContextProvider>
  );
};

export default Withdraw;
