import { V2Balances, V2BalanceArray, V2BalanceObj } from "contexts/types";
import BigNumber from "bignumber.js";

export const mergeV2IDsToBalances = (v2IDs: BigNumber[]): V2Balances => {
  const v2Balances: { [v2ID: number]: BigNumber } = {};
  for (const v2IDBN of v2IDs) {
    const v2ID = Number(v2IDBN.toString());
    if (v2Balances[v2ID] == null) v2Balances[v2ID] = new BigNumber(0);
    v2Balances[v2ID] = v2Balances[v2ID].plus(1);
  }

  const ids: string[] = [];
  const balances: string[] = [];
  const response: V2Balances = Object.assign<V2BalanceArray, V2BalanceObj>([ids, balances], {
    ids,
    balances,
  });
  for (const [v2ID, v2Balance] of Object.entries(v2Balances)) {
    ids.push(v2ID);
    balances.push(v2Balance.toString());
  }
  return response;
};
