import ModalFooterText from "components/Modal/ModalFooterText";
import PrimaryButton from "components/UI/PrimaryButton";
import { BorrowPageContext } from "contexts/borrow/borrowContext";
import React, { useContext, useEffect, useState } from "react";
import Modal from "components/Modal";
import { useNFTsContext } from "contexts/global/nftsContext";
import CardWithTable from "components/Modal/CardWithTable";
import { NFT_CONTRIBUTION_MULTIPLIER } from "constants/constants";

type LinkNFTModalProps = {
  setModalOpened: any;
  showModal: boolean;
  setLoading: any;
  setApproved: any;
  page?: string;
};
const LinkNFTModal = ({
  showModal,
  setModalOpened,
  setLoading,
  setApproved,
}: LinkNFTModalProps) => {
  const { setNFTs } = useContext(BorrowPageContext);
  const { allClaimedNFTs } = useNFTsContext();
  const nftsList = allClaimedNFTs?.filter((nft) => nft.status == "Staked");

  const [nftsLookupObject, setNftsLookupObject] = useState(
    nftsList.reduce((acc: any, val: any) => {
      const n = { ...acc };
      n[val.id] = val.selected;
      return n;
    }, {}),
  );

  const [allSelected, setAllSelected] = useState(false);

  const [baseAmount, setBaseAmount] = useState(0);

  useEffect(() => {
    let allSelected = true;
    let baseAmount = 0;

    nftsList.map((nft: any) => {
      if (!nftsLookupObject[nft.id]) {
        allSelected = false;
      } else {
        baseAmount = baseAmount + Number(nft.credit) * NFT_CONTRIBUTION_MULTIPLIER;
      }
    });
    setAllSelected(allSelected);
    setBaseAmount(baseAmount);
  }, [nftsLookupObject, nftsList]);

  const LinkNFTModalBody = (
    //my-10 lg:mx-24
    <CardWithTable
      header={
        <>
          <div className="mb-2 whitespace-pre-wrap">
            Linking your NFT will increase your base loan size. Please note, if your unsecured loan
            defaults you will have 30 days to repay your loan before linked NFT will be auctioned -
            {` `}
            <u>
              <a
                className="hover:text-white"
                target="_blank"
                rel="noopener noreferrer"
                href="https://medium.com/teller-finance/recap-fortune-teller-sale-next-steps-ec9ea58ddda0"
              >
                read more
              </a>
            </u>
            {` `} for details.
          </div>
        </>
      }
      rows={
        nftsList
          ? [
              {
                left: (
                  <div className="font-medium text-black">
                    <input
                      type="checkbox"
                      className="form-checkbox h-4 w-4 mr-2"
                      style={{ color: "#8B7CF1" }}
                      checked={allSelected}
                      onChange={() => {
                        const updatednftsLookupObject = { ...nftsLookupObject };
                        nftsList.map((nft: any) => {
                          updatednftsLookupObject[nft.id] = !allSelected;
                        });
                        setNftsLookupObject(updatednftsLookupObject);
                      }}
                    />
                    NFT ID
                  </div>
                ),
                right: <div className="font-medium text-black">Credit</div>,
              },
              ...nftsList.map((nft: any) => {
                return {
                  left: (
                    <div className="font-light text-black">
                      <input
                        type="checkbox"
                        className="form-checkbox h-4 w-4 mr-2"
                        style={{ color: "#8B7CF1" }}
                        checked={nftsLookupObject ? nftsLookupObject[nft.id] : false}
                        onChange={() => {
                          const updatednftsLookupObject = { ...nftsLookupObject };
                          updatednftsLookupObject[nft.id] = !nftsLookupObject[nft.id];
                          setNftsLookupObject(updatednftsLookupObject);
                        }}
                      />
                      {`ID ${nft.id}`}
                    </div>
                  ),
                  right: `$${(Number(nft.credit) * NFT_CONTRIBUTION_MULTIPLIER).toLocaleString()}`,
                  onRowClick: () => {
                    const updatednftsLookupObject = { ...nftsLookupObject };
                    updatednftsLookupObject[nft.id] = !nftsLookupObject[nft.id];
                    setNftsLookupObject(updatednftsLookupObject);
                  },
                };
              }),
            ]
          : []
      }
    />
  );

  const linkNFT = async () => {
    const updatedNFTsList = nftsList.map((nft: any) => {
      nft.selected = nftsLookupObject[nft.id];
      return nft;
    });
    setNFTs(updatedNFTsList);
    setModalOpened(false);
    setLoading(false);
    setApproved(true);
  };

  const LinkNFTModalCTA = (
    <PrimaryButton mt="mt-0" text="Submit" onClick={linkNFT} disabled={!nftsList} />
  );

  const LinkNFTFooterText = (
    <ModalFooterText
      text="Total base loan amount:"
      highlightedText={baseAmount.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })}
    />
  );

  return (
    <Modal
      title="Link Fortune Teller NFT"
      body={LinkNFTModalBody}
      CTA={LinkNFTModalCTA}
      footerText={LinkNFTFooterText}
      showModal={showModal}
      setModalOpened={setModalOpened}
    />
  );
};
export default LinkNFTModal;
