import { useEtherscanLink } from "hooks/useEtherscanLink";
import React, { useContext } from "react";

interface TSXLinkType {
  hash: string | null;
}

const TransactionHashLink = ({ hash }: TSXLinkType) => {
  const href = useEtherscanLink(hash, "address");

  return (
    <a target="_blank" rel="noreferrer" href={href} className="link text-gray">
      <u>here</u>
    </a>
  );
};
export default TransactionHashLink;
