import React from "react";
import { NotificationsContextProvider } from "./notificationsContext";
import { ContextProps } from "../types";
import { Web3StateContextProvider } from "./web3StateContext";
import { OnboardContextProvider } from "./onboardContext";
import { NotifyContextProvider } from "./notifyContext";
import { BlockNumberContextProvider } from "./blockNumberContext";
import { PlaidContextProvider } from "./plaidContext";
import { NFTsContextProvider } from "./nftsContext";
import { TellerContextProvider } from "./tellerContext";
import { UIStateContextProvider } from "./uiContext";
import { TransactionsContextProvider } from "./transactionsContext";
import { LoansContextProvider } from "./loansContext";
import { TokensContextProvider } from "./tokensContext";

/**
 * Context wrapper for the state that is shared across the app
 */
export const GlobalContextProvider = ({
  children,
  Web3StateContextMockValues = null,
}: ContextProps) => {
  return (
    <UIStateContextProvider>
      <TransactionsContextProvider>
        <NotificationsContextProvider>
          <Web3StateContextProvider Web3StateContextMockValues={Web3StateContextMockValues}>
            <TokensContextProvider>
              <OnboardContextProvider>
                <NotifyContextProvider>
                  <BlockNumberContextProvider>
                    <TellerContextProvider>
                      <LoansContextProvider>
                        <NFTsContextProvider>
                          <PlaidContextProvider>{children}</PlaidContextProvider>
                        </NFTsContextProvider>
                      </LoansContextProvider>
                    </TellerContextProvider>
                  </BlockNumberContextProvider>
                </NotifyContextProvider>
              </OnboardContextProvider>
            </TokensContextProvider>
          </Web3StateContextProvider>
        </NotificationsContextProvider>
      </TransactionsContextProvider>
    </UIStateContextProvider>
  );
};
//ui
