import React from "react";

interface numberInputProps {
  value: string;
  setValue: () => void;
  decimals?: number;
  className?: string;
  style?: any;
}

export const numberInputEnforcer = (value: string, decimals = 18) => {
  const escapeRegExp = (string: string): string => {
    return string.replace(/[,.*+?^${}()|[\]\\]/g, "\\$&");
  };
  const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d{0,${decimals}}$`);

  if (value === "" || inputRegex.test(escapeRegExp(value))) {
    return true;
  }
};

export const onBlurFormat = (value: string) => {
  const newValue = parseFloat(value);
  if (isNaN(newValue)) {
    value = "0";
  }
  return value;
};

const NumberInput = ({
  value,
  setValue,
  decimals = 18,
  className = "",
  style = {},
}: numberInputProps) => {
  return (
    <input
      style={style}
      className={`input bg-teal-lightest ${className}`}
      value={value}
      onChange={(e) => {
        const { value } = e.target;
        if (numberInputEnforcer(value, decimals)) {
          setValue(value);
        }
      }}
      onBlur={(e) => {
        setValue(onBlurFormat(e.target.value));
      }}
      inputMode="decimal"
      title="Token amount"
      autoComplete="off"
      autoCorrect="off"
      type="text"
      maxLength={49}
      spellCheck="false"
    />
  );
};

export default NumberInput;
