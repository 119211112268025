import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import Onboard from "bnc-onboard";
import Web3 from "web3";
import { useWeb3StateContext } from "./web3StateContext";
import { useMemo } from "react";

const defaultOnboardState = {
  onboard: null as null,
  initOnboard: () => null as any,
  changeWallet: () => null as any,
  callWeb3FromProvider: () => null as any,
};

const OnboardContext = createContext<any>(defaultOnboardState);

export const OnboardContextProvider = ({ children }: any) => {
  const [onboard, setOnboard] = useState(null);

  const { setNetwork, setWeb3, updateUserAddress } = useWeb3StateContext();

  const initOnboard = useCallback(() => {
    const onboard = Onboard({
      walletSelect: {
        wallets,
      },
      walletCheck: [{ checkName: "connect" }],
      ...BlockNativeOptions,
      subscriptions: {
        network: async (network: any) => {
          if (network === 4) {
            onboard.config({ networkId: 4 });
          }
          if (network === 1) {
            onboard.config({ networkId: 1 });
          }
          network =
            typeof network === "number" && !Number.isNaN(network) ? network.toString() : "unknown";
          setNetwork(network);
        },
        wallet: async (wallet: any) => {
          const web3 = new Web3(wallet.provider);
          setWeb3(web3);
        },
        address: async (address: any) => {
          updateUserAddress(address);
          localStorage.setItem("selectedWallet-1", onboard.getState().wallet.name);
        },
      },
    });
    setOnboard(onboard);
    return onboard;
  }, [setNetwork, setWeb3, updateUserAddress]);

  const callWeb3FromProvider = useCallback(async () => {
    try {
      const newOnboard = onboard ? onboard : await initOnboard();
      const lastLoginDetails = localStorage.getItem("selectedWallet-1");
      await newOnboard.walletSelect(lastLoginDetails);
      const loggedIn = await newOnboard.walletCheck();
      return loggedIn;
    } catch (e) {
      console.log("web3 provider error", e);
      return false;
    }
  }, [onboard, initOnboard]);

  const changeWallet = useCallback(async () => {
    try {
      const newOnboard = onboard ? onboard : await initOnboard();
      await newOnboard.walletSelect();
      const loggedIn = await newOnboard.walletCheck();
      return loggedIn;
    } catch (e) {
      console.log("web3 provider error", e);
      return false;
    }
  }, [onboard, initOnboard]);

  useEffect(() => {
    const previouslySelectedWallet = localStorage.getItem("selectedWallet-1");
    if (previouslySelectedWallet) {
      callWeb3FromProvider();
    }
  }, [callWeb3FromProvider]);

  const value = useMemo(() => {
    return {
      onboard,
      initOnboard,
      changeWallet,
      callWeb3FromProvider,
    };
  }, [onboard, initOnboard, changeWallet, callWeb3FromProvider]);

  return <OnboardContext.Provider value={value}>{children}</OnboardContext.Provider>;
};

const wallets = [
  { walletName: "metamask", preferred: true, rpcUrl: process.env.REACT_APP_RPC_URL },
  {
    walletName: "walletConnect",
    preferred: true,
    infuraKey: process.env.REACT_APP_INFURA_API_KEY,
  },
  {
    walletName: "walletLink",
    rpcUrl: process.env.REACT_APP_RPC_URL,
    label: "Coinbase",
    appName: "Teller Finance",
    preferred: true,
  },
  { walletName: "coinbase", preferred: true },
  { walletName: "fortmatic", preferred: true },
  { walletName: "portis", preferred: true },
  { walletName: "trust", preferred: true, rpcUrl: process.env.REACT_APP_RPC_URL },
];

export const BlockNativeOptions = {
  dappId: process.env.REACT_APP_BLOCKNATIVE_API_KEY,
  networkId: Number(process.env.REACT_APP_BLOCKNATIVE_NETWORK),
};

export const useOnboardContext: () => {
  onboard: any;
  initOnboard: any;
  changeWallet: any;
  callWeb3FromProvider: any;
} = () => {
  const context = useContext(OnboardContext);
  if (context === undefined) {
    throw new Error("useOnboard must be used within a useOnboardProvider");
  }
  return context;
};
