import "./footer.scss";

import React from "react";
import { BrowserRouter as Router, Link, NavLink, Route, Switch } from "react-router-dom";
import { useBlockNumberContext } from "contexts/global/blockNumberContext";
import useDarkNav from "hooks/useDarkNav";

const Footer = () => {
  const { blockNumber } = useBlockNumberContext();
  const latestBlock = blockNumber;

  const subgraphOn = "Online"; //TODO

  const { darkNav } = useDarkNav();
  return (
    <div className={"footer flex items-center  "}>
      <div
        className={`max-w-7xl mx-auto px-4 sm:px-12 md:px-16 text-xs sm:text-sm flex flex-row xs:flex-col-reverse justify-between flex-1 items-center my-2 xs:items-start xs:mx-4 ${
          darkNav ? "text-white hover:text-white" : "text-lightest-gray"
        }`}
      >
        <div className="flex flex-row xs:flex-col justify-start my-2">
          <div className="flex flex-row items-center pr-4">
            <div className={`status-circle mr-2 ${latestBlock ? "success" : "bg-gray-light"} `} />
            <div> Latest block {latestBlock && `: ${latestBlock}`} </div>
          </div>
          <div className="flex flex-row items-center">
            <div
              className={`status-circle mr-2 ${
                subgraphOn === null ? "bg-gray-light" : subgraphOn ? "success" : "error"
              }`}
            />
            <div> The Graph {subgraphOn !== null && `: ${subgraphOn ? "Online" : "Offline"}`} </div>
          </div>
        </div>
        <Link style={{ textDecoration: "none" }} to={"/stats"} className="xs:my-2">
          <div className={darkNav ? "" : "link"}>Global stats</div>
        </Link>
      </div>
    </div>
  );
};

export default Footer;
