import React from "react";

import { useNFTsContext } from "contexts/global/nftsContext";
import { useWeb3StateContext } from "contexts/global/web3StateContext";

import ClaimNFTModal from "pages/NFTDashboard/ClaimNFTModal";
import useModal from "hooks/useModal";
import Button from "components/Button";

export const NavNFTButton = () => {
  const { allNFTsFromDistribution, totalClaimed, totalUnclaimed } = useNFTsContext();
  const { network, userAddress } = useWeb3StateContext();

  const disabled = !userAddress || !allNFTsFromDistribution || allNFTsFromDistribution.length < 1;
  totalClaimed == null || (network.id != 42 && network.id != 1);
  const allWasClaimed = totalUnclaimed == 0 && totalClaimed > 0;

  const { closeModal, modalOpened, setModalOpened } = useModal();

  const openClaimModal = () => {
    if (disabled) return;
    setModalOpened(true);
  };
  const claimButtonCopy = allWasClaimed
    ? `${totalClaimed} NFT${totalClaimed > 1 ? "s" : ""}`
    : "Claim NFT";

  return (
    <>
      {
        <Button
          color="orange"
          type="primary"
          text={claimButtonCopy}
          size="md"
          CTA={openClaimModal}
          disabled={disabled}
        />
      }
      {modalOpened && <ClaimNFTModal closeModal={closeModal} />}
    </>
  );
};
