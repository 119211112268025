import React, { useContext } from "react";

import BR from "components/UI/BR";
import CustomSubmenuLink from "components/UI/CustomSubmenuLink";
import TableRow from "components/UI/TableRow";
import copy from "constants/copy.json";
import { DashboardContext } from "contexts/dashboard/dashboardContext";
import { LoanInterface } from "contexts/types";
import { SushiswapContext } from "contexts/dashboard/sushiswapContext";
import { roundToFourDecimals } from "utils/helperFunctions";

const SushiswapLoansList = () => {
  const { loans } = useContext(DashboardContext);
  const { setSelectedLoan } = useContext(SushiswapContext);
  const escrowLoans = loans
    ? loans.filter((loan: LoanInterface) => {
        return (
          loan?.escrow !== "0x0000000000000000000000000000000000000000" &&
          loan?.loanType !== "Unsecured" &&
          !loan?.isTerms &&
          loan?.statusName !== "Repaid"
        );
      })
    : null;
  const { description } = copy.pages.dashboard["spend-sushiswap"];

  return (
    <div className="my-4 flex-1">
      <div className="text-gray mb-2">{description}</div>
      <div className="text-gray text-sm">
        {escrowLoans.length < 1 && "You can only use Secured loans with <135% collateral"}
      </div>
      {escrowLoans.length > 0 && (
        <div className="border-thin my-4">
          {escrowLoans.map((loan: LoanInterface, i: any) => {
            return (
              <div key={loan?.id}>
                <TableRow title={`${loan?.id}`}>
                  <CustomSubmenuLink
                    title={`${roundToFourDecimals(loan?.borrowedAmountNumber)} ${loan?.token}`}
                    CTA={() => {
                      setSelectedLoan(loan);
                    }}
                  />
                </TableRow>
                {escrowLoans.length - 1 !== i && <BR />}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SushiswapLoansList;
