import React from "react";

import Arrow from "components/UI/Arrow";

type loanSelectCardProps = {
  onClick: () => void;
  title: string;
  subTitle: string;
  className?: string;
  logos: Array<string>;
};
const LoanSelectCard = ({ onClick, title, subTitle, className = "" }: loanSelectCardProps) => {
  return (
    <div
      className={`pointer p-4 md:p-8 bg-white rounded-lg flex flex-row text-left items-center	 ${className}`}
      onClick={() => {
        onClick();
      }}
    >
      <div className="mr-4 ml-2">
        <div className="font-medium text-xl xs:text-lg">{title}</div>
        <div className="my-3">{subTitle}</div>
      </div>
      <Arrow direction="right" />
    </div>
  );
};

export default LoanSelectCard;
