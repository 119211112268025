import { useNFTsContext } from "contexts/global/nftsContext";
import { useWeb3StateContext } from "contexts/global/web3StateContext";
import { createNewTransactionWithHandler } from "actions/utils";
import React, { useCallback, useMemo } from "react";
import { useTellerContext } from "contexts/global/tellerContext";
interface ClaimParams {
  merkleIndex: number;
  nodeIndex: number;
  amount: string;
  merkleProof: string[];
}
export const useClaimNFT = () => {
  const { unclaimedNFTs } = useNFTsContext();
  const integrationsDisabled = false; //useIntegrationsDisabled(); TODO
  const { NFTDistributorContract } = useTellerContext();
  const { userAddress } = useWeb3StateContext();

  const claimParams: ClaimParams[] = useMemo(() => {
    if (!unclaimedNFTs) return [];
    return unclaimedNFTs.map((nft: any) => {
      const claimData = {} as ClaimParams;
      claimData.merkleIndex = nft.merkleIndex;
      claimData.nodeIndex = nft.nodeIndex;
      claimData.amount = nft.amount;
      claimData.merkleProof = nft.merkleProof;
      return claimData;
    });
  }, [unclaimedNFTs]);

  const claimNFT = useCallback(
    async (transactionHandler: any) => {
      if (!NFTDistributorContract) return;

      await createNewTransactionWithHandler(
        () =>
          NFTDistributorContract.methods
            .claim(userAddress, claimParams)
            .send({ from: userAddress }),
        transactionHandler,
      );
    },
    [userAddress, claimParams, NFTDistributorContract],
  );

  return claimNFT;
};
